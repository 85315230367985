import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { useResourceCreate, useLocale, useComponentVisibility } from '@koopajs/react';
import { IconButton, Box } from '@mui/material';
import { TrackEvent } from '@koopajs/app';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@mui/icons-material';
import { useMeetingCreateForm } from '../hooks/useMeetingCreateForm';
import { MeetingCreateForm } from '../Meetings/MeetingCreateForm';

interface ICreateMeetingDialogProps {
  isOpen: boolean;
  onClose: (() => Promise<void>) | (() => void);
}

export const CreateMeetingDialog: React.FC<ICreateMeetingDialogProps> = (props) => {
  const { isOpen, onClose } = props;

  const { t } = useLocale();
  const navMenu = useComponentVisibility('navMenu');

  const keyPrefix = 'Dialogs.CreateMeeting';
  const { handleFormData } = useMeetingCreateForm();

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { createResource, errorMessage } = useResourceCreate({ path: '/meetings' });

  const handleSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const formDataTyped = formData as {
      meetingTemplateId?: string;
      lastMeetingId?: string;
      templateType: 'none' | 'last-meeting' | 'another-meeting';
    };

    switch (formDataTyped.templateType) {
      case 'last-meeting':
        formDataTyped.meetingTemplateId = formDataTyped.lastMeetingId;
        break;
      case 'none':
        formDataTyped.meetingTemplateId = undefined;
        break;
    }

    const form = handleFormData(formDataTyped);

    let meetingId = '';
    const res = await createResource(form, (id) => {
      meetingId = id;
    });

    if (!res) {
      // TODO: do we need the snackbar if we have the error message component?
      enqueueSnackbar(t(keyPrefix + '.snackbarCreateMeetingFail'), {
        variant: 'error',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('create-meeting-fail');
    } else {
      navMenu.setHidden();
      await onClose();
      history.push(`/meetings/${meetingId}/edit`);
    }

    return res;
  }, []);

  return (
    <Dialog.View
      isCloseVisible={false}
      isOpen={isOpen}
      onClose={onClose}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      maxWidth="md"
      forcedFullScreen={false}
      dialogProps={{ className: 'rr-block' }}
    >
      <MeetingCreateForm
        onClose={onClose}
        onSubmit={handleSubmit}
        errorMessage={errorMessage}
        meetingState="create"
      />
      <Box sx={{ p: 2 }} />
    </Dialog.View>
  );
};
