import { IResolution, IMinute } from 'types';
import { ToSignItem } from './ToSignItem';
import { Box } from '@mui/material';

interface IToSignListProps {
  itemsToSign: (IMinute | IResolution)[];
  isSignable?: boolean;
}

export const ToSignList: React.FC<IToSignListProps> = (props) => {
  const { itemsToSign, isSignable } = props;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {itemsToSign.map((item) => {
        return <ToSignItem itemToSign={item} isSignable={isSignable} key={item.id} />;
      })}
    </Box>
  );
};
