import { useLocale, usePermissions } from '@koopajs/react';
import { TablePagination, Stack, Box } from '@mui/material';
import { PastMeetingCard } from './PastMeetingCard';
import { useCallback } from 'react';
import { IMeeting, IUploadedMeeting } from 'types';
import { UploadMeetingButton } from 'components/UploadedMeeting/UploadMeetingButton';
import { UploadedMeetingCard } from './UploadedMeetingCard';

interface IPastMeetingsListProps {
  meetings: IMeeting[] | IUploadedMeeting[];
  size?: number;
  page?: number;
  totalCount?: number;
  isReloading: boolean;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
}

export const PastMeetingsList: React.FC<IPastMeetingsListProps> = (props) => {
  const { meetings, size, page, totalCount, isReloading, onPageChange, onSizeChange } = props;

  const { t } = useLocale();

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const handlePageChange = useCallback(
    (e: unknown, newPage: number) => {
      onPageChange(newPage);
    },
    [page]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSizeChange(Number(e.target.value)),
    []
  );

  return (
    <>
      <Stack sx={{ opacity: isReloading ? 0.35 : 1 }} spacing={3}>
        {meetings.map((meeting) => {
          if (meeting.$model === 'uploaded-meeting') {
            return <UploadedMeetingCard key={meeting.id} uploadedMeeting={meeting} />;
          } else {
            return <PastMeetingCard key={meeting.id} pastMeeting={meeting} />;
          }
        })}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: isUserWorkspaceOwner ? 'space-between' : 'flex-end',
          alignItems: { xs: 'flex-start', sm: 'center' },
          flexDirection: { xs: 'column-reverse', sm: 'row' }
        }}
      >
        {isUserWorkspaceOwner && <UploadMeetingButton isProcessing={isReloading} />}

        <TablePagination
          rowsPerPageOptions={[5, 20, 50]}
          component="div"
          count={totalCount || 999}
          rowsPerPage={size || 20}
          page={page ? page : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleSizeChange}
          SelectProps={{
            native: true
          }}
          // eslint-disable-next-line react/jsx-no-bind
          labelDisplayedRows={({ from, to, count }) => {
            const transOptions = { from, to, count };

            return count !== -1
              ? t('common:TablePagination.labelDisplayedRowsCount', transOptions)
              : t('common:TablePagination.labelDisplayedRowsMoreThan', transOptions);
          }}
          labelRowsPerPage={t('common:TablePagination.labelRowsPerPage')}
        />
      </Box>
    </>
  );
};
