/* eslint-disable react/jsx-no-bind */
import React, { InputHTMLAttributes, useContext } from 'react';
import { FormContext } from '@koopajs/react';
import { Controller, RegisterOptions, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ICoreProps } from '@koopajs/mui';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox as CheckboxMUI,
  FormHelperText,
  Box
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export interface IControlledCheckboxProps extends ICoreProps {
  name: string;
  isOptional?: boolean;
  value?: {
    checkedValue: string | number | boolean;
    uncheckedValue: string | number | boolean;
  };
  color?: 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  validationRules?: RegisterOptions;
  testId?: string;
  sxFormControlLabel?: SxProps<Theme>;
}

interface ICheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
}

export const ControlledCheckbox: React.FC<IControlledCheckboxProps> = (props) => {
  const {
    isLoading,
    validationRules,
    isDisabled,
    isOptional,
    sx,
    name,
    color,
    variant,
    i18n,
    sxFormControlLabel,
    testId,
    value
  } = props;
  const context = useContext(FormContext);
  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'FormCheckbox' });

  if (!context.form) {
    throw new Error('"Form.Checkbox" component needs to be use inside a FormController');
  }

  function resolveErrors(obj: any, path: string): any {
    // split the path into parts, handling indices within brackets
    const pathParts = path.split('.').flatMap((part) => {
      const match = part.match(/(\w+)\[(\d+)\]/);
      if (match) {
        // if the part has an array index, return it split into two parts
        return [match[1], Number(match[2])];
      } else {
        // otherwise, return the part as-is
        return [part];
      }
    });

    // iterate over the parts to navigate the object safely
    return pathParts.reduce((acc, key) => {
      if (acc && acc.hasOwnProperty(key)) {
        return acc[key];
      } else {
        return undefined;
      }
    }, obj);
  }

  const errors = resolveErrors(context.form.formState.errors, name);

  const handleCheckboxChange = ({
    e,
    field
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    field: ControllerRenderProps<FieldValues, string>;
  }): void => {
    if (value) {
      const reactHookFormValue = e.target.checked ? value.checkedValue : value.uncheckedValue;
      // context.form?.setValue(name, reactHookFormValue);
      field.onChange(reactHookFormValue);
    } else {
      field.onChange(e);
    }
  };

  return (
    <FormControl
      fullWidth
      margin={variant === 'dense' ? 'dense' : 'normal'}
      disabled={context.isProcessing || isLoading || isDisabled}
      error={Boolean(errors)}
      sx={sx}
    >
      <Controller
        render={({ field }) => {
          return (
            <FormControlLabel
              label={t('label')}
              componentsProps={{
                typography: {
                  color: errors && 'error'
                }
              }}
              sx={sxFormControlLabel}
              control={
                <CheckboxMUI
                  {...field}
                  name={field.name}
                  // if value is passed checked state view is based on value, else it is a boolean
                  checked={value ? field.value === value.checkedValue : Boolean(field.value)}
                  onChange={(e) => {
                    handleCheckboxChange({ e, field });
                  }}
                  inputProps={{ 'data-cy': testId || `koopa_checkbox_${name}` } as ICheckboxInputProps}
                  color={color || 'primary'}
                  size={variant === 'dense' ? 'small' : 'medium'}
                />
              }
            />
          );
        }}
        name={name}
        rules={{ required: !isOptional, ...validationRules }}
        control={context.form.control}
      />
      <FormHelperText>{errors ? t('errorText') : t('helperText')}</FormHelperText>
    </FormControl>
  );
};
