import { useLocale } from '@koopajs/react';
import { Box, Typography } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { IMeeting } from 'types';
import { MeetingCard } from './MeetingCard';

interface IMeetingCards {
  meetings: IMeeting[];
}

export const MeetingCards: React.FC<IMeetingCards> = (props) => {
  const { meetings: meetingsProp } = props;

  const { t, locale } = useLocale();

  const meetingsByMonthArray = useMemo(() => {
    // sort meetings into MM YYYY buckets
    const meetingsByMonthObject: { [k: string]: IMeeting[] } = {};
    meetingsProp.forEach((meeting) => {
      const formattedDate = meeting.startDateTime
        ? DateTime.fromISO(meeting.startDateTime).startOf('month').toISO()
        : '';

      if (meetingsByMonthObject[formattedDate]) {
        meetingsByMonthObject[formattedDate].push(meeting);
      } else {
        meetingsByMonthObject[formattedDate] = [meeting];
      }
    });

    // turn monthly buckets object into array and sort
    const meetingsByMonthArray: { date: string; meetings: IMeeting[] }[] = [];
    for (const [date, meetings] of Object.entries(meetingsByMonthObject)) {
      meetingsByMonthArray.push({ date: date, meetings: meetings });
    }
    return meetingsByMonthArray.sort((a, b) => {
      const dateA = a.date ? new Date(a.date).valueOf() : Infinity;
      const dateB = b.date ? new Date(b.date).valueOf() : Infinity;
      return dateA - dateB;
    });
  }, [meetingsProp]);

  return (
    <Box>
      {meetingsByMonthArray.map((meetingsByMonth) => {
        const { date, meetings } = meetingsByMonth;

        const formattedDate = DateTime.fromISO(date).setLocale(locale).toFormat('LLLL yyyy');

        const capitalizeMonth = (formattedDate: string): string => {
          return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        };

        return (
          <Box key={date}>
            <Typography sx={{ display: 'flex', alignItems: 'center', mb: '14px', mt: '24px' }}>
              <CalendarToday sx={{ mr: '8px', opacity: '0.6' }} />
              {date ? capitalizeMonth(formattedDate) : t('common:noDate')}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {meetings
                .sort((a, b) => {
                  const dateA: any = a.startDateTime ? new Date(a.startDateTime).valueOf() : '';
                  const dateB: any = b.startDateTime ? new Date(b.startDateTime).valueOf() : '';
                  return dateA - dateB;
                })
                .map((meeting) => {
                  return (
                    <Box key={meeting.id} sx={{ minWidth: '0px', pb: '24px', flexBasis: '100%' }}>
                      <MeetingCard meeting={meeting} />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
