import React, { useCallback, useMemo } from 'react';
import { Dialog } from '@koopajs/mui';
import { Typography, Button, Box, Container } from '@mui/material';
import questionMark from '../../assets/question-mark.png';
import { useComponentVisibility } from '@koopajs/react';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';
import { processUsersForInput } from 'utils/processUsersForInput';
import { ITopicResolutionOutcome } from 'types';

interface INoResolutionBookSwitchModal {
  isOpen: boolean;
  handleOnClose: () => void;
  liveMeeting: IUseLiveMeetingResponse;
}

export const NoResolutionBookSwitchModal: React.FC<INoResolutionBookSwitchModal> = (props) => {
  const { isOpen, handleOnClose, liveMeeting } = props;
  const { t, nextTopicId, handleChangeCurrentTopic, handleMeetingPermanentlyFinished, topic, users } =
    liveMeeting;

  const keyPrefix = 'Modals.NoResolutionBookSwitchModal.';

  const handleContinue = useCallback(() => {
    if (nextTopicId) {
      handleChangeCurrentTopic(nextTopicId);
    }
  }, [nextTopicId]);

  const handleEndMeeting = useCallback(async () => {
    await handleMeetingPermanentlyFinished();
  }, []);

  type UserArrayForInput = { label: string; id: string }[];
  let dialogDefaultValues: {
    title?: string;
    notes?: string;
    resolutionOutcome?: ITopicResolutionOutcome<UserArrayForInput>;
  } = {};
  // formatting for resource picker default values
  const membersVotes = useMemo(() => {
    const returnObj: {
      membersFor?: UserArrayForInput;
      membersAbstained?: UserArrayForInput;
      membersAgainst?: UserArrayForInput;
    } = {};
    if (topic?.resolutionOutcome?.membersFor?.length)
      returnObj.membersFor = processUsersForInput({
        users,
        selectedUserIds: topic?.resolutionOutcome?.membersFor
      });
    if (topic?.resolutionOutcome?.membersAbstained?.length)
      returnObj.membersAbstained = processUsersForInput({
        users,
        selectedUserIds: topic?.resolutionOutcome?.membersAbstained
      });
    if (topic?.resolutionOutcome?.membersAgainst?.length)
      returnObj.membersAgainst = processUsersForInput({
        users,
        selectedUserIds: topic?.resolutionOutcome?.membersAgainst
      });
    return returnObj;
  }, [users.length, JSON.stringify(topic?.resolutionOutcome)]);

  dialogDefaultValues = {
    resolutionOutcome: {
      isUnanimous: topic?.resolutionOutcome?.isUnanimous,
      outcome: topic?.resolutionOutcome?.outcome || 'approved',
      mover: topic?.resolutionOutcome?.mover,
      seconder: topic?.resolutionOutcome?.seconder,
      ...membersVotes
    }
  };

  const showDialog = useComponentVisibility('recordVote');

  const handleShowRecordVote = useCallback(() => {
    handleOnClose();
    showDialog.setVisibleWithContext({
      liveMeeting: props.liveMeeting,
      defaultValues: dialogDefaultValues,
      isResolutionBookSwitchChecked: true
    });
  }, [JSON.stringify(props.liveMeeting)]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleOnClose}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'no-resolution-book-switch-modal' } }}
    >
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <Box>
          <img src={questionMark} srcSet="" loading="lazy" style={{ width: '8%' }} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ m: 2 }}>
            {t(keyPrefix + 'title')}
          </Typography>
          <Typography sx={{ m: 2 }}>{t(keyPrefix + 'description')}</Typography>
        </Box>

        {/* Showing the record vote dialog */}
        <Button
          sx={{ px: 8, mx: 1 }}
          variant="outlined"
          onClick={handleShowRecordVote}
          data-cy="no-resolution-book-switch-modal_add-to-book-button"
        >
          {t(keyPrefix + 'labelAddToBook')}
        </Button>

        {/* Letting the user go to the next topic */}
        {nextTopicId ? (
          <Button
            sx={{ px: 8, mx: 1 }}
            variant="contained"
            onClick={handleContinue}
            data-cy="no-resolution-book-switch-modal_continue-meeting-button"
          >
            {t('common:continue')}
          </Button>
        ) : (
          <Button
            sx={{ px: 8, mx: 1 }}
            variant="contained"
            onClick={handleEndMeeting}
            data-cy="no-resolution-book-switch-modal_end-meeting-button"
          >
            {t('MeetingStateInProgress.labelEndMeeting')}
          </Button>
        )}
      </Container>
    </Dialog.View>
  );
};
