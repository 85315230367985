import { useLocale, useParamsKey, useResourceList } from '@koopajs/react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Stack, Button } from '@mui/material';
import { PageContainer } from 'components/temp/PageContainer';
import { IDocumentRecord } from 'types';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { camelCase } from 'lodash';
import { useResourceListQuery } from 'utils/useResourceListQuery';
import { DocumentsTable } from 'components/DocumentRecords/DocumentsTable';
import { useUploadDocumentRecordPermissions } from 'utils/DocumentRecords/useUploadDocumentRecordPermissions';
import { AddDocumentRecordButton } from 'components/DocumentRecords/AddDocumentRecordButton';
import { Loading } from '@koopajs/mui';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useFeatureGate } from '@statsig/react-bindings';
import { FeatureLocked } from 'components/FeatureLocked';

export const DocumentRecordsCategory: React.FC = () => {
  const categoryKebabCase = useParamsKey('key');

  const category = camelCase(categoryKebabCase);

  const { t } = useLocale();

  const { search, handlePageChange, handleSizeChange } = useResourceListQuery();

  const { canUserUploadDocuments, committeesUserCanUpload } = useUploadDocumentRecordPermissions();

  const { value: isDocumentRecordsEnabled } = useFeatureGate('document-records-preview');

  const {
    resources: documentRecords,
    state,
    isProcessing,
    totalCount
  } = useResourceList<IDocumentRecord>({
    path: '/document-records',
    searchParams: {
      sort: 'lastDocumentActiveSince:DESC',
      filters: [`category:${category}`],
      size: 10,
      ...search.searchParams
    }
  });

  if (!isDocumentRecordsEnabled) {
    return <FeatureLocked />;
  }

  if (state === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  return (
    <>
      <Helmet>
        <title>{`${t(`common:documentRecordCategories.${category}`)} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ p: '24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Breadcrumbs
            hierarchyArray={[{ path: '/document-records', text: t('common:navigation.documents') }]}
            pageTitle={t(`common:documentRecordCategories.${category}`)}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'space-between' },
              alignItems: { xs: 'flex-start' },
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 3
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                component={RouterLink}
                to="/document-records"
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>
              <Typography variant="h1" sx={{ fontSize: '24px' }}>
                {t(`common:documentRecordCategories.${category}`)}
              </Typography>
            </Box>
            {canUserUploadDocuments ? (
              <AddDocumentRecordButton
                committees={committeesUserCanUpload}
                defaultValues={{ category }}
                isCategoryFieldDisabled
              />
            ) : (
              // hidden button to reduce layout shift while we wait for permissions request
              <Button sx={{ visibility: 'hidden' }}>.</Button>
            )}
          </Box>

          <DocumentsTable
            documentRecords={documentRecords}
            totalCount={totalCount}
            search={search}
            listState={state}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            canUserUploadDocuments={canUserUploadDocuments}
            committeesUserCanUploadTo={committeesUserCanUpload}
            addDocumentRecordButtonProps={{ defaultValues: { category }, isCategoryFieldDisabled: true }}
            isCategoryColumnHidden={true}
          />
          <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
            {/* desktop 'back' button */}
            <Button
              component={RouterLink}
              to="/document-records"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {t('common:labelBack')}
            </Button>
            {/* mobile back arrow button */}
            <Button
              component={RouterLink}
              to="/document-records"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
