import { IMeeting } from 'types';
import { Dialog } from '@koopajs/mui';
import { MeetingReviewForDialog } from 'components/Meetings/MeetingReviewForDialog';

interface IPastMeetingApprovalDialogProps {
  isOpen: boolean;
  onClose: (() => Promise<void>) | (() => void);
  minutes: IMeeting;
}

export const PastMeetingApprovalDialog: React.FC<IPastMeetingApprovalDialogProps> = (props) => {
  const { minutes, isOpen, onClose } = props;

  return (
    <Dialog.View
      isCloseVisible={true}
      isOpen={isOpen}
      onClose={onClose}
      i18n={{ keyPrefix: 'Dialogs.PastMeetingApprovalDialog.DialogView' }}
    >
      <MeetingReviewForDialog meeting={minutes} isViewMode={true} />
    </Dialog.View>
  );
};
