import { DateTime } from 'luxon';

export const dateTimestamps: { [k: string]: () => number } = {
  getTodayStart: () => DateTime.now().startOf('day').toMillis(),
  getTodayEnd: () => DateTime.now().endOf('day').toMillis(),
  getYesterdayStart: () => DateTime.now().minus({ day: 1 }).startOf('day').toMillis(),
  getYesterdayEnd: () => DateTime.now().minus({ day: 1 }).endOf('day').toMillis(),
  getPastWeek: () => DateTime.now().minus({ day: 7 }).startOf('day').toMillis(),
  getPastMonth: () => DateTime.now().minus({ day: 30 }).startOf('day').toMillis(),
  // getPastMonth: () => DateTime.now().minus({ month: 1 }).startOf('day').toMillis(),
  getPastYear: () => DateTime.now().minus({ year: 1 }).startOf('day').toMillis()
};

export const dateFilters: { generateFilter: () => string; label: string; key: string }[] = [
  // {
  //   generateFilter: () => `${dateTimestamps.getTodayStart()} TO ${dateTimestamps.getTodayEnd()}`,
  //   label: 'Today',
  //   key: 'today'
  // },
  // {
  //   generateFilter: () => `${dateTimestamps.getYesterdayStart()} TO ${dateTimestamps.getYesterdayEnd()}`,
  //   label: 'Yesterday',
  //   key: 'yesterday'
  // },
  {
    generateFilter: () => `${dateTimestamps.getPastWeek()} TO ${dateTimestamps.getTodayEnd()}`,
    label: 'Past week',
    key: 'pastWeek'
  },
  {
    generateFilter: () => `${dateTimestamps.getPastMonth()} TO ${dateTimestamps.getTodayEnd()}`,
    label: 'Past 30 days',
    key: 'pastMonth'
  },
  {
    generateFilter: () => `${dateTimestamps.getPastYear()} TO ${dateTimestamps.getTodayEnd()}`,
    label: 'Past year',
    key: 'pastYear'
  }
];
