import { IUseLiveMeetingResponse } from '../../useLiveMeeting';
import { RecordVoteBanner } from './RecordVoteBanner';
import { RecordVoteButton } from './RecordVoteButton';

interface IRecordVoteProps {
  liveMeeting: IUseLiveMeetingResponse;
}

export const RecordVote: React.FC<IRecordVoteProps> = (props) => {
  const { liveMeeting } = props;

  const { topic, isCommitteeTakingResolutions } = liveMeeting;

  return (
    <>
      {topic?.resolutionOutcome?.outcome ? (
        <RecordVoteBanner liveMeeting={props.liveMeeting} />
      ) : (
        <RecordVoteButton liveMeeting={props.liveMeeting} />
      )}
    </>
  );
};
