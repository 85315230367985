import { useLocale } from '@koopajs/react';
import { DateFormat, formatDate } from 'utils/DateTime/formatDate';
import { TimeStartEnd } from './TimeStartEnd';

interface IDateTimeStartEndProps {
  isoStringDate?: string;
  dateFormat?: DateFormat;
  separator?: string;
  isoStringStart?: string;
  isoStringEnd?: string;
  showNoDateNoTime?: boolean;
}

export const DateTimeStartEnd: React.FC<IDateTimeStartEndProps> = (props) => {
  const {
    isoStringDate,
    dateFormat,
    separator = ' ',
    isoStringStart,
    isoStringEnd,
    showNoDateNoTime
  } = props;
  const { t, locale } = useLocale();

  if (!isoStringDate && !isoStringStart && !isoStringEnd && !showNoDateNoTime) return t('common:noDate');

  return (
    <>
      {isoStringDate ? (
        <span>{formatDate({ isoString: isoStringDate, locale, format: dateFormat })}</span>
      ) : showNoDateNoTime ? (
        t('common:noDate')
      ) : null}
      {((isoStringDate && isoStringStart) || showNoDateNoTime) && <span>{separator}</span>}
      {isoStringStart ? (
        <span>
          <TimeStartEnd isoStringStart={isoStringStart} isoStringEnd={isoStringEnd} />
        </span>
      ) : showNoDateNoTime ? (
        t('common:noTime')
      ) : null}
    </>
  );
};
