import { ICommitteeMember } from 'types';
import { Container, Box, Breadcrumbs, Stack, Typography, Link, Paper, Avatar, Button } from '@mui/material';
import { useLocale, useParamsKey, useResourceShow } from '@koopajs/react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { IUserPublicProfile } from '@koopajs/app';
import { Loading } from '@koopajs/mui';
import { PageContainer } from 'components/temp/PageContainer';
import { useEffect, useState } from 'react';
import { Api } from '@koopajs/app';
import { EmptyState } from 'components/EmptyState';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { CommitteeMemberCard } from 'components/Organization/OrganizationMembers/MemberShow/CommitteMemberCard';

export const OrganizationMember: React.FC = () => {
  const userId = useParamsKey('id');

  const {
    resource: user,
    errorMessage,
    isProcessing
  } = useResourceShow<IUserPublicProfile>({
    path: '/organization-users',
    id: userId
  });

  const extractedErrorMessage = extractErrorMessage(errorMessage);

  const [committeeMembers, setCommitteeMembers] = useState<ICommitteeMember[]>([]);
  const [state, setState] = useState('loading');

  useEffect(() => {
    const fetchCommitteeMembers = async () => {
      const res = await Api.client.get(
        `/committee-members?size=50&filters[]=${encodeURIComponent(`userId:"${userId}"`)}`
      );
      return res;
    };
    fetchCommitteeMembers()
      .then((res) => {
        setCommitteeMembers(res.data.resources);
        setState('loaded');
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  const isRequiredSignature = committeeMembers.some((cM) => cM.roles?.some((r) => r.isOptional === false));

  const { t } = useLocale();
  const keyPrefix = 'OrganizationMember';

  if (isProcessing || state === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  if (errorMessage === 'Error 403: You do not have permission to view this user') {
    return (
      <EmptyState
        title={extractedErrorMessage}
        button={
          <Button component={RouterLink} to="/" variant="contained" disableElevation>
            {t('NotFound.EmptyState.goToDashboardButton')}
          </Button>
        }
      />
    );
  }
  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.organization')} - Panorama`}</title>
      </Helmet>

      <PageContainer sxChildren={{ padding: '24px', position: 'relative' }} className="rr-block">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to="/organization"
            sx={{ cursor: 'pointer' }}
          >
            {t('common:navigation.organization')}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to="/organization/members"
            sx={{ cursor: 'pointer' }}
          >
            {t('common:navigation.organizationMembers')}
          </Link>
          <Typography color="text.primary">{user?.username}</Typography>
        </Breadcrumbs>

        <Container sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3, px: 0 }}>
          {/* HEADER - AVATAR */}
          <Stack direction="row" alignItems="center">
            {user?.profilePicture && (
              <Avatar
                src={user?.profilePicture}
                sx={{ width: 60, height: 60, fontSize: '24px', mr: 3 }}
                alt={t('common:profilePhotoOf', { name: user.username })}
              >
                {user?.firstname?.[0]}
                {user?.lastname?.[0]}
              </Avatar>
            )}
            <Typography variant="h1" sx={{ fontSize: '24px' }} data-cy="organization_member-username_title">
              {user?.username}
            </Typography>
          </Stack>
          {/* HEADER - CONTACT */}
          <Paper variant="outlined" sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="body1" component="h3">
              {t(keyPrefix + '.contactCard.contact')}
            </Typography>
            <Typography variant="body1" sx={{ display: 'flex', fontSize: '14px' }}>
              <Box component="span" sx={{ width: { xs: '160px', lg: '224px' }, display: 'inline-block' }}>
                {t(keyPrefix + '.contactCard.email')}
              </Box>
              <Box component="span">{user?.email}</Box>
            </Typography>
          </Paper>
          {/* COMMITTEE CARDS */}
          <Stack direction="row" flexWrap="wrap" gap={3}>
            {state !== 'loaded' ? (
              <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
            ) : (
              committeeMembers?.map((committeeMember) => {
                return (
                  <CommitteeMemberCard
                    key={committeeMember.id}
                    committeeMember={committeeMember}
                    updateCommitteeMembers={setCommitteeMembers}
                  />
                );
              })
            )}
          </Stack>
          {isRequiredSignature && (
            <Typography variant="caption" color="textSecondary">
              {t(keyPrefix + '.requiredSignature')}
            </Typography>
          )}
        </Container>
      </PageContainer>
    </>
  );
};
