import { DateTime } from 'luxon';
import { IMeetingFormData } from '../../types';

interface IUseMeetingCreateFormResponse {
  handleFormData: (formData: object) => IMeetingFormData;
}

export const useMeetingCreateForm = (): IUseMeetingCreateFormResponse => {
  const handleFormData = (formData: object): IMeetingFormData => {
    const form = formData as {
      startDate?: string;
      endDate?: string;
      startTime?: string;
      endTime?: string;
      participants?: string[];
      location: { url?: string; type?: string; address?: string };
    };

    // adds http to url if missing
    if (form.location?.url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(form.location.url)) {
        form.location.url = 'http://' + form.location.url;
      }
    }

    if (!form.location.url) form.location.url = undefined;

    if (!form.location.address) form.location.address = undefined;

    if (form.location.type === 'inPerson') {
      form.location.url = undefined;
    }

    if (form.location.type === 'remote') {
      form.location.address = undefined;
    }

    const formValues = { ...form };

    if (formValues.startDate && formValues.startTime) {
      form.startDate = DateTime.fromFormat(
        `${formValues.startDate} ${formValues.startTime}`,
        'yyyy-MM-dd HH:mm'
      )
        .toUTC()
        .toFormat('yyyy-MM-dd');

      form.startTime = DateTime.fromFormat(
        `${formValues.startDate} ${formValues.startTime}`,
        'yyyy-MM-dd HH:mm'
      )
        .toUTC()
        .toFormat('HH:mm');
    } else if (formValues.startTime) {
      form.startTime = DateTime.fromFormat(formValues.startTime, 'HH:mm').toUTC().toFormat('HH:mm');
    }

    if (formValues.startDate && formValues.endTime) {
      form.endTime = DateTime.fromFormat(`${formValues.startDate} ${formValues.endTime}`, 'yyyy-MM-dd HH:mm')
        .toUTC()
        .toFormat('HH:mm');
    } else if (formValues.endTime) {
      form.endTime = DateTime.fromFormat(formValues.endTime, 'HH:mm').toUTC().toFormat('HH:mm');
    }

    if (formValues.startDate && formValues.endTime) {
      const formEndDate = DateTime.fromFormat(
        `${formValues.startDate} ${formValues.endTime}`,
        'yyyy-MM-dd HH:mm'
      )
        .toUTC()
        .toISODate();

      form.endDate = formEndDate;
    } else if (formValues.startDate) {
      form.endDate = form.startDate;
    } else {
      form.endDate = '';
    }

    const participants = form.participants?.map((participant: string) => ({ userId: participant }));

    // const date = form.startDateTime;
    //const timeStart = form.meetingStartTime;
    // const timeEnd = form.meetingEndTime;

    // let meetingDateForm;

    // if (date && timeStart) {
    //   meetingDateForm = new Date(`${date}T${timeStart}`).toISOString();
    // } else if (date) {
    //   meetingDateForm = new Date(date).toISOString();
    // } else {
    //   meetingDateForm = undefined;
    // }

    // const meetingEndForm = date && timeEnd ? new Date(`${date}T${timeEnd}`).toISOString() : undefined;

    return {
      ...form,
      participants: participants && participants.length ? participants : undefined
    };
  };
  return {
    handleFormData
  };
};
