import { AllTopicType, checkIsGenericTopicType } from 'utils/topicTypeArrays';
import { TopicChip } from './TopicChip';

interface ITopicChipConditionsProps {
  topicType: AllTopicType;
  isOutlined?: boolean;
  isPrintView?: boolean;
}

export const TopicChipConditions: React.FC<ITopicChipConditionsProps> = (props) => {
  const { topicType, isOutlined, isPrintView } = props;
  if (checkIsGenericTopicType(topicType)) {
    return <TopicChip topicType={topicType} isOutlined={isOutlined} isPrintView={isPrintView} />;
  } else if (topicType === 'ceoReport') {
    return <TopicChip topicType="information" isOutlined={isOutlined} isPrintView={isPrintView} />;
  } else if (['nominations', 'agendaApproval', 'pastMinutesApproval', 'adjournment'].includes(topicType)) {
    return <TopicChip topicType="resolution" isOutlined={isOutlined} isPrintView={isPrintView} />;
  } else {
    return null;
  }
};
