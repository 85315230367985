import { Box, Stack, Typography } from '@mui/material';
import { ISignatureWithTitle, IUserPublicProfileTemp } from 'types';
import { useLocale, User } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';

interface ISignatureListProps {
  signatures: ISignatureWithTitle[];
  description?: React.ReactNode;
}

export const SignatureList: React.FC<ISignatureListProps> = (props) => {
  const { signatures, description } = props;
  const { t } = useLocale();

  const keyPrefix = 'Components.SignatureList';

  return (
    <Box data-cy="signature-list">
      {description || t(keyPrefix + '.documentSignedBy')}
      {Boolean(signatures.length) && (
        <Stack
          gap={4}
          sx={{
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {signatures.map((signature) => {
            return (
              <Stack
                key={signature.userId}
                sx={{
                  width: { xs: '100%', md: 'calc(50% - 16px)' }, // 50% - (0.5 * gap)
                  textAlign: 'right'
                }}
                data-cy="signature"
              >
                <Box sx={{ maxWidth: '100%' }}>
                  <User id={signature.userId}>
                    {(profile: IUserPublicProfileTemp | undefined) => {
                      return (
                        <Stack gap={0.25}>
                          {profile?.signaturePicture ? (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                height: { md: '96px' }
                              }}
                            >
                              <Box
                                component="img"
                                src={profile?.signaturePicture}
                                alt={t(keyPrefix + '.signatureAlt', { username: profile?.username || '' })}
                                sx={{
                                  width: 'auto',
                                  height: 'auto',
                                  maxWidth: '100%',
                                  maxHeight: '96px',
                                  display: 'block',
                                  objectFit: 'contain',
                                  objectPosition: 'right center'
                                }}
                              />
                            </Box>
                          ) : (
                            <Stack sx={{ minHeight: { md: '96px' }, justifyContent: 'center' }}>
                              <Typography
                                sx={{
                                  fontFamily: "'Kalam', cursive",
                                  fontSize: '32px'
                                }}
                              >
                                {profile?.username}
                              </Typography>
                            </Stack>
                          )}
                          <Typography sx={{ fontWeight: 'bold' }}>{profile?.username}</Typography>
                          {signature.title && (
                            <Typography>{t(`common:committeeMemberTitles.${signature.title}`)}</Typography>
                          )}
                          <Typography variant="body2">
                            <DateTimeStartEnd
                              isoStringDate={signature.signedAt}
                              dateFormat="DATE_FULL"
                              separator={` ${t('common:at')} `}
                              isoStringStart={signature.signedAt}
                            />
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'block',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              fontSize: '10px'
                            }}
                          >
                            {t(keyPrefix + '.IpAddress')}: {signature.clientIp}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'block',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              fontSize: '10px'
                            }}
                          >
                            <Box>{t(keyPrefix + '.reference')}: </Box>
                            <Box>{signature.reference}</Box>
                          </Typography>
                        </Stack>
                      );
                    }}
                  </User>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Box>
  );
};
