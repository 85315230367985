import { Button, ListItem, Link, Stack, Box, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { IMeeting } from 'types';
import { useCallback, useState } from 'react';
import { PastMeetingApprovalDialog } from './PastMeetingApprovalDialog';
import { formatDate } from 'utils/DateTime/formatDate';
import { useLocale, useResourceDelete, useResourceShow } from '@koopajs/react';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

interface IPastMeetingApprovalItemProps {
  minutes: IMeeting;
  isViewPrint?: boolean;
  isEditable?: boolean;
  meetingId: string;
  topicId: string;
}

export const PastMeetingApprovalItem: React.FC<IPastMeetingApprovalItemProps> = (props) => {
  const { minutes, isViewPrint, isEditable, meetingId, topicId } = props;
  const { locale, t } = useLocale();

  const keyPrefix = 'Components.TopicCard.PastMeetingApprovalItem';

  const [isPastMeetingApprovalDialogOpen, setIsPastMeetingApprovalDialogOpen] = useState(false);
  const handleShowPastMeetingApprovalDialog = useCallback((value: boolean) => {
    return () => {
      setIsPastMeetingApprovalDialogOpen(value);
    };
  }, []);

  const { deleteResource, isProcessing: isDeleteProcessing } = useResourceDelete({
    path: `/meetings/${meetingId}/topics/${topicId}/minutes-to-approve`,
    id: minutes.id,
    customReducer: {
      path: { resourceType: '/meetings', resourceId: meetingId },
      mapping: (meeting: object, pastMeetingsToApprove: object) => {
        const oldMeeting = meeting as IMeeting;

        const oldPastMeetingsToApprove = oldMeeting.topics?.find(
          (topic) => topic.id === topicId
        )?.pastMeetingsToApprove;

        if (oldPastMeetingsToApprove) {
          const newPastMeetingsToApprove = oldPastMeetingsToApprove.filter(
            (pm) => pm.meetingId !== minutes.id
          );
          return {
            ...oldMeeting,
            topics: oldMeeting.topics?.map((t) => {
              if (t.id === topicId) {
                return {
                  ...t,
                  pastMeetingsToApprove: newPastMeetingsToApprove
                };
              }
              return t;
            })
          };
        }

        return oldMeeting;
      }
    }
  });

  // workaround for meeting processing state
  const { isProcessing: isMeetingProcessing } = useResourceShow({
    path: `/meetings`,
    id: meetingId,
    useCache: true
  });

  const committeeType = useCommitteeName(minutes?.committeeId);
  const date = formatDate({ isoString: minutes.startDate, locale, format: 'DATE_FULL' });

  return (
    <>
      <PastMeetingApprovalDialog
        minutes={minutes}
        isOpen={isPastMeetingApprovalDialogOpen}
        onClose={handleShowPastMeetingApprovalDialog(false)}
      />
      <ListItem
        disableGutters
        key={minutes.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 0,
          mb: { xs: 2, sm: 1 },
          '&&:last-child': { mb: 0 }
        }}
        data-cy="meeting-prepare_selected-past-minutes_list-item"
      >
        {isEditable ? (
          <Stack
            justifyContent="space-between"
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ width: '100%' }}
          >
            <Stack direction="row" alignItems="center" sx={{ whiteSpace: 'pre-wrap' }}>
              <AccessTime sx={{ mr: '8px' }} />
              {date}
              {' | '}
              {committeeType}
              {' | '}
              {minutes.title}
            </Stack>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="outlined"
                onClick={deleteResource}
                disabled={isDeleteProcessing || isMeetingProcessing}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                <Box component="span" sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                  {t(keyPrefix + '.removeFromAgenda')}
                </Box>
                <Box component="span" sx={{ display: { md: 'none' } }}>
                  {t('common:remove')}
                </Box>
              </Button>
            </Box>
          </Stack>
        ) : (
          <Button
            component={Link}
            onClick={handleShowPastMeetingApprovalDialog(true)}
            variant="outlined"
            sx={{
              color: isViewPrint ? 'inherit' : 'link.main',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'pre-wrap',
              borderColor: 'link.main',
              '&:hover': {
                borderColor: 'link.main'
              },
              borderRadius: '4px',
              border: '1px solid',
              textTransform: 'none',
              px: '10px',
              py: '4px'
            }}
            size="small"
          >
            <AccessTime fontSize="small" sx={{ mr: 1 }} />{' '}
            <Typography variant="body2">
              {date}
              {' | '}
              {committeeType}
              {' | '}
              {minutes.title}
            </Typography>
          </Button>
        )}
      </ListItem>
    </>
  );
};
