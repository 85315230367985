import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@koopajs/mui';
import { useResourceCreate, useComponentVisibility } from '@koopajs/react';
import { IMeeting, ITopicResolutionOutcome } from 'types';
import { RecordVoteDialogOptions } from './RecordVote/RecordVoteDialogOptions';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import { Box, Alert } from '@mui/material';
import { TextField } from 'components/temp/TextFieldTemp';

export const AddResolution: React.FC = () => {
  const keyPrefix = 'Dialogs.AddResolution';

  const dialog = useComponentVisibility('addResolution');
  const { liveMeeting } = dialog.getContext() as { liveMeeting: IUseLiveMeetingResponse };

  const {
    currentTopicId,
    meetingId,
    handleChangeCurrentTopic,
    isCommitteeTakingResolutions,
    topic,
    t,
    users,
    participants
  } = liveMeeting || {};

  const { createResource } = useResourceCreate({
    path: `/meetings/${meetingId}/topics/?nextTopicId=${currentTopicId}`,
    customReducer: {
      path: { resourceType: '/meetings', resourceId: meetingId },
      mapping: (oldMeeting: object, newMeeting: object) => {
        const newMeetingObject = newMeeting as IMeeting;
        return {
          ...oldMeeting,
          topics: newMeetingObject.topics
        };
      }
    }
  });

  const [isUnanimousSelected, setIsUnanimousSelected] = useState(false);

  useEffect(() => {
    // isUnanimousSelected will be true whenever the dialog is opened
    if (dialog.isVisible) {
      setIsUnanimousSelected(true);
    }
  }, [dialog.isVisible]);

  const handleSubmit = useCallback(
    async (formData: any): Promise<boolean> => {
      try {
        const payload: {
          type: 'resolution';
          title?: string;
          notes?: string;
          resolutionOutcome: ITopicResolutionOutcome;
          isResolution?: boolean;
        } = {
          type: 'resolution',
          resolutionOutcome: {}
        };

        if (isUnanimousSelected) {
          Object.assign(payload, {
            title: formData.title,
            notes: formData.notes,
            isResolution:
              formData.isResolution &&
              isCommitteeTakingResolutions &&
              formData.resolutionOutcome.outcome !== 'reported'
                ? true
                : false,
            resolutionOutcome: {
              isUnanimous: true,
              outcome: formData.resolutionOutcome.outcome,
              mover: formData.resolutionOutcome.mover,
              seconder: formData.resolutionOutcome.seconder
            }
          });
        } else {
          Object.assign(payload, formData);
          payload.resolutionOutcome.isUnanimous = false;
          payload.isResolution =
            formData.isResolution &&
            isCommitteeTakingResolutions &&
            formData.resolutionOutcome.outcome !== 'reported'
              ? true
              : false;
        }
        let newResolutionId: string | undefined;
        payload.resolutionOutcome.recordedAt = new Date().toISOString();
        const res = await createResource(payload, (id, updatedMeeting) => {
          const response = updatedMeeting as { meeting: IMeeting; newTopicId: string } | undefined;
          newResolutionId = response?.newTopicId;
        });
        if (res) {
          handleChangeCurrentTopic(newResolutionId as string);
        }
        return res;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    [currentTopicId, isUnanimousSelected]
  );

  return (
    <>
      <Dialog.Form
        dialogKey="addResolution"
        onSubmit={handleSubmit}
        isCancelVisible
        i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
        maxWidth="lg"
        dialogProps={{ className: 'rr-block' }}
      >
        {topic?.type === 'inCamera' && <Alert severity="warning">{t(keyPrefix + '.inCameraAlert')}</Alert>}
        <TextField
          i18n={{ keyPrefix: keyPrefix + '.TextFieldTitle' }}
          name="title"
          validationRules={{ maxLength: 150 }}
          sx={{ width: '100%' }}
        />
        <Box sx={{ mt: '12px' }}>
          <RichTextMultiLine
            name="notes"
            isOptional={true}
            validationRules={{ maxLength: 20000 }}
            height="unset"
            minHeight={`${4 * 23 + 16.5 * 2}px`} // (rows * 23px) + (padding * 2)
            maxHeight={`${20 * 23 + 16.5 * 2}px`}
            i18n={{ keyPrefix: 'MeetingStateInProgress.RichTextMultiLineNotes' }}
          />
        </Box>
        <RecordVoteDialogOptions
          participants={participants}
          topic={topic}
          isCommitteeTakingResolutions={isCommitteeTakingResolutions}
          isUnanimousSelected={isUnanimousSelected}
          isAddResolutionDialog={true}
          setIsUnanimousSelected={setIsUnanimousSelected}
          users={users}
        />
      </Dialog.Form>
    </>
  );
};
