import { useLocale } from '@koopajs/react';
import { Stack, Typography, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { AddDocument } from '../Form/Fields/AddDocument';
import React from 'react';

interface ITopicTitleProps {
  liveMeeting: IUseLiveMeetingResponse;
  addDocumentComponent?: React.ReactNode;
}

export const TopicTitle: React.FC<ITopicTitleProps> = (props) => {
  const { liveMeeting, addDocumentComponent } = props;

  const { topic } = liveMeeting;

  const { t } = useLocale();

  return (
    <Stack direction={{ sm: 'row' }} alignItems="space-between">
      <Stack direction="row" alignItems="center" sx={{ minWidth: 0 }}>
        {topic?.type === 'inCamera' && <LockIcon fontSize="small" />}
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontSize: 24, textOverflow: 'ellipsis', overflow: 'hidden' }}
          data-cy="meeting-in-progress_topic-title"
        >
          {topic?.title || t('common:topicTypes.' + topic?.type)}
        </Typography>
      </Stack>
      {addDocumentComponent && <Box sx={{ ml: 'auto' }}>{addDocumentComponent}</Box>}
    </Stack>
  );
};
