import { PermissionRoleChip } from './PermissionRoleChip';
import { CommitteeMemberRole } from 'types';
import { Stack, Box } from '@mui/material';

// new array to dictate the order of the roles
export const committeeMemberRolesOrdered: [
  CommitteeMemberRole,
  CommitteeMemberRole,
  CommitteeMemberRole,
  CommitteeMemberRole,
  CommitteeMemberRole,
  CommitteeMemberRole,
  CommitteeMemberRole
] = [
  'createMeetings',
  'createResolutionProjects',
  'takingNotes',
  'reviewMeetings',
  'createDocumentRecords',
  'signMinutes',
  'signResolutions'
];
interface IPermissionChipsMobile {
  roles: {
    role: CommitteeMemberRole;
    isOptional?: boolean | undefined;
  }[];
}

export const PermissionChipsMobile: React.FC<IPermissionChipsMobile> = (props) => {
  const { roles } = props;
  return (
    <Stack gap="14px">
      {committeeMemberRolesOrdered.map((role) => {
        const permissionRole = roles?.find((r) => r.role === role);
        if (permissionRole) {
          return (
            <Box key={permissionRole.role}>
              <PermissionRoleChip
                role={permissionRole.role}
                isOptional={permissionRole.isOptional}
                isLongText
              />
            </Box>
          );
        }
      })}
    </Stack>
  );
};
