import { CommitteeMemberRole } from 'types';
import { useLocale } from '@koopajs/react';
import { useScreenSize } from 'utils/useScreenSize';
import { Typography, Box } from '@mui/material';
import { PermissionTableMobileAccordion } from './PermissionTableMobileAccordion';
import { PermissionRoleChip } from '../../PermissionRoleChip';

interface IPermissionsTableProps {
  permissions: { role: CommitteeMemberRole; isOptional?: boolean }[];
}

export const PermissionTableMember: React.FC<IPermissionsTableProps> = (props) => {
  const { permissions } = props;

  const { t } = useLocale();
  const keyPrefix = 'OrganizationMember.PermissionTableMember';

  const { isScreenAboveMd } = useScreenSize();

  const permissionsTable: { [key: string]: CommitteeMemberRole[] }[] = [
    { permissionsCreate: [`createMeetings`, `createResolutionProjects`] },
    { permissionsWrite: [`takingNotes`] },
    {
      permissionsEdit: [`reviewMeetings`, `createDocumentRecords`]
    },
    { permissionsSign: [`signMinutes`, `signResolutions`] }
  ];

  return (
    <>
      {!isScreenAboveMd ? (
        <>
          {/* MOBILE ACCORDION */}
          <PermissionTableMobileAccordion permissions={permissions} />
        </>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {permissionsTable.map((row, index) => (
            <Box key={index} sx={{ flex: 1, display: 'flex', mt: 2 }}>
              {Object.keys(row).map((key: string) => {
                const possiblePermissions = permissionsTable[index][key];
                return (
                  <Box
                    key={key}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    data-cy={`permissions-table-${key}`}
                  >
                    <Typography variant="body2">{t(keyPrefix + `.${key}`)}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {possiblePermissions.map((possiblePermission) => {
                        const memberPermissionRole = permissions.find(
                          (permission) => permission.role === possiblePermission
                        );

                        if (memberPermissionRole) {
                          return (
                            <Box>
                              <PermissionRoleChip
                                key={memberPermissionRole.role}
                                role={memberPermissionRole.role}
                                isOptional={memberPermissionRole.isOptional}
                                sx={{ fontSize: '13px' }}
                              />
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
