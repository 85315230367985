import React from 'react';
import { useFacet, useLocale } from '@koopajs/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  Box,
  Divider
} from '@mui/material';
import { User } from '@koopajs/react';

export const Owner: React.FC<{ id: string }> = ({ id }) => {
  return <User id={id}>{(profile) => (profile ? <>{profile?.username}</> : <span />)}</User>;
};

export interface IUserFacetProps {
  path: string;
  facetKey?: string;
  title?: string;
  showCount?: boolean;
  isSingleValue?: boolean;
}

export const UserFacetSidebar: React.FC<IUserFacetProps> = (props) => {
  const { path, title, facetKey = '$ownedBy.keyword', showCount = false, isSingleValue = true } = props;

  const { t } = useLocale();

  const facet = useFacet({ facetKey, path, isSingleValue });

  if (!facet.values || facet.values.length === 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginTop: { xs: 4, lg: 0 } }}>
        <Box>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              backgroundColor: 'transparent',
              '&:before': {
                display: 'block'
                // background:
                //   'linear-gradient(266deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(224,224,224,1) 100%)'
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography variant="button">
                {title ? title : t('Sidebars.Facets.UserFacet.titleDefault')}
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ p: 0 }}>
              <List sx={{ my: -0.5 }}>
                {facet.values &&
                  facet.values.map((value) => {
                    const isSelected = value.key === facet.selectedValue;
                    return (
                      <ListItem disablePadding key={value.key} sx={{ py: 0.5 }}>
                        <ListItemButton
                          selected={isSelected}
                          onClick={facet.addFacetFilter(value.key) as any}
                          sx={{
                            p: 0,
                            '&.Mui-selected': {
                              backgroundColor: 'secondary.lighter'
                            }
                          }}
                        >
                          <ListItemText
                            primary={<Owner id={value.key} />}
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                          {showCount && (
                            <ListItemIcon>
                              <Chip label={value.count} size="small" sx={{ minWidth: '25px' }} />
                            </ListItemIcon>
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
    </>
  );
};
