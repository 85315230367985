import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import './style.css';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';

export interface IRichTextReadOnlyProps {
  value: string;
  style?: string;
  sxEditorContainer?: SxProps<Theme>;
}

export const RichTextReadOnly: React.FC<IRichTextReadOnlyProps> = (props) => {
  const { value, style, sxEditorContainer } = props;
  const editor = useEditor({
    editable: false,
    extensions: [Link, StarterKit, Table, TableRow, TableHeader, TableCell],
    content: value,
    parseOptions: {
      preserveWhitespace: 'full'
    },
    editorProps: {
      attributes: {
        class: 'tiptap-editor read-only',
        ...(style ? { style } : {})
      }
    }
  });

  return (
    <Box
      sx={{
        ...sxEditorContainer
      }}
      className="tiptap-editor-container"
    >
      <EditorContent editor={editor} />
    </Box>
  );
};

export default RichTextReadOnly;
