import { Box, Typography } from '@mui/material';
import { IUserPublicProfile } from '@koopajs/app';
import { useLocale, useResourceList } from '@koopajs/react';
import { ICommitteeMember } from 'types';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { processUsersForInput } from 'utils/processUsersForInput';
import { ResourcePicker } from 'components/ResourcePicker';

interface IParticipantsFieldProps {
  name: string;
  users: IUserPublicProfile[];
  defaultParticipants?: { id: string; label: string }[];
  committeeId: string;
  keyPrefix: string;
  isOptional?: boolean;
  isLabelHidden?: boolean;
  label?: string;
  testId?: string;
}

export const ParticipantsField: React.FC<IParticipantsFieldProps> = (props) => {
  const {
    name,
    users,
    committeeId,
    defaultParticipants,
    isOptional,
    keyPrefix,
    isLabelHidden,
    testId,
    label
  } = props;

  const { t } = useLocale();
  const today = DateTime.now().toISODate();

  const { resources: committeeMembersInSelectedCommittee } = useResourceList<ICommitteeMember>({
    path: `/committees/${committeeId}/members`,
    searchParams: {
      filters: [`startAt:[* TO ${today}]`, `(endAt:[${today} TO *] OR (NOT _exists_:endAt))`],
      size: 50
    }
  });

  const committeeMembersInSelectedCommitteeIds = committeeMembersInSelectedCommittee?.map(
    (committeeMember) => committeeMember.userId
  );

  const committeeMembersInSelectedCommitteeFormatted = useMemo(() => {
    return processUsersForInput({
      users,
      selectedUserIds: committeeMembersInSelectedCommitteeIds
    });
  }, [users.length, committeeId, committeeMembersInSelectedCommitteeIds.join('')]);

  return (
    <>
      {users && users.length > 0 && (defaultParticipants || committeeMembersInSelectedCommitteeFormatted) && (
        <Box sx={{ mt: 1.5, mb: 2 }} data-cy={testId || 'meeting-create-form_participants-field'}>
          {!isLabelHidden && (
            <Typography variant="caption" sx={{ ml: 0.5, color: 'rgba(0, 0, 0, 0.87)' }}>
              {label || t('common:participants')}
            </Typography>
          )}
          <ResourcePicker
            i18n={{ keyPrefix: keyPrefix + '.FormResourcePicker' }}
            name={name}
            isOptional={isOptional}
            defaultValue={defaultParticipants || committeeMembersInSelectedCommitteeFormatted}
            resources={users.map((user) => ({ id: user?.id, label: user?.username }))}
            isOpenOnFocus
            isDisabledCloseOnSelect
            isMultiple
            translatedLabel=""
            translatedLabelMultipleSelected=""
            translatedErrorText=""
            sx={{ mt: 1 }}
          />
        </Box>
      )}
    </>
  );
};
