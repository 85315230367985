import { useFacet, useLocale } from '@koopajs/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  List,
  Divider
} from '@mui/material';

interface IResolutionOutcomeFacetProps {
  path: string;
}

export const ResolutionOutcomeFacetSidebar: React.FC<IResolutionOutcomeFacetProps> = (props) => {
  const { path } = props;

  const { t } = useLocale();
  const keyPrefix = 'Sidebars.Facets.ResolutionOutcomeFacet';

  const facet = useFacet({ facetKey: 'resolutionOutcome.outcome.keyword', path, isSingleValue: true });

  if (!facet.values || facet.values.length === 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginTop: { xs: 4, lg: 0 } }}>
        <Box>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              backgroundColor: 'transparent',
              '&:before': {
                display: 'block'
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography variant="button">{t(keyPrefix + '.title')}</Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ p: 0 }}>
              {facet.values.map((value) => (
                <Box key={value.key}>
                  <List sx={{ my: -0.5 }}>
                    <ListItem disablePadding sx={{ py: 0.5 }}>
                      <ListItemButton onClick={facet.addFacetFilter(value.key) as any} sx={{ p: 0 }}>
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={t(`common:TopicModel.resolutionOutcome.outcome.${value.key}`) || value.key}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
    </>
  );
};
