import { IResolution, IMinute } from 'types';
import { Paper } from '@mui/material';
import { ToSignItemResolution } from './ToSignItemResolution';
import { ToSignItemMinute } from './ToSignItemMinute';

interface IToSignItemProps {
  itemToSign: IMinute | IResolution;
  isSignable?: boolean;
}

export const ToSignItem: React.FC<IToSignItemProps> = (props) => {
  const { itemToSign, isSignable } = props;

  return (
    <>
      <Paper
        sx={{
          p: 3,
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '100%',
          minWidth: 0
        }}
        variant="outlined"
        data-cy={itemToSign.model === 'Resolution' ? 'resolution-to-sign-card' : 'minutes-to-sign-card'}
      >
        {itemToSign.model === 'Resolution' && (
          <ToSignItemResolution resolution={itemToSign} isSignable={isSignable} />
        )}
        {itemToSign.model === 'Minute' && <ToSignItemMinute minute={itemToSign} isSignable={isSignable} />}
      </Paper>
    </>
  );
};
