import { Sidebar } from '@koopajs/mui/dist/components/Layout/Sidebar';
import { ICoreProps } from '@koopajs/mui';
import { Grid, Container } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export interface IPageContainerProps extends ICoreProps {
  sidebar?: React.ReactNode;
  children: React.ReactNode;
  fluidContainer?: boolean;
  sxChildren?: SystemStyleObject;
  sxSidebar?: SystemStyleObject;
  testId?: string;
  className?: string;
}

export const PageContainer: React.FC<IPageContainerProps> = (props) => {
  const { sx, sxChildren, sxSidebar, sidebar, children, fluidContainer, testId, className } = props;
  return (
    <Grid container sx={{ height: '100%', minHeight: 0, ...sx }} data-cy={testId} className={className}>
      {sidebar && (
        <Grid item lg={2} sx={{ overflow: 'auto', height: { xs: 'auto', lg: '100%' }, ...sxSidebar }}>
          <Sidebar>{sidebar}</Sidebar>
        </Grid>
      )}

      <Grid
        item
        lg={sidebar ? 10 : 12}
        sx={{ pl: 1, overflow: 'auto', height: '100%', width: '100%', ...sxChildren }}
      >
        {fluidContainer ? <Container>{children}</Container> : <>{children}</>}
      </Grid>
    </Grid>
  );
};
