import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface IUseScreenSizeResponse {
  isMobileScreen: boolean;
  isScreenAboveSm: boolean;
  isScreenAboveMd: boolean;
  isScreenAboveLg: boolean;
  isScreenAboveXl: boolean;
}

export const useScreenSize = (): IUseScreenSizeResponse => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isScreenAboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isScreenAboveMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenAboveLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isScreenAboveXl = useMediaQuery(theme.breakpoints.up('xl'));

  return { isMobileScreen, isScreenAboveSm, isScreenAboveMd, isScreenAboveLg, isScreenAboveXl };
};
