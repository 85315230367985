import { useCallback } from 'react';
import { useComponentVisibility } from '@koopajs/react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IAddResolutionButton {
  liveMeeting: IUseLiveMeetingResponse;
  sx?: SxProps<Theme>;
}

export const AddResolutionButton: React.FC<IAddResolutionButton> = (props) => {
  const { sx, liveMeeting } = props;
  const { t, isCommitteeTakingResolutions } = liveMeeting;

  const defaultValues = {
    resolutionOutcome: {
      outcome: 'approved'
    }
  };

  const showDialog = useComponentVisibility('addResolution');
  const handleShowDialog = useCallback(() => {
    showDialog.setVisibleWithContext({ liveMeeting: props.liveMeeting, defaultValues });
  }, [JSON.stringify(props.liveMeeting)]);

  if (!isCommitteeTakingResolutions) {
    return null;
  }

  return (
    <Button
      startIcon={<Add />}
      onClick={handleShowDialog}
      type="submit"
      sx={sx}
      data-cy="meeting-in-progress_add-resolution-button"
    >
      {t('MeetingStateInProgress.labelAddResolution')}
    </Button>
  );
};
