import { useLocale, Ref } from '@koopajs/react';
import { Typography, Skeleton, Box } from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { IMeetingRef } from 'types';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { theme } from 'components/Core/theme';

interface IMinutesToApproveItem {
  minutesId: string;
}

export const MinutesToApproveItem: React.FC<IMinutesToApproveItem> = (props) => {
  const { minutesId } = props;

  const { locale } = useLocale();

  return (
    <Ref content={`ref:meeting:${minutesId}`}>
      {(item) => {
        const minutes = item as IMeetingRef;

        const committeeType = useCommitteeName(minutes?.committeeId as string);

        if (!minutes) return <Skeleton variant="rounded" width="100%" height={40} />;
        return (
          <Box
            sx={{
              mb: '14px',
              opacity: 0.6,
              display: 'flex',
              border: `1px solid ${theme.palette.customGrey?.light}`,
              borderRadius: '4px',
              p: '4px 10px',
              gap: 1
            }}
          >
            <AccessTimeIcon />
            <Typography variant="body1" sx={{ whiteSpace: 'normal' }}>
              {formatDate({
                isoString: minutes?.startDateTime as string,
                locale,
                format: 'DATE_FULL'
              })}
              {' | '}
              {committeeType}
            </Typography>
          </Box>
        );
      }}
    </Ref>
  );
};
