import { useCallback, useState } from 'react';
import { IDocument } from 'types';
import { useFileUpload, useResourceCreate, useLocale } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDropzone, DropzoneState, FileRejection } from 'react-dropzone';

export interface IUseDocumentDropzoneProps {
  path: string;
}

export interface IUseDocumentDropzoneResponse {
  dropzone: DropzoneState;
  isProcessing: boolean;
}

export const useDocumentDropzone = (props: IUseDocumentDropzoneProps): IUseDocumentDropzoneResponse => {
  const { path } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useLocale();
  const keyPrefix = 'hooks.useDocumentDropzone';
  const fileUpload = useFileUpload();

  const documentCreate = useResourceCreate({
    path: path
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const uploadFile = async (file: File): Promise<void> => {
    const fileUri = await fileUpload.upload(file);

    const res = await documentCreate.createResource(
      {
        attachedFile: {
          uri: fileUri,
          format: file.type,
          name: file.name,
          sizeBytes: file.size
        }
      } as unknown as IDocument
      // (id: string) => (newId = id)
    );

    if (res) {
      enqueueSnackbar(t(keyPrefix + '.snackbarUploadSuccess'), {
        variant: 'success',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-upload', {
        fileSizeBytes: file.size,
        fileFormat: file.type
      });
    } else {
      enqueueSnackbar(t(keyPrefix + '.snackbarUploadFail'), {
        variant: 'error',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-upload-fail');
    }
  };

  const uploadFilesWithDelay = async (files: File[], delayMs: number): Promise<void> => {
    for (const file of files) {
      await uploadFile(file);
      await new Promise((resolve) => {
        setTimeout(resolve, delayMs);
      });
    }
  };

  const handleOnDrop = useCallback(async (files: File[]) => {
    setIsProcessing(true);

    await uploadFilesWithDelay(files, 100);

    setIsProcessing(false);
  }, []);

  const handleOnDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    //code: "file-too-large" | "file-too-small" | "too-many-files" | "file-invalid-type" | string;

    let isErrorTooManyFilesShowed = false;

    rejectedFiles.map((rejectedFile) => {
      const errorMessageCode = rejectedFile.errors[0].code;
      const fileName = rejectedFile.file.name;

      if (errorMessageCode !== 'too-many-files') {
        return enqueueSnackbar(t(`${keyPrefix}.${errorMessageCode}`, { fileName: fileName }), {
          variant: 'error',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      } else if (errorMessageCode === 'too-many-files' && !isErrorTooManyFilesShowed) {
        isErrorTooManyFilesShowed = true;
        return enqueueSnackbar(t(`${keyPrefix}.${'too-many-files'}`), {
          variant: 'error',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      }
    });
  }, []);

  const dropzone = useDropzone({
    accept: [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'image/jpeg',
      'image/png'
    ],
    multiple: true,
    maxSize: 15000000, //15MB,
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop,
    onDropRejected: handleOnDropRejected,
    maxFiles: 5
  });

  return { dropzone, isProcessing: isProcessing || documentCreate.isProcessing };
};
