import React, { useCallback, useRef, useEffect } from 'react';
import { Dialog, Loading } from '@koopajs/mui';
import { useHistory } from 'react-router-dom';
import { useResourceList, useResourceShow } from '@koopajs/react';
import { IMinute, IApprovedTopic, ISignatureWithTitle } from 'types';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import { TopicCardMinutes } from 'components/TopicCard/TopicCardMinutes';
import { EventsTimeline } from 'components/EventsTimeline';
import { SignatureList } from 'components/Signature/SignatureList';
import { IUserPublicProfile } from '@koopajs/app';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { extractErrorMessage } from 'utils/extractErrorMessage';

interface IViewMinutes {
  minutesId: string;
  pathOnClose: string;
}

export const ViewMinutes: React.FC<IViewMinutes> = (props) => {
  const { minutesId, pathOnClose } = props;

  const history = useHistory();

  const {
    resource: minutes,
    isProcessing,
    errorMessage
  } = useResourceShow<IMinute>({
    path: '/minutes',
    id: minutesId
  });

  const extractedErrorMessage = extractErrorMessage(errorMessage);
  const minutesType = useCommitteeName(minutes?.committeeId || '');
  const keyPrefix =
    minutesType === 'Committee meeting'
      ? 'Dialogs.ViewMinutes.DialogViewCommittee'
      : 'Dialogs.ViewMinutes.DialogView';

  const handleCloseDialog = useCallback(() => {
    history.push({ pathname: pathOnClose, search: history.location?.search });
  }, [history, pathOnClose]);

  const componentRef = useRef(null);

  const timelineEvents = generateMeetingTimeline(minutes);

  const signaturesWithTitles = getSignaturesWithTitles(minutes);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to these minutes") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/minutes');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, minutesId]);

  return (
    <Dialog.View
      isOpen={Boolean(minutesId && minutes)}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix }}
      footerActions={
        <RenderPrintButton
          keyPrefix={keyPrefix}
          componentRef={componentRef}
          documentTitle={minutes?.title}
          size="small"
        />
      }
      dialogProps={{ className: 'rr-block' }}
    >
      <Box sx={{ display: 'none' }}>
        <ViewMinutesPrintVersion
          minutes={minutes}
          topics={minutes?.approvedTopics as IApprovedTopic[]}
          ref={componentRef}
        />
      </Box>

      {isProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        minutes && (
          <>
            <MinutesHeaderCard minutes={minutes} />

            <Paper variant="outlined" sx={{ mb: 6.25 }}>
              <Stack spacing={2}>
                {minutes.approvedTopics?.map((topic, index, array) => {
                  return (
                    <Box key={topic.id}>
                      <TopicCardMinutes
                        topic={topic}
                        order={index + 1}
                        isEditable={false}
                        userHasEditAccess={false}
                        meeting={minutes}
                      />
                      {index !== array.length - 1 && <Divider sx={{ mx: 3 }} />}
                    </Box>
                  );
                })}
              </Stack>
            </Paper>

            <EventsTimeline timelineEvents={timelineEvents} />
            {signaturesWithTitles && (
              <Box sx={{ my: 4 }}>
                <SignatureList signatures={signaturesWithTitles} />
              </Box>
            )}
          </>
        )
      )}
    </Dialog.View>
  );
};
