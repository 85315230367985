import React from 'react';
import { Stack, Box, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { Sidebar as KoopaSidebar } from '@koopajs/mui/dist/components/Layout/Sidebar';

interface ISidebarProps {
  facetList?: React.ReactNode;
  onHideSidebar: () => void;
  // isProcessing?: boolean;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  const { facetList, onHideSidebar } = props;

  const { t } = useLocale();

  return (
    <KoopaSidebar>
      <Stack spacing={3} sx={{ p: '24px 18px', display: { xs: 'block', lg: 'none' } }}>
        {facetList && facetList}

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ display: { xs: 'block', lg: 'none' }, mb: 2 }}
            onClick={onHideSidebar}
          >
            {t('Components.Sidebar.viewResults')}
          </Button>
        </Box>
      </Stack>
    </KoopaSidebar>
  );
};
