export type StartTopicType =
  | 'quorum'
  | 'nominations'
  | 'agendaApproval'
  | 'pastMinutesApproval'
  | 'ceoReport';
export const startTopicTypes: StartTopicType[] = [
  'quorum',
  'nominations',
  'agendaApproval',
  'pastMinutesApproval',
  'ceoReport'
];

export type EndTopicType = 'miscellaneous' | 'inCamera' | 'adjournment';
export const endTopicTypes: EndTopicType[] = ['miscellaneous', 'inCamera', 'adjournment'];

export type DefaultTopicType = StartTopicType | EndTopicType;
export const defaultTopicTypes: DefaultTopicType[] = [
  'quorum',
  'agendaApproval',
  'pastMinutesApproval',
  'ceoReport',
  ...endTopicTypes
];

export type SmartTopicType = StartTopicType | 'inCamera' | 'adjournment';
export const smartTopicTypes: SmartTopicType[] = [...startTopicTypes, 'inCamera', 'adjournment'];

export type GenericTopicType = 'information' | 'discussion' | 'resolution';
export const genericTopicTypes: GenericTopicType[] = ['information', 'discussion', 'resolution'];

export type InCameraTopicType = 'inCamera';
export const inCameraTopicTypes: InCameraTopicType[] = ['inCamera'];

export type AllTopicType = DefaultTopicType | GenericTopicType;
export const allTopicTypes: AllTopicType[] = [...defaultTopicTypes, ...genericTopicTypes];

export type UnmovableTopicType = 'quorum' | 'nominations' | EndTopicType;
export const unmovableTopicTypes: UnmovableTopicType[] = ['quorum', 'nominations', ...endTopicTypes];

export type DecisionTopicType =
  | 'resolution'
  | 'nominations'
  | 'agendaApproval'
  | 'pastMinutesApproval'
  | 'adjournment';
export const decisionTopicTypes: DecisionTopicType[] = [
  'resolution',
  'nominations',
  'agendaApproval',
  'pastMinutesApproval',
  'adjournment'
];

export const checkIsStartTopic = (topicType: string): boolean => {
  return startTopicTypes.some((type) => type === topicType);
};

export const checkIsEndTopic = (topicType: string): boolean => {
  return endTopicTypes.some((type) => type === topicType);
};

export const checkIsSmartTopicType = (topicType: string): boolean => {
  return smartTopicTypes.some((type) => type === topicType);
};

export const checkIsDefaultTopicType = (topicType: string): boolean => {
  return defaultTopicTypes.some((type) => type === topicType);
};

export const checkIsGenericTopicType = (topicType: string): boolean => {
  return genericTopicTypes.some((type) => type === topicType);
};

export const checkIsUnmovableTopicType = (topicType: string): boolean => {
  return unmovableTopicTypes.some((type) => type === topicType);
};

export const checkIsDecisionTopicType = (topicType: string): boolean => {
  return decisionTopicTypes.some((type) => type === topicType);
};

export const checkIsInCameraTopicType = (topicType: string): boolean => {
  return inCameraTopicTypes.some((type) => type === topicType);
};
