import { DateTime } from 'luxon';
import { IDocumentRecord } from 'types';

export const generateDocumentRecordFormDefaultValues = (
  documentRecord: IDocumentRecord,
  options?: { isActiveSinceToday: boolean }
): Record<string, string | undefined> => {
  const [reviewIntervalTimeUnit, reviewIntervalValue] = documentRecord.reviewInterval
    ? Object.entries(documentRecord.reviewInterval)[0]
    : [];

  const { isActiveSinceToday } = options || {};

  return {
    lastDocumentTitle: documentRecord.lastDocumentTitle,
    committeeId: documentRecord.committeeId,
    category: documentRecord.category,
    lastDocumentActiveSince: isActiveSinceToday
      ? DateTime.now().toFormat('yyyy-MM-dd')
      : documentRecord.lastDocumentActiveSince &&
        DateTime.fromISO(documentRecord.lastDocumentActiveSince).toUTC().toFormat('yyyy-MM-dd'),
    ...(documentRecord.reviewInterval
      ? {
          reviewInterval: `${reviewIntervalTimeUnit} ${reviewIntervalValue}`
        }
      : // at this point if we have review at we know it's custom
      documentRecord.toReviewAt
      ? { reviewInterval: 'custom' }
      : { reviewInterval: 'none' }),
    toReviewAt:
      documentRecord.toReviewAt && DateTime.fromISO(documentRecord.toReviewAt).toUTC().toFormat('yyyy-MM-dd')
  };
};
