import React, { useCallback, useState, useEffect } from 'react';
import { ContentTOC } from './Steps/ContentTOC';
import { ContentName } from './Steps/ContentName';
import { ContentWorkspace } from './Steps/ContentWorkspace';
import { Layout } from '@koopajs/mui';
import { useUserUpdate, useWorkspaceShow } from '@koopajs/react';
import { Stepper, Step, StepContent, StepLabel, Typography, Paper, Box } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useRouteMatch } from 'react-router-dom';
import { TrackEvent } from '@koopajs/app';
import _ from 'lodash';

export const Onboarding: React.FC = () => {
  const appName = 'Panorama';
  // TODO: Change url for new name
  const commercialWebsiteUrl = 'https://usepanorama.com/';
  const appUrl = 'https://app.usepanorama.com/';
  const privacyPolicyUrl = 'https://usepanorama.com/privacy';
  const latestTOCVersion = '2023-01-09';

  const { t, setLocale } = useLocale();
  const keyPrefix = 'OnBoarding';

  const { updateUser, errorMessage: errorMessageUserUpdate } = useUserUpdate();
  const { workspace } = useWorkspaceShow();

  // TODO: Later on let's take the value from the login flow but this is quick fix for now :)
  useEffect(() => {
    const core = async (): Promise<void> => {
      await setLocale('fr-CA');
    };
    core().catch(console.log);
  }, []);

  const match = useRouteMatch('/invitation/:token');
  const urlParams = match?.params as { token?: string };
  // TODO: should I check if this is a valid token?
  const invitedWorkspace = urlParams?.token ? urlParams.token.split('.')[0] : '';

  const [step, setStep] = useState(0);
  const moveNext = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const movePrevious = useCallback(() => {
    setStep((s) => s - 1);
  }, []);

  const finishOnBoarding = useCallback(async (): Promise<void> => {
    const isSuccess = await updateUser({
      activatedAt: new Date().toISOString()
    });

    if (isSuccess) {
      TrackEvent('onboarding-success');
    }
  }, []);

  return (
    <Layout.PageContainer fluidContainer>
      <Paper elevation={1} sx={{ padding: 2.5 }}>
        <Box sx={{ mt: 2.5, mb: 1.25 }}>
          <Typography variant="h3">{t(keyPrefix + '.title')}</Typography>
          <Typography variant="subtitle1">{t(keyPrefix + '.subTitle')}</Typography>
        </Box>
        <Stepper activeStep={step} orientation="vertical">
          <Step>
            <StepLabel>{t(keyPrefix + '.tocStepTitle')}</StepLabel>
            <StepContent>
              <ContentTOC
                moveNext={moveNext}
                movePrevious={movePrevious}
                appName={appName}
                commercialWebsiteUrl={commercialWebsiteUrl}
                appUrl={appUrl}
                privacyPolicyUrl={privacyPolicyUrl}
                latestTOCVersion={latestTOCVersion}
                i18n={{ keyPrefix: `${keyPrefix}.ContentTOC` }}
              />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>{t(keyPrefix + '.nameStepTitle')}</StepLabel>
            <StepContent>
              <ContentName
                moveNext={moveNext}
                movePrevious={movePrevious}
                i18n={{ keyPrefix: `${keyPrefix}.ContentName` }}
                finishOnBoarding={invitedWorkspace || !_.isEmpty(workspace) ? finishOnBoarding : undefined}
              />
            </StepContent>
          </Step>

          {!invitedWorkspace && _.isEmpty(workspace) && (
            <Step>
              <StepLabel>{t(keyPrefix + '.workspaceStepTitle')}</StepLabel>
              <StepContent>
                <ContentWorkspace
                  moveNext={moveNext}
                  movePrevious={movePrevious}
                  i18n={{ keyPrefix: `${keyPrefix}.ContentWorkspace` }}
                  finishOnBoarding={finishOnBoarding}
                  errorMessageUserUpdate={errorMessageUserUpdate}
                />
              </StepContent>
            </Step>
          )}
        </Stepper>
      </Paper>
    </Layout.PageContainer>
  );
};
