export const transformFormDataReviewInterval = (
  reviewInterval?: string
): { [k: string]: number } | undefined => {
  if (reviewInterval === 'none' || reviewInterval === 'custom') return undefined;

  if (reviewInterval && typeof reviewInterval === 'string') {
    const [timeUnit, value] = reviewInterval.split(' ');

    if (timeUnit && !isNaN(parseInt(value))) {
      const validTimeUnits = ['months', 'years'];
      const parsedValue = parseInt(value);

      if (validTimeUnits.includes(timeUnit)) {
        return { [timeUnit]: parsedValue };
      } else {
        console.error('Invalid time unit in reviewInterval:', timeUnit);
      }
    }
  }
  return undefined;
};
interface IAttachedFile {
  uri: string;
  format: string;
  name: string;
  sizeBytes: number;
}

interface IBaseFormData {
  activeSince?: string;
  lastDocumentActiveSince?: string;
  toReviewAt?: string;
  attachedFile: IAttachedFile;
}

interface IFormData extends IBaseFormData {
  reviewInterval?: string;
}

interface IPayload extends IBaseFormData {
  reviewInterval?: { [k: string]: number } | null;
}
export const transformDocumentRecordDataForm = ({
  data,
  file,
  fileUri
}: {
  data: object;
  file?: File;
  fileUri?: string;
}): IPayload => {
  const formData = data as IFormData;

  if (fileUri && file) {
    formData.attachedFile = {
      uri: fileUri,
      format: file.type,
      name: file.name,
      sizeBytes: file.size
    };
  }

  const transformedReviewInterval = transformFormDataReviewInterval(formData?.reviewInterval);

  const payload: IPayload = {
    ...formData,
    reviewInterval: transformedReviewInterval || null,
    ...(formData.lastDocumentActiveSince
      ? { lastDocumentActiveSince: new Date(formData.lastDocumentActiveSince + `T00:00:00Z`).toISOString() }
      : {}),
    ...(formData.activeSince
      ? { activeSince: new Date(formData.activeSince + `T00:00:00Z`).toISOString() }
      : {}),
    toReviewAt:
      formData?.reviewInterval === 'custom' && formData.toReviewAt
        ? new Date(formData.toReviewAt + `T00:00:00Z`).toISOString()
        : ''
  };

  return payload;
};
