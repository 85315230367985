import { IResolution, IMinute } from 'types';

export const calculateMissingSignatureCount = (itemToSign: IResolution | IMinute): number => {
  const idealSignaturesRemaining =
    (itemToSign?.realSignaturesRequired || 0) - (itemToSign.signatures?.length || 0); //1
  const requiredSignersLeftToSign = itemToSign.membersLeftToSign?.filter((m) => !m.isOptional).length || 0;

  return idealSignaturesRemaining > requiredSignersLeftToSign
    ? idealSignaturesRemaining
    : requiredSignersLeftToSign;
};
