import { Alert, Link as MuiLink } from '@mui/material';

import { useComponentVisibility, useLocale } from '@koopajs/react';
import { theme } from './Core/theme';

export const DemoWorkspaceBanner: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'Components.DemoWorkspaceBanner';

  const userMenu = useComponentVisibility('userMenu');

  return (
    <Alert
      severity="warning"
      sx={{
        borderRadius: '0',
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: theme.palette.warning.light,
        justifyContent: 'center',
        textAlign: 'center',
        padding: '0px 24px'
      }}
    >
      {t(keyPrefix + '.message')}{' '}
      <MuiLink
        sx={{
          cursor: 'pointer',
          color: 'link.main',
          textDecoration: 'none'
        }}
        onClick={userMenu.setVisible}
      >
        {t(keyPrefix + '.viewWorkspacesLink')}
      </MuiLink>
    </Alert>
  );
};
