import { Dispatch, SetStateAction, useState } from 'react';
import { IDocument, ITopic } from 'types';

interface IUseDocumentCountProps {
  topic: ITopic;
}

interface IUseDocumentCountResponse {
  currentDocumentIndex: number;
  setCurrentDocumentIndex: Dispatch<SetStateAction<number>>;
  linkDocumentsCount: number;
  setLinkDocumentsCount: Dispatch<SetStateAction<number>>;
  fileDocumentsCount: number;
  setFileDocumentsCount: Dispatch<SetStateAction<number>>;
  hasDocuments: boolean;
}

export const useDocumentCount = (props: IUseDocumentCountProps): IUseDocumentCountResponse => {
  const { topic } = props;

  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [linkDocumentsCount, setLinkDocumentsCount] = useState(0);
  const [fileDocumentsCount, setFileDocumentsCount] = useState(0);
  const hasDocuments = Boolean(topic.documentsIds && topic.documentsIds.length > 0);

  return {
    currentDocumentIndex,
    setCurrentDocumentIndex,
    linkDocumentsCount,
    setLinkDocumentsCount,
    fileDocumentsCount,
    setFileDocumentsCount,
    hasDocuments
  };
};
