import React, { InputHTMLAttributes, useContext } from 'react';
import { FormControlLabel, FormHelperText, Checkbox as CheckboxMUI, FormControl } from '@mui/material';
import { FormContext } from '@koopajs/react';
import { ICoreProps } from '@koopajs/mui';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';

export interface ICheckboxProps extends ICoreProps {
  name: string;
  isOptional?: boolean;
  isChecked?: boolean;
  color?: 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  onChange?: (() => Promise<void>) | (() => void);
  /**
   * @deprecated Do not use. Will be removed soon.
   */
  label?: string;
  testId?: string;
  customLabel?: React.ReactNode;
}

interface ICheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
}

// TODO: Remove this file once the Koopa PR #457 is merged! :)

export const NewCheckbox: React.FC<ICheckboxProps> = (props) => {
  const context = useContext(FormContext);
  const {
    isOptional,
    name,
    isLoading,
    sx,
    isDisabled,
    isChecked,
    onChange,
    color,
    variant,
    i18n,
    testId,
    label,
    customLabel
  } = props;
  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'FormCheckbox' });

  if (!context.form) {
    throw new Error('"Form.Checkbox" component needs to be use inside a FormController');
  }

  const input = context.form.register(name, {
    required: !isOptional,
    disabled: isDisabled
  });
  const { ref, ...rest } = input;

  const inputOnChange = async (e: any) => {
    await input.onChange(e);
    if (onChange) await onChange();
  };

  return (
    <FormControl
      fullWidth
      margin={variant === 'dense' ? 'dense' : 'normal'}
      sx={{ ...sx }}
      disabled={context.isProcessing || isLoading || isDisabled}
    >
      <FormControlLabel
        componentsProps={{
          typography: {
            color: context.form.formState.errors[name] && 'error'
          }
        }}
        data-cy={testId || `koopa_checkbox-label_${name}`}
        control={
          <CheckboxMUI
            {...rest}
            inputProps={{ 'data-cy': testId || `koopa_checkbox_${name}` } as ICheckboxInputProps}
            inputRef={ref}
            color={color || 'primary'}
            defaultChecked={isChecked}
            onChange={inputOnChange}
            size={variant === 'dense' ? 'small' : 'medium'}
          />
        }
        label={customLabel ? customLabel : t('label')}
      />
      <FormHelperText error={context.form.formState.errors[name]}>
        {context.form.formState.errors[name] ? t('errorText') : t('helperText')}
      </FormHelperText>
    </FormControl>
  );
};
