import React from 'react';
import { IParticipant } from '../types';
import { User } from '@koopajs/react';
import { Box, Chip, Avatar, Stack, SxProps, Theme } from '@mui/material';

interface IParticipantsListProps {
  participants?: IParticipant[];
  sx?: SxProps<Theme>;
  chipVariant?: 'filled' | 'outlined';
}

export const ParticipantsList: React.FC<IParticipantsListProps> = (props) => {
  const { participants, sx, chipVariant } = props;
  return (
    <Box
      component="ul"
      sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', overflow: 'hidden', p: 0, ...sx }}
      data-cy="participants-list"
    >
      {participants?.map((p) => {
        return (
          <User key={p.userId} id={p.userId as string}>
            {(profile) => {
              return (
                <Chip
                  component="li"
                  sx={{ mr: 2, my: 0.5 }}
                  avatar={<Avatar alt={profile?.username} src={profile?.profilePicture} />}
                  label={profile?.username}
                  variant={chipVariant}
                  data-cy="participants-list_user-chip"
                />
              );
            }}
          </User>
        );
      })}
    </Box>
  );
};
