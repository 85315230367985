import { IUserPublicProfile } from '@koopajs/app';
import { isDefined } from './isDefined';

interface IProcessUsersForInputProps {
  users: IUserPublicProfile[];
  selectedUserIds?: string[];
}

export const processUsersForInput = (props: IProcessUsersForInputProps): { label: string; id: string }[] => {
  const { users, selectedUserIds } = props;

  if (!selectedUserIds) {
    return [];
  }

  const mappedUsers = selectedUserIds
    .map((userId) => {
      const foundUser = users.find((user) => user.id === userId);

      return foundUser
        ? {
            label: foundUser?.username || '',
            id: foundUser.id
          }
        : undefined;
    })
    .filter(isDefined);

  return mappedUsers;
};
