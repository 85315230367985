import { useLocale } from '@koopajs/react';
import { Stack } from '@mui/material';
import { CommitteeTypeFacetSidebar } from '../Facets/CommitteeTypeFacetSidebar';
import { DateRangeFacetSidebar } from '../Facets/DateRangeFacetSidebar';
import { UserFacetSidebar } from '../Facets/UserFacetSidebar';
import { SortBySidebar } from '../Facets/SortBySidebar';

export const FacetListInCameraSidebar: React.FC = () => {
  const { t } = useLocale();

  const path = '/in-camera';

  return (
    <Stack spacing={3}>
      <CommitteeTypeFacetSidebar path={path} facetKey="meetingCommitteeId.keyword" />

      <DateRangeFacetSidebar path={path} facetKey="meetingStartDateTime" />

      <UserFacetSidebar
        path={path}
        facetKey="visibleBy.keyword"
        title={t('Sidebars.Facets.UserFacet.titleVisibleBy')}
      />

      <SortBySidebar
        sortOptions={[
          {
            key: 'meetingStartDateTime',
            direction: 'asc',
            label: t('Sidebars.SortBy.labelOldest')
          },
          {
            key: 'meetingStartDateTime',
            direction: 'desc',
            label: t('Sidebars.SortBy.labelNewest')
          }
        ]}
      />
    </Stack>
  );
};
