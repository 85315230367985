import { useCallback } from 'react';
import { useComponentVisibility } from '@koopajs/react';
import { Button, Stack } from '@mui/material';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { theme } from '../../../../Core/theme';

interface IRecordVoteButton {
  liveMeeting: IUseLiveMeetingResponse;
  sx?: SxProps<Theme>;
}

export const RecordVoteButton: React.FC<IRecordVoteButton> = (props) => {
  const { sx, liveMeeting } = props;

  const { t } = liveMeeting;

  const defaultValues = {
    resolutionOutcome: {
      outcome: 'approved'
    }
  };

  const showDialog = useComponentVisibility('recordVote');
  const handleShowDialog = useCallback(() => {
    showDialog.setVisibleWithContext({ liveMeeting: props.liveMeeting, defaultValues });
  }, [JSON.stringify(props.liveMeeting)]);

  return (
    <Stack direction="row" spacing={0.75} sx={sx}>
      <Button
        variant="contained"
        onClick={handleShowDialog}
        startIcon={<HowToVoteIcon />}
        data-cy="meeting-in-progress_record-vote-button"
        sx={{
          color: 'white',
          background: `linear-gradient(90deg, ${theme.palette.automation.purple} 0%, ${theme.palette.automation.blue} 100%)`
        }}
      >
        {t('MeetingStateInProgress.ResolutionFields.labelRecordVote')}
      </Button>
    </Stack>
  );
};
