import { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, IconButton, Button } from '@mui/material';

import { useComponentVisibility, useLocale } from '@koopajs/react';
import { IDocument } from 'types';
import { BoardMemberViewDocumentAnnotation } from 'components/BoardMemberView/DocumentAnnotation';

export const BoardMemberViewDocumentDialog: React.FC = () => {
  const keyPrefix = 'Dialogs.BoardMemberViewDocumentDialog';
  const { t } = useLocale();

  const dialog = useComponentVisibility('boardMemberViewDocumentDialog');

  const dialogContext = dialog.getContext() as {
    pdfDocuments: IDocument[];
    documentId: string;
  };

  const { pdfDocuments } = dialogContext;

  const [currentDocumentId, setCurrentDocumentId] = useState(dialogContext.documentId);

  useEffect(() => {
    setCurrentDocumentId(dialogContext.documentId);
  }, [dialogContext.documentId]);

  const handleClose = useCallback(async () => {
    dialog.setHidden();
  }, []);

  return (
    <Dialog
      open={dialog.isVisible}
      onClose={handleClose}
      PaperProps={{ 'data-cy': 'board-member-view-document-dialog' }}
      fullScreen
    >
      <DialogTitle>
        {t(keyPrefix + '.title')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey'
          }}
          data-cy="board-member-view-document-dialog_close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <>
        <DialogContent
          sx={{
            py: 0,
            lineHeight: 0 // this is getting rid of some random spacing being added
          }}
        >
          <>
            {currentDocumentId && (
              <BoardMemberViewDocumentAnnotation
                documentId={currentDocumentId}
                sx={{ height: '100%' }}
                sxContainer={{ height: '100%' }}
              />
            )}
            {/* TODO: this was for testing back/next functionality */}
            {/* {pdfDocuments?.map((document) => {
              return (
                <Button onClick={() => setCurrentDocumentId(document.id)}>
                  {document.attachedFile.name}
                </Button>
              );
            })} */}
          </>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>{t('common:quit')}</Button>
        </DialogActions>
      </>
    </Dialog>
  );
};
