import { CommitteeMemberRole } from 'types';

export const committeeMemberRoles: CommitteeMemberRole[] = [
  'createMeetings',
  'takingNotes',
  'reviewMeetings',
  'signMinutes',
  'signResolutions',
  'createResolutionProjects',
  'createDocumentRecords'
];
