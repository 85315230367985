import React, { useCallback } from 'react';
import { TextField, Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { IResourcePickerChildrenSingleProps } from '../types';

export const AutoCompleteSingleValue: React.FC<IResourcePickerChildrenSingleProps> = (props) => {
  const {
    isProcessing: isLoadingForm,
    isLoading,
    isDisabled,
    isOptional,
    hasError,
    value,
    handleClose,
    handleSelectionChange,
    filterOptions,
    isOpenOnFocus,
    resources,
    width,
    sx,
    translatedLabel
  } = props;
  const isProcessing = isLoading || isDisabled || isLoadingForm;

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        disabled={isProcessing}
        autoFocus
        required={!isOptional}
        error={Boolean(hasError)}
        label={translatedLabel}
      />
    ),
    [isProcessing, isOptional, hasError, translatedLabel]
  );

  return (
    <Autocomplete
      disablePortal
      autoHighlight
      openOnFocus={isOpenOnFocus}
      options={resources}
      sx={{ width: width, ...sx }}
      value={value}
      onClose={handleClose}
      onChange={handleSelectionChange}
      filterOptions={filterOptions}
      renderInput={renderInput}
      // eslint-disable-next-line react/jsx-no-bind
      isOptionEqualToValue={(option, val) => option.id === val.id}
    />
  );
};
