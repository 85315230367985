import { DateTime } from 'luxon';

interface IFormatTimeProps {
  isoString: string;
  locale: string;
}

export const formatTime = (props: IFormatTimeProps): string => {
  const { locale, isoString } = props;

  let timeFormat = locale;

  // will show 12 h 00 as 12:00
  if (locale === 'fr-CA') {
    timeFormat = 'fr';
  }

  return DateTime.fromISO(isoString).setLocale(timeFormat).toLocaleString(DateTime.TIME_SIMPLE);
};
