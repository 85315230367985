import { useLocale } from '@koopajs/react';
import { Chip, Typography, Box } from '@mui/material';
import { User } from '@koopajs/mui';
import { TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { ITopic } from '../../../../../types';
import { PastMeetingsToApproveList } from '../../../../TopicCard/PastMinutesApprovalField/PastMeetingsToApproveList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { formatTime } from 'utils/DateTime/formatTime';
import { DateTime } from 'luxon';
import { TopicChipConditions } from 'components/TopicChipConditions';
import { DocumentList } from 'components/DocumentList';

interface IAgendaApprovalTopicProps {
  topic: ITopic;
  order: number;
}

export const AgendaApprovalTopic: React.FC<IAgendaApprovalTopicProps> = (props) => {
  const { topic, order } = props;

  const { t, locale } = useLocale();

  const renderTopicStartTime = (): React.ReactNode => {
    return (
      <>
        {topic.calculatedTopicStartTime ? (
          <Chip
            label={formatTime({
              isoString: DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO(),
              locale
            })}
            sx={{
              mr: { xs: 0, sm: 3 },
              fontVariantNumeric: 'tabular-nums'
            }}
            variant="outlined"
          />
        ) : (
          <Chip
            label={<TimerOutlinedIcon sx={{ fontSize: '16px' }} />}
            sx={{
              mr: { xs: 0, sm: 3 },
              '& .MuiChip-label': {
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '50px'
              }
            }}
          />
        )}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, maxWidth: '100px', width: '100%', mr: 1 }}>
        {renderTopicStartTime()}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: 0 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: { sm: 'center' },
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' }
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: '700', whiteSpace: 'normal', flex: 2, mr: 1 }}>
            {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: 1, sm: 3 },
              flex: 1
            }}
          >
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>{renderTopicStartTime()}</Box>
            <User id={topic.assignedTo} variant="avatar-only" />

            <TopicChipConditions topicType={topic.type} />
          </Box>
        </Box>
        {[
          'quorum',
          'nominations',
          'agendaApproval',
          'pastMinutesApproval',
          'ceoReport',
          'information',
          'discussion',
          'resolution',
          'miscellaneous',
          'adjournment'
        ].includes(topic.type) &&
          topic.description && (
            <RichTextReadOnly value={topic.description} sxEditorContainer={{ pt: 1, mb: 1.5 }} />
          )}
        {topic.type === 'pastMinutesApproval' && (
          <PastMeetingsToApproveList meetingId={topic.meetingId} topicId={topic.id} topic={topic} hideLabel />
        )}

        {topic.documentsIds && topic.documentsIds.length > 0 && (
          <DocumentList
            path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
            sxDocument={{ display: 'flex' }}
          />
        )}
      </Box>
    </Box>
  );
};
