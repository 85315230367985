import { useLocale } from '@koopajs/react';
import { IMeeting, IMinute } from 'types';

export const generateMeetingTimeline = (
  meeting?: IMeeting | IMinute
): {
  title: string;
  timestamp?: string;
  userId?: string;
}[] => {
  const { t } = useLocale();
  const keyPrefix = 'Components.EventsTimeline';

  if (!meeting) return [];

  return [
    {
      title: t(keyPrefix + '.agendaSent'),
      timestamp: meeting.agendaSentAt,
      userId: meeting.agendaSentBy
    },
    {
      title: t(keyPrefix + '.markedAsSent'),
      timestamp: meeting.markAsSentAt,
      userId: meeting.markAsSentBy
    },
    {
      title: t(keyPrefix + '.noticeSent'),
      timestamp: meeting.invitationSentAt,
      userId: meeting.invitationSentBy
    },
    {
      title: t(keyPrefix + '.updatedOn'),
      timestamp: meeting.$updatedAt
      //userId: meeting.$updatedBy as string
    },
    { title: t(keyPrefix + '.createdOn'), timestamp: meeting.$createdAt, userId: meeting.$ownedBy as string }
  ];
};
