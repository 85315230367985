import { useCallback, useEffect, useState } from 'react';
import { useLocale } from '@koopajs/react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDropzone, DropzoneState, FileRejection } from 'react-dropzone';

export interface IUseSingleDocumentInMemoryDropzoneProps {
  isStoringFileInMemory?: boolean;
}
export interface IUseSingleDocumentInMemoryDropzoneResponse {
  dropzone: DropzoneState;
  isProcessing: boolean;
  file?: File;
  blobUrl?: string;
  resetFileInState: () => void;
}

export const useSingleDocumentInMemoryDropzone = (
  props: IUseSingleDocumentInMemoryDropzoneProps
): IUseSingleDocumentInMemoryDropzoneResponse => {
  const { isStoringFileInMemory } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useLocale();
  const keyPrefix = 'hooks.useDocumentDropzone';

  const [fileInState, setFileInState] = useState<File | undefined>(undefined);
  const [blobUrl, setPdfBlobUrl] = useState<string | undefined>(undefined);

  const resetFileInState = () => {
    setFileInState(undefined);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOnDrop = useCallback(
    async (files: File[]) => {
      setIsProcessing(true);

      const droppedFile = files[0];

      setFileInState(droppedFile);

      setIsProcessing(false);
    },
    [isStoringFileInMemory]
  );

  useEffect(() => {
    let blobUrl = '';

    const filesWithBlobUrl = ['application/pdf', 'image/jpeg', 'image/png'];

    if (fileInState && filesWithBlobUrl.includes(fileInState.type)) {
      // Create a Blob URL from the file
      blobUrl = URL.createObjectURL(fileInState);

      // Set the Blob URL as the iframe source
      setPdfBlobUrl(blobUrl);
    } else {
      setPdfBlobUrl(undefined);
    }

    // Clean up the Blob URL
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
    // selected a few variables rather than passing the file
  }, [fileInState?.name, fileInState?.size, fileInState?.lastModified]);

  const handleOnDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    //code: "file-too-large" | "file-too-small" | "too-many-files" | "file-invalid-type" | string;
    setIsProcessing(false);

    const errorMessageCode = rejectedFiles[0].errors[0].code;

    return enqueueSnackbar(t(`${keyPrefix}.${errorMessageCode}`), {
      variant: 'error',
      action: (key) => (
        // eslint-disable-next-line react/jsx-no-bind
        <IconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      )
    });
  }, []);

  const dropzone = useDropzone({
    accept: [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'image/jpeg',
      'image/png'
    ],
    multiple: false,
    maxSize: 15000000, //15MB
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop,
    onDropRejected: handleOnDropRejected
  });

  return {
    dropzone,
    isProcessing: isProcessing,
    file: fileInState,
    blobUrl,
    resetFileInState
  };
};
