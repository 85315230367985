import { EmptyState } from './EmptyState';
import { Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { DelayComponentRender } from './DelayComponentRender';

export const FeatureLocked = () => {
  const keyPrefix = 'Components.FeatureLocked';
  const { t } = useLocale();

  return (
    <DelayComponentRender
      delay={1250}
      component={
        <EmptyState
          title={t(keyPrefix + '.title')}
          button={
            <Button href="mailto:support@usepanorama.com" variant="contained" disableElevation>
              {t(keyPrefix + '.contactUsButtonLabel')}
            </Button>
          }
        />
      }
    />
  );
};
