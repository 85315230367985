import enDefault from './en/default';
import frDefault from './fr/default';

import enKoopaCore from '@koopajs/mui/dist/locales/en/koopaCore.json';
import frKoopaCore from '@koopajs/mui/dist/locales/fr/koopaCore.json';

import enCommon from './en/common.json';
import frCommon from './fr/common.json';

export const en: Record<string, { [k: string]: unknown }> = {
  translation: enDefault,
  koopaCore: enKoopaCore,
  common: enCommon
};
export const fr: Record<string, { [k: string]: unknown }> = {
  translation: frDefault,
  koopaCore: frKoopaCore,
  common: frCommon
};
