import { Stack, Box } from '@mui/material';
import { Document } from 'components/DocumentList/Document';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IDocumentList {
  liveMeeting: IUseLiveMeetingResponse;
  sx?: SxProps<Theme>;
  isAgendaDocuments?: boolean;
  isProcessing?: boolean;
  setIsProcessing?: (isProcessing: boolean) => void;
}

export const DocumentListInProgress: React.FC<IDocumentList> = (props) => {
  const { sx, isAgendaDocuments, isProcessing: isProcessingOtherDocuments, setIsProcessing } = props;
  const { agendaDocuments, minutesDocuments, meetingId, currentTopicId } = props.liveMeeting;

  const documents = isAgendaDocuments ? agendaDocuments : minutesDocuments;

  const path = isAgendaDocuments
    ? `/meetings/${meetingId}/topics/${currentTopicId}/documents`
    : `/meetings/${meetingId}/topics/${currentTopicId}/minutes-documents`;

  return (
    <>
      {documents.length > 0 && (
        <Stack spacing={1} sx={sx} data-cy="meeting-in-progress_document-list">
          {documents.map((document) => {
            return (
              <Box key={document.id}>
                <Document
                  document={document}
                  isDeletable={isAgendaDocuments ? false : true}
                  path={path}
                  isProcessing={Boolean(isProcessingOtherDocuments)}
                  setIsProcessing={setIsProcessing}
                />
              </Box>
            );
          })}
        </Stack>
      )}
    </>
  );
};
