import { useAppContext, useUserShow, useUserUpdate } from '@koopajs/react';

export interface IUseTimezoneResponse {
  timezone: string;
  setTimezone: (
    newTimezone: string,
    options?: {
      skipUpdateUser?: boolean;
    }
  ) => Promise<void>;
  isProcessing?: boolean;
}

export const useTimezone = (): IUseTimezoneResponse => {
  const context = useAppContext();
  const { updateUser, isProcessing } = useUserUpdate();
  const { user } = useUserShow();

  const setTimezone = async (
    newTimezone: string,
    options?: {
      skipUpdateUser?: boolean;
    }
  ): Promise<void> => {
    context.setTimezone(newTimezone);
    if (!options?.skipUpdateUser) {
      await updateUser({ timezone: newTimezone });
    }
  };

  const timezone = (user?.timezone as string) || context.timezone;

  // TODO: if changing this usage in koopa - need to update date component
  return { timezone, setTimezone, isProcessing };
};
