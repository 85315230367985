import React, { useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
  Box,
  IconButton
} from '@mui/material';

import { useComponentVisibility } from '@koopajs/react';
import { FormController } from '@koopajs/react';
import { ErrorMessage, ICoreProps } from '@koopajs/mui';
import { Button, ButtonSubmit } from '@koopajs/mui/dist/components/Form';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';

export interface IFormProps extends ICoreProps {
  /**
   * @deprecated Do not use. Will be removed soon.
   */
  title?: string | React.ReactNode;
  /**
   * @deprecated Do not use. Will be removed soon.
   */
  description?: string;
  /**
   * @deprecated Do not use. Will be removed soon.
   */

  labelSubmit?: string;
  /**
   * @deprecated Do not use. Will be removed soon.
   */
  labelCancel?: string;

  dialogKey: string;
  dialogProps?: Partial<DialogProps>;
  onSubmit: (resource: object) => Promise<boolean>;
  onClose?: (() => Promise<void>) | (() => void);
  children: React.ReactNode;
  leftAction?: React.ReactNode;
  mainActionBtnVariant?: 'text' | 'contained' | 'outlined';
  isCancelVisible?: boolean;
  isSubmitDisabled?: boolean;
  errorMessage?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const DialogFormTemp: React.FC<IFormProps> = (props) => {
  const {
    children,
    leftAction,
    onSubmit,
    onClose,
    dialogKey,
    dialogProps,
    mainActionBtnVariant,
    isCancelVisible,
    isSubmitDisabled,
    isLoading,
    isDisabled,
    sx,
    i18n,
    maxWidth = 'sm'
  } = props;

  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'DialogForm' });
  const coreKeyPrefix = 'DialogForm';
  const keyPrefix = i18n?.keyPrefix || coreKeyPrefix;

  const dialog = useComponentVisibility(dialogKey);
  const [isProcessing, setIsProcessing] = useState(false);
  const dialogContext = dialog.getContext();
  const defaultValues =
    typeof dialogContext.defaultValues === 'object'
      ? (dialogContext.defaultValues as { [k: string]: unknown })
      : undefined;

  const submit = useCallback(
    async (resource: object): Promise<boolean> => {
      setIsProcessing(true);
      const isSuccess = await onSubmit(resource);
      if (isSuccess) {
        dialog.setHidden();
      }

      setIsProcessing(false);
      return isSuccess;
    },
    [onSubmit]
  );

  const handleClose = useCallback(async () => {
    if (onClose) {
      await onClose();
    }
    dialog.setHidden();
  }, []);

  return (
    <Dialog
      open={dialog.isVisible}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      {...dialogProps}
      sx={sx}
    >
      <DialogTitle sx={{ pb: t('description') || props.description ? 0 : 1 }}>
        {t('title') || props.title || ''}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <FormController onSubmit={submit} defaultValues={defaultValues} style={{ display: 'contents' }}>
        <>
          <DialogContent sx={{ pt: 0 }}>
            {(t('description') || props.description) && (
              <DialogContentText>{t('description') || props.description}</DialogContentText>
            )}

            {children}
          </DialogContent>

          <ErrorMessage error={props.errorMessage} />

          <DialogActions sx={{ mx: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              {leftAction}

              <Box sx={{ flexBasis: 1 }} />
              <Stack direction="row" spacing={2} sx={{ textAlign: 'right' }}>
                {isCancelVisible && (
                  <Button i18n={{ keyPrefix: `${keyPrefix}.Button` }} onClick={handleClose} />
                )}
                <ButtonSubmit
                  i18n={{ keyPrefix: `${keyPrefix}.ButtonSubmit` }}
                  variant={mainActionBtnVariant || 'contained'}
                  isDisabled={isSubmitDisabled}
                />
              </Stack>
            </Stack>
          </DialogActions>

          <LinearProgress
            style={{ visibility: isProcessing || isLoading || isDisabled ? 'inherit' : 'hidden' }}
          />
        </>
      </FormController>
    </Dialog>
  );
};
