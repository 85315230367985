import { CommitteeMemberRole, IActiveCommitteeMembers } from 'types';

export const getCurrentUserRoles = (props: {
  activeCommitteeMembers?: IActiveCommitteeMembers[];
  userId?: string;
}): CommitteeMemberRole[] => {
  const { activeCommitteeMembers, userId } = props;

  const currentUserAcm = activeCommitteeMembers?.find((acm) => acm.userId === userId);

  return (
    currentUserAcm?.roles?.map((r) => {
      return r.role;
    }) || []
  );
};
