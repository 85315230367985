import { Alert, Link as MuiLink } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { theme } from './Core/theme';

interface IWarningMandateBannerProps {
  committeeNames?: string[];
  userId?: string;
  mandateStatus: 'expired' | 'expiring';
}

export const WarningMandateBanner: React.FC<IWarningMandateBannerProps> = (props) => {
  const { committeeNames, userId, mandateStatus } = props;
  const { t } = useLocale();
  const keyPrefix = 'Components.WarningMandateBanner';

  const names = committeeNames?.map((cName) => cName).join(', ') || '';
  const translationMessage = mandateStatus === 'expired' ? '.messageExpired' : '.messageExpiring';

  return (
    <Alert
      icon={false}
      severity={mandateStatus === 'expired' ? 'error' : 'warning'}
      sx={{
        borderRadius: '0',
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        // TODO:
        borderColor: mandateStatus === 'expired' ? theme.palette.error.main : theme.palette.warning.main,
        justifyContent: 'center',
        textAlign: 'center',
        padding: '0px 24px'
      }}
      data-cy="warning-mandate-banner"
    >
      {t(keyPrefix + translationMessage, {
        committeeName: names,
        count: committeeNames?.length
      })}{' '}
      <MuiLink
        href={`/organization/members/${userId}`}
        sx={{
          cursor: 'pointer',
          color: 'link.main',
          textDecoration: 'none'
        }}
      >
        {t(keyPrefix + '.viewMandateLink', { count: committeeNames?.length })}
      </MuiLink>
    </Alert>
  );
};
