import { useCallback } from 'react';
import { IMeeting } from 'types';
import { Box, Divider, Stack } from '@mui/material';
import { Dialog } from '@koopajs/mui';
import { checkIsEndTopic, checkIsUnmovableTopicType } from 'utils/topicTypeArrays';
import { useParamsKey } from '@koopajs/react';
import { useState } from 'react';
import { AddTopicButton } from 'components/Meetings/AddTopicButton';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';
import { useHistory } from 'react-router-dom';
import { MeetingTopicCardEditableMovable } from 'components/Meetings/MeetingTopicCardEditable/MeetingTopicCardEditableMovable';

interface IAgendaApprovalDialog {
  liveMeeting: IUseLiveMeetingResponse;
}

export const AgendaApprovalDialog: React.FC<IAgendaApprovalDialog> = (props) => {
  const { liveMeeting } = props;
  const { meeting, users, meetingId, participants, currentTopicId, topic } = liveMeeting;

  const urlKey = useParamsKey('key');
  const isAgendaApprovalVisible = urlKey === 'agenda-approval';

  const history = useHistory();

  const keyPrefix = 'Dialogs.AgendaApprovalDialog.DialogView';

  const [topicIdEditable, setTopicIdEditable] = useState('');

  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicIdEditable(topicId);
  }, []);

  const handleUnsetTopicEditable = useCallback(() => {
    setTopicIdEditable('');
  }, []);

  const agendaApprovalIndex = meeting?.topics?.findIndex((t) => t.type === 'agendaApproval') || 0;

  const topicsUpToAgendaApprovalCount =
    meeting?.topics?.filter((t, index) => index <= agendaApprovalIndex).length || 0; //including agendaApproval

  const filteredTopicsStart = meeting?.topics?.filter(
    (t, index) => !checkIsEndTopic(t.type) && index > agendaApprovalIndex
  );

  const filteredTopicsEnd = meeting?.topics?.filter((t) => checkIsEndTopic(t.type));

  const notEndTopicCount =
    meeting?.topics?.reduce((acc, topic) => {
      return !checkIsEndTopic(topic.type) ? acc + 1 : acc;
    }, 0) || 0;

  const handleOnClose = useCallback(() => {
    history.push(`/meetings/${meetingId}/topics/${currentTopicId}`);
  }, [meetingId, currentTopicId]);
  return (
    <Dialog.View
      isOpen={isAgendaApprovalVisible && topic?.type === 'agendaApproval'}
      onClose={handleOnClose}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix }}
      maxWidth="lg"
      dialogProps={{ className: 'rr-block' }}
    >
      <Stack>
        {filteredTopicsStart?.map((topic, index) => {
          const previousTopic = meeting.topics?.[topicsUpToAgendaApprovalCount + index - 1];
          const nextTopic = meeting.topics?.[topicsUpToAgendaApprovalCount + index + 1];
          const isPreviousTopicMovable = index !== 0 && !checkIsUnmovableTopicType(previousTopic?.type || '');
          const isNextTopicMovable = !checkIsUnmovableTopicType(nextTopic?.type || '');

          return (
            <Box key={topic.id} sx={{ mt: '0px !important' }}>
              <MeetingTopicCardEditableMovable
                meetingId={meetingId}
                topic={topic}
                handleSetTopicEditable={handleSetTopicEditable}
                order={topicsUpToAgendaApprovalCount + index + 1}
                isEditable={topic.id === topicIdEditable}
                users={users}
                participants={participants}
                isMeetingStarted={true}
                onSubmitSuccess={handleUnsetTopicEditable}
                previousTopicId={isPreviousTopicMovable ? previousTopic?.id : undefined}
                nextTopicId={isNextTopicMovable ? nextTopic?.id : undefined}
              />
              <Divider sx={{ mx: 3 }} />
            </Box>
          );
        })}

        <Box sx={{ px: 3 }}>
          <AddTopicButton
            meeting={meeting as IMeeting}
            workspaceId={meeting?.workspaceId}
            setTopicIdEditable={setTopicIdEditable}
          />
          <Divider sx={{ mt: 2 }} />
        </Box>

        {filteredTopicsEnd?.map((topic, index) => {
          return (
            <Box key={topic.id} sx={{ mt: '0px !important' }}>
              <MeetingTopicCardEditableMovable
                meetingId={meetingId}
                topic={topic}
                handleSetTopicEditable={handleSetTopicEditable}
                order={notEndTopicCount + index + 1}
                isEditable={topic.id === topicIdEditable}
                users={users}
                participants={participants}
                isMeetingStarted={true}
                onSubmitSuccess={handleUnsetTopicEditable}
              />
              {index !== filteredTopicsEnd.length - 1 && <Divider sx={{ mx: 3 }} />}
            </Box>
          );
        })}
      </Stack>
    </Dialog.View>
  );
};
