import { ITopic } from 'types';
import { calculateDuration } from './calculateDuration';
import { TFunction } from 'react-i18next';

// This calculate the Estimate time of the meeting

export const calculateMeetingDuration = (
  t: TFunction<'translation', undefined>,
  topics?: ITopic[]
): string => {
  const durationSec =
    topics?.reduce((acc, topic) => {
      if (!isNaN(topic.durationSeconds)) {
        return acc + topic.durationSeconds;
      } else {
        return acc;
      }
    }, 0) || 0;

  const duration = calculateDuration(t, durationSec * 1000);
  return duration;
};
