import React, { useCallback } from 'react';
import {
  useUserShow,
  useWorkspaceList,
  useComponentVisibility,
  useLogout,
  usePermissions,
  useLocale,
  useWorkspaceShow
} from '@koopajs/react';
import { App } from '@koopajs/mui';
import { useHistory } from 'react-router';
import { Box, IconButton, Button, Typography, Link, Divider } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { IWorkspace } from 'types';
import { theme } from '../theme';

const i18nTransComponents: {
  [key: string]: React.ReactElement;
} = {
  a: (
    <a
      style={{ color: theme.palette.link.main, textDecoration: 'none' }}
      href={`mailto:support@usepanorama.com`}
    />
  ),
  span: <span style={{ opacity: '0.6' }} />,
  p: <p style={{ fontWeight: 400, fontSize: '0.875rem', margin: 0 }} />
};

export const UserProfile: React.FC = () => {
  const { user } = useUserShow();
  const { logout } = useLogout();
  const history = useHistory();
  const { t } = useLocale();
  const keyPrefix = 'UserProfile';
  const { workspaces, activeWorkspaceId, setActiveWorkspace } = useWorkspaceList();
  const createWorkspaceDialog = useComponentVisibility('createWorkspace');
  const userMenu = useComponentVisibility('userMenu');
  const hasPermissions = usePermissions({ requiredPermissions: ['workspace:edit'] });
  const workspace = useWorkspaceShow();

  const sortedWorkspaces = workspaces?.sort((a: IWorkspace, b: IWorkspace): number => {
    const userA: string = a.name || '';
    const userB: string = b.name || '';
    return userA.localeCompare(userB, 'en', { sensitivity: 'base' });
  });

  const handleWorkspaceSwitch = useCallback(async (workspaceId: string) => {
    const isSuccess = await setActiveWorkspace(workspaceId);
    if (isSuccess) {
      createWorkspaceDialog.setHidden();
      history.push('/');
    }
  }, []);

  const handleLogout = useCallback(async (): Promise<void> => {
    await logout(window.location.origin);
    window.location.reload();
  }, []);

  if (!user) {
    return null;
  }

  const username =
    user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : 'Unknown';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ mb: 1 }}>
        <IconButton
          aria-label={t('common:close')}
          onClick={userMenu.setHidden}
          sx={{ display: { xs: 'block', sm: 'none', position: 'fixed', right: 1 } }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          '& .MuiAvatar-root': {
            backgroundColor: 'customGrey.light',
            color: '#FFFFFF'
          }
        }}
      >
        <App.Profile
          i18n={{ keyPrefix: keyPrefix + '.AppProfile' }}
          username={username}
          userProfilePicture={user.profilePicture as string}
          userInitials={
            (user?.firstname ? user?.firstname?.charAt(0) : '') +
            (user?.lastname ? user?.lastname?.charAt(0) : '')
          }
          isSettingsHidden={Boolean(workspace.workspace?.isDemo) || !hasPermissions}
          activeWorkspaceId={activeWorkspaceId}
          // activeWorkspaceAvatar={workspace?.icon}
          workspaces={sortedWorkspaces}
          onWorkspaceSwitch={handleWorkspaceSwitch}
          // onWorkspaceCreation={createWorkspaceDialog.setVisible}
          handleProfileClose={userMenu.setHidden}
          onLogout={handleLogout}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          marginTop: 'auto',
          marginBottom: 4
        }}
      >
        <Divider sx={{ mx: 2, width: '100%' }} />
        <Button
          component={Link}
          target="_blank"
          rel="noopener"
          variant="outlined"
          sx={{
            width: '90%',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: '14px',
            padding: '5px 15px',
            lineHeight: '1.75'
          }}
          endIcon={<OpenInNewIcon />}
          href="https://usepanorama.atlassian.net/servicedesk/customer/portals"
        >
          {t(keyPrefix + '.viewHelpCenterLabel')}
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ opacity: '0.6', mb: 1 }}>
            {t(keyPrefix + '.technicalProblem')}
          </Typography>
          <Trans i18nKey={keyPrefix + '.writeToUs-html'} t={t} components={i18nTransComponents} />
        </Box>
      </Box>
    </Box>
  );
};
