import { Box, Typography, Divider, List, Paper } from '@mui/material';
import { IMeeting } from 'types';
import { useLocale, useResourceShow } from '@koopajs/react';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { DateTime } from 'luxon';
import { TimeStartEnd } from 'components/TimeStartEnd';
import { UsersWithTitlesList } from './UsersWithTitlesList';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { PastMinuteTopic } from './PastMinuteTopic';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

interface IPastMinutesItem {
  minutesId: string;
  setCurrentVisibleMinuteIndex: Dispatch<SetStateAction<number>>;
  index: number;
}

export const PastMinutesItem: React.FC<IPastMinutesItem> = (props) => {
  const { minutesId, setCurrentVisibleMinuteIndex, index } = props;

  const { t } = useLocale();
  const { resource: minutes } = useResourceShow<IMeeting>({ path: '/meetings', id: minutesId });

  const keyPrefix = 'BoardMemberView.TopicCards.PastMinutesApproval';

  const committeeType = useCommitteeName(minutes?.committeeId || '');

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(minutes?.activeCommitteeMembers);

  const presentParticipants = minutes?.participants?.filter((p) => p.isPresent);
  const absentParticipants = minutes?.participants?.filter((p) => !p.isPresent);

  const startTimeISOString =
    minutes?.startTime &&
    DateTime.fromFormat(minutes?.startTime as string, 'HH:mm', {
      zone: 'UTC'
    }).toString();
  const endTimeISOString =
    minutes?.endTime &&
    DateTime.fromFormat(minutes?.endTime as string, 'HH:mm', {
      zone: 'UTC'
    }).toString();

  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the element is intersecting the middle of the viewport
        if (entry.isIntersecting && entry.boundingClientRect.top < window.innerHeight / 2) {
          setCurrentVisibleMinuteIndex(index);
        }
      },
      {
        root: null, // observing viewport
        threshold: 0, // trigger as soon as any part of the element is visible
        rootMargin: '-50% 0px -50% 0px' // top/bottom margin to trigger at middle of viewport
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Paper
      elevation={6}
      sx={{
        p: { xs: '18px', lg: '60px' },
        maxWidth: '912px',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        width: '100%',
        minWidth: 0
      }}
      ref={targetRef}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="overline">{t('common:boardMinutes')}</Typography>

        <Typography variant="h6" sx={{ whiteSpace: 'normal' }}>
          <DateTimeStartEnd
            isoStringDate={
              minutes?.startDateTime ||
              (minutes?.startDate
                ? DateTime.fromFormat(`${minutes.startDate}`, 'yyyy-MM-dd').toString()
                : undefined)
            }
            dateFormat="DATE_HUGE"
          />
          {' | '} {committeeType}
        </Typography>

        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <Typography variant="body2">{minutes?.title}</Typography>
          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            &#183;
          </Typography>

          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            <TimeStartEnd
              isoStringStart={minutes?.startDateTime || startTimeISOString}
              isoStringEnd={minutes?.endDateTime || endTimeISOString}
            />
          </Typography>

          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            &#183;
          </Typography>

          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            {t(`common:locationType.${minutes?.location?.type}`)}
          </Typography>
        </Box>

        {presentParticipants && presentParticipants?.length > 0 && (
          <Box>
            <Typography variant="caption">
              {t(keyPrefix + '.presentParticipants', { count: presentParticipants.length })}
            </Typography>
            <List sx={{ pt: 0 }}>
              <UsersWithTitlesList
                userIds={presentParticipants?.map((user) => user.userId)}
                committeeMembersTitles={committeeMembersTitlesObj}
              />
            </List>
          </Box>
        )}
        {absentParticipants && absentParticipants?.length > 0 && (
          <Box>
            <Typography variant="caption">
              {t(keyPrefix + '.absentParticipants', { count: absentParticipants.length })}
            </Typography>
            <List sx={{ pt: 0 }}>
              <UsersWithTitlesList
                userIds={absentParticipants?.map((user) => user.userId)}
                committeeMembersTitles={committeeMembersTitlesObj}
              />
            </List>
          </Box>
        )}

        <Divider
          sx={{
            mt: '14px',
            mb: `${14 - 8}px` // 14 - 8px gap
          }}
        />
        {/* TOPICS */}

        {minutes?.topics?.map((topic, i) => {
          const topicsLength = Number(minutes?.topics?.length);
          return (
            <Box key={topic.id}>
              <PastMinuteTopic minutes={minutes} topic={topic} order={i + 1} />
              {i !== topicsLength - 1 && (
                <Divider
                  sx={{
                    mt: '14px',
                    mb: `${14 - 8}px` // 14 - 8px gap
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};
