import { DateTime, Interval } from 'luxon';

export const isMeetingTimeDiffValid = (startTime: string, endTime: string): boolean => {
  const meetingTimeDifference = Interval.fromDateTimes(
    DateTime.fromISO(startTime),
    DateTime.fromISO(endTime)
  );

  //checks if time difference is not negative
  const isMeetingTimeDifferenceValid = meetingTimeDifference.isValid;

  if (endTime) {
    return isMeetingTimeDifferenceValid;
  } else {
    return true;
  }
};
