/* eslint-disable @rushstack/no-new-null */
import React, { useEffect, useState, useContext } from 'react';
import { useComponentVisibility, FormContext } from '@koopajs/react';
import { useController } from 'react-hook-form';
import {
  IResourcePickerChildrenMultiProps,
  IResourcePickerChildrenSingleProps,
  IResourcePickerOption,
  IResourcePickerProps,
  TValue
} from './types';
import { AutoCompleteMultiValue } from './components/AutoCompleteMultiValue';
import { AutoCompleteSingleValue } from './components/AutoCompleteSingleValue';
import { CardEmpty } from './components/CardEmpty';
import { CardMultiValue } from './components/CardMultiValue';
import { CardSingleValue } from './components/CardSingleValue';
import { FilterOptionsState } from '@mui/material';
import { isList, ensureValueList, ensureValueSingle, filter } from './utils';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';

export const ResourcePicker: React.FC<IResourcePickerProps> = (props) => {
  const { i18n, isDisabled, isLoading, sx } = props;
  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'FormResourcePicker' });

  const context = useContext(FormContext);

  if (!context.form) {
    throw new Error('"Form.ResourcePicker" component needs to be used inside a FormController');
  }

  let defValue;
  if (props.defaultValue) {
    if (Array.isArray(props.defaultValue) && props.isMultiple) {
      defValue = props.defaultValue || [];
    } else {
      defValue = props.defaultValue || '';
    }
  }
  const fieldController = useController({
    name: props.name,
    control: context.form.control,
    defaultValue: defValue,
    rules: {
      required: !props.isOptional
    }
  });

  const [selectedValue, setSelectedValue] = useState<TValue>(fieldController.field.value);

  const value: TValue = props.isMultiple && !selectedValue ? [] : selectedValue;

  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);

  const formVisibility = useComponentVisibility(props.createDialogId || '');

  //runs if we want to track the the selected value changes (eg. we call the api when the value changes)
  useEffect(() => {
    if (selectedValue && props.onChange) {
      props.onChange(selectedValue);
    }
  }, [selectedValue, props.onChange]);

  //runs if we provide default value
  useEffect(() => {
    if (props.defaultValue) {
      if (Array.isArray(props.defaultValue) && props.defaultValue.length) {
        setSelectedValue(props.defaultValue);
      } else if (!Array.isArray(props.defaultValue)) {
        if (props.defaultValue?.id && props.defaultValue?.label) {
          setSelectedValue(props.defaultValue);
        }
      }
    }
  }, [props.defaultValue]);
  //runs every time selected value changes - controls the form
  useEffect(() => {
    if (Array.isArray(selectedValue)) {
      fieldController.field.onChange(selectedValue.map((i) => i.id));
    } else if (selectedValue) {
      fieldController.field.onChange(selectedValue.id);
    } else {
      fieldController.field.onChange(undefined);
    }
  }, [selectedValue]);

  //creates an option - eg. creates new entity
  const createOption = (id: string, data: object): void => {
    if (props.isMultiple) {
      setSelectedValue(
        props.onCreateTransform ? [props.onCreateTransform({ id, ...data })] : [data as IResourcePickerOption]
      );
    } else {
      setSelectedValue(
        props.onCreateTransform ? props.onCreateTransform({ id, ...data }) : (data as IResourcePickerOption)
      );
    }
  };

  //opens the autocomplete (when we don't pass any options to choose from and we pass to resource picker dialog id to create new options this dialog will show up)
  const handleOpen = (): void => {
    if (props.resources.length === 0) {
      formVisibility.setVisibleWithContext({
        onCreate: createOption
      });
    } else {
      setIsAutoCompleteOpen(true);
    }
  };

  const handleClose = (): void => {
    setIsAutoCompleteOpen(false);
  };

  //tracks the changes while selecting a value - used by autocomplete
  const handleSelectionChange = (
    event: React.SyntheticEvent,
    // eslint-disable-next-line
    newValue: null | IResourcePickerOption | IResourcePickerOption[]
  ): void => {
    if (!newValue) {
      setSelectedValue({ id: 'fake-autocomplete-id', label: '' });
      setIsAutoCompleteOpen(false);
    }
    const isAddButton = (i?: IResourcePickerOption): boolean => i?.id === 'add';

    if (isList(newValue)) {
      if (newValue.find(isAddButton)) {
        formVisibility.setVisibleWithContext({
          defaultValues: {
            name: newValue.find(isAddButton)?.subLabel
          },
          onCreate: (id: string, data: object) =>
            setSelectedValue([
              props.onCreateTransform
                ? props.onCreateTransform({ id, ...data })
                : (data as IResourcePickerOption),
              ...newValue.filter((i) => i.id !== 'add')
            ])
        });
      } else {
        setSelectedValue(newValue.length > 0 ? newValue : undefined);
      }
    } else {
      if (isAddButton(newValue || undefined)) {
        formVisibility.setVisibleWithContext({
          defaultValues: {
            name: newValue?.subLabel
          },
          onCreate: createOption
        });
      } else {
        setSelectedValue(newValue || undefined);
      }
    }
  };

  //options rendered when we open the autocomplete, if we pass the dialog id to create new options we we'll see eg. :'add new entity' at the bottom of the autocomplete
  const filterOptions = (
    options: IResourcePickerOption[],
    params: FilterOptionsState<IResourcePickerOption>
  ): IResourcePickerOption[] => {
    const filtered = filter(options, params);

    const addText = `${t('labelAdd')} ${params.inputValue}`;
    const addNewText = t('labelAddNew');

    if (props.createDialogId) {
      filtered.push({
        id: 'add',
        subLabel: params.inputValue,
        label: params.inputValue !== '' ? addText : addNewText
      });
    }

    return filtered;
  };

  if (props.isMultiple === true) {
    /**
     * Multi-value Mode
     */

    const typedValue = ensureValueList(value);

    const childProps: IResourcePickerChildrenMultiProps = {
      ...props,
      value: typedValue,
      handleOpen,
      handleClose,
      handleSelectionChange,
      filterOptions,
      hasError: context.form.formState.errors[props.name],
      isProcessing: context.isProcessing || isLoading || isDisabled,
      width: props.width || '100%',
      sx: sx,
      translatedLabel: t('label'),
      translatedLabelMultipleSelected: t('labelMultipleSelected'),
      translatedErrorText: t('errorText'),
      cardVariant: props.cardVariant
    };

    //return when we're selecting the values and autocomplete is opened

    if (isAutoCompleteOpen) {
      return <AutoCompleteMultiValue {...childProps} />;
    }

    //return if we want different behavior for the selected value than <CardMultiValue/>

    if (typedValue.length > 0) {
      if (props.renderWhenSelected) {
        return props.renderWhenSelected(typedValue, handleOpen);
      }
      //return card with selected value
      return <CardMultiValue {...childProps} />;
    }
    //return if we want different behavior for the selected value than <CardEmpty/>

    if (props.renderWhenEmpty) {
      return props.renderWhenEmpty(handleOpen);
    }
    // no values are selected
    return <CardEmpty {...childProps} />;
  } else {
    /**
     * Single-value Mode
     */

    const typedValue = ensureValueSingle(value);

    const childProps: IResourcePickerChildrenSingleProps = {
      ...props,
      value: typedValue,
      handleOpen,
      handleClose,
      handleSelectionChange,
      filterOptions,
      hasError: context.form.formState.errors[props.name],
      isProcessing: context.isProcessing || isLoading || isDisabled,
      width: props.width || '100%',
      sx: sx,
      translatedLabel: t('label'),
      translatedErrorText: t('errorText'),
      cardVariant: props.cardVariant
    };

    //return when we're selecting the value and autocomplete is opened
    if (isAutoCompleteOpen) {
      return <AutoCompleteSingleValue {...childProps} />;
    }
    //return if we want different behavior for the selected value than <CardSingleValue/>
    if (typedValue) {
      if (props.renderWhenSelected) {
        return props.renderWhenSelected(typedValue, handleOpen);
      }

      //return when 1 value is selected
      return <CardSingleValue {...childProps} />;
    }
    //return if we want different behavior for the selected value than <CardEmpty/>

    if (props.renderWhenEmpty) {
      return props.renderWhenEmpty(handleOpen);
    }

    // return CardEmpty when no value is selected
    return <CardEmpty {...childProps} />;
  }
};

export default React.memo(ResourcePicker);
