import { useLocale } from '@koopajs/react';
import { EmptyState } from 'components/EmptyState';
import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const NotFound: React.FC = () => {
  const { t } = useLocale();

  return (
    <EmptyState
      title={t('NotFound.EmptyState.title')}
      button={
        <Button component={RouterLink} to="/" variant="contained" disableElevation>
          {t('NotFound.EmptyState.goToDashboardButton')}
        </Button>
      }
    />
  );
};
