/* eslint-disable no-extra-parens */
import RichTextMultiLine from 'components/temp/RichTextMultiLine';

interface IBodyFieldProps {
  variant?: 'large' | 'small';
  isNotAutofocused?: boolean;
}

export const BodyField: React.FC<IBodyFieldProps> = ({ variant = 'large', isNotAutofocused }) => {
  return (
    <RichTextMultiLine
      name="notes"
      validationRules={{ maxLength: 20000 }}
      isOptional={true}
      height="100%"
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
      sxEditorContainer={{
        flexGrow: 1,
        // hacky way to access extra div in tiptap
        '& > div': { height: '100%' }
      }}
      minHeight={`${3 * 23 + 16.5 * 2}px`} // (rows * 23px) + (padding * 2)
      i18n={{ keyPrefix: 'MeetingStateInProgress.RichTextMultiLineNotes' }}
      textFieldVariant="transparent"
      isAutofocused={!isNotAutofocused}
    />
  );
};
