import { useResourceDelete, useResourceUpdate } from '@koopajs/react';
import { ITopic, IMeeting } from 'types';
import { useCallback } from 'react';
import { useResourceShow } from '@koopajs/react';
import { parseUTCDateTime } from 'utils/parseUTCDateTime';

interface IUseTopicProps {
  topicId: string;
  meetingId: string;
  isMeetingStarted: boolean;
  onSubmitSuccess?: () => void;
}

interface IUseTopicResponse {
  onSubmit: (formData: any) => Promise<boolean>;
  deleteTopic: () => Promise<boolean>;
  updateTopic: (data: object) => Promise<boolean>;
}

export const useTopic = (props: IUseTopicProps): IUseTopicResponse => {
  const { topicId, meetingId, isMeetingStarted, onSubmitSuccess } = props;
  const { resource: meeting } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId,
    useCache: true
  });

  const foundTopic = meeting?.topics?.find((t) => t.id === topicId);

  const { updateResource: updateTopic } = useResourceUpdate({
    path: `/meetings/${meetingId}/topics`,
    id: topicId,
    customReducer: {
      path: {
        resourceType: `/meetings`,
        resourceId: meetingId
      },
      mapping: (meeting: object, editedTopic: object) => {
        const oldMeeting = meeting as IMeeting;
        const newEditedTopic = editedTopic as ITopic;
        return {
          ...oldMeeting,
          topics: oldMeeting.topics?.map((t: ITopic) => {
            if (t.id === newEditedTopic.id) {
              return newEditedTopic;
            } else {
              return t;
            }
          })
        };
      }
    }
  });

  const onSubmit = useCallback(
    async (formData: {
      visibleBy: { [k: string]: string };
      pastMeetingsToApprove: { [k: string]: string };
      description: string;
      notes: string;
      quorumReachedAtOverwrittenTime: string;
      quorumReachedAtOverwritten: string;
    }): Promise<boolean> => {
      let visibleByArray: string[] | undefined = [];
      if (formData.visibleBy) {
        for (const [id, value] of Object.entries(formData.visibleBy)) {
          if (value) visibleByArray.push(id);
        }
      } else {
        visibleByArray = undefined;
      }

      let minutesToApproveArray: { meetingId: string; isApproved: boolean }[] | undefined = [];
      if (formData.pastMeetingsToApprove) {
        for (const [id, value] of Object.entries(formData.pastMeetingsToApprove)) {
          if (value) minutesToApproveArray.push({ meetingId: id, isApproved: false });
        }
      } else {
        minutesToApproveArray = undefined;
      }

      if (formData.quorumReachedAtOverwrittenTime) {
        const quorumDateTimeOverwritten = parseUTCDateTime(
          meeting?.minutesStartedAt as string,
          formData.quorumReachedAtOverwrittenTime
        );

        formData.quorumReachedAtOverwritten = quorumDateTimeOverwritten.toISOString();
      }

      try {
        let notes = foundTopic?.notes;
        let description = foundTopic?.description;

        // prepare page - we update description and notes fields
        if (!meeting?.minutesStartedAt) {
          notes = formData.description;
          description = formData.description;

          //meeting in progress - agenda approval topic
        } else if (meeting.minutesStartedAt && !meeting.minutesEndedAt) {
          description = formData.description;

          // if notes has not been updated on an individual topic page
          if (foundTopic?.description === foundTopic?.notes) {
            notes = formData.description;
          }

          //review page we update only notes fields
        } else if (meeting.minutesEndedAt) {
          notes = formData.notes;
        }

        const res = await updateTopic({
          ...formData,
          visibleBy: visibleByArray,
          pastMeetingsToApprove: minutesToApproveArray,
          ...(formData.description && { description }),
          ...((formData.notes || formData.description) && { notes })
        });

        if (onSubmitSuccess && res) {
          onSubmitSuccess();
        }

        return res;
      } catch (error) {
        return false;
      }
    },
    [topicId, JSON.stringify(meeting)]
  );

  const {
    deleteResource: deleteTopic
    // isProcessing: isProcessingDelete,
    // errorMessage: errorMessageDelete
  } = useResourceDelete({
    path: `/meetings/${meetingId}/topics`,
    id: topicId,
    customReducer: {
      path: { resourceType: `/meetings`, resourceId: meetingId },
      mapping: (prevMeeting: object, newMeeting: object) => {
        const oldMeeting = prevMeeting as IMeeting;
        return {
          ...oldMeeting,
          topics: oldMeeting.topics?.filter((t: ITopic) => t.id !== topicId)
        };
      }
    }
  });

  return { onSubmit, deleteTopic, updateTopic };
};
