import { IUploadedResolution } from 'types';
import { Typography, Box, Stack, Button, Paper } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { SystemStyleObject } from '@mui/system';
import { formatDate } from 'utils/DateTime/formatDate';
import { DocumentList } from 'components/DocumentList';

interface IUploadedResolutionCard {
  resolution: IUploadedResolution;
}

export const UploadedResolutionCard: React.FC<IUploadedResolutionCard> = (props) => {
  const { resolution } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'Resolutions.ResolutionCard';
  const history = useHistory();

  const renderViewResolutionButton = (props?: { sx?: SystemStyleObject }): React.ReactElement => {
    const { sx } = props || {};
    return (
      <Button
        component={RouterLink}
        sx={{ ...sx }}
        to={{ pathname: `/uploaded-resolutions/${resolution.id}`, search: history.location?.search }}
        data-cy="uploaded-resolution-card_action-button"
      >
        {t(keyPrefix + '.viewResolutionButtonLabel')}
      </Button>
    );
  };

  return (
    <Paper
      data-cy="uploaded-resolution-card"
      data-committee-id={resolution.committeeId}
      variant="outlined"
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        my: 2
      }}
    >
      {/* Reso Number, Title & Button */}
      <Stack direction="row" justifyContent="space-between" sx={{ mb: { xs: '14px', md: 0 } }}>
        <Box sx={{ minWidth: 0 }}>
          {resolution.resolutionNumber && (
            <Typography variant="caption" sx={{ opacity: '0.6' }}>
              {t(keyPrefix + '.resolution', { number: resolution.resolutionNumber })}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{ fontSize: '18px', textOverflow: 'ellipsis', overflow: 'hidden' }}
            data-cy="uploaded-resolution-card_title"
          >
            {resolution.title}
          </Typography>
          <Typography variant="body2" sx={{ opacity: '0.6', mt: '14px' }}>
            {t(`common:TopicModel.resolutionOutcome.outcome.${resolution.resolutionOutcome.outcome}`)}

            {resolution.resolutionOutcome.recordedAt && (
              <>
                <span> {t('common:on')} </span>
                {formatDate({ isoString: resolution.resolutionOutcome.recordedAt, locale })}
              </>
            )}
          </Typography>
        </Box>
        {/* desktop button */}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {renderViewResolutionButton({ sx: { whiteSpace: 'nowrap' } })}
        </Box>
      </Stack>

      {/* Documents */}
      {resolution.documentsIds && resolution.documentsIds.length > 0 && (
        <DocumentList
          key={resolution.id}
          path={`/uploaded-resolutions/${resolution.id}/documents`}
          isAccordion={true}
          accordionOptions={{ documentsVisible: 0 }}
          showDivider
        />
      )}
      {/* mobile button */}
      <Box sx={{ display: { md: 'none' } }}>{renderViewResolutionButton({ sx: { p: 0, mt: 3 } })}</Box>
    </Paper>
  );
};
