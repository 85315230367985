import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useResourceList, useComponentVisibility, useLocale } from '@koopajs/react';
import { AccessValidator } from '@koopajs/mui';
import {
  Button,
  Paper,
  IconButton,
  Stack,
  Box,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Dialog } from '@koopajs/mui';
import { InvitationRows } from './InvitationsRows';
import { IUserPermission, IWorkspaceInvitation } from '../../../types';

const PermissionOptions: { [k: string]: string } = {
  owner: 'owner',
  admin: 'admin',
  member: 'team-member',
  basic: 'external'
};

export const SettingsPermissions: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'Settings.UsersAndPermissions';
  const invitations = useResourceList<IWorkspaceInvitation>({
    path: '/invitations',
    searchParams: { size: 50 }
  });
  const users = useResourceList<IUserPermission>({ path: '/users', searchParams: { size: 50 } });
  const inviteUser = useComponentVisibility('inviteUser');
  const editUserPermissions = useComponentVisibility('editPermissions');
  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // invitations

  const showInvitationDialog = useCallback(() => {
    inviteUser.setVisible();
  }, []);

  const handleCloseInvitationMenu = useCallback(() => {
    setAnchorEl(null);
    setOpenRowId(null);
  }, []);

  //edit permissions

  const handleOpenUserMenu = useCallback(
    (userId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenRowId(userId);
    },
    []
  );

  const handleCloseUserMenu = useCallback(() => {
    setAnchorEl(null);
    setOpenRowId(null);
  }, []);

  const handleEditPermissions = useCallback((userIdValue: string) => {
    return () => {
      editUserPermissions.setVisibleWithContext({ userId: userIdValue });
      handleCloseInvitationMenu();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('common:settings')} - Panorama`}</title>
      </Helmet>
      <Stack direction="row" justifyContent="space-between" sx={{ mx: 1, my: 2 }}>
        <Typography variant="body1">{`${t(keyPrefix + '.users')}`}</Typography>
        {/* <Button startIcon={<AddIcon />} size="small" variant="outlined" onClick={showInvitationDialog}>
          {`${t(keyPrefix + '.labelInviteUser')}`}
        </Button> */}
      </Stack>
      <Paper variant="outlined">
        <Dialog.EditPermissions
          options={[
            // { id: 'admin', key: 'admin' },
            { id: 'member', key: 'team-member' },
            { id: 'basic', key: 'external' }
          ]}
          userId=""
          isDeleteVisible={true}
          i18n={{ keyPrefix: keyPrefix + '.DialogEditPermissions' }}
        />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{`${t(keyPrefix + '.name')}`}</TableCell>
                <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  {`${t(keyPrefix + '.status')}`}
                </TableCell>
                <TableCell align="right">{`${t(keyPrefix + '.permission')}`}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.resources.map((user) => (
                <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar src={user.profilePicture as string} />
                      <Stack direction="column">
                        <Box>{user.username as string}</Box>
                        <Box>{user.email as string}</Box>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {`${t(keyPrefix + '.active')}`}
                  </TableCell>
                  {/* //TODO: in future we can have more than 1 permission */}
                  <TableCell align="right">
                    {user?.permissions &&
                      user?.permissions?.map((p: string) => t(keyPrefix + `.${PermissionOptions[p]}`))}
                  </TableCell>
                  <TableCell align="right">
                    <AccessValidator permissions="permissions:update">
                      {(props) => (
                        <>
                          {props.hasPermissions && (
                            <>
                              <IconButton onClick={handleOpenUserMenu(user.id)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={openRowId === user.id}
                                onClose={handleCloseUserMenu}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <MenuItem onClick={handleEditPermissions(user.id)}>
                                  {' '}
                                  {`${t(keyPrefix + '.editPermissions')}`}
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </>
                      )}
                    </AccessValidator>
                  </TableCell>
                </TableRow>
              ))}

              {invitations.resources && invitations.resources.length > 0 && (
                <>
                  {invitations.resources.map((invitation) => {
                    if (!invitation.acceptedAt) {
                      return <InvitationRows invitation={invitation} key={invitation.id} />;
                    }
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
