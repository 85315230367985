import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { Typography, Button, Container, Stack } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';
import { ITopic } from 'types';
import { useTopic } from 'components/hooks/useTopic';

interface IDeleteReviewTopic {
  topic: ITopic;
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export const DeleteReviewTopic: React.FC<IDeleteReviewTopic> = (props) => {
  const { topic, isOpen, onClose, onSubmitSuccess } = props;

  const { t } = useLocale();
  const keyPrefix = 'Modals.ModalDeleteReviewTopic';

  const { deleteTopic } = useTopic({
    topicId: topic.id,
    meetingId: topic.meetingId,
    isMeetingStarted: true
  });

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  const handleDeleteTopic = useCallback(async () => {
    await deleteTopic();

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  }, [topic.id]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseModal}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'delete-topic-dialog' } }}
      maxWidth="md"
    >
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 3 }}
      >
        <Typography variant="h6">{t(keyPrefix + '.title')}</Typography>

        <Typography sx={{ whiteSpace: 'pre-line' }}>{t(keyPrefix + '.description')}</Typography>

        {renderConfirmDeleteField()}

        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={handleCloseModal}>
            {t('common:labelCancel')}
          </Button>
          <Button
            data-cy="delete-topic-dialog_delete-button"
            color="error"
            sx={{ px: 6.5, mx: 1 }}
            variant="contained"
            onClick={handleDeleteTopic}
            disabled={!hasUserTypedDeleteConfirmation}
          >
            {t(keyPrefix + '.labelDelete')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
