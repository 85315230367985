import React, { useCallback } from 'react';
import { useWorkspaceShow, useWorkspaceUpdate, useFileUpload, useLocale } from '@koopajs/react';
import { Settings, ErrorMessage } from '@koopajs/mui';
import { ImageEditor } from '@koopajs/mui/dist/components/Settings/ImageEditor';
import { Divider, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import Panorama from '../../../assets/panorama-logo.png';

export const SettingsGeneral: React.FC = () => {
  const { t } = useLocale();
  const { workspace, errorMessage } = useWorkspaceShow();
  const { updateWorkspace } = useWorkspaceUpdate();
  const { upload } = useFileUpload();
  const keyPrefix = 'Settings.General';

  const handleProfileChange = useCallback(
    async (values: { [k: string]: unknown }): Promise<boolean> => {
      return await updateWorkspace(values);
    },
    [updateWorkspace]
  );

  const handlePhotoChange = useCallback(
    async (photo: Blob): Promise<void> => {
      const icon = await upload(photo, 'cdn');
      await handleProfileChange({ icon });
    },
    [handleProfileChange]
  );

  if (!workspace) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`${t('common:settings')} - Panorama`}</title>
      </Helmet>

      <ErrorMessage error={errorMessage} />
      <Box sx={{ py: 2 }}>
        <ImageEditor
          appLogoSrc={workspace.icon || Panorama}
          handleValueChange={handlePhotoChange}
          i18n={{ keyPrefix: keyPrefix + '.ImageEditor' }}
        />
      </Box>
      <Divider />
      <Box sx={{ py: 2 }}>
        <Settings.FieldEditor
          uniqueDialogId="Name"
          value={workspace.name || 'Unknown'}
          fields={[{ key: 'name', label: 'Name' }]}
          handleValueChange={handleProfileChange}
          i18n={{ keyPrefix: keyPrefix + '.FieldEditor' }}
          defaultValues={workspace}
        />
      </Box>
      {workspace.inboundEmailAddress && (
        <>
          <Divider />

          <Box sx={{ py: 2 }}>
            <Settings.EmailInboundLink
            // value={`Lawy ${workspace.name} <${workspace.inboundEmailAddress}>`}
            // url={`mailto:Lawy ${workspace.name} <${workspace.inboundEmailAddress}>`}
            />
          </Box>
        </>
      )}
      <Divider />

      <Box sx={{ py: 2 }}>
        <Settings.ReadOnly
          i18n={{ keyPrefix: keyPrefix + '.ReadOnlyWorkspaceId' }}
          value={`w:${workspace.id}`}
        />
      </Box>
    </>
  );
};
