import {
  useLocale,
  useResourceList,
  useWorkspaceShow,
  useResourceUpdate,
  useResourceShow
} from '@koopajs/react';
import { Box, Paper, Skeleton } from '@mui/material';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { IMeeting, ITopic } from 'types';
import { useCallback } from 'react';
import { PastMinutesApprovalField } from '../PastMinutesApprovalField';
import { ErrorMessage } from '@koopajs/mui';

interface IPastMinutesApprovalFieldFormControllerProps {
  topic: ITopic;
}

export const PastMinutesApprovalFieldFormController: React.FC<
  IPastMinutesApprovalFieldFormControllerProps
> = (props) => {
  const { topic } = props;

  const { updateResource: addPastMeetingsToApprove, isProcessing: isAddPastMeetingsToApproveProcessing } =
    useResourceUpdate({
      path: `/meetings/${topic.meetingId}/topics/${topic.id}/minutes-to-approve`,
      id: '',
      customReducer: {
        path: {
          resourceType: `/meetings`,
          resourceId: topic.meetingId
        },
        mapping: (meeting: object, topic: object) => {
          const oldMeeting = meeting as IMeeting;
          const updatedPastMinutesApprovalTopic = topic as ITopic;

          const updatedTopics = oldMeeting.topics?.map((t) => {
            if (t.id === updatedPastMinutesApprovalTopic.id) {
              return {
                ...t,
                pastMeetingsToApprove: updatedPastMinutesApprovalTopic.pastMeetingsToApprove
              };
            }
            return t;
          });

          return {
            ...oldMeeting,
            topics: updatedTopics
          };
        }
      }
    });

  const onSubmit = useCallback(
    async (formData: object) => {
      const formTyped = formData as { pastMeetingsToApprove?: { [k: string]: string } };
      const payload: {
        pastMeetingsToApprove: { meetingId: string; isApproved: boolean }[];
      } = { pastMeetingsToApprove: [] };

      if (formTyped.pastMeetingsToApprove) {
        for (const [id, value] of Object.entries(formTyped.pastMeetingsToApprove)) {
          if (value) payload.pastMeetingsToApprove.push({ meetingId: id, isApproved: false });
        }
      }

      try {
        await addPastMeetingsToApprove(payload);
        return true;
      } catch (error) {
        return false;
      }
    },
    [topic.meetingId, topic.id]
  );

  // workaround for error message
  const { errorMessage } = useResourceShow({
    path: `/meetings`,
    id: topic.meetingId,
    useCache: true
  });

  const { resources: meetings, isProcessing: isMeetingsProcessing } = useResourceList<IMeeting>({
    path: '/minutes-to-approve'
  });

  // fixes bug where current meeting shows
  const filteredMeetings = meetings.filter((meeting) => meeting.id !== topic.meetingId);

  return (
    <FormControllerTemp onSubmit={onSubmit} defaultValues={{}} onSubmitSuccessResetType="DEFAULT_VALUES">
      <ErrorMessage error={errorMessage} />
      <>
        {filteredMeetings.length > 0 && (
          <>
            <Paper variant="outlined" sx={{ mt: 2 }}>
              <PastMinutesApprovalField
                isProcessing={isMeetingsProcessing || isAddPastMeetingsToApproveProcessing}
                meetings={filteredMeetings}
                topic={topic}
              />
            </Paper>
          </>
        )}
      </>
    </FormControllerTemp>
  );
};
