import React from 'react';
import { IResourcePickerChildrenMultiProps } from '../types';
import { Card, CardActionArea, Avatar, AvatarGroup, CardContent, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const concatenateProps: {
  whiteSpace: string;
  overflow: string;
  textOverflow: string;
} = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const CardMultiValue: React.FC<IResourcePickerChildrenMultiProps> = (props) => {
  const {
    width,
    value: values,
    isProcessing: isLoadingForm,
    isLoading,
    isDisabled,
    handleOpen,
    isAvatar,
    sx,
    translatedLabelMultipleSelected,
    cardVariant
  } = props;
  const isProcessing = isLoading || isDisabled || isLoadingForm;

  const sxProps = values.length > 2 ? concatenateProps : '';

  return (
    <Card
      variant={cardVariant || 'outlined'}
      sx={{
        width: width,
        opacity: isProcessing ? 0.5 : 1,
        ...sx
      }}
    >
      <CardActionArea onClick={handleOpen} disabled={isProcessing} sx={{ height: '100%' }}>
        <CardContent sx={{ display: 'flex', p: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '0px' }}>
            {isAvatar && (
              <AvatarGroup max={3} sx={{ marginRight: 2 }}>
                {values.map((value) => (
                  <Avatar key={value.id}>{value.labelAvatar ? value.labelAvatar : value.label?.[0]}</Avatar>
                ))}
              </AvatarGroup>
            )}
            <Box sx={{ ml: isAvatar ? 0 : 1, py: isAvatar ? 0 : 1, minWidth: '0px' }}>
              <Typography variant="body2" color="primary" sx={{ ...sxProps }}>
                {values.map((i) => i.label).join(', ')}
              </Typography>
              <Typography variant="body2" color="#00000099">
                {values.length === 1
                  ? values[0].subLabel
                  : `${values.length} ${translatedLabelMultipleSelected}`}
              </Typography>
            </Box>
          </Box>
          <EditIcon sx={{ color: '#0000008a' }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
