import React from 'react';
import { useLocale } from '@koopajs/react';
import { Box, Typography, Chip } from '@mui/material';
import { ITopic } from '../../../types';
import { Person as PersonIcon, TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { formatTime } from 'utils/DateTime/formatTime';
import { DateTime } from 'luxon';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { DocumentList } from 'components/DocumentList';
import { PastMeetingsToApproveList } from '../../TopicCard/PastMinutesApprovalField/PastMeetingsToApproveList';
import { User } from '@koopajs/mui';
import { TopicChipConditions } from 'components/TopicChipConditions';

interface IMeetingViewTopicCardProps {
  topic: ITopic;
  order: number;
  showApprovedPastMeetingsToApprove?: boolean;
}

export const MeetingViewTopicCard: React.FC<IMeetingViewTopicCardProps> = (props) => {
  const { topic, order, showApprovedPastMeetingsToApprove } = props;

  const { t, locale } = useLocale();
  const hasDocuments = topic.documentsIds && topic.documentsIds.length > 0;

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      data-cy="meeting-view_topic-card"
      className={`meeting-view_topic-card-${topic.type}`}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {/* TIMESTAMP */}
        <Chip
          label={
            topic.calculatedTopicStartTime ? (
              formatTime({
                isoString: DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO(),
                locale
              })
            ) : (
              <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
            )
          }
          sx={{
            mr: { xs: 'auto', lg: 8 },
            ...(topic.calculatedTopicStartTime
              ? { fontVariantNumeric: 'tabular-nums' }
              : {
                  '& .MuiChip-label': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '50px'
                  }
                })
          }}
        />
        <Box sx={{ display: 'flex', gap: 4 }}>
          {/* MEMBER NAME DESKTOP*/}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 1 }}>
            <PersonIcon color="action" fontSize="small" />
            <Box
              sx={{
                opacity: 0.6,
                ml: 1,
                minWidth: '0px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <User id={topic.assignedTo} variant="text-only" />
            </Box>
          </Box>
          {/* TOPIC TYPE */}
          <Box sx={{ minWidth: '100px' }}>
            <TopicChipConditions topicType={topic.type} />
          </Box>
        </Box>
      </Box>
      {/* ORDER & TITLE */}
      <Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
        </Typography>
      </Box>{' '}
      {/* MEMBER TITLE MOBILE*/}
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center', gap: 1 }}>
        <PersonIcon color="action" fontSize="small" />
        <Box
          sx={{
            opacity: 0.6,
            ml: 1,
            minWidth: '0px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <User id={topic.assignedTo} variant="text-only" />
        </Box>
      </Box>
      {/* BODY FIELD */}
      {[
        'quorum',
        'nominations',
        'agendaApproval',
        'pastMinutesApproval',
        'ceoReport',
        'information',
        'discussion',
        'resolution',
        'miscellaneous',
        'adjournment'
      ].includes(topic.type) &&
        topic.description && (
          <RichTextReadOnly
            value={topic.description}
            sxEditorContainer={{
              fontSize: '1rem',
              lineHeight: '1.4375em',
              letterSpacing: '0.00938em',
              color: 'rgba(0, 0, 0, 0.87)'
            }}
          />
        )}
      {/* DOCUMENTS LIST */}
      {[
        'pastMinutesApproval',
        'ceoReport',
        'information',
        'discussion',
        'resolution',
        'miscellaneous'
      ].includes(topic.type) &&
        hasDocuments && (
          <DocumentList path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`} />
        )}{' '}
      {/* SELECT PAST MINUTES APPROVAL */}
      {topic.type === 'pastMinutesApproval' && (
        <PastMeetingsToApproveList
          meetingId={topic.meetingId}
          topicId={topic.id}
          topic={topic}
          showApprovedMeetings={showApprovedPastMeetingsToApprove ? true : false}
        />
      )}
    </Box>
  );
};
