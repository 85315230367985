import { CommitteeMemberTitle, IActiveCommitteeMembers } from 'types';

export const getCommitteeMembersTitlesObj = (
  activeCommitteeMembers?: IActiveCommitteeMembers[]
): Record<string, CommitteeMemberTitle> | {} => {
  if (!activeCommitteeMembers) return {};

  return activeCommitteeMembers.reduce<Record<string, CommitteeMemberTitle>>(
    (accumulator, committeeMember) => {
      accumulator[committeeMember.userId] = committeeMember.title;
      return accumulator;
    },
    {}
  );
};
