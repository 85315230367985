import { Button, Link } from '@mui/material';
import { useLocale, useResourceUpdate } from '@koopajs/react';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

interface IValidateMinutesButton {
  meetingId: string;
  isRevalidate?: boolean;
}

export const ValidateMinutesButton: React.FC<IValidateMinutesButton> = (props) => {
  const { meetingId, isRevalidate } = props;

  const { t } = useLocale();

  const history = useHistory();

  const { updateResource } = useResourceUpdate({ path: '/meetings-to-review', id: meetingId });

  const handleUpdateMeetingTimeline = useCallback(async () => {
    const res = await updateResource({});

    // Temporary slow down the application a little bit to
    // make sure the back-end syncs all the information
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (res) {
      history.push('/pending-review');
    }
  }, []);

  return (
    <Button
      component={Link}
      variant={isRevalidate ? 'outlined' : 'contained'}
      onClick={handleUpdateMeetingTimeline}
      data-cy="meeting-review-page_validate-button"
    >
      {isRevalidate ? t('common:labelRevalidate') : t('common:labelValidate')}
    </Button>
  );
};
