import { CommitteeMemberRole, CommitteeMemberTitle, ICommitteeMember } from 'types';
import { useLocale } from '@koopajs/react';
import { Box, Stack, Typography, Paper, Chip, Divider, IconButton } from '@mui/material';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { PermissionTableMember } from './PermissionTableMember';
import { MandateStartEndDates } from 'components/Organization/MandateStartEndDates';
import { Edit as EditIcon } from '@mui/icons-material';
import { Dispatch, useCallback, useState, SetStateAction } from 'react';
import { CommitteeMemberCardEditable } from './CommitteeMemberCardEditable';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { DateTime } from 'luxon';
import { committeeMemberRoles } from 'utils/committeeMembers/committeeMemberRoles';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import { usePermissions } from '@koopajs/react';
import { Api } from '@koopajs/app';

interface ICommitteeMemberCardProps {
  committeeMember: ICommitteeMember;
  updateCommitteeMembers: Dispatch<SetStateAction<ICommitteeMember[]>>;
}

export const CommitteeMemberCard: React.FC<ICommitteeMemberCardProps> = (props) => {
  const { committeeMember, updateCommitteeMembers } = props;
  const { t } = useLocale();
  const keyPrefix = 'OrganizationMember';
  const committeeName = useCommitteeName(committeeMember.committeeId);

  const [isInEditMode, setIsInEditMode] = useState(false);

  const { triggerSnackbar } = useAppSnackbar();
  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const handleSetToEditMode = useCallback(() => {
    return () => setIsInEditMode(true);
  }, []);

  const handleSubmit = useCallback(
    async (formData: object): Promise<boolean> => {
      const payload = { ...formData } as {
        startAt: string;
        endAt: string;
        title: CommitteeMemberTitle;
        roles: {
          role: CommitteeMemberRole;
          isOptional?: boolean;
        }[];
      };

      // remove falsy roles
      let updatedRoles = payload.roles?.filter((role) => role.role);
      // sets reviewMeetings to be optional by default
      updatedRoles = updatedRoles?.map((role) =>
        role.role === 'reviewMeetings' ? { role: 'reviewMeetings', isOptional: true } : role
      );

      payload.roles = updatedRoles;

      if (payload.startAt) payload.startAt = new Date(payload.startAt + `T00:00:00Z`).toISOString();
      if (payload.endAt) payload.endAt = new Date(payload.endAt + `T00:00:00Z`).toISOString();

      try {
        const res = await Api.client.put(
          `/committees/${committeeMember.committeeId}/members/${committeeMember.id}`,
          { resource: payload }
        );
        const updatedCommitteeMember = res.data.resource as ICommitteeMember;

        updateCommitteeMembers((prev) => {
          const updatedCommitteeMembers = prev.map((member) => {
            if (member.id === updatedCommitteeMember.id) {
              return updatedCommitteeMember;
            } else {
              return member;
            }
          });
          return updatedCommitteeMembers;
        });

        setIsInEditMode(false);
      } catch (e) {
        console.log(e);
        triggerSnackbar({
          snackbarText: t('OrganizationCommittee.CommitteeMemberRow.snackbarEditFail'),
          variant: 'error'
        });
      }

      return true;
    },
    [committeeMember.id, committeeMember.committeeId, updateCommitteeMembers]
  );

  const defaultValues = {
    startAt:
      committeeMember.startAt && DateTime.fromISO(committeeMember.startAt).toUTC().toFormat('yyyy-MM-dd'),
    endAt: committeeMember.endAt && DateTime.fromISO(committeeMember.endAt).toUTC().toFormat('yyyy-MM-dd'),
    title: committeeMember.title,
    roles: committeeMemberRoles.map((role) => {
      const roleDefaultValue = committeeMember.roles?.find((r) => r.role === role);

      if (roleDefaultValue) {
        return roleDefaultValue;
      } else {
        return { role: '' };
      }
    })
  };

  return (
    <Paper
      key={committeeMember.id}
      variant="outlined"
      sx={{ p: 3, flexBasis: { xs: '100%', md: 'calc(50% - 12px)', minWidth: '0px' } }}
      data-cy="organization_member-committee-card"
    >
      {isInEditMode && isUserWorkspaceOwner ? (
        <FormControllerTemp
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          onSubmitSuccessResetType="NO_RESET"
        >
          <CommitteeMemberCardEditable committeeName={committeeName} />
        </FormControllerTemp>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" component="h3">
              {committeeName}
            </Typography>
            {isUserWorkspaceOwner && (
              <IconButton
                data-cy="organization_member-committee-card_edit-button"
                sx={{ alignSelf: 'flex-start', ml: 2 }}
                onClick={handleSetToEditMode()}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
          <Stack sx={{ mt: '30px' }} spacing={2.5}>
            {/* TITLE (ROLE) */}
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="span"
                sx={{
                  width: { xs: '96px', sm: '160px', md: '224px' },
                  display: 'inline-block'
                }}
              >
                {t(keyPrefix + '.committeeCard.title')}
              </Box>

              <Box
                component="span"
                sx={{
                  minWidth: '0px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <Chip variant="outlined" label={t(`common:committeeMemberTitles.${committeeMember.title}`)} />
              </Box>
            </Typography>

            {/* MANDATE */}
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component="span"
                sx={{ width: { xs: '96px', sm: '160px', lg: '224px' }, display: 'inline-block' }}
              >
                {t(keyPrefix + '.committeeCard.mandate')}
              </Box>
              <MandateStartEndDates committeeMember={committeeMember} />
            </Typography>
          </Stack>{' '}
          <Divider
            sx={{ mt: { xs: 0, md: 3 }, mb: { xs: 0, md: 1 }, display: { xs: 'none', md: 'block' } }}
          />
          {/* PERMISSIONS */}
          {committeeMember.roles?.length && committeeMember.roles.length > 0 ? (
            <PermissionTableMember permissions={committeeMember.roles || []} />
          ) : (
            <>
              <Divider sx={{ my: { xs: 3, md: 0 }, display: { xs: 'block', md: 'none' } }} />
              <Typography variant="caption" color="textSecondary">
                {t(keyPrefix + '.noPermissions')}
              </Typography>
            </>
          )}
        </>
      )}
    </Paper>
  );
};
