import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useWorkspaceShow, useWorkspaceUpdate, useWorkspaceCreate, FormController } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { Button, Box, Stack } from '@mui/material';
import { ICoreProps, ErrorMessage } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { useHistory } from 'react-router-dom';
import { TextField } from 'components/temp/TextFieldTemp';

export interface IContentWorkspaceProps extends ICoreProps {
  moveNext: () => void;
  movePrevious: () => void;
  finishOnBoarding?: () => Promise<void>;
  errorMessageUserUpdate?: string;
}

export const ContentWorkspace: React.FC<IContentWorkspaceProps> = (props: IContentWorkspaceProps) => {
  const { movePrevious, i18n, finishOnBoarding, errorMessageUserUpdate } = props;

  const { t } = useLocale();
  const keyPrefix = i18n?.keyPrefix;

  const { workspace, isProcessing, errorMessage } = useWorkspaceShow();
  const { updateWorkspace } = useWorkspaceUpdate();
  const { createWorkspace } = useWorkspaceCreate();
  const history = useHistory();

  const onSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const workspaceName: string = (formData as unknown as { name: string }).name;

    const isSuccessful = workspace?.id
      ? await updateWorkspace({ name: workspaceName })
      : await createWorkspace(workspaceName);

    if (isSuccessful) {
      if (finishOnBoarding) {
        await finishOnBoarding();
      }
      TrackEvent('onboarding-workspace');
      history.push('/');
    }

    return isSuccessful;
  }, []);

  return (
    <Box>
      <Typography>{t(keyPrefix + '.title')}</Typography>
      <ErrorMessage error={errorMessage} />
      {errorMessageUserUpdate && <ErrorMessage error={errorMessageUserUpdate} />}

      <Box>
        <Box>
          <FormController onSubmit={onSubmit} defaultValues={{ name: workspace?.name || '' }}>
            <TextField
              isAutoFocus
              name="name"
              validationRules={{ minLength: 2, maxLength: 60 }}
              i18n={{ keyPrefix: `${keyPrefix}.TextFieldWorkspace` }}
            />
            <Stack direction="row" spacing={2}>
              <Button onClick={movePrevious} disabled={isProcessing}>
                {t('common:labelBack')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                // disabled={isProcessingUserShow || isProcessingUserUpdate}
              >
                {t('common:labelFinish')}
              </Button>
            </Stack>
          </FormController>
        </Box>
      </Box>
    </Box>
  );
};
