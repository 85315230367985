import { useLocale } from '@koopajs/react';
import { Stack, Typography, Tooltip } from '@mui/material';
import { Bolt as BoltIcon } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { theme } from '../Core/theme';

interface ISmartTopicLabel {
  sx?: SxProps<Theme>;
  gradientId?: string;
}

export const SmartTopicLabel: React.FC<ISmartTopicLabel> = (props) => {
  const { sx, gradientId } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard';

  return (
    <Tooltip title={t(keyPrefix + `.smartTopicTooltip`)}>
      <Stack direction="row" display="inline-flex" sx={{ alignItems: 'center', ...sx }}>
        <svg width={0} height={0}>
          <defs>
            <linearGradient
              id={gradientId || 'boltIconGradient'}
              x1="12.0038"
              y1="3"
              x2="12.0038"
              y2="21"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor={theme.palette.automation.purple} />
              <stop offset="100%" stopColor={theme.palette.automation.blue} />
            </linearGradient>
          </defs>
        </svg>
        <BoltIcon sx={{ fill: `url(#${gradientId || 'boltIconGradient'})` }} />

        <Typography noWrap={true} sx={{ opacity: 0.6, ml: 0.5, whiteSpace: { xs: 'normal', sm: 'nowrap' } }}>
          <>{t(keyPrefix + `.smartTopic`)}</>
        </Typography>
      </Stack>
    </Tooltip>
  );
};
