import React, { useContext, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import { DateTimePicker } from './DateTimePickerTemp';
import { FormContext } from '@koopajs/react';
import { IDateTimePickerProps } from './DateTimePickerTemp';
import { useLocale } from '@koopajs/react';

export interface IDateTimePickerWithButtonsProps {
  originalTimeValue?: string;
  dateTimePickerProps: IDateTimePickerProps;
}

export const DateTimePickerWithButton: React.FC<IDateTimePickerWithButtonsProps> = (props) => {
  const { originalTimeValue, dateTimePickerProps } = props;
  const { form } = useContext(FormContext);
  const { t } = useLocale();

  const keyPrefix = 'Components.TopicCard.DateTimePickerWithButton';

  const handleSetValueForm = useCallback((): void => {
    if (originalTimeValue) {
      if (form && form.setValue) {
        form.setValue(dateTimePickerProps.name, originalTimeValue);
      }
    }
  }, [form, originalTimeValue, dateTimePickerProps.name]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <DateTimePicker {...dateTimePickerProps} />
      <Button
        sx={{
          textTransform: 'none',
          textDecoration: 'none',
          color: 'link.main',
          fontSize: '12px',
          fontWeight: '400',
          cursor: 'pointer',
          borderRadius: '0px',
          padding: '0px',
          '&:hover': {
            textDecoration: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          '&:active': {
            boxShadow: 'none'
          }
        }}
        onClick={handleSetValueForm}
        data-cy={`topic-card-minutes_revert-to-original-${dateTimePickerProps.name}`}
      >
        {t(keyPrefix + '.buttonLabel')}
      </Button>
    </Box>
  );
};
