import { FormController, useLocale, useResourceUpdate } from '@koopajs/react';
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  Stack
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCallback, useEffect, useState } from 'react';
import { IDocumentRecord } from 'types';
import { ErrorMessage, Form } from '@koopajs/mui';
import { DateTime } from 'luxon';
import { useDocumentReviewIntervalOptions } from 'utils/DocumentRecords/useDocumentReviewIntervalOptions';
import { transformDocumentRecordDataForm } from 'utils/transformDocumentRecordDataForm';
import { DocumentRecordFormFields } from './DocumentRecordFormFields';
import { generateDocumentRecordFormDefaultValues } from 'utils/DocumentRecords/generateDocumentRecordFormDefaultValues';

interface IDocumentDetailsCardProps {
  documentRecord: IDocumentRecord;
  userHasEditAccess: boolean;
  committeeName?: string;
}

export const DocumentDetailsCard: React.FC<IDocumentDetailsCardProps> = (props) => {
  const { documentRecord, userHasEditAccess, committeeName } = props;

  const documentReviewFieldOptions = useDocumentReviewIntervalOptions();

  const [reviewIntervalTimeUnit, reviewIntervalValue] = documentRecord?.reviewInterval
    ? Object.entries(documentRecord.reviewInterval)[0]
    : [];

  // uses custom date if set, or generates date if we're using interval
  const toReviewByDate = documentRecord.toReviewAt
    ? DateTime.fromISO(documentRecord.toReviewAt).toUTC().toString()
    : documentRecord.reviewInterval && documentRecord.lastDocumentActiveSince
    ? DateTime.fromISO(documentRecord.lastDocumentActiveSince)
        .toUTC()
        .plus(documentRecord.reviewInterval)
        .toString()
    : '';

  const [isInEditMode, setIsInEditMode] = useState(false);

  const handleSetToEditMode = useCallback(() => {
    return () => setIsInEditMode(true);
  }, []);

  // close edit mode when a document is added or deleted from the document record history
  useEffect(() => {
    setIsInEditMode(false);
  }, [documentRecord.documentsIds?.length]);

  const { t, locale } = useLocale();
  const keyPrefix = 'DocumentRecord.DetailsCard';

  const { updateResource, errorMessage } = useResourceUpdate({
    path: '/document-records',
    id: documentRecord.id
  });

  const handleSubmit = useCallback(async (data: object): Promise<boolean> => {
    const payload = transformDocumentRecordDataForm({ data });

    const res = await updateResource(payload);

    if (res) {
      setIsInEditMode(false);
    }

    return res;
  }, []);

  return (
    <Paper
      variant="outlined"
      sx={{ p: { md: 3, xs: 2 }, display: 'flex', flexDirection: 'row', minWidth: '0px' }}
      data-cy="document-details-card"
    >
      {isInEditMode ? (
        <FormController
          onSubmit={handleSubmit}
          defaultValues={generateDocumentRecordFormDefaultValues(documentRecord)}
          style={{ width: '100%' }}
        >
          <Stack direction={{ xs: 'column-reverse', md: 'row' }}>
            <Box sx={{ flex: 1 }}>
              <ErrorMessage error={errorMessage} sx={{ mb: 0.5 }} />
              <DocumentRecordFormFields
                committeesOptions={[
                  {
                    id: documentRecord.committeeId,
                    label: committeeName || 'UNDEFINED'
                  }
                ]}
                committeeFieldState="disabled"
                categoryFieldState="disabled"
              />
            </Box>
            <Stack sx={{ ml: 2, alignItems: 'end' }}>
              <Form.ButtonSubmit i18n={{ keyPrefix: keyPrefix + '.ButtonSubmit' }} />
            </Stack>
          </Stack>
        </FormController>
      ) : (
        <>
          <List sx={{ width: '100%', minWidth: '0px', p: 0 }}>
            <ListItem sx={{ px: 0 }}>
              {/* title */}
              <ListItemText
                primary={t('common:title')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                sx={{ overflow: 'hidden', px: 0 }}
                secondary={
                  <Typography
                    sx={{
                      display: 'inline-block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%'
                    }}
                    component="span"
                    color="text.primary"
                    className="rr-mask"
                  >
                    {documentRecord.lastDocumentTitle}
                  </Typography>
                }
              />
            </ListItem>
            {/* committee */}
            <ListItem sx={{ px: 0 }}>
              <ListItemText
                primary={t(keyPrefix + '.labelGroup')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                secondary={
                  <Typography
                    sx={{
                      display: 'inline',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '0px'
                    }}
                    component="span"
                    color="text.primary"
                  >
                    {committeeName}
                  </Typography>
                }
                sx={{ px: 0 }}
              />
            </ListItem>
            {/* category */}
            <ListItem sx={{ px: 0 }}>
              <ListItemText
                primary={t(keyPrefix + '.labelCategory')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                secondary={
                  <Typography
                    sx={{
                      display: 'inline',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '0px'
                    }}
                    component="span"
                    color="text.primary"
                  >
                    {t(`common:documentRecordCategories.${documentRecord.category}`)}
                  </Typography>
                }
                sx={{ px: 0 }}
              />
            </ListItem>
            {/* active since */}
            {documentRecord.lastDocumentActiveSince && (
              <ListItem
                sx={{ px: 0, width: 'auto', mr: { xs: 4, sm: 0 } }}
                data-cy="document-details-card_last-reviewed-at"
              >
                <ListItemText
                  primary={t(keyPrefix + '.labelLastDocumentActiveSince')}
                  primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                  secondary={
                    <Typography
                      sx={{
                        display: 'inline',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        minWidth: '0px'
                      }}
                      component="span"
                      color="text.primary"
                    >
                      {formatDate({ isoString: documentRecord.lastDocumentActiveSince, locale, isUTC: true })}
                    </Typography>
                  }
                  sx={{ px: 0 }}
                />
              </ListItem>
            )}
            <Box sx={{ display: 'flex', gap: { xs: 0, sm: 10 }, flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
              {/* review interval */}
              {documentRecord.reviewInterval && (
                <ListItem sx={{ px: 0, width: 'auto' }} data-cy="document-details-card_review-interval">
                  <ListItemText
                    primary={t(keyPrefix + '.labelFrequency')}
                    primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                    secondary={
                      <Typography
                        sx={{
                          display: 'inline',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          minWidth: '0px'
                        }}
                        component="span"
                        color="text.primary"
                      >
                        {
                          documentReviewFieldOptions?.find(
                            (o) => o.id === `${reviewIntervalTimeUnit} ${reviewIntervalValue}`
                          )?.label
                        }
                      </Typography>
                    }
                    sx={{ px: 0 }}
                  />
                </ListItem>
              )}

              {/* review date */}
              {toReviewByDate && (
                <ListItem sx={{ px: 0, width: { xs: '100%', sm: 'auto' } }}>
                  <ListItemText
                    data-cy="document-details-card_to-review-by"
                    primary={t(keyPrefix + '.labelToReview')}
                    primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                    secondary={
                      <Typography
                        sx={{
                          display: 'inline',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          minWidth: '0px'
                        }}
                        component="span"
                        color="text.primary"
                      >
                        {formatDate({ isoString: toReviewByDate, locale, isUTC: true })}
                      </Typography>
                    }
                    sx={{ px: 0 }}
                  />
                </ListItem>
              )}
            </Box>
          </List>

          {userHasEditAccess && (
            <Box>
              <Tooltip title={t('common:labelEdit')}>
                <IconButton
                  sx={{ alignSelf: 'flex-start', ml: 2 }}
                  onClick={handleSetToEditMode()}
                  data-cy="document-details-card_edit-button"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};
