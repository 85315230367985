import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFacet, useLocale } from '@koopajs/react';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { Link as RouterLink } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { theme } from 'components/Core/theme';

interface ICategoryFolderListProps {}

export const CategoryFolderList: React.FC<ICategoryFolderListProps> = (props) => {
  const { t } = useLocale();
  const keyPrefix = 'DocumentRecords.CategoryFolderList';

  const facet = useFacet({ facetKey: 'category.keyword', path: '/document-records' });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography component="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
        {t(keyPrefix + '.title')}
      </Typography>
      <Box>
        <Box
          sx={{
            display: 'grid',
            gap: '24px',
            gridTemplateColumns: {
              xs: '1fr',
              sm: '1fr 1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr',
              xl: '1fr 1fr 1fr 1fr'
            }
          }}
        >
          {['constituentDocuments', 'policies', 'budgetsAndFinancialStatements', 'strategyAndReports']?.map(
            (category) => (
              <Box
                key={category}
                component={RouterLink}
                data-cy="document-records_category-folder"
                to={`/document-records/categories/${kebabCase(category)}`}
                role="button"
                tabIndex={0}
                sx={{
                  color: 'inherit',
                  textDecoration: 'inherit',
                  padding: '14px',
                  backgroundColor: '#FFF',
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.customGrey?.light}`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '14px',
                  cursor: 'pointer'
                }}
              >
                <Typography sx={{ display: 'flex' }}>
                  <FolderSpecialIcon sx={{ mr: 1 }} color="action" />
                  {t(`common:documentRecordCategories.${category}`)} ({facet.getCountForKey(category) || 0})
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};
