import { ICommittee, ICommitteeMember, IMeeting } from 'types';
import { Form, ErrorMessage } from '@koopajs/mui';
import { useResourceList, useLocale, useUserShow, useComponentVisibility } from '@koopajs/react';
import { Box, Button, Alert, Link } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { TextField } from 'components/temp/TextFieldTemp';
import { Api, IUserPublicProfile } from '@koopajs/app';
import { isMeetingTimeDiffValid } from 'utils/isMeetingTimeDifferenceValid';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { FormContext } from '@koopajs/react';
import { TextMultiLine } from 'components/temp/TextMultiLineTemp';
import { DateTime, Interval } from 'luxon';
import { CopyAgendaField } from './CopyAgendaField';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ParticipantsField } from '../../ParticipantsField';
import { formatDate } from 'utils/DateTime/formatDate';
import { SelectTemp } from 'components/temp/SelectTemp';

interface IMeetingCreateFormProps {
  onClose?: (() => Promise<void>) | (() => void);
  onSubmit: (formData: object) => Promise<boolean>;
  defaultValues?: { [k: string]: unknown };
  defaultParticipants?: { id: string; label: string }[];
  meeting?: IMeeting;
  errorMessage?: string;
  meetingState: 'create' | 'edit';
  showRequiredErrors?: boolean;
  sxSubmitButton?: SxProps<Theme>;
}

export const MeetingCreateFormFields: React.FC<IMeetingCreateFormProps> = (props) => {
  const { onClose, defaultParticipants, meeting, errorMessage, meetingState, showRequiredErrors } = props;

  const { t, locale } = useLocale();

  const history = useHistory();

  const navMenu = useComponentVisibility('navMenu');

  const today = DateTime.now().toISODate();

  const keyPrefix = 'MeetingEdit.MeetingForm';
  const context = useContext(FormContext);
  const { user } = useUserShow();

  const committeeId = context.form?.watch('committeeId');
  const location = context.form?.watch('location');
  const startDate = context.form?.watch('startDate');
  const meetingStartTime = context.form?.watch('startTime');
  const meetingEndTime = context.form?.watch('endTime');

  const showMeetingUrlField = location?.type === 'hybrid' || location?.type === 'remote';
  const showMeetingAddressField = location?.type === 'hybrid' || location?.type === 'inPerson';

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees'
  });

  // get all the committees that the user can create meetings in
  const { resources: currentUserCommitteeMembersActiveMandateCreatorRole } =
    useResourceList<ICommitteeMember>({
      path: '/committee-members',
      searchParams: {
        filters: [
          `userId:"${user?.id}"`,
          'roles.role:"createMeetings"',
          `startAt:[* TO ${today}]`,
          `(endAt:[${today} TO *] OR (NOT _exists_:endAt))`
        ]
      }
    });

  //get the ids of committees that the user is allowed to create meetings for
  const committeeIdsUserCanCreateMeetingIn = currentUserCommitteeMembersActiveMandateCreatorRole.map(
    (member) => member.committeeId
  );

  //show the user only the committees that they can create a meeting for
  const filteredCommittees = committees.filter((c) => committeeIdsUserCanCreateMeetingIn.includes(c.id));

  const { resources: users } = useResourceList<IUserPublicProfile>({
    path: '/users',
    searchParams: { size: 50 }
  });

  // manually fetch last meeting for participants & location autopopulating
  const [lastMeetingFromCommittee, setLastMeetingFromCommittee] = useState<IMeeting | undefined>(undefined);
  useEffect(() => {
    if (meetingState === 'create') {
      const fetchLastMeetingByCommittee = async () => {
        const res = await Api.client.get(
          `/meeting-templates?size=5&filters[]=${encodeURIComponent(
            `committeeId:${committeeId}`
          )}&sort=${encodeURIComponent('$createdAt:DESC')}`
        );
        return res;
      };
      fetchLastMeetingByCommittee()
        .then((res) => {
          setLastMeetingFromCommittee(res.data.resources[0] || null);
        })
        .catch(() => {
          setLastMeetingFromCommittee(undefined);
        });
    }
  }, [committeeId]);

  // update values of location based on last meeting
  useEffect(() => {
    if (location?.type && lastMeetingFromCommittee) {
      const isAddressDirty = context.form?.getFieldState('location.address')?.isDirty;
      const isUrlDirty = context.form?.getFieldState('location.url')?.isDirty;

      const address = lastMeetingFromCommittee.location?.address;
      const url = lastMeetingFromCommittee.location?.url;
      if (address && !isAddressDirty) context.form?.setValue('location.address', address);
      if (url && !isUrlDirty) context.form?.setValue('location.url', url);
    }
  }, [location?.type, lastMeetingFromCommittee?.id]);

  const renderDateTimeError = useCallback((): React.ReactNode => {
    const isMissingDateTime = meeting?.errorMessages?.includes('missing_date_time');
    const isMissingDate = meeting?.errorMessages?.includes('missing_date');
    const isMissingTimeStart = meeting?.errorMessages?.includes('missing_time_start');
    const isMissingTimeEnd = meeting?.errorMessages?.includes('missing_time_end');

    if (!showRequiredErrors) return;
    // missing everything
    if (isMissingDateTime && isMissingDate && isMissingTimeStart && isMissingTimeEnd) {
      return (
        <ErrorMessage sx={{ width: '100%', my: 1 }} error={t('MeetingEdit.MeetingForm.errorNoDateAndTime')} />
      );
      //missing date only
    } else if (isMissingDateTime && isMissingDate && !isMissingTimeStart && !isMissingTimeEnd) {
      return <ErrorMessage sx={{ width: '100%', my: 1 }} error={t('MeetingEdit.MeetingForm.errorNoDate')} />;
      //missing date and end time
    } else if (isMissingDateTime && isMissingDate && !isMissingTimeStart && isMissingTimeEnd) {
      return (
        <ErrorMessage
          sx={{ width: '100%', my: 1 }}
          error={t(`MeetingEdit.MeetingForm.errorNoDateAndTimeEnd`)}
        />
      );
      //missing only time
    } else if (isMissingDateTime && !isMissingDate && isMissingTimeStart && isMissingTimeEnd) {
      return <ErrorMessage sx={{ width: '100%', my: 1 }} error={t('MeetingEdit.MeetingForm.errorNoTime')} />;

      //missing end time only
    } else if (!isMissingDateTime && !isMissingDate && !isMissingTimeStart && isMissingTimeEnd) {
      return (
        <ErrorMessage sx={{ width: '100%', my: 1 }} error={t('MeetingEdit.MeetingForm.errorNoTimeEnd')} />
      );
    }
  }, [meeting?.errorMessages, showRequiredErrors]);

  // create array of mandates for currently selected committee
  const currentUserCommitteeMembersForSelectedCommittee =
    currentUserCommitteeMembersActiveMandateCreatorRole.filter((cm) => {
      return cm.committeeId === committeeId;
    });

  const closeCreateDialogAndGoToMandatePage = useCallback(async () => {
    navMenu.setHidden();
    if (onClose) await onClose();
    history.push(`/organization/members/${user?.id}`);
  }, []);

  const renderMandateWarning = (): React.ReactNode => {
    if (!startDate) return null;

    const todaysDate = DateTime.now().toUTC().startOf('day');
    const meetingDate = DateTime.fromISO(startDate, { zone: 'utc' });

    const meetingDateIso = meetingDate.toISODate();

    let alertText: React.ReactNode = '';

    if (committeeId) {
      // check if meeting date is outside of user's mandate
      const isMeetingDateWithinAMandate = currentUserCommitteeMembersForSelectedCommittee.some((cM) => {
        const interval = Interval.fromDateTimes(
          DateTime.fromISO(cM.startAt, { zone: 'utc' }),
          DateTime.fromISO(cM.endAt || '2100-01-01T00:00:00.000Z', { zone: 'utc' }).endOf('day')
        );

        return interval.contains(meetingDate);
      });

      if (!isMeetingDateWithinAMandate) {
        alertText = (
          <>
            {t(keyPrefix + '.errorDateOutsideMandateMain', {
              date: formatDate({ isoString: meetingDateIso, locale })
            })}{' '}
            <Link onClick={closeCreateDialogAndGoToMandatePage}>
              {t(keyPrefix + '.errorDateOutsideMandateLinkText')}
            </Link>
          </>
        );
      }
    }

    // check if meeting date is in past
    if (!alertText && meetingDate < todaysDate) {
      alertText = t(keyPrefix + '.errorDateInPast', {
        date: formatDate({ isoString: meetingDateIso, locale })
      });
    }

    return alertText ? (
      <Alert severity="info" sx={{ my: 2 }}>
        {alertText}
      </Alert>
    ) : null;
  };

  return (
    <Box
      sx={{
        ...(meetingState === 'edit'
          ? {
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              alignItems: { xs: 'flex-end', md: 'flex-start' }
            }
          : {})
      }}
    >
      <Box sx={{ width: '100%', minWidth: '0px' }}>
        <ErrorMessage error={errorMessage} />
        <TextField
          name="title"
          validationRules={{ maxLength: 100 }}
          i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
        />
        <SelectTemp
          name="committeeId"
          i18n={{ keyPrefix: keyPrefix + '.FieldCommitteeType' }}
          options={filteredCommittees
            ?.sort((committeeA, committeeB) => {
              return committeeA.name > committeeB.name ? 1 : -1;
            })
            .map((committee) => ({
              id: committee.id,
              label: committee.name
            }))}
          isDisabled={committeeId && meetingState === 'edit'}
        />
        {renderDateTimeError()}
        <DateTimePicker
          name="startDate"
          i18n={{ keyPrefix: keyPrefix + '.FieldDate' }}
          type="date"
          inputProps={{ min: '2000-01-01', max: '2099-12-31' }}
          isOptional={true}
          validationRules={{
            //required: Boolean(meetingEndTime),
            pattern: /^[2]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/g
          }}
          sx={{
            '& .MuiInputBase-input': {
              backgroundColor: 'white'
            }
          }}
        />
        {renderMandateWarning()}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DateTimePicker
            name="startTime"
            i18n={{ keyPrefix: keyPrefix + '.FieldMeetingStartTime' }}
            type="time"
            isOptional={true}
            validationRules={{
              required: Boolean(meetingEndTime),
              pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
            }}
            sx={{
              '& .MuiInputBase-input': {
                backgroundColor: 'white'
              }
            }}
          />
          <DateTimePicker
            sx={{
              ml: 4,
              '& .MuiInputBase-input': {
                backgroundColor: 'white'
              }
            }}
            name="endTime"
            isOptional={true}
            i18n={{ keyPrefix: keyPrefix + '.FieldMeetingEndTime' }}
            type="time"
            validationRules={{
              validate: (meetingEndTimeValue) => {
                if (!meetingStartTime) return;
                const valid = isMeetingTimeDiffValid(meetingStartTime, meetingEndTimeValue);
                if (!valid) {
                  return t(`${keyPrefix + '.FieldMeetingEndTime.errorText'}`);
                }
              },
              pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g,
              deps: ['startTime', 'startDate']
            }}
          />
        </Box>
        {showRequiredErrors && meeting?.errorMessages?.includes('missing_location_type') && (
          <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.errorNoLocationType')} />
        )}
        <Form.Select
          name="location.type"
          i18n={{ keyPrefix: keyPrefix + '.FieldLocationType' }}
          options={[
            {
              id: 'remote',
              label: t('common:locationType.remote')
            },
            {
              id: 'inPerson',
              label: t('common:locationType.inPerson')
            },
            {
              id: 'hybrid',
              label: t('common:locationType.hybrid')
            }
          ]}
          isOptional={true}
        />
        {showMeetingUrlField && (
          <TextField
            name="location.url"
            isOptional={true}
            i18n={{ keyPrefix: keyPrefix + '.FieldLocationUrl' }}
            validationRules={{
              pattern:
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
            }}
          />
        )}
        {showMeetingAddressField && (
          <>
            {showRequiredErrors && meeting?.errorMessages?.includes('missing_location_address') && (
              <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.errorNoLocationAddress')} />
            )}
            <TextMultiLine
              isOptional={true}
              name="location.address"
              validationRules={{ maxLength: 250 }}
              rows={4}
              i18n={{
                keyPrefix: keyPrefix + '.FieldLocationAddress'
              }}
            />
          </>
        )}
        {showRequiredErrors && meeting?.errorMessages?.includes('missing_participants') && (
          <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.errorNoParticipants')} />
        )}
        {(committeeId || meetingState === 'edit') && (
          <ParticipantsField
            name="participants"
            keyPrefix="MeetingEdit.MeetingForm"
            users={users}
            committeeId={committeeId}
            defaultParticipants={defaultParticipants}
            isOptional={true}
          />
        )}
        {meetingState === 'create' && <CopyAgendaField lastMeetingFromCommittee={lastMeetingFromCommittee} />}
      </Box>
      {meetingState === 'create' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: '#fff',
            px: 3,
            pb: 2,
            pt: 1,
            right: 0,
            left: 0,
            zIndex: 100
          }}
        >
          <Button onClick={onClose}>{t('common:labelCancel')}</Button>

          <Form.ButtonSubmit i18n={{ keyPrefix: keyPrefix + '.ButtonSubmit' }} />
        </Box>
      )}
      {meetingState === 'edit' && (
        <Button type="submit" variant="contained" sx={{ ml: 2 }}>
          {t('common:labelSave')}
        </Button>
      )}
    </Box>
  );
};
