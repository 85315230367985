import { useComponentVisibility, useResourceList } from '@koopajs/react';
import { Box, Button, Stack } from '@mui/material';
import { IDocument } from 'types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { BoardMemberViewTopicCardDocumentButton } from './DocumentButton';
import { BoardMemberViewDocumentAnnotation } from '../../../DocumentAnnotation';
import { SystemStyleObject } from '@mui/system';

interface IDocumentListProps {
  path: string;
  setCurrentDocumentIndex: Dispatch<SetStateAction<number>>;
  setLinkDocumentsCount: Dispatch<SetStateAction<number>>;
  setFileDocumentsCount: Dispatch<SetStateAction<number>>;
  sxButtonContainer?: SystemStyleObject;
}

export const DocumentList: React.FC<IDocumentListProps> = (props) => {
  const { path, setCurrentDocumentIndex, setLinkDocumentsCount, setFileDocumentsCount, sxButtonContainer } =
    props;

  const { resources: documents } = useResourceList<IDocument>({
    path
  });

  const links: IDocument[] = [];
  const pdfDocuments: IDocument[] = [];
  const otherDocuments: IDocument[] = [];

  for (const document of documents) {
    if (document.attachedFile.format === 'url') {
      links.push(document);
    } else if (document.attachedFile.format === 'application/pdf') {
      pdfDocuments.push(document);
    } else {
      otherDocuments.push(document);
    }
  }

  useEffect(() => {
    setLinkDocumentsCount(links.length);
    setFileDocumentsCount(pdfDocuments.length + otherDocuments.length);
  }, [documents.length]);

  // this will probably move into context and actually do something but putting it here for now until we have it
  const isDesktopModeEnabled = false;

  const boardMemberViewDocumentDialog = useComponentVisibility('boardMemberViewDocumentDialog');
  const openDialog = useCallback(
    (documentId: string) => {
      return () => {
        boardMemberViewDocumentDialog.setVisibleWithContext({
          documentId,
          pdfDocuments
        });
      };
    },
    [pdfDocuments.length]
  );

  if (!documents.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      {links.map((document, index) => {
        return (
          <Box key={document.id} sx={sxButtonContainer} data-cy="board-member-view_document-link-button">
            <Button
              sx={{
                textTransform: 'none',
                color: 'link.main',
                p: 0.2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
              component="a"
              target="_blank"
              rel="noopener"
              href={document.attachedFile.uri}
            >
              <OpenInNewIcon />

              <Box
                sx={{
                  ml: 1,
                  maxWidth: '500px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '16px'
                }}
              >
                {document?.attachedFile?.name || document?.attachedFile?.uri}
              </Box>
            </Button>
          </Box>
        );
      })}
      {otherDocuments.map((document, index) => {
        return (
          <BoardMemberViewTopicCardDocumentButton
            sxContainer={sxButtonContainer}
            key={document.id}
            document={document}
            // documentIndex={index}
            // setCurrentDocumentIndex={setCurrentDocumentIndex}
          />
        );
      })}
      {pdfDocuments.map((document, index) => {
        if (!isDesktopModeEnabled) {
          return (
            <BoardMemberViewTopicCardDocumentButton
              sxContainer={sxButtonContainer}
              key={document.id}
              document={document}
              buttonActionProps={{ onClick: openDialog(document.id) }}
              // documentIndex={otherDocuments.length + index}
              // setCurrentDocumentIndex={setCurrentDocumentIndex}
            />
          );
        }
        return (
          <BoardMemberViewDocumentAnnotation
            key={document.id}
            // document={document}
            documentId={document.id}
            topicId={document.topicId}
            sx={{ height: { xs: 'calc(100vh - 64px)', lg: '100vh' } }}
            // documentIndex={otherDocuments.length + index}
            // setCurrentDocumentIndex={setCurrentDocumentIndex}
          />
        );
      })}
    </Stack>
  );
};
