/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { TextField, InputProps, InputLabelProps, InputBaseComponentProps, Autocomplete } from '@mui/material';
import { FormContext } from '@koopajs/react';
import { RegisterOptions, Controller } from 'react-hook-form';
import { ICoreProps } from '@koopajs/mui';
import { useLocale as useCoreLocale } from '@koopajs/mui/dist/useCoreLocale';
import { useLocale } from '@koopajs/react';

export interface ITextFieldPublicProps extends ICoreProps {
  name: string;
  validationRules?: RegisterOptions;
  // type?: string;
  InputLabelProps?: InputLabelProps;
  InputProps?: InputProps;
  inputProps?: InputBaseComponentProps;
  // onBlur?: React.FocusEventHandler<HTMLInputElement>;
  // onFocus?: React.FocusEventHandler<HTMLInputElement>;
  isOptional?: boolean;
  // isAutoFocus?: boolean;
  testId?: string;
  options: string[];
}

export const AutocompleteTemp: React.FC<ITextFieldPublicProps> = (props) => {
  const {
    name,
    // isAutoFocus,
    isOptional,
    isDisabled,
    isLoading,
    validationRules,
    // type,
    // onFocus,
    // onBlur,
    sx,
    i18n,
    InputLabelProps = {},
    InputProps = {},
    inputProps = {},
    testId,
    options
  } = props;

  const context = useContext(FormContext);
  // TODO: when adding to koopa, create a separate place in i18n files
  const { t } = useCoreLocale({ ...i18n, coreKeyPrefix: 'FormTextFieldBase' });
  const { t: ult } = useLocale();
  const keyPrefix = 'Components.AutocompleteTemp.validationRules';

  if (!context.form) {
    throw new Error('"Form.Autocomplete" component needs to be use inside a FormController');
  }

  if (typeof validationRules?.maxLength === 'number') {
    const maxLength = validationRules.maxLength;
    validationRules.maxLength = { value: maxLength, message: ult(keyPrefix + '.maxLength', { maxLength }) };
  }
  if (typeof validationRules?.minLength === 'number') {
    const minLength = validationRules.minLength;
    validationRules.minLength = { value: minLength, message: ult(keyPrefix + '.minLength', { minLength }) };
  }

  const resolve = (path: string, obj: { [k: string]: unknown }): { [k: string]: unknown } => {
    return path.split('.').reduce(function (prev: any, curr: string) {
      return prev ? prev[curr] : null;
    }, obj || self);
  };

  const errors = resolve(name, context.form.formState.errors);
  const errorMessageFromValidation = errors && (errors.message as string);

  return (
    <Controller
      control={context.form.control}
      name={name}
      rules={{
        required: { value: !isOptional, message: ult(keyPrefix + '.required') },
        disabled: isDisabled,
        // onBlur: onBlur,
        ...validationRules
      }}
      render={({ field }) => {
        return (
          <Autocomplete
            freeSolo={true}
            disableClearable
            options={options}
            disabled={isDisabled || isLoading}
            onChange={(event, values, reason) => field.onChange(values)}
            value={field.value}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  InputLabelProps={{ ...params.InputLabelProps, ...InputLabelProps }}
                  InputProps={{ ...params.InputProps, ...InputProps }}
                  inputProps={{
                    'data-cy': testId || `koopa_autocomplete-field_${name}`,
                    ...params.inputProps,
                    ...inputProps
                  }}
                  onChange={field.onChange}
                  fullWidth
                  margin="normal"
                  // type={props.type || type || 'string'}
                  // autoFocus={isAutoFocus}
                  placeholder={t('placeholder')}
                  label={t('label')}
                  helperText={errors ? errorMessageFromValidation || t('errorText') : t('helperText')}
                  error={Boolean(errors)}
                  // onFocus={onFocus}
                  sx={sx}
                />
              );
            }}
          />
        );
      }}
    />
  );
};
