import { useLocale } from '@koopajs/react';

export const useDocumentCategorySelectOptions = (): { id: string; label: string }[] => {
  const { t } = useLocale();

  return [
    {
      id: 'constituentDocuments',
      label: t('common:documentRecordCategories.constituentDocuments')
    },
    { id: 'policies', label: t('common:documentRecordCategories.policies') },
    {
      id: 'budgetsAndFinancialStatements',
      label: t('common:documentRecordCategories.budgetsAndFinancialStatements')
    },
    { id: 'strategyAndReports', label: t('common:documentRecordCategories.strategyAndReports') }
  ];
};
