import { ICommittee, ICommitteeMember } from 'types';
import { Container, Box, Stack, Typography, Link, Paper, Divider, Button } from '@mui/material';
import { useResourceList, useLocale, useParamsKey, useResourceShow, usePermissions } from '@koopajs/react';
import { Link as RouterLink } from 'react-router-dom';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { Helmet } from 'react-helmet';
import { CommitteeMembersList } from 'components/Organization/OrganizationCommittees/Committee/CommitteeMembersList';
import { PageContainer } from 'components/temp/PageContainer';
import { EmptyState } from 'components/EmptyState';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { Loading } from '@koopajs/mui';
import { Breadcrumbs } from 'components/Breadcrumbs';

export const OrganizationCommittee: React.FC = () => {
  const committeeId = useParamsKey('id');

  const {
    resource: committee,
    errorMessage,
    isProcessing: isCommitteeProcessing
  } = useResourceShow<ICommittee>({
    path: '/committees',
    id: committeeId
  });

  const { resources: committeeMembers, isProcessing: isCommitteeMembersProcessing } =
    useResourceList<ICommitteeMember>({
      path: `/committees/${committeeId}/members`,
      searchParams: { size: 50 }
    });

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const extractedErrorMessage = extractErrorMessage(errorMessage);
  const { t } = useLocale();
  const keyPrefix = 'OrganizationCommittee';

  const minuteRequiredSignaturesNumber = committee?.operationsRequired?.minuteSignatures?.amount;
  const resolutionRequiredSignaturesNumber = committee?.operationsRequired?.resolutionSignatures?.amount;

  const isThereMinutesSignees = committeeMembers.some((member) => {
    return member?.roles?.some((role) => role.role === 'signMinutes');
  });

  const isThereResolutionsSignees = committeeMembers.some((member) => {
    return member?.roles?.some((role) => role.role === 'signResolutions');
  });

  const renderResolutionNumberFormat = (): string | JSX.Element => {
    if (committee?.resolutionNumberFormat === 'custom') {
      return `#${t(keyPrefix + '.manualEntry')}`;
    } else if (committee?.resolutionNumberFormat === 'prefix-date-meetingresnum') {
      return `#${committee?.resolutionNumberPrefix || ''}${t(keyPrefix + '.meetingDate')}-`;
    } else if (committee?.resolutionNumberFormat === 'prefix-resnum') {
      return committee.resolutionNumberPrefix ? (
        `#${committee.resolutionNumberPrefix}`
      ) : (
        <Box color="grey" component="span">
          {t(keyPrefix + '.toBeSet')}
        </Box>
      );
    }
    return '';
  };

  if (isCommitteeProcessing || isCommitteeMembersProcessing)
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  if (errorMessage === "Error 403: You don't have access to this committee") {
    return (
      <EmptyState
        title={extractedErrorMessage}
        button={
          <Button component={RouterLink} to="/" variant="contained" disableElevation>
            {t('NotFound.EmptyState.goToDashboardButton')}
          </Button>
        }
      />
    );
  }
  if (!committee) return null;

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.organization')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ padding: '24px' }} className="rr-block">
        <Breadcrumbs
          hierarchyArray={[{ path: '/organization', text: t('common:navigation.organization') }]}
          pageTitle={committee?.name}
          sx={{ mb: 3 }}
        />
        <Box sx={{ mb: 6 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'left', sm: 'center' }}
            justifyContent="space-between"
          >
            <Typography variant="h1" sx={{ fontSize: '24px', mb: { xs: 2, sm: 0 } }}>
              {committee?.name}
            </Typography>
            <Typography color="grey">
              {t(keyPrefix + `.lastUpdatedAt`)} &nbsp;
              <DateTimeStartEnd
                isoStringDate={committee?.$updatedAt as string}
                dateFormat="DATE_FULL"
                separator={` ${t('common:at')} `}
                isoStringStart={committee?.$updatedAt as string}
              />
            </Typography>
          </Stack>
          {/* Minutes rules card */}
          {(minuteRequiredSignaturesNumber !== undefined || isThereMinutesSignees) && (
            <Paper
              sx={{
                p: 3,
                my: 3,
                display: 'flex',
                flexDirection: 'column',
                flexBasis: '100%'
              }}
              variant="outlined"
            >
              <Typography fontSize="18px" sx={{ mb: 2 }}>
                {t(keyPrefix + '.minutesAndReports')}
              </Typography>

              <Stack direction={{ sm: 'row' }} gap={{ xs: 0.5, sm: 0 }}>
                <Typography sx={{ display: 'block', minWidth: { xs: '240px', sm: '350px', md: '500px' } }}>
                  {t(keyPrefix + `.requiredSignaturesNumber`)}
                </Typography>
                <Typography>
                  {minuteRequiredSignaturesNumber !== undefined ? (
                    minuteRequiredSignaturesNumber
                  ) : (
                    <Box color="grey" component="span">
                      {t(keyPrefix + '.toBeSet')}
                    </Box>
                  )}
                </Typography>
              </Stack>
            </Paper>
          )}
          {/* Resolution rules card */}
          <Paper
            sx={{
              p: 3,
              my: 3,
              display: 'flex',
              flexDirection: 'column',
              flexBasis: '100%'
            }}
            variant="outlined"
          >
            <Typography fontSize="18px" sx={{ mb: 2 }}>
              {t(keyPrefix + '.resolutions')}
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              {committee?.isTakingResolutions ? (
                <>
                  <PlaylistAddCheckIcon />
                  <Typography>{t(keyPrefix + `.canTakeResolution`)}</Typography>
                </>
              ) : (
                <>
                  <PlaylistRemoveIcon />
                  <Typography>{t(keyPrefix + `.cannotTakeResolution`)}</Typography>
                </>
              )}
            </Stack>

            {committee?.isTakingResolutions && (
              <>
                <Stack direction={{ sm: 'row' }} gap={{ xs: 0.5, sm: 0 }} sx={{ mb: 1 }}>
                  <Typography sx={{ display: 'block', minWidth: { xs: '240px', sm: '350px', md: '500px' } }}>
                    {t(keyPrefix + `.nomenclature`)}
                  </Typography>
                  <Typography>{renderResolutionNumberFormat()}</Typography>
                </Stack>
              </>
            )}
            {(resolutionRequiredSignaturesNumber !== undefined || isThereResolutionsSignees) && (
              <Stack direction={{ sm: 'row' }} gap={{ xs: 0.5, sm: 0 }}>
                <Typography sx={{ display: 'block', minWidth: { xs: '240px', sm: '350px', md: '500px' } }}>
                  {t(keyPrefix + `.requiredSignaturesNumber`)}
                </Typography>
                <Typography>
                  {resolutionRequiredSignaturesNumber !== undefined ? (
                    resolutionRequiredSignaturesNumber
                  ) : (
                    <Box color="grey" component="span">
                      {t(keyPrefix + '.toBeSet')}
                    </Box>
                  )}
                </Typography>
              </Stack>
            )}
          </Paper>
          {/* Members section with their permissions */}
          <CommitteeMembersList
            committeeMembers={committeeMembers}
            isProcessing={isCommitteeMembersProcessing}
          />
          {isUserWorkspaceOwner && (
            <>
              <Divider sx={{ mt: 6, mb: 4 }} />
              <Stack alignItems="center" sx={{ gap: '14px', textAlign: 'center' }}>
                <Typography>
                  <Box component="span" sx={{ opacity: 0.6 }}>
                    {t(keyPrefix + `.toAddUserText`)}{' '}
                  </Box>
                  <Box
                    component="a"
                    href="https://usepanorama.atlassian.net/servicedesk/customer/portal/1/group/1/create/9"
                    sx={{ color: 'link.main', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(keyPrefix + `.toAddUserLinkText`)}
                  </Box>
                </Typography>
                <Typography>
                  <Box component="span" sx={{ opacity: 0.6 }}>
                    {t(keyPrefix + `.toDeleteUserText`)}{' '}
                  </Box>
                  <Box
                    component="a"
                    href="https://usepanorama.atlassian.net/servicedesk/customer/portal/1/article/3866864"
                    sx={{ color: 'link.main', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(keyPrefix + `.toDeleteUserLinkText`)}
                  </Box>
                </Typography>
              </Stack>
            </>
          )}
        </Box>
      </PageContainer>
    </>
  );
};
