import { IMeeting } from 'types';
import { useCallback, useState, useRef } from 'react';
import { useUserShow, useLocale } from '@koopajs/react';
import { Box, Stack, Typography, Paper, Divider } from '@mui/material';
import { EventsTimeline } from '../EventsTimeline';
import { TopicCardMinutes } from '../TopicCard/TopicCardMinutes';
import _ from 'lodash';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import { isCustomResNumFormat } from 'utils/isCustomResNumFormat';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

interface IMeetingReviewForDialog {
  meeting: IMeeting;
  isBeingReviewedInsideMeeting?: boolean;
  isViewMode?: boolean;
}

export const MeetingReviewForDialog: React.FC<IMeetingReviewForDialog> = (props) => {
  const { meeting, isBeingReviewedInsideMeeting, isViewMode } = props;
  const topics = meeting.topics;
  const { user: currentUser } = useUserShow();

  const isCustomResNum = isCustomResNumFormat(meeting?.committeeId as string);

  const componentRef = useRef(null);

  const { t } = useLocale();

  const keyPrefix = 'MeetingStateReview';

  const [topicIdEditable, setTopicIdEditable] = useState('');

  const isUserReviewer =
    currentUser?.id && meeting.reviewers?.some((reviewer) => reviewer.userId === currentUser.id);

  const userHasEditAccess = Boolean(
    !meeting.boardApprovedAt && !isViewMode && (isBeingReviewedInsideMeeting || isUserReviewer)
  );

  const uniqueUserReviewsCount = meeting.reviewers?.filter((reviewers) => reviewers.latestReview).length;

  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicIdEditable(topicId);
  }, []);

  const handleUnsetTopicEditable = useCallback(() => {
    setTopicIdEditable('');
  }, []);

  const timelineEvents = generateMeetingTimeline(meeting);

  return (
    <Box sx={{ padding: '24px' }} className="rr-block">
      <Box sx={{ display: 'none' }}>
        <ViewMinutesPrintVersion minutes={meeting} topics={topics} ref={componentRef} isReview={true} />
      </Box>

      <Box>
        {isBeingReviewedInsideMeeting && (
          <Box sx={{ mb: 2 }}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} alignItems={{ sm: 'center' }}>
              {meeting?.reviewers && meeting?.reviewers?.length > 0 && (
                <Typography
                  variant="body2"
                  sx={{ textAlign: 'right', opacity: 0.6, mb: { xs: 1, sm: 0 }, ml: { sm: 'auto' } }}
                >
                  {t(keyPrefix + '.reviewCountOfTotal', {
                    reviewsMade: uniqueUserReviewsCount,
                    reviewsPossible: meeting.reviewers.length
                  })}
                </Typography>
              )}
            </Stack>
          </Box>
        )}

        <MinutesHeaderCard minutes={meeting} userHasEditAccess={userHasEditAccess} />

        <Paper variant="outlined">
          <Stack spacing={2}>
            {topics?.map((topic, index) => {
              return (
                <Box key={topic.id}>
                  <TopicCardMinutes
                    topic={topic}
                    handleSetTopicEditable={handleSetTopicEditable}
                    order={index + 1}
                    isEditable={topic.id === topicIdEditable}
                    onSubmitSuccess={handleUnsetTopicEditable}
                    userHasEditAccess={userHasEditAccess}
                    meeting={meeting}
                    isMeeting={true}
                    isCustomResNum={isCustomResNum}
                    isBeingReviewedInsideMeeting={isBeingReviewedInsideMeeting}
                  />
                  {index !== topics.length - 1 && <Divider sx={{ mx: 3 }} />}
                </Box>
              );
            })}
          </Stack>
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <EventsTimeline timelineEvents={timelineEvents} />
        </Box>
      </Box>
    </Box>
  );
};
