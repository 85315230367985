import { useLocale, User } from '@koopajs/react';
import { List, ListItem, Typography, Box } from '@mui/material';
import { CommitteeMemberTitle } from 'types';

interface IUserListProps {
  userIds: string[];
  icon?: React.ReactNode;
  committeeMembersTitles?: { [k: string]: CommitteeMemberTitle };
}

export const UsersWithTitlesList: React.FC<IUserListProps> = (props) => {
  const { userIds, icon, committeeMembersTitles } = props;

  const { t } = useLocale();

  if (!userIds?.length) {
    return null;
  }

  return (
    <>
      <List
        sx={{
          p: 0,
          display: 'grid',
          alignItems: 'flex-start',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' }
        }}
      >
        {userIds?.map((userId) => {
          const title = committeeMembersTitles?.[userId];

          return (
            <ListItem disableGutters key={userId} sx={{ pr: 1 }}>
              <User id={userId}>
                {(user) => {
                  return (
                    <Box>
                      {icon && icon}
                      <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
                        {user?.username}
                      </Typography>
                      {title && (
                        <Typography variant="body2" sx={{ opacity: '0.6', whiteSpace: 'normal' }}>
                          {t(`common:committeeMemberTitles.${title}`)}
                        </Typography>
                      )}
                    </Box>
                  );
                }}
              </User>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
