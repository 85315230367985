import { useLocale, useParamsKey, useResourceShow } from '@koopajs/react';
import { Helmet } from 'react-helmet';
import { Button, Stack } from '@mui/material';
import { PageContainer } from 'components/temp/PageContainer';
import { IDocument, IDocumentRecord } from 'types';
import { Loading } from '@koopajs/mui';
import { DocumentAttachedToDocumentRecord } from 'components/DocumentRecords/DocumentAttachedToDocumentRecord';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { DelayComponentRender } from 'components/DelayComponentRender';
import { EmptyState } from 'components/EmptyState';
import { Link as RouterLink } from 'react-router-dom';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { useFeatureGate } from '@statsig/react-bindings';
import { FeatureLocked } from 'components/FeatureLocked';

export const DocumentAttachedToDocumentRecordView: React.FC = () => {
  const documentId = useParamsKey('documentId');

  const { t } = useLocale();

  const {
    resource: document,
    isProcessing: documentIsProcessing,
    errorMessage
  } = useResourceShow<IDocument>({
    path: '/documents',
    id: documentId
  });

  const { value: isDocumentRecordsEnabled } = useFeatureGate('document-records-preview');

  const { resource: documentRecord, isProcessing: documentRecordIsProcessing } =
    useResourceShow<IDocumentRecord>({
      path: '/document-records',
      id: document?.attachedToDocumentRecord?.documentRecordId
    });
  const extractedErrorMessage = extractErrorMessage(errorMessage);

  if (!isDocumentRecordsEnabled) {
    return <FeatureLocked />;
  }

  if (!document) {
    if (documentIsProcessing) {
      return (
        <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        </Stack>
      );
    } else {
      const is404 = errorMessage?.includes('404');
      return (
        <DelayComponentRender
          delay={1250}
          component={
            <EmptyState
              title={is404 ? t('DocumentRecord.EmptyState.title') : extractedErrorMessage}
              description={is404 && t('DocumentRecord.EmptyState.description')}
              button={
                <Button component={RouterLink} to="/" variant="contained" disableElevation>
                  {t('NotFound.EmptyState.goToDashboardButton')}
                </Button>
              }
            />
          }
        />
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${t('common:document')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ p: '24px' }}>
        <Breadcrumbs
          hierarchyArray={[
            {
              path: `/document-records`,
              text: t('common:navigation.documents')
            },
            {
              path: `/document-records/${document.attachedToDocumentRecord?.documentRecordId}`,
              text: documentRecord?.lastDocumentTitle || '',
              className: 'rr-mask'
            }
          ]}
          pageTitle={document.attachedToDocumentRecord?.title || ''}
          sx={{ mb: '24px', display: { xs: 'none', sm: 'block' } }}
          className="rr-mask"
        />

        <DocumentAttachedToDocumentRecord
          document={document}
          documentRecord={documentRecord}
          documentIsProcessing={documentIsProcessing}
          documentRecordIsProcessing={documentRecordIsProcessing}
        />
      </PageContainer>
    </>
  );
};
