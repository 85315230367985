import { useState, useCallback } from 'react';
import { TrackEvent } from '@koopajs/app';
import { IconButton, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IUseSearchParamsResponse, useLocale } from '@koopajs/react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { theme } from './Core/theme';

const transformSearchInput = (input: string): string | undefined => {
  if (!input) return undefined;
  const searchInput = input
    .split(' ')
    .map((word) => `*${word}*`)
    .join(' ');
  return searchInput;
};

interface ISearchBarProps {
  isReloading: boolean;
  search: IUseSearchParamsResponse;
  sx?: SxProps<Theme>;
  placeholderText?: string;
}

export const SearchBar: React.FC<ISearchBarProps> = (props) => {
  const { search, isReloading, sx, placeholderText } = props;

  const [searchInput, setSearchInput] = useState('');

  const { t } = useLocale();
  const keyPrefix = 'Components.SearchBar';

  useEffect(() => {
    if (search.searchParams.query) {
      setSearchInput(search.searchParams.query.replace(/\*/g, ''));
    }
  }, []);

  const handleSearch = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      search.setSearchParams({
        query: transformSearchInput(searchInput)
      });

      TrackEvent('searchBar');
    },
    [searchInput]
  );

  const handleResetSearch = useCallback((): void => {
    search.setSearchParams({
      query: undefined
    });
    setSearchInput('');
  }, []);

  const handleOnChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setSearchInput(e.target.value);
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSearch}
      sx={{
        border: 'none',
        ...sx
      }}
    >
      <TextField
        className="rr-block"
        placeholder={placeholderText || t(keyPrefix + '.placeholder')}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#FFF',
            borderRadius: '50px',
            '& fieldset': {
              border: `1px solid ${theme.palette.customGrey?.light}`
            },
            '&:hover fieldset': {
              border: `1px solid ${theme.palette.customGrey?.light}`
            },
            '&.Mui-focused fieldset': {
              border: `1px solid ${theme.palette.customGrey?.light}`
            }
          }
        }}
        disabled={isReloading}
        value={searchInput}
        onChange={handleOnChange}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton type="submit" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
              {searchInput.length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={handleResetSearch} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          )
        }}
      />
    </Box>
  );
};
