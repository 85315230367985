import { useLocale } from '@koopajs/react';
import { IResolution } from 'types';

export const generateResolutionTimeline = (
  resolution?: IResolution
): {
  title: string;
  timestamp?: string;
  userId?: string;
}[] => {
  const { t } = useLocale();
  const keyPrefix = 'Components.EventsTimeline';

  if (!resolution) return [];

  const timelineSignatures =
    resolution?.signatures?.map((signature) => ({
      title: t(keyPrefix + '.signedBy'),
      userId: signature.userId,
      timestamp: signature.signedAt
    })) || [];

  return [
    { title: t(keyPrefix + '.updatedOn'), timestamp: resolution?.$updatedAt as string },
    {
      title: t(keyPrefix + '.createdOn'),
      timestamp: resolution?.$createdAt as string,
      userId: resolution.$createdBy as string
    },
    {
      title: t(keyPrefix + '.sendForAdoption'),
      timestamp: resolution?.attachedToEmail?.sentForAdoptionAt as string
    },
    ...timelineSignatures
  ];
};
