import { createTheme, Theme, PaletteOptions } from '@mui/material/styles';
import '../../theme.css';

const palette = {
  primary: {
    main: '#0B3C4F'
  },
  secondary: {
    main: '#79D8E2',
    light: '#AFE8EE',
    lighter: '#D6F3F6'
  },
  tertiary: {
    main: '#D2E825',
    light: '#E4F17C',
    lighter: '#F1F8BD'
  },
  greenOne: {
    '100': '#52C979',
    '60': '#97DFAF',
    '30': '#CBEED6'
  },
  greenTwo: {
    '100': '#89E58B',
    '60': '#B6EDB7',
    '30': '#DBF7DC'
  },
  blue: {
    '100': '#86D2EA',
    '60': '#B6E4F2',
    '30': '#DAF1F8'
  },
  bluePurple: {
    '100': '#9EBCFB',
    '60': '#C5D7FD',
    '30': '#E1EAFD'
  },
  purpleOne: {
    '100': '#AD88D7',
    '60': '#CEB8E7',
    '30': '#E6DBF3'
  },
  purpleTwo: {
    '100': '#CE71C5',
    '60': '#E2AADC',
    '30': '#F0D4ED'
  },
  pink: {
    '100': '#F7738D',
    '60': '#FAABBB',
    '30': '#FCD5DC'
  },
  red: {
    '100': '#FF8A73',
    '60': '#FFB9AB',
    '30': '#FFDBD5'
  },
  orange: {
    '100': '#FFA659',
    '60': '#FFCA9B',
    '30': '#FFE4CD'
  },
  yellow: {
    '100': '#FFD04C',
    '60': '#FFE394',
    '30': '#FFF0C9'
  },
  info: {
    main: '#0037FF'
  },
  success: {
    main: '#00C165'
  },
  warning: {
    main: '#FF6600'
  },
  error: {
    main: '#F41919'
    // light: '#F4433680'
  },
  link: {
    main: '#0099FF'
  },
  customGrey: {
    lighter: '#F3F7F9',
    light: '#C8CCCE',
    main: '#E8EDEF',
    dark: '#939899',
    darker: '#4E5254'
  },
  automation: {
    purple: '#9269F7',
    blue: '#284CE5'
  }
};

export const theme: Theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Circular Std, Arial, sans-serif'
  },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            backgroundColor: palette.customGrey?.dark, //palette.customGrey.darkMedium
            color: '#FFFFFF'
          }
        },
        outlined: {
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: palette.customGrey?.dark,
            borderColor: palette.customGrey?.dark
          }
        },
        text: {
          '&.Mui-disabled': {
            color: palette.customGrey?.dark
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState: { color, variant } }) => ({
          // default filled chip
          ...(color === 'default' &&
            variant === 'filled' && {
              backgroundColor: palette.customGrey?.main
            }),
          // default outlined chip
          ...(color === 'default' &&
            variant === 'outlined' && {
              borderColor: palette.customGrey?.light
            }),
          '& .MuiChip-avatar': {
            color: palette.customGrey?.darker,
            backgroundColor: palette.customGrey?.light
          }
        })
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.customGrey?.main,
          color: palette.customGrey?.darker
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: palette.customGrey?.darker
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderColor: palette.customGrey?.light
        }
      }
    },
    // STEPPER
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: palette.customGrey?.light,
          '&.Mui-completed': {
            color: palette.primary.main
          },
          '&.Mui-active': {
            color: palette.primary.main
          }
        }
      }
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderColor: palette.customGrey?.light
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: palette.customGrey?.light
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.customGrey?.light
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: palette.customGrey?.light
        }
      }
    }
  }
});
