import React, { useCallback } from 'react';
import { useLocale, FormContext } from '@koopajs/react';
import { IMeeting, IMinute, ITopicResolutionOutcome } from 'types';
import { useTopic } from 'components/hooks/useTopic';
import { IUserPublicProfile } from '@koopajs/app';
import { alphabeticalUserSortFunction } from 'utils/alphabeticalUserSortFunction';
import { ITopic } from 'types';
import { RecordVoteBanner } from 'components/Meetings/InProgress/Header/RecordVote/RecordVoteBanner';
import { useContext } from 'react';

interface IEditVoteOutcomeProps {
  topic: ITopic;
  meeting?: IMeeting | IMinute;
  users: IUserPublicProfile[];
  isCommitteeTakingResolutions: boolean;
}

export const EditVoteOutcome: React.FC<IEditVoteOutcomeProps> = (props) => {
  const { t } = useLocale();

  const { topic, meeting, users, isCommitteeTakingResolutions } = props;

  const context = useContext(FormContext);
  const hideEditingVote = topic.type === 'pastMinutesApproval' ? true : false;

  const { updateTopic } = useTopic({
    meetingId: meeting?.id as string,
    topicId: topic.id,
    isMeetingStarted: true
  });

  const handleTopicUpdate = useCallback(
    async (data: object) => {
      const formData = data as {
        resolutionOutcome: ITopicResolutionOutcome;
        isResolution: boolean;
      };

      const topicOutcomeUpdates = {
        ...formData.resolutionOutcome,
        recordedAt: topic.resolutionOutcome?.recordedAt
      };

      const topicIsResolutionUpdates = formData.isResolution;

      const res = await updateTopic({
        isResolution: topicIsResolutionUpdates,
        resolutionOutcome: topicOutcomeUpdates
      });

      if (res) {
        context.form?.setValue('isResolution', topicIsResolutionUpdates);
        context.form?.setValue('resolutionOutcome', topicOutcomeUpdates);
      }

      return res;
    },
    [updateTopic, topic.resolutionOutcome?.recordedAt]
  );

  const isUserParticipantToMeeting = (u: IUserPublicProfile): boolean => {
    return Boolean(meeting?.participants?.find((p) => p.userId === u.id));
  };
  const participants = users.filter(isUserParticipantToMeeting).sort(alphabeticalUserSortFunction);

  // we use a component RecordVoteBanner from The Live Meeting
  const voteProps = {
    meeting,
    topic,
    isCommitteeTakingResolutions,
    users,
    participants,
    handleTopicUpdate,
    t
  };

  return <RecordVoteBanner liveMeeting={voteProps as any} hideEditingVote={hideEditingVote} />;
};
