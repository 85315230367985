import { useSnackbar, VariantType } from 'notistack';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

export const useAppSnackbar = (): {
  triggerSnackbar: (args: { snackbarText: React.ReactNode; variant?: VariantType | undefined }) => void;
} => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = useCallback((key: any) => {
    return () => {
      closeSnackbar(key);
    };
  }, []);

  const triggerSnackbar = (args: {
    snackbarText: React.ReactNode;
    variant?: VariantType | undefined;
  }): void => {
    const { snackbarText, variant } = args;

    enqueueSnackbar(snackbarText, {
      variant,
      action: (key: any) => (
        <IconButton size="small" onClick={handleCloseSnackbar(key)}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      )
    });
  };
  return { triggerSnackbar };
};
