import { IResolution, ITopic } from 'types';
import { Stack, Box, Typography } from '@mui/material';
import { PeopleAlt as PeopleAltIcon } from '@mui/icons-material';
import { useLocale } from '@koopajs/react';
import React from 'react';

interface IResolutionOutcomeVotesProps {
  topic: ITopic | IResolution | undefined;
  hideIcon?: boolean;
}

export const ResolutionOutcomeVotes: React.FC<IResolutionOutcomeVotesProps> = (props) => {
  const { topic, hideIcon } = props;
  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};

  const { t } = useLocale();
  const keyPrefix = 'Components.ResolutionOutcome';

  const renderVoteColumn = (membersList: string[], titleTransKey: string): React.ReactNode => {
    return (
      <Box>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          {!hideIcon && <PeopleAltIcon sx={{ opacity: 0.5 }} />}
          <Typography>{t(titleTransKey, { count: membersList.length })}</Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <Stack spacing={3} data-cy="resolution-outcome-votes">
        <Stack direction={{ sm: 'row' }} spacing={{ xs: 1, sm: 4, md: 6, lg: 12.5 }}>
          {membersAgainst && renderVoteColumn(membersAgainst, `${keyPrefix}.votes.titleVotesAgainst`)}
          {membersAbstained && renderVoteColumn(membersAbstained, `${keyPrefix}.votes.titleVotesAbstained`)}
          {membersFor && renderVoteColumn(membersFor, `${keyPrefix}.votes.titleVotesFor`)}
        </Stack>
      </Stack>
    </>
  );
};
