import { IMeeting } from '../../types';
import { calculateMeetingDuration } from 'utils/calculateMeetingDuration';
import { useLocale } from '@koopajs/react';
import { IUser } from '@koopajs/app';
import { getCurrentUserRoles } from 'utils/getCurrentUserRoles';
import { useCommitteeName } from './useCommitteeName';

interface IUseMeetingStatusResponse {
  isMeetingInPreparation: boolean;
  isMeetingNoticeSent: boolean;
  isMeetingAgendaSent: boolean;
  isMeetingMissingParticipants: boolean;
  isMeetingReadyToStart: boolean;
  hasMeetingStarted: boolean;
  hasMeetingEnded: boolean;
  meetingDuration: string;
  meetingHasNoteTaker: boolean;
  meetingCommittee?: string;
  isUserMeetingNoteTakerRole: boolean;
  isUserMeetingCreatorRole: boolean;
  isUserCurrentNoteTaker: boolean;
  isCurrentUserMeetingOwner: boolean;
  isUserAuthorizedEditor: boolean;
  canUserCurrentlyEditMeeting: boolean;
  canUserContinueMeeting: boolean;
}

interface IUseMeetingStatusProps {
  meeting?: IMeeting;
  user?: IUser;
}

export const useMeetingStatus = (props: IUseMeetingStatusProps): IUseMeetingStatusResponse => {
  const { meeting, user } = props;
  const { t } = useLocale();

  //MEETING

  const isMeetingInPreparation = !meeting?.invitationSentAt;
  const isMeetingNoticeSent = Boolean(meeting?.invitationSentAt);
  const isMeetingAgendaSent = Boolean(meeting?.agendaSentAt);
  const isMeetingMissingParticipants = !meeting?.participants?.length;
  const isMeetingReadyToStart = isMeetingAgendaSent && !isMeetingMissingParticipants;
  const hasMeetingStarted = Boolean(meeting?.minutesStartedAt);
  const hasMeetingEnded = Boolean(meeting?.minutesEndedAt);
  const meetingDuration = calculateMeetingDuration(t, meeting?.topics);
  const meetingHasNoteTaker = Boolean(meeting?.currentNoteTakerId);
  const meetingCommittee = useCommitteeName(meeting?.committeeId as string);

  //USER

  const userRoles = getCurrentUserRoles({
    activeCommitteeMembers: meeting?.activeCommitteeMembers,
    userId: user?.id
  });

  const isUserMeetingNoteTakerRole = userRoles.includes('takingNotes');
  const isUserMeetingCreatorRole = userRoles.includes('createMeetings');
  const isUserCurrentNoteTaker = meeting?.currentNoteTakerId === user?.id;
  const isCurrentUserMeetingOwner = user?.id === meeting?.$ownedBy;
  const isUserAuthorizedEditor = isUserMeetingCreatorRole || isCurrentUserMeetingOwner;
  const canUserCurrentlyEditMeeting = isUserAuthorizedEditor && !hasMeetingStarted;
  const canUserContinueMeeting =
    isUserCurrentNoteTaker || (isUserMeetingNoteTakerRole && !meetingHasNoteTaker);

  return {
    isMeetingInPreparation,
    isMeetingNoticeSent,
    isMeetingAgendaSent,
    isMeetingMissingParticipants,
    isMeetingReadyToStart,
    hasMeetingStarted,
    hasMeetingEnded,
    meetingDuration,
    meetingHasNoteTaker,
    meetingCommittee,
    isUserMeetingNoteTakerRole,
    isUserMeetingCreatorRole,
    isUserCurrentNoteTaker,
    isCurrentUserMeetingOwner,
    isUserAuthorizedEditor,
    canUserCurrentlyEditMeeting,
    canUserContinueMeeting
  };
};
