/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { FormContext } from '@koopajs/react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { ICoreProps } from '@koopajs/mui';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio as RadioMUI,
  FormHelperText,
  Box
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export interface IRadioFieldTempProps extends ICoreProps {
  isOptional?: boolean;
  row?: boolean;
  name: string;
  validationRules?: RegisterOptions;
  defaultValue?: string;
  options: {
    id: string;
    label: React.ReactNode;
  }[];
  testId?: string;
  sxFormLabel?: SxProps<Theme>;
  sxFormControlLabel?: SxProps<Theme>;

  /**
   * @deprecated Do not use. Will be removed soon.
   */
  label?: string;
}

export const RadioFieldTemp: React.FC<IRadioFieldTempProps> = (props) => {
  const {
    isLoading,
    validationRules,
    isDisabled,
    isOptional,
    sx,
    name,
    options,
    row,
    variant,
    defaultValue,
    i18n,
    sxFormControlLabel,
    sxFormLabel,
    testId
  } = props;
  const context = useContext(FormContext);
  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'FormRadio' });

  if (!context.form) {
    throw new Error('"Form.Radio" component needs to be use inside a FormController');
  }

  const resolve = (path: string, obj: { [k: string]: unknown }): { [k: string]: unknown } => {
    return path.split('.').reduce(function (prev: any, curr: string) {
      return prev ? prev[curr] : null;
    }, obj || self);
  };

  const errors = resolve(name, context.form.formState.errors);

  return (
    <FormControl
      component="fieldset"
      margin={variant === 'dense' ? 'dense' : 'normal'}
      disabled={context.isProcessing || isLoading || isDisabled}
      error={Boolean(errors)}
      fullWidth
      sx={sx}
    >
      <FormLabel
        disabled={context.isProcessing || isLoading || isDisabled}
        component="legend"
        sx={{ mb: variant === 'dense' ? 0 : 1, ...sxFormLabel }}
      >
        {t('label')}
      </FormLabel>

      <Controller
        render={({ field }) => (
          <RadioGroup
            value={field.value || defaultValue || ''}
            name={field.name}
            onChange={field.onChange}
            row={Boolean(row)}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                data-cy={testId || `koopa_switch-label_${name}`}
                value={option.id}
                control={<RadioMUI sx={variant === 'dense' ? { m: 0.5, p: 0 } : {}} />}
                label={option.label}
                componentsProps={{
                  typography: {
                    color: errors ? 'error' : ''
                  }
                }}
                sx={sxFormControlLabel}
              />
            ))}
          </RadioGroup>
        )}
        name={name}
        rules={{ required: !isOptional, ...validationRules }}
        control={context.form.control}
      />

      <FormHelperText>{errors ? t('errorText') : t('helperText')}</FormHelperText>
    </FormControl>
  );
};
