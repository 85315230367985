import { Api } from '@koopajs/app';
import { useEffect, useState } from 'react';
import { IMeeting, ITopic } from 'types';

interface IUsePastMeetingsApprovalListArgs {
  topic: ITopic;
}

interface IUsePastMeetingsApprovalListResponse {
  allMeetings: IMeeting[];
  meetingsApprovedDuringMeeting: IMeeting[];
  meetingsNotApproved: IMeeting[];
}
export const usePastMeetingsApprovalList = (
  args: IUsePastMeetingsApprovalListArgs
): IUsePastMeetingsApprovalListResponse => {
  const { topic } = args;

  const [allMeetings, setAllMeetings] = useState<IMeeting[]>([]);
  const [meetingsApprovedDuringMeeting, setMeetingsApprovedDuringMeeting] = useState<IMeeting[]>([]);
  const [meetingsNotApproved, setMeetingsNotApproved] = useState<IMeeting[]>([]);

  useEffect(() => {
    if (!topic.pastMeetingsToApprove?.length) {
      setAllMeetings([]);
      return;
    }

    const fetchPastMeetings = async () => {
      const res = await Api.client.get(
        `/meetings?size=50&filters[]=${encodeURIComponent(
          `id:${topic.pastMeetingsToApprove?.map((pm) => pm.meetingId).join('|')}`
        )}`
      );
      return res;
    };

    fetchPastMeetings()
      .then((res) => {
        // all meetings
        const allMeetings: IMeeting[] = res.data.resources;
        setAllMeetings(allMeetings);

        // unapproved meetings
        const meetingsNotApproved = allMeetings.filter((m) => !m.boardApprovedAt);
        setMeetingsNotApproved(meetingsNotApproved);

        // meetings approved during meeting
        const pastMeetingsApprovedDuringMeeting = topic.pastMeetingsToApprove
          ?.filter((m) => m.isApproved)
          .map((m) => m.meetingId);
        if (pastMeetingsApprovedDuringMeeting?.length) {
          const meetingsApprovedDuringMeeting = allMeetings.filter((m) => {
            return m.boardApprovedAt && pastMeetingsApprovedDuringMeeting.includes(m.id);
          });
          setMeetingsApprovedDuringMeeting(meetingsApprovedDuringMeeting);
        }
      })
      .catch(() => {
        // TODO: error handling
        setAllMeetings([]);
        setMeetingsApprovedDuringMeeting([]);
        setMeetingsNotApproved([]);
      });
  }, [JSON.stringify(topic.pastMeetingsToApprove)]);

  return { allMeetings, meetingsApprovedDuringMeeting, meetingsNotApproved };
};
