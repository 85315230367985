import { BodyField } from './Fields/BodyField';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { RecordVote } from '../Header/RecordVote';
import { TopicTitle } from '../Header/TopicTitle';

interface IAdjournmentFields {
  liveMeeting: IUseLiveMeetingResponse;
}

export const AdjournmentFields: React.FC<IAdjournmentFields> = ({ liveMeeting }) => {
  return (
    <MeetingInProgressFormController liveMeeting={liveMeeting}>
      <TopicTitle liveMeeting={liveMeeting} />
      <RecordVote liveMeeting={liveMeeting} />
      <BodyField />
    </MeetingInProgressFormController>
  );
};
