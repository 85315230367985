import { ReactInstance, useCallback } from 'react';
import { useLocale } from '@koopajs/react';
import { Button } from '@mui/material';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { SystemStyleObject } from '@mui/system';

const pageStyle: string = `
  @page {
    size: letter;
    }
`;

export const RenderPrintButton: React.FC<{
  keyPrefix?: string;
  componentRef: React.MutableRefObject<ReactInstance | null>;
  documentTitle?: string;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  sx?: SystemStyleObject;
}> = (props) => {
  const { keyPrefix, componentRef, documentTitle, variant = 'contained', size, sx } = props;
  const { t } = useLocale();

  const handleTriggerButton = useCallback(() => {
    return (
      <Button size={size} variant={variant} startIcon={<PrintIcon />} sx={sx}>
        {keyPrefix ? t(keyPrefix + '.labelPrint') : t('common:print')}
      </Button>
    );
  }, []);

  return (
    <ReactToPrint
      trigger={handleTriggerButton}
      // eslint-disable-next-line react/jsx-no-bind
      content={() => componentRef.current}
      documentTitle={documentTitle}
      pageStyle={pageStyle}
    />
  );
};
