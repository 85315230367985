import { TextFieldBase, ITextFieldBaseProps } from './TextFieldBaseTemp';

export interface ITextMultiLineProps extends ITextFieldBaseProps {
  rows?: number;
  minRows?: number;
  maxRows?: number;
}

export const TextMultiLine: React.FC<ITextMultiLineProps> = (props) => {
  const { rows, minRows, maxRows, isDisabled, sx, propsTextField, ...restProps } = props;
  return (
    <TextFieldBase
      testId={`koopa_text-field-multiline_${props.name}`}
      {...restProps}
      isDisabled={isDisabled}
      propsTextField={{
        multiline: true,
        rows,
        minRows,
        maxRows,
        ...propsTextField
      }}
      sx={sx}
    />
  );
};
