import { Api } from '@koopajs/app';
import { useResourceList, useUserShow } from '@koopajs/react';
import { useEffect, useState } from 'react';
import { ICommittee, ICommitteeMember } from 'types';

export const useUploadDocumentRecordPermissions = () => {
  const { user: currentUser } = useUserShow();

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees'
  });

  const [committeeMembersWithCreateDocRecordsPermission, setCommitteeMembersWithCreateDocRecordsPermission] =
    useState<ICommitteeMember[]>([]);

  useEffect(() => {
    const fetchCommitteeMembersWhoCanUploadDocs = async () => {
      const res = await Api.client.get(
        '/committee-members?size=50&filters[]=roles.role:"createDocumentRecords"'
      );

      return res;
    };
    fetchCommitteeMembersWhoCanUploadDocs()
      .then((res) => {
        setCommitteeMembersWithCreateDocRecordsPermission(res.data.resources);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentUser?.id]);

  //committees that the user can upload documents to - ids

  const committeeIdsUserCanCreateDocumentRecords = committeeMembersWithCreateDocRecordsPermission
    .filter((cM) => cM.userId === currentUser?.id)
    .map((c) => c.committeeId);

  const canUserUploadDocuments = committeeIdsUserCanCreateDocumentRecords.length > 0;

  //committees that the user can upload documents to - array of objects
  const committeesUserCanUpload = committees?.filter((c) =>
    committeeIdsUserCanCreateDocumentRecords.includes(c.id)
  );

  return { canUserUploadDocuments, committeesUserCanUpload };
};
