import { IUseResourceListResponse, useLocale } from '@koopajs/react';
import { Typography, Box } from '@mui/material';
import { Loading } from '@koopajs/mui';
import { IUserPublicProfile } from '@koopajs/app';
import { MemberCard } from './MemberCard';
import { alphabeticalUserSortFunction } from 'utils/alphabeticalUserSortFunction';

interface IMembersListProps {
  usersUseResourceList: IUseResourceListResponse<IUserPublicProfile>;
}

export const MembersList: React.FC<IMembersListProps> = (props) => {
  const { t } = useLocale();
  const keyPrefix = 'Organization.OrganizationMembers';

  const { usersUseResourceList } = props;

  const { resources: users, state } = usersUseResourceList;

  return (
    <>
      <Typography variant="h2" sx={{ marginTop: '24px', fontWeight: 500, fontSize: '20px' }}>
        {t(keyPrefix + '.title')} ({users.length || 0})
      </Typography>

      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {state === 'loading' || state === 'reloading' ? (
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        ) : (
          <>
            {users.sort(alphabeticalUserSortFunction).map((u) => {
              return <MemberCard user={u} key={u.id} />;
            })}
          </>
        )}
      </Box>
    </>
  );
};
