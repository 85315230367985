import React from 'react';
import { IResourcePickerChildrenSingleProps } from '../types';
import { Card, CardActionArea, Avatar, CardContent, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const CardSingleValue: React.FC<IResourcePickerChildrenSingleProps> = (props) => {
  const {
    handleOpen,
    isProcessing: isLoadingForm,
    isLoading,
    isDisabled,
    isAvatar,
    value,
    width,
    sx,
    cardVariant
  } = props;
  const isProcessing = isLoading || isDisabled || isLoadingForm;
  return (
    <Card
      variant={cardVariant || 'outlined'}
      sx={{
        width: width,
        opacity: isProcessing ? 0.5 : 1,
        ...sx
      }}
    >
      <CardActionArea onClick={handleOpen} disabled={isProcessing} sx={{ height: '100%' }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isAvatar && (
              <Avatar sx={{ marginRight: 2 }}>
                {value?.labelAvatar ? value?.labelAvatar : value?.label?.[0]}
              </Avatar>
            )}
            <Box sx={{ ml: isAvatar ? 0 : 1 }}>
              <Typography variant="body2" color="primary">
                {value?.label}
              </Typography>
              <Typography variant="body2" color="#00000099">
                {value?.subLabel}
              </Typography>
            </Box>
          </Box>
          <EditIcon sx={{ color: '#0000008a' }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
