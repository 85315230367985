/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { FormContext, useLocale } from '@koopajs/react';
import TipTapEditor, { ITipTapEditorEditableProps } from './TipTapEditor';
import { Controller, RegisterOptions, Validate } from 'react-hook-form';

export interface IRichTextMultiLineProps extends ITipTapEditorEditableProps {
  name: string;
  validationRules?: RegisterOptions;
  testId?: string;
  isAutofocused?: boolean;
}

export const RichTextMultiLine: React.FC<IRichTextMultiLineProps> = (props) => {
  const {
    name,
    isOptional,
    isDisabled,
    isLoading,
    validationRules,
    height,
    minHeight,
    maxHeight,
    onBlur,
    onFocus,
    i18n,
    sx,
    sxEditorContainer,
    testId,
    isLabelVisible,
    textFieldVariant = 'default',
    isAutofocused
  } = props;

  const { t, locale } = useLocale();
  const keyPrefix = i18n?.keyPrefix || 'Components.TempRichTextMultiLine';
  // const { t } = useLocale({ ...i18n, coreKeyPrefix: 'RichTextMultiLine' });

  const context = useContext(FormContext);

  if (!context.form) {
    throw new Error('"Form.RichTextMultiLine" component needs to be use inside a FormController');
  }

  const resolve = (path: string, obj: { [k: string]: unknown }): { [k: string]: unknown } => {
    return path.split('.').reduce(function (prev: any, curr: string) {
      return prev ? prev[curr] : null;
    }, obj || self);
  };

  const errors = resolve(name, context.form.formState.errors);
  const errorMessageFromValidation = errors && (errors.message as string);

  const updatedValidationRules = { ...validationRules };

  // strip html for min/max length validation rules and create new validation methods
  const { minLength, maxLength } = validationRules || {};
  let validate: Validate<any> | Record<string, Validate<any>> | undefined =
    maxLength || minLength ? {} : undefined;

  if (maxLength) {
    validate!.strippedMaxLength = (v) => {
      if (!v) return true;
      return v.replace(/<[^>]*>?/gm, '').length <= maxLength
        ? true
        : t(keyPrefix + '.validationRules.maxLength', { maxLength: maxLength.toLocaleString(locale) });
    };
    delete updatedValidationRules?.maxLength;
  }
  if (minLength) {
    validate!.strippedMinLength = (v) => {
      if (!v) return true;
      return v?.replace(/<[^>]*>?/gm, '').length >= minLength
        ? true
        : t(keyPrefix + '.validationRules.minLength', { minLength: minLength.toLocaleString(locale) });
    };
    delete updatedValidationRules?.minLength;
  }

  return (
    <Controller
      render={({ field }) => {
        return (
          <TipTapEditor
            value={field.value || ''}
            onChange={field.onChange}
            formState={context.form!.formState}
            isError={Boolean(errors)}
            helperText={errors ? errorMessageFromValidation || t(keyPrefix + '.errorText') : undefined}
            height={height}
            minHeight={minHeight}
            maxHeight={maxHeight}
            i18n={{ keyPrefix }}
            isDisabled={isDisabled || isLoading}
            onBlur={onBlur}
            onFocus={onFocus}
            sx={sx}
            sxEditorContainer={sxEditorContainer}
            testId={testId || `koopa_rich-text-multi-line_${name}`}
            isLabelVisible={isLabelVisible}
            textFieldVariant={textFieldVariant}
            isAutofocused={isAutofocused}
          />
        );
      }}
      name={name}
      rules={{
        required: { value: !isOptional, message: t(keyPrefix + '.validationRules.required') },
        validate,
        ...updatedValidationRules
      }}
      control={context.form.control}
    />
  );
};

export default RichTextMultiLine;
