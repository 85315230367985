import { DateTime } from 'luxon';
import { IMeeting, ITopic } from 'types';

export const calculateTopicsStartTime = (meeting: IMeeting): ITopic[] | undefined => {
  let topics = meeting.topics;

  if (meeting?.startTime) {
    const meetingStartTimeISO = DateTime.fromFormat(meeting.startTime as string, 'HH:mm', {
      zone: 'UTC'
    });
    let cumulativeMeetingDurationUnix = meetingStartTimeISO.toMillis() / 1000;

    topics = meeting.topics?.map((topic: ITopic) => {
      const updatedTopic = { ...topic, calculatedTopicStartTime: cumulativeMeetingDurationUnix };
      cumulativeMeetingDurationUnix = cumulativeMeetingDurationUnix + (topic.durationSeconds || 0);
      return updatedTopic;
    });
  }

  return topics;
};
