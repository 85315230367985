import React, { useCallback } from 'react';
import { useUserShow, useUserUpdate, FormController } from '@koopajs/react';
import { Api, TrackEvent } from '@koopajs/app';
import { Button, Box, Stack } from '@mui/material';
import { ICoreProps, ErrorMessage } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { useRouteMatch } from 'react-router-dom';
import { TextField } from 'components/temp/TextFieldTemp';

export interface IContentNameProps extends ICoreProps {
  moveNext: () => void;
  movePrevious: () => void;
  finishOnBoarding?: () => Promise<void>;
}

export const ContentName: React.FC<IContentNameProps> = (props: IContentNameProps) => {
  const { moveNext, movePrevious, i18n, finishOnBoarding } = props;

  const match = useRouteMatch('/invitation/:token');
  const urlParams = match?.params as { token?: string };

  const { t } = useLocale();
  const keyPrefix = i18n?.keyPrefix;

  const { user, isProcessing: isProcessingUserShow, errorMessage: errorMessageUserShow } = useUserShow();
  const {
    updateUser,
    isProcessing: isProcessingUserUpdate,
    errorMessage: errorMessageUserUpdate
  } = useUserUpdate();

  const onSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const isSuccessful = await updateUser({ ...formData });
    if (isSuccessful) {
      TrackEvent('onboarding-name');
      if (finishOnBoarding && urlParams?.token) {
        await finishOnBoarding();
        await Api.client.post(`/invitations/${urlParams.token}/accepts`);
        // TODO: for now triggering a refresh but we should change in future
        location.replace('/');
      } else if (finishOnBoarding) {
        await finishOnBoarding();
        location.replace('/');
      } else {
        moveNext();
      }
    }

    return isSuccessful;
  }, []);

  return (
    <Box>
      <ErrorMessage error={errorMessageUserShow} />
      <ErrorMessage error={errorMessageUserUpdate} />

      <Box>
        <Box>
          <Box className={'style.inputWrapper'} sx={{ mt: 1.25, mb: 1.25 }}>
            <FormController
              onSubmit={onSubmit}
              defaultValues={{ firstname: user?.firstname || '', lastname: user?.lastname || '' }}
            >
              <TextField
                isAutoFocus
                name="firstname"
                i18n={{ keyPrefix: `${keyPrefix}.TextFieldFirstName` }}
                validationRules={{ minLength: 2, maxLength: 50 }}
              />
              <TextField
                name="lastname"
                i18n={{ keyPrefix: `${keyPrefix}.TextFieldLastName` }}
                validationRules={{ minLength: 2, maxLength: 50 }}
              />
              <Stack direction="row" spacing={2}>
                <Button onClick={movePrevious} disabled={isProcessingUserShow || isProcessingUserUpdate}>
                  {t('common:labelBack')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isProcessingUserShow || isProcessingUserUpdate}
                  type="submit"
                >
                  {finishOnBoarding ? t('common:labelFinish') : t('common:labelNext')}
                </Button>
              </Stack>
            </FormController>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
