import { ISignature } from 'types';
import { isDefined } from 'utils/isDefined';
import { Stack, Typography, Box, Button, Tooltip, Chip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { User, useLocale } from '@koopajs/react';

interface ILatestReviewsByUser {
  reviewers?: {
    userId: string;
    latestReview?: ISignature | undefined;
  }[];
}

export const LatestReviewsByUser: React.FC<ILatestReviewsByUser> = (props) => {
  const { reviewers } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.LatestReviewsByUser';

  const latestReviewsByUser: ISignature[] =
    reviewers
      ?.map((reviewers) => reviewers.latestReview)
      .filter(isDefined)
      .sort((a, b) => {
        const dateA: any = a?.signedAt ? new Date(a.signedAt).valueOf() : '';
        const dateB: any = b?.signedAt ? new Date(b.signedAt).valueOf() : '';
        return dateB - dateA;
      }) || [];

  if (!latestReviewsByUser.length) return null;

  return (
    <>
      {latestReviewsByUser.map((review) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }} key={review.userId} data-cy="user-review">
            <DoneIcon fontSize="small" sx={{ opacity: '0.6', mr: 0.5 }} />
            <Typography variant="body2" sx={{ opacity: '0.6' }}>
              {t(`${keyPrefix}.reviewedBy`)}
            </Typography>
            &nbsp;
            <User id={review?.userId}>
              {(profile) =>
                profile ? (
                  <Typography variant="body2" sx={{ opacity: '0.6' }}>
                    {profile?.username}
                  </Typography>
                ) : (
                  <Typography />
                )
              }
            </User>
            <Typography variant="body2" sx={{ opacity: '0.6' }}>
              &nbsp;
              {t('common:on')}
              &nbsp;
              <DateTimeStartEnd
                isoStringDate={review?.signedAt}
                dateFormat="DATE_FULL_NO_YEAR"
                separator={` ${t('common:at')} `}
                isoStringStart={review?.signedAt}
              />
            </Typography>
          </Box>
        );
      })}
    </>
  );
};
