import { Duration } from 'luxon';
import { TFunction } from 'react-i18next';

export const calculateDuration = (t: TFunction<'translation', undefined>, durationMillis: number): string => {
  if (!durationMillis || durationMillis < 60000) {
    return '0 minutes';
  }

  const { hours, minutes: mins } = Duration.fromMillis(durationMillis)
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  let durationString = '';
  if (hours) {
    durationString =
      durationString + (hours !== 1 ? hours + ' ' + t('common:hours') : hours + ' ' + t('common:hour'));
    if (mins) {
      durationString = durationString + ' ';
    }
  }
  if (mins) {
    durationString =
      durationString + (mins !== 1 ? mins + ' ' + t('common:minutes') : mins + ' ' + t('common:minute'));
  }
  return durationString;
};
