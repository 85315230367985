import { ICommitteeMember } from 'types';
import { Stack, Typography, Chip, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocale } from '@koopajs/react';
import { MandateStartEndDates } from '../../MandateStartEndDates';
import { PermissionChipsMobile } from '../../PermissionChipsMobile';
import { UserChip } from 'components/UserChip';

interface ICommitteeMemberAccordionProps {
  committeeMember: ICommitteeMember;
  editButton?: React.ReactNode;
  isAccordionExpanded: boolean;
  handleChangeAccordion: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export const CommitteeMemberAccordion: React.FC<ICommitteeMemberAccordionProps> = (props) => {
  const { committeeMember, editButton, isAccordionExpanded, handleChangeAccordion } = props;
  const { t } = useLocale();
  const keyPrefix = 'OrganizationCommittee.CommitteeMemberAccordion';

  return (
    <>
      <Accordion
        key={committeeMember.userId}
        elevation={0}
        disableGutters
        onChange={handleChangeAccordion}
        expanded={isAccordionExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0, minHeight: 'auto', '& .MuiAccordionSummary-content': { m: 0 } }}
        >
          <Box>
            <Stack gap={1} direction="row" flexWrap="wrap">
              <UserChip userId={committeeMember.userId} variant="outlined" />
              <Chip variant="outlined" label={t(`common:committeeMemberTitles.${committeeMember.title}`)} />
            </Stack>
            <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
              <MandateStartEndDates committeeMember={committeeMember} />
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 3 }}>
          {committeeMember.roles && committeeMember.roles?.length > 0 && (
            <PermissionChipsMobile roles={committeeMember.roles} />
          )}

          {committeeMember.roles && committeeMember.roles.length > 0 ? (
            <Typography variant="body2" color="gray" sx={{ mt: 3 }}>
              {t(keyPrefix + '.signatureRequired')}
            </Typography>
          ) : (
            <Typography variant="body2" color="grey">
              {t('common:numberPermissions', {
                count: 0
              })}
            </Typography>
          )}
        </AccordionDetails>

        {editButton && editButton}
      </Accordion>
      {!isAccordionExpanded && (
        <Typography variant="body2" color="grey" sx={{ pt: '14px' }}>
          {t('common:numberPermissions', {
            count: committeeMember.roles?.length || 0
          })}
        </Typography>
      )}
    </>
  );
};
