import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useState, useCallback } from 'react';
import { useResourceDelete, useLocale } from '@koopajs/react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton, Stack, Box } from '@mui/material';
import { Date as KoopaDate, AccessValidator } from '@koopajs/mui';
import { IWorkspaceInvitation } from 'types';
export interface IInvitationRows {
  invitation: IWorkspaceInvitation;
}

export const InvitationRows: React.FC<IInvitationRows> = ({ invitation }) => {
  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useLocale();
  const keyPrefix = 'Settings.UsersAndPermissions';

  const { deleteResource } = useResourceDelete({ path: '/invitations', id: invitation.id });

  const handleOpenInvitationMenu = useCallback(
    (invitationId: string) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenRowId(invitationId);
    },
    []
  );

  const handleCloseInvitationMenu = useCallback(() => {
    setAnchorEl(null);
    setOpenRowId(null);
  }, []);

  const handleRevokeInvitation = useCallback(async () => {
    await deleteResource();
    handleCloseInvitationMenu();
  }, []);

  const handleCopyToClipboardInvitationUrl = useCallback((url: string) => {
    return async (): Promise<void> => {
      try {
        await navigator.clipboard.writeText(url);
      } catch (e) {
        console.log(e);
      }
      handleCloseInvitationMenu();
    };
  }, []);

  const displayPermission = (invitationPermissions: string[]): string => {
    //TODO: we assume for now that the user has only 1 permission
    const permission = invitationPermissions?.[0];
    if (permission === 'member') {
      return t(keyPrefix + '.team-member');
    }
    if (permission === 'admin') {
      return t(keyPrefix + '.admin');
    }
    if (permission === 'owner') {
      return t(keyPrefix + '.owner');
    } else return permission;
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell key={invitation.id} component="th" scope="row">
        <Stack direction="column">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Chip label={invitation.email as string} />
          </Stack>
          <Box sx={{ ml: 1, mt: 1, display: { xs: 'table-cell', sm: 'none' } }}>
            {t(keyPrefix + '.Invitations.invitationPending')}
            <br />
            {t(keyPrefix + '.Invitations.expires')}&nbsp;
            <KoopaDate isoDate={invitation.expiredAt as string} />
          </Box>
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
        <>
          {invitation.rejectedAt && <p>{`${t(keyPrefix + '.Invitations.rejected')}`}</p>}
          {invitation.expiredAt && !invitation.acceptedAt && !invitation.rejectedAt && (
            <p>
              {t(keyPrefix + '.Invitations.invitationPending')}
              <br />
              {t(keyPrefix + '.Invitations.expires')}&nbsp;
              <KoopaDate isoDate={invitation.expiredAt as string} />{' '}
            </p>
          )}
        </>
      </TableCell>
      <TableCell align="right">{displayPermission(invitation?.permissions)}</TableCell>
      <TableCell align="right">
        <AccessValidator permissions="permissions:update">
          {(props) => (
            <>
              {props.hasPermissions && !invitation.rejectedAt && (
                <>
                  <IconButton onClick={handleOpenInvitationMenu(invitation.id)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openRowId === invitation.id}
                    onClose={handleCloseInvitationMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    {!invitation.rejectedAt && (
                      <MenuItem onClick={handleCopyToClipboardInvitationUrl(invitation.inviteUrl as string)}>
                        {`${t(keyPrefix + '.Invitations.copyLink')}`}
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleRevokeInvitation}>{`${t(
                      keyPrefix + '.Invitations.revokeInvitation'
                    )}`}</MenuItem>
                  </Menu>
                </>
              )}
            </>
          )}
        </AccessValidator>
      </TableCell>
    </TableRow>
  );
};
