import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import emptyStateImage from '../assets/image.svg';
import { useLocale } from '@koopajs/react';
import { PageContainer } from './temp/PageContainer';
import { Helmet } from 'react-helmet';

interface IEmptyStateProps {
  title?: React.ReactNode;
  windowTitle?: string;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  button?: React.ReactNode;
  image?: string;
}

export const EmptyState: React.FC<IEmptyStateProps> = (props) => {
  const { title, windowTitle, subTitle, description, image = emptyStateImage, button } = props;
  const { t } = useLocale();

  return (
    <>
      {windowTitle && (
        <Helmet>
          <title>{windowTitle}</title>
        </Helmet>
      )}
      <PageContainer sxChildren={{ padding: '24px' }}>
        <Container sx={{ textAlign: 'center', mt: 4 }}>
          {title || subTitle || description ? (
            <Box sx={{ mb: 4 }}>
              {title && (
                <Typography variant="h4" component="h4">
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography variant="h6" component="h6" sx={title ? { mt: 1 } : {}}>
                  {subTitle}
                </Typography>
              )}
              {description && (
                <Typography variant="subtitle1" sx={title || subTitle ? { mt: 1 } : {}}>
                  {description}
                </Typography>
              )}
            </Box>
          ) : (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h4" component="h4">
                {t('Components.EmptyState.title')}
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                {t('Components.EmptyState.description.emptyDatabase')}
              </Typography>
            </Box>
          )}
          {button && button}
          <Box>
            <img src={image} srcSet="" loading="lazy" style={{ width: '50%' }} />
          </Box>
        </Container>
      </PageContainer>
    </>
  );
};
