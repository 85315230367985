import { Stack } from '@mui/material';
import { ApproveMinutesItem } from './Item';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';

interface IApproveMinutesList {
  liveMeeting: IUseLiveMeetingResponse;
  isSidebarSection?: boolean;
}

export const ApproveMinutesList: React.FC<IApproveMinutesList> = (props) => {
  const { liveMeeting, isSidebarSection } = props;
  const { topic } = liveMeeting;

  return (
    <>
      {topic?.pastMeetingsToApprove && topic?.pastMeetingsToApprove.length > 0 && (
        <Stack spacing={1.5} data-cy="meeting-in-progress_past-minutes-approval-list">
          {topic?.pastMeetingsToApprove?.map(({ meetingId }) => (
            <ApproveMinutesItem
              key={meetingId}
              liveMeeting={props.liveMeeting}
              minutesId={meetingId}
              isSidebarSection={isSidebarSection}
            />
          ))}
        </Stack>
      )}
    </>
  );
};
