import React from 'react';
import { SettingsGeneral } from './pages/general';
import { SettingsPermissions } from './pages/permissions';
import { Layout } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';

export const Settings: React.FC = () => {
  const keyPrefix = 'Settings';
  const { t } = useLocale();
  return (
    <Layout.MultiPageContainer
      title="Settings"
      i18n={{ keyPrefix }}
      homepagePath="/settings"
      sx={{ pb: 4, overflow: 'auto' }}
      pages={[
        {
          title: t(keyPrefix + '.general'),
          path: 'general',
          component: SettingsGeneral
        },
        {
          title: t(keyPrefix + '.usersAndPermissions'),
          path: 'permissions',
          component: SettingsPermissions
        }
      ]}
    />
  );
};
