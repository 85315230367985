import { IconButton } from '@mui/material';
import { theme } from './Core/theme';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

interface ILightBulbIconButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

export const LightBulbIconButton: React.FC<ILightBulbIconButtonProps> = (props) => {
  const { ariaLabel, onClick } = props;

  return (
    <IconButton
      aria-label={ariaLabel}
      onClick={onClick}
      sx={{ '&:hover': { backgroundColor: 'tertiary.lighter' } }}
    >
      <LightbulbIcon
        sx={{
          color: 'tertiary.main',
          stroke: `${theme.palette.primary.main}`,
          strokeWidth: 1
        }}
      />
    </IconButton>
  );
};
