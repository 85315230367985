import { Button, Stack } from '@mui/material';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { EmptyState } from 'components/EmptyState';
import { DelayComponentRender } from 'components/DelayComponentRender';
import { useLocale } from '@koopajs/react';
import { Link as RouterLink } from 'react-router-dom';
import { Loading } from '@koopajs/mui';

interface IMeetingLoadingOrErrorProps {
  errorMessage: string | undefined;
  isProcessing: boolean;
}

export const MeetingLoadingOrError: React.FC<IMeetingLoadingOrErrorProps> = (props): JSX.Element => {
  const { errorMessage, isProcessing } = props;

  const { t } = useLocale();

  if (isProcessing) {
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );
  } else if (errorMessage && errorMessage !== 'Error 404: Not Found') {
    const extractedErrorMessage = extractErrorMessage(errorMessage);
    return (
      <EmptyState
        title={extractedErrorMessage}
        button={
          <Button component={RouterLink} to="/" variant="contained" disableElevation>
            {t('NotFound.EmptyState.goToDashboardButton')}
          </Button>
        }
      />
    );
  }

  return (
    <DelayComponentRender
      delay={1250}
      component={
        <EmptyState
          title={t('Components.MeetingLoadingOrError.EmptyState.title')}
          description={t('Components.MeetingLoadingOrError.EmptyState.description')}
          button={
            <Button component={RouterLink} to="/" variant="contained" disableElevation>
              {t('NotFound.EmptyState.goToDashboardButton')}
            </Button>
          }
        />
      }
    />
  );
};
