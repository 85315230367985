import { useLocation } from 'react-router-dom';

export const useCheckIfHidingNavAndSidebar = (): boolean => {
  const location = useLocation();

  const regexMeetingInProgressPath =
    /\/meetings\/[0-7][0-9A-HJKMNP-TV-Z]{25}\/topics\/[0-7][0-9A-HJKMNP-TV-Z]{25}.*/gi;
  const regexBoardMemberViewPath = /\/meeting-annotate\/[0-7][0-9A-HJKMNP-TV-Z]{25}.*/gi;

  return (
    regexMeetingInProgressPath.test(location.pathname) || regexBoardMemberViewPath.test(location.pathname)
  );
};
