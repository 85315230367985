import { useSearchParams } from '@koopajs/react';
import { useCallback, useEffect } from 'react';

export const useResourceListQuery = () => {
  const search = useSearchParams();

  const handlePageChange = useCallback((page: number): void => {
    search.setSearchParams({ page });
  }, []);

  const handleSizeChange = useCallback((size: number): void => {
    search.setSearchParams({ size, page: undefined });
  }, []);

  useEffect(() => {
    search.setSearchParams({ page: undefined });
  }, [JSON.stringify(search?.searchParams?.filters)]);

  return { search, handlePageChange, handleSizeChange };
};
