import { useResourceList, useLocale } from '@koopajs/react';
import { Stack, Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material';
import { IDocument } from 'types';
import { Document } from './Document';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AddDocumentButton } from './AddDocumentButton';
import { AddLinkButton } from './AddLinkButton';
import { SystemStyleObject } from '@mui/system';
import { useState } from 'react';
import { DocumentFormat } from 'types';

interface IAccordionTrue {
  isAccordion: true;
  accordionOptions?: {
    documentsVisible?: number;
    label?: string;
    defaultExpanded?: boolean;
    isBlueVariant?: boolean;
  };
}

interface IAccordionFalse {
  isAccordion?: false;
  accordionOptions?: never;
}

interface IDocumentListBasePropsCommon {
  isEditable?: boolean;
  isViewPrint?: boolean;
  showDivider?: boolean;
  path: string;
  sxDivider?: SystemStyleObject;
  sxDocument?: SystemStyleObject;
  skipDocumentType?: DocumentFormat[];
}

type IDocumentListBaseProps = (IAccordionTrue | IAccordionFalse) & IDocumentListBasePropsCommon;

export const DocumentList: React.FC<IDocumentListBaseProps> = (props) => {
  const {
    isAccordion,
    accordionOptions,
    isEditable,
    isViewPrint,
    showDivider,
    path,
    sxDivider,
    sxDocument,
    skipDocumentType
  } = props;

  const [isProcessingDocuments, setIsProcessingDocuments] = useState(false);

  const { t } = useLocale();

  const {
    documentsVisible = 2,
    label = t('Components.DocumentList.accordionSummary'),
    defaultExpanded,
    isBlueVariant
  } = accordionOptions || {};

  const { resources } = useResourceList<IDocument>({
    path: path
  });

  let documents = resources;

  if (skipDocumentType) {
    documents = resources.filter(
      (document) => !skipDocumentType.includes(document.attachedFile.format as DocumentFormat)
    );
  }
  const documentsAccordion = resources.slice(documentsVisible);

  if (isAccordion) {
    documents = resources.slice(0, documentsVisible);
  }

  const AccordionDocuments = (): React.ReactElement => {
    return (
      <>
        <Accordion
          elevation={0}
          sx={{
            '&:before': {
              display: 'none'
            },
            marginTop: '0!important',
            // TODO:
            ...(isBlueVariant ? { backgroundColor: 'primary.light', p: '14px' } : {})
          }}
          defaultExpanded={defaultExpanded}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="small" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-cy="document-list_accordion-summary"
            sx={{
              p: 0,
              width: 'fit-content',
              minHeight: '0px!important',
              ...{
                '& .MuiAccordionSummary-content': isBlueVariant
                  ? { m: '0px!important' }
                  : { my: '20px!important' }
              }
            }}
          >
            <Typography variant="caption" sx={{ p: 0, opacity: 0.6 }}>
              <>{label}</>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              p: 0,
              ...(isBlueVariant ? { mt: '8px' } : {})
            }}
          >
            <Stack spacing={1}>
              {documentsAccordion.map((document) => {
                return (
                  <Stack key={document.id} direction="row">
                    <Document
                      document={document}
                      isDeletable={isEditable}
                      path={path}
                      isProcessing={isProcessingDocuments}
                      setIsProcessing={setIsProcessingDocuments}
                      sx={sxDocument}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <>
      {resources.length > 0 && (
        <>
          {showDivider && <Divider sx={{ mt: '14px', ...sxDivider }} />}
          <Stack spacing={1}>
            {isViewPrint && (
              <Typography sx={{ opacity: 0.8 }}>
                {t('common:Documents.documentsAttachedToMinutes', { count: documents.length })}
              </Typography>
            )}
            {documents.map((document, index) => {
              return (
                <Stack key={document.id} direction="row">
                  <Document
                    document={document}
                    isDeletable={isEditable}
                    isViewPrint={isViewPrint}
                    path={path}
                    isProcessing={isProcessingDocuments}
                    setIsProcessing={setIsProcessingDocuments}
                    sx={sxDocument}
                  />
                </Stack>
              );
            })}
            {isAccordion && documentsAccordion?.length > 0 && <AccordionDocuments />}
          </Stack>
        </>
      )}
      {isEditable && path && (
        <Stack direction="row" flexWrap="wrap" gap="0 16px">
          <AddDocumentButton
            path={path}
            isProcessing={isProcessingDocuments}
            setIsProcessing={setIsProcessingDocuments}
          />
          <AddLinkButton
            path={path}
            isProcessing={isProcessingDocuments}
            setIsProcessing={setIsProcessingDocuments}
          />
        </Stack>
      )}
    </>
  );
};
