import { IMeeting } from 'types';
//import { FormController } from '@koopajs/react';
import { MeetingCreateFormFields } from './MeetingCreateFormFields';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';

interface IMeetingCreateFormProps {
  onClose?: (() => Promise<void>) | (() => void);
  onSubmit: (formData: object) => Promise<boolean>;
  defaultValues?: { [k: string]: unknown };
  defaultParticipants?: { id: string; label: string }[];
  meeting?: IMeeting;
  errorMessage?: string;
  meetingState: 'create' | 'edit';
  showRequiredErrors?: boolean;
}

export const MeetingCreateForm: React.FC<IMeetingCreateFormProps> = (props) => {
  const {
    onClose,
    onSubmit,
    defaultParticipants,
    defaultValues,
    meeting,
    errorMessage,
    meetingState,
    showRequiredErrors
  } = props;

  return (
    <FormControllerTemp
      onSubmit={onSubmit}
      onSubmitSuccessResetType="FORM_PAYLOAD"
      defaultValues={defaultValues}
      testId="meeting-create-form"
    >
      <MeetingCreateFormFields
        onSubmit={onSubmit}
        defaultParticipants={defaultParticipants}
        meetingState={meetingState}
        onClose={onClose}
        meeting={meeting}
        errorMessage={errorMessage}
        showRequiredErrors={showRequiredErrors}
      />
    </FormControllerTemp>
  );
};
