import React, { useCallback } from 'react';
import {
  TextField,
  Autocomplete,
  Checkbox,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from '@mui/material';
import { IResourcePickerChildrenMultiProps, IResourcePickerOption } from '../types';

export const AutoCompleteMultiValue: React.FC<IResourcePickerChildrenMultiProps> = (props) => {
  const {
    isProcessing: isLoadingForm,
    isLoading,
    isDisabledCloseOnSelect,
    isDisabled,
    isOptional,
    hasError,
    value,
    handleClose,
    handleSelectionChange,
    filterOptions,
    isOpenOnFocus,
    resources,
    width,
    sx,
    translatedLabel
  } = props;

  const isProcessing = isLoading || isDisabled || isLoadingForm;

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        autoFocus
        disabled={isProcessing}
        required={!isOptional}
        error={Boolean(hasError)}
        label={translatedLabel}
      />
    ),
    [isProcessing, isOptional, hasError, translatedLabel]
  );

  const renderOption = useCallback(
    (
      renderProps: React.HTMLAttributes<HTMLLIElement>,
      option: IResourcePickerOption,
      { selected }: AutocompleteRenderOptionState
    ) => (
      <li {...renderProps}>
        {option.id !== 'add' && <Checkbox style={{ marginRight: 4 }} checked={selected} />}
        {option.label}
      </li>
    ),
    []
  );

  return (
    <Autocomplete
      multiple
      disablePortal
      autoHighlight
      disableCloseOnSelect={isDisabledCloseOnSelect}
      openOnFocus={isOpenOnFocus}
      options={resources}
      sx={{ width: width, ...sx }}
      value={value}
      onClose={handleClose}
      onChange={handleSelectionChange}
      filterOptions={filterOptions}
      renderInput={renderInput}
      renderOption={renderOption}
      // eslint-disable-next-line react/jsx-no-bind
      isOptionEqualToValue={(option, val) => option.id === val.id}
    />
  );
};
