import { useLocale, useUserShow, useWorkspaceShow, useWorkspaceList, useLogout } from '@koopajs/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Typography,
  Link,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListSubheader,
  ListItemAvatar,
  Container
} from '@mui/material';
import { useHistory } from 'react-router';
import { IWorkspace } from 'types';
import { Dialog } from '@koopajs/mui';
import { useCallback } from 'react';

export const WorkspaceNotApproved: React.FC = () => {
  const { workspace } = useWorkspaceShow<IWorkspace>();
  const { workspaces, activeWorkspaceId, setActiveWorkspace } = useWorkspaceList();
  const history = useHistory();
  const { locale, t } = useLocale();
  const user = useUserShow();
  const { logout } = useLogout();

  const emailcontentEng = `subject=Workspace%20activation&body=%0D%0A%0D%0APlease%20do%20not%20remove.%0D%0AuserId: ${user.user?.id}%0D%0A workspaceId: ${workspace?.id}`;
  const emailcontentFr = `subject=Activation%20de%20l'espace%20de%20travail&body=%0D%0A%0D%0ANe%20pas%20supprimer%0D%0AuserId: ${user.user?.id}%0D%0A workspaceId: ${workspace?.id}`;

  const handleSwitchWorkspace = (workspaceId: string): (() => Promise<void>) => {
    return async () => {
      const isSuccess = await setActiveWorkspace(workspaceId);
      if (isSuccess) {
        history.push('/');
      }
    };
  };

  const handleLogout = useCallback(async () => {
    await logout();
  }, []);

  return (
    <Dialog.View
      isOpen={!workspace?.isApproved}
      forcedFullScreen={false}
      sx={{ '&.MuiModal-root': { backdropFilter: 'blur(1px)' } }}
      onClose={handleLogout}
      maxWidth="md"
    >
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
        <WarningAmberIcon color="warning" sx={{ mb: 3 }} fontSize="large" />
        <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            {t('Modals.ModalWorkspaceNotActivated.title')}
          </Typography>
        </Box>
        <Typography component="h2" textAlign="center">
          {t('Modals.ModalWorkspaceNotActivated.titleContinue')}
        </Typography>
        <Link
          sx={{
            display: 'inline',
            cursor: 'pointer',
            color: 'link.main',
            textTransform: 'none',
            fontSize: '1.25rem',
            fontWeight: '400',
            textDecoration: 'none',
            mt: 2
          }}
          href={`mailto:info@usepanorama.com?${locale === 'fr-CA' ? emailcontentFr : emailcontentEng}`}
        >
          info@usepanorama.com
        </Link>
        {workspaces?.length > 1 && (
          <>
            <Divider variant="fullWidth" sx={{ mt: 3, width: '100%' }} />
            <Box sx={{ width: '80%' }}>
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {t('Modals.ModalWorkspaceNotActivated.switchWorkspace')}
                  </ListSubheader>
                }
              >
                {workspaces.map(
                  (w) =>
                    activeWorkspaceId !== w.id && (
                      <ListItem key={w.id} sx={{ py: 0 }}>
                        <ListItemButton onClick={handleSwitchWorkspace(w.id)}>
                          <ListItemAvatar>
                            <Avatar src={workspace?.icon} sx={{ width: 30, height: 30 }}>
                              {w.name?.[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={w.name} />
                        </ListItemButton>
                      </ListItem>
                    )
                )}
              </List>
            </Box>
          </>
        )}
      </Container>
    </Dialog.View>
  );
};
