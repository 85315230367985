import { BodyField } from './Fields/BodyField';
import { AddResolutionButton } from '../Header/AddResolutionButton';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { TopicTitle } from '../Header/TopicTitle';
import { Box } from '@mui/material';

interface IMiscellaneousFields {
  liveMeeting: IUseLiveMeetingResponse;
}

export const MiscellaneousFields: React.FC<IMiscellaneousFields> = (props) => {
  return (
    <MeetingInProgressFormController liveMeeting={props.liveMeeting}>
      <TopicTitle liveMeeting={props.liveMeeting} />
      <BodyField />
      <Box>
        <AddResolutionButton liveMeeting={props.liveMeeting} />
      </Box>
    </MeetingInProgressFormController>
  );
};
