import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { useFeatureGate } from '@statsig/react-bindings';
import { useWorkspaceShow } from '@koopajs/react';
import { useLocale } from '@koopajs/react';
import { theme } from 'components/Core/theme';
const DOCUMENT_ANNOTATION_APP_URL: string = process.env.REACT_APP_DOCUMENT_ANNOTATION_APP_URL!;

interface IBoardMemberViewDocumentAnnotation {
  documentId: string;
  topicId?: string;
  sx?: SxProps<Theme>;
  sxContainer?: SxProps<Theme>;
  // document: IDocument;
  // documentIndex: number;
  // setCurrentDocumentIndex: Dispatch<SetStateAction<number>>;
}

export const BoardMemberViewDocumentAnnotation: React.FC<IBoardMemberViewDocumentAnnotation> = (props) => {
  const {
    documentId,
    topicId,
    sx: sxProp,
    sxContainer
    // documentIndex, setCurrentDocumentIndex
  } = props;

  const { locale } = useLocale();

  const { workspace } = useWorkspaceShow();

  // const targetRef = useRef(null);

  const { value: isDocumentAnnotationsEnabled } = useFeatureGate('document_annotations');

  const documentUrl = `${DOCUMENT_ANNOTATION_APP_URL}/${workspace?.id}/d/${documentId}?locale=${locale}${
    isDocumentAnnotationsEnabled ? '' : '&readonly=true'
  }`;

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       // Check if the element is intersecting the middle of the viewport
  //       if (entry.isIntersecting && entry.boundingClientRect.top < window.innerHeight / 2) {
  //         setCurrentDocumentIndex(documentIndex);
  //       }
  //     },
  //     {
  //       root: null, // observing viewport
  //       threshold: 0, // trigger as soon as any part of the element is visible
  //       rootMargin: '-50% 0px -50% 0px' // top/bottom margin to trigger at middle of viewport
  //     }
  //   );

  //   if (targetRef.current) {
  //     observer.observe(targetRef.current);
  //   }

  //   return () => {
  //     if (targetRef.current) {
  //       observer.unobserve(targetRef.current);
  //     }
  //   };
  // }, []);

  // TODO: should this be a better name?
  const sx = {
    width: '100%',
    border: `1px solid ${theme.palette.customGrey?.light}`,
    borderRadius: '4px',
    ...sxProp
  };

  return (
    <Box
      // ref={targetRef}
      id={documentId}
      // TODO: are we using this?
      // data-topic-id={topicId}
      sx={sxContainer}
    >
      <Box
        data-cy="document-annotation-iframe"
        component="iframe"
        sx={sx}
        src={documentUrl}
        data-read-only={isDocumentAnnotationsEnabled ? 'false' : 'true'}
      />
    </Box>
  );
};
