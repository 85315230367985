import { StartReactApp } from '@koopajs/react';
import { Sentry, Amplify } from '@koopajs/plugins';
import { App } from './components/Core/app';
import './styles.scss';

import { en, fr } from './locales';

StartReactApp(<App />, {
  locales: {
    // additionalLocaleSupported: ['en-CA', 'en-GB', 'fr', 'fr-CA'],
    additionalLocaleSupported: ['fr', 'fr-CA'],
    translations: { en, fr }
  },
  plugins: [new Amplify(), new Sentry()]
});
