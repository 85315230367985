import React, { useContext } from 'react';
import {
  TextField,
  TextFieldProps,
  InputProps,
  InputLabelProps,
  InputBaseComponentProps
} from '@mui/material';
import { FormContext } from '@koopajs/react';
import { RegisterOptions } from 'react-hook-form';
import { ICoreProps } from '@koopajs/mui';
import { useLocale as useCoreLocale } from '@koopajs/mui/dist/useCoreLocale';
import { useLocale } from '@koopajs/react';

export interface ITextFieldPublicProps extends ICoreProps {
  name: string;
  validationRules?: RegisterOptions;
  type?: string;
  InputLabelProps?: InputLabelProps;
  InputProps?: InputProps;
  inputProps?: InputBaseComponentProps;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  isOptional?: boolean;
  isAutoFocus?: boolean;
  isMultiline?: boolean;
  // TODO: add to ICoreProps
  testId?: string;
  className?: string;
}

export interface ITextFieldBaseProps extends ITextFieldPublicProps {
  propsTextField?: TextFieldProps;
}

export const TextFieldBase: React.FC<ITextFieldBaseProps> = (props) => {
  const {
    name,
    isAutoFocus,
    isOptional,
    isDisabled,
    isLoading,
    validationRules,
    type,
    onFocus,
    onBlur,
    sx,
    i18n,
    propsTextField,
    InputLabelProps,
    InputProps,
    inputProps,
    testId,
    className
  } = props;

  const context = useContext(FormContext);
  const { t } = useCoreLocale({ ...i18n, coreKeyPrefix: 'FormTextFieldBase' });
  // ult = use locale t, temporary for now
  const { t: ult } = useLocale();
  const keyPrefix = 'Components.TextFieldBaseTemp.validationRules';

  if (!context.form) {
    throw new Error('"Form.TextField" component needs to be use inside a FormController');
  }

  if (typeof validationRules?.maxLength === 'number') {
    const maxLength = validationRules.maxLength;
    validationRules.maxLength = { value: maxLength, message: ult(keyPrefix + '.maxLength', { maxLength }) };
  }
  if (typeof validationRules?.minLength === 'number') {
    const minLength = validationRules.minLength;
    validationRules.minLength = { value: minLength, message: ult(keyPrefix + '.minLength', { minLength }) };
  }

  const { ref, ...rest } = context.form.register(name, {
    required: { value: !isOptional, message: ult(keyPrefix + '.required') },
    disabled: isDisabled,
    onBlur: onBlur,
    ...validationRules
  });

  const resolve = (path: string, obj: { [k: string]: unknown }): { [k: string]: unknown } => {
    return path.split('.').reduce(function (prev: any, curr: string) {
      return prev ? prev[curr] : null;
    }, obj || self);
  };

  const errors = resolve(name, context.form.formState.errors);
  const errorMessageFromValidation = errors && (errors.message as string);

  return (
    <TextField
      {...propsTextField}
      {...rest}
      fullWidth
      margin="normal"
      type={props.type || type || 'string'}
      autoFocus={isAutoFocus}
      placeholder={t('placeholder')}
      label={t('label')}
      inputRef={ref}
      helperText={errors ? errorMessageFromValidation || t('errorText') : t('helperText')}
      error={Boolean(errors)}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      inputProps={{ 'data-cy': testId, ...inputProps }}
      onFocus={onFocus}
      disabled={isDisabled || isLoading}
      sx={sx}
      className={className}
    />
  );
};
