import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TrackEvent } from '@koopajs/app';
import { SnackbarKey, OptionsObject } from 'notistack';

interface IFileUploadSnackbarProps {
  isSuccess: boolean;
  trackEvent: string;
  file: File;
  enqueueSnackbar: (message: string, options?: OptionsObject) => SnackbarKey;
  closeSnackbar: (key: SnackbarKey) => void;
  successMessage: string;
  failMessage: string;
}

export const fileUploadSnackbar = (props: IFileUploadSnackbarProps): void => {
  const { isSuccess, file, trackEvent, enqueueSnackbar, closeSnackbar, successMessage, failMessage } = props;
  if (isSuccess) {
    enqueueSnackbar(successMessage, {
      variant: 'success',
      action: (key: SnackbarKey) => (
        // eslint-disable-next-line react/jsx-no-bind
        <IconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      )
    });

    TrackEvent(`${trackEvent}-upload-success`, {
      fileSizeBytes: file.size,
      fileFormat: file.type
    });
  } else {
    enqueueSnackbar(failMessage, {
      variant: 'error',
      action: (key: SnackbarKey) => (
        // eslint-disable-next-line react/jsx-no-bind
        <IconButton size="small" onClick={() => closeSnackbar(key)}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      )
    });

    TrackEvent(`${trackEvent}-upload-fail`);
  }
};
