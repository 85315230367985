import React, { useCallback } from 'react';
import { Dialog, Loading } from '@koopajs/mui';
import { Typography, Button, Box, Container, IconButton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocale, useResourceDelete } from '@koopajs/react';
import { Trans } from 'react-i18next';
import { IDocument } from 'types';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@mui/icons-material';
import { TrackEvent } from '@koopajs/app';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';

interface IDeleteHistoricalDocument {
  document: IDocument;
  isOpen: boolean;
  onClose: () => void;
}
export const i18nTransComponents: { [k: string]: React.ReactElement } = { div: <div /> };

export const DeleteHistoricalDocument: React.FC<IDeleteHistoricalDocument> = (props) => {
  const { document, isOpen, onClose } = props;

  const documentRecordId = document.attachedToDocumentRecord?.documentRecordId;

  const { deleteResource: deleteDocument, isProcessing } = useResourceDelete({
    path: `/document-records/${documentRecordId}/historical-documents`,
    id: document.id
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useLocale();
  const history = useHistory();
  const keyPrefix = 'Modals.DeleteHistoricalDocument';

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseDeleteModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  const handleOnSubmit = useCallback(async () => {
    const res = await deleteDocument();

    if (res) {
      history.push(`/document-records/${documentRecordId}`);

      handleCloseDeleteModal();

      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteSuccess'), {
        variant: 'success',
        action: (key: any) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
    } else {
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteFail'), {
        variant: 'error',
        action: (key: any) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('historical-document-delete-fail');
    }

    return true;
  }, [documentRecordId, deleteDocument]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseDeleteModal}
      forcedFullScreen={false}
      dialogProps={{
        PaperProps: {
          'data-cy': 'delete-historical-document_record-modal'
        }
      }}
      maxWidth="md"
    >
      {isProcessing ? (
        <Stack sx={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
        </Stack>
      ) : (
        <Container
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3
          }}
        >
          <Typography variant="h6" component="h2">
            {t(keyPrefix + '.title')}
          </Typography>

          <Trans i18nKey={`${keyPrefix}.deleteDocument`} t={t} components={i18nTransComponents} />

          {renderConfirmDeleteField()}

          <Box sx={{ display: 'flex', mt: 1 }}>
            <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={handleCloseDeleteModal}>
              {t('common:labelCancel')}
            </Button>
            <Button
              color="error"
              sx={{ px: 6.5, mx: 1 }}
              variant="contained"
              type="submit"
              onClick={handleOnSubmit}
              disabled={!hasUserTypedDeleteConfirmation}
            >
              {t(keyPrefix + '.labelDelete')}
            </Button>
          </Box>
        </Container>
      )}
    </Dialog.View>
  );
};
