import { Typography, Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IBreadcrumbsProps {
  hierarchyArray: { path: string; text: string; className?: string }[];
  pageTitle: string;
  sx?: SxProps<Theme>;
  className?: string;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
  const { hierarchyArray, pageTitle, sx, className } = props;

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      data-cy="breadcrumbs"
      sx={{
        '& li': { minWidth: '0px' },
        ...sx
      }}
    >
      {hierarchyArray.map((item) => {
        return (
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={item.path}
            key={item.text}
            className={item.className}
          >
            {item.text}
          </Link>
        );
      })}
      <Typography
        color="text.primary"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        className={className}
      >
        {pageTitle}
      </Typography>
    </MuiBreadcrumbs>
  );
};
