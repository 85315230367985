import React from 'react';
import { User } from '@koopajs/react';
import { Box, Chip, Avatar, Stack } from '@mui/material';

interface IMissingSigneesListProps {
  signees?: string[];
}

export const MissingSigneesList: React.FC<IMissingSigneesListProps> = (props) => {
  const { signees } = props;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {signees?.map((s) => {
        return (
          <User key={s} id={s}>
            {(profile) => {
              return (
                <>
                  <Stack direction="row" spacing={1} sx={{ mr: 2, my: 0.5 }}>
                    <Chip
                      avatar={<Avatar alt={profile?.username} src={profile?.profilePicture} />}
                      label={profile?.username}
                    />
                  </Stack>
                </>
              );
            }}
          </User>
        );
      })}
    </Box>
  );
};
