import { ISearchParams, IResource, Selectors, Operations, ICustomReducer } from '@koopajs/app';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

type ResourceListState =
  | 'loaded'
  | 'loaded-empty'
  | 'loaded-no-search-result'
  | 'loaded-error'
  | 'loading'
  | 'reloading';
export interface IUseResourceListProps {
  path: string;
  searchParams?: ISearchParams;
  useCache?: boolean;
  customReducer?: ICustomReducer;
}

export interface IUseResourceListResponse<R> {
  errorMessage?: string;
  isProcessing: boolean;
  resources: R[];
  state: ResourceListState;
  facets?: { key: string; values: { key: string; count: number }[] }[];
  totalCount?: number;
  activeView?: string;
  views?: { key: string }[];
}

export const useResourceList = <R extends IResource = IResource>(
  props: IUseResourceListProps
): IUseResourceListResponse<R> => {
  const { path, searchParams = {}, useCache = false, customReducer } = props;

  const dispatch = useDispatch();

  const activeWorkspaceId = useSelector(Selectors.Workspaces.getActiveWorkspaceId);
  const { errorMessage, isProcessing, metadata } = useSelector(Selectors.Resources.state(path));
  const resources = useSelector(Selectors.Resources.getResources<R>(path));
  const resourcesFetchOperation = Operations.Resources.fetch(dispatch);

  const facets = metadata?.facets || undefined;
  const totalCount = metadata?.totalCount || undefined;
  const activeView = metadata?.activeView || undefined;
  const views = metadata?.views || undefined;

  useEffect(() => {
    const core = async (): Promise<void> => {
      await resourcesFetchOperation(activeWorkspaceId as string, path, searchParams, { customReducer });
    };

    if (!useCache) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      core();
    }
  }, [
    path,
    useCache,
    activeWorkspaceId,
    searchParams.page,
    searchParams.query,
    searchParams.size,
    searchParams.sort,
    searchParams.view,
    JSON.stringify(searchParams.filters)
  ]);

  let state: ResourceListState;

  if (isProcessing) {
    if (errorMessage || resources.length) {
      state = 'reloading';
    } else {
      state = 'loading';
    }
  } else {
    const filteredSearchParams = Object.keys(searchParams).filter((param) => param !== 'sort');

    if (errorMessage) {
      state = 'loaded-error';
    } else if (resources.length === 0 && filteredSearchParams.length === 0) {
      state = 'loaded-empty';
    } else if (resources.length === 0) {
      state = 'loaded-no-search-result';
    } else {
      state = 'loaded';
    }
  }

  return {
    state,
    errorMessage,
    isProcessing,
    resources,
    // @ts-ignore
    facets,
    // @ts-ignore
    totalCount,
    // @ts-ignore
    activeView,
    // @ts-ignore
    views
  };
};
