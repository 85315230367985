import React from 'react';
import { Dialog } from '@koopajs/mui';
import { Typography, Button, Box, Container } from '@mui/material';
import exitImage from '../../assets/exitImage.png';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';

interface IExitMeeting {
  isOpen: boolean;
  handleOnClose: () => void;
  liveMeeting: IUseLiveMeetingResponse;
}

export const ExitMeeting: React.FC<IExitMeeting> = (props) => {
  const { isOpen, handleOnClose, liveMeeting } = props;
  const { t, handleMeetingTemporaryExit } = liveMeeting;

  const keyPrefix = 'Modals.ModalExitMeeting.';

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleOnClose}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'exit-meeting-modal' } }}
    >
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <Box>
          <img src={exitImage} srcSet="" loading="lazy" style={{ width: '8%' }} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ m: 2 }}>
            {t(keyPrefix + 'title')}
          </Typography>
          <Typography sx={{ m: 2 }}>{t(keyPrefix + 'description')}</Typography>
        </Box>
        <Button
          sx={{ px: 8, mx: 1 }}
          variant="outlined"
          onClick={handleOnClose}
          data-cy="exit-meeting-modal_cancel-button"
        >
          {t('common:labelCancel')}
        </Button>
        <Button
          sx={{ px: 10, mx: 1 }}
          variant="contained"
          onClick={handleMeetingTemporaryExit}
          data-cy="exit-meeting-modal_exit-button"
        >
          OK
        </Button>
      </Container>
    </Dialog.View>
  );
};
