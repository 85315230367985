import { BodyField } from './Fields/BodyField';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { RecordVote } from '../Header/RecordVote';
import { Paper, Button, Typography } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { Edit as EditIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { TopicTitle } from '../Header/TopicTitle';

interface IAgendaApprovalFields {
  liveMeeting: IUseLiveMeetingResponse;
}

export const AgendaApprovalFields: React.FC<IAgendaApprovalFields> = ({ liveMeeting }) => {
  const { meetingId, currentTopicId } = liveMeeting;
  const { t } = useLocale();
  const keyPrefix = 'MeetingStateInProgress.AgendaApproveFields';

  const history = useHistory();

  const handleGotToAgendaApproval = useCallback(() => {
    history.push(`/meetings/${meetingId}/topics/${currentTopicId}/agenda-approval`);
  }, [meetingId, currentTopicId]);

  return (
    <MeetingInProgressFormController liveMeeting={liveMeeting}>
      <TopicTitle liveMeeting={liveMeeting} />
      <Button
        onClick={handleGotToAgendaApproval}
        sx={{ textTransform: 'none', width: '100%', p: 0 }}
        data-cy="meeting-in-progress_agenda-approval-btn"
      >
        <Paper
          variant="outlined"
          sx={{
            p: '14px 24px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography>{t(keyPrefix + '.modifyAgendaLabel')}</Typography>
          <EditIcon sx={{ opacity: 0.6 }} />
        </Paper>
      </Button>

      <RecordVote liveMeeting={liveMeeting} />
      <BodyField variant="small" />
    </MeetingInProgressFormController>
  );
};
