import React from 'react';
import { IResourcePickerChildrenMultiProps, IResourcePickerChildrenSingleProps } from '../types';
import { Card, CardActionArea, CardHeader } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const CardEmpty: React.FC<IResourcePickerChildrenSingleProps | IResourcePickerChildrenMultiProps> = (
  props
) => {
  const {
    width,
    isProcessing: isLoadingForm,
    isLoading,
    isDisabled,
    handleOpen,
    isOptional,
    hasError,
    sx,
    translatedLabel,
    translatedErrorText,
    cardVariant
  } = props;
  const isProcessing = isLoading || isDisabled || isLoadingForm;

  return (
    <Card
      variant={cardVariant || 'outlined'}
      sx={{
        width: width,
        opacity: isProcessing ? 0.5 : 1,
        ...sx
      }}
    >
      <CardActionArea onClick={handleOpen} disabled={isProcessing} sx={{ height: '100%' }}>
        <CardHeader
          avatar={<AddIcon />}
          title={`${translatedLabel} ${!isOptional ? '*' : ''}`}
          titleTypographyProps={{ color: hasError ? 'error' : undefined }}
          subheader={hasError && translatedErrorText}
          subheaderTypographyProps={{ color: 'error', variant: 'caption' }}
        />
      </CardActionArea>
    </Card>
  );
};
