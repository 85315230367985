import { IMeeting, IMinute, IResolution, ISignatureWithTitle } from 'types';

export const getSignaturesWithTitles = (
  resource?: IMinute | IResolution | IMeeting
): ISignatureWithTitle[] | undefined => {
  if (!resource || !resource?.signatures || resource.signatures.length === 0) return undefined;

  return resource?.signatures?.map((signature) => {
    const foundActiveCommitteeMember = resource?.activeCommitteeMembers?.find(
      (acm) => acm.userId === signature.userId
    );
    return foundActiveCommitteeMember
      ? {
          ...signature,
          title: foundActiveCommitteeMember.title
        }
      : signature;
  });
};
