/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import SelectMUI from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputBaseComponentProps, MenuListProps } from '@mui/material';
import { FormContext } from '@koopajs/react';
import { useLocale as useCoreLocale } from '@koopajs/mui/dist/useCoreLocale';
import { ICoreProps } from '@koopajs/mui';

export interface ISelectTempProps extends ICoreProps {
  name: string;
  defaultValue?: unknown;
  options: {
    id: string;
    label: string;
  }[];
  isOptional?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  inputProps?: InputBaseComponentProps;
  isLabelHidden?: boolean;
}

interface ISelectMenuProps extends MenuListProps {
  'data-cy'?: string;
}

export const SelectTemp: React.FC<ISelectTempProps> = (props) => {
  const {
    name,
    options,
    isOptional,
    isLoading,
    isDisabled,
    sx,
    defaultValue,
    variant,
    i18n,
    inputProps,
    isLabelHidden
  } = props;
  const context = useContext(FormContext);
  const { t } = useCoreLocale({ ...i18n, coreKeyPrefix: 'FormSelect' });

  if (!context.form) {
    throw new Error('"Form.Select" component needs to be use inside a FormController');
  }

  return (
    <FormControl fullWidth margin="normal" sx={{ ...sx }}>
      {!isLabelHidden && (
        <InputLabel disabled={context.isProcessing} id={`koopa-select-${name}`}>
          {t('label')}
        </InputLabel>
      )}

      <Controller
        render={({ field }) => (
          <SelectMUI
            name={field.name}
            value={field.value || defaultValue || ''}
            onChange={field.onChange}
            labelId={isLabelHidden ? undefined : `koopa-select-${name}`}
            label={isLabelHidden ? undefined : t('label')}
            disabled={context.isProcessing || isLoading || isDisabled}
            required={!isOptional}
            variant={variant}
            inputProps={inputProps}
            MenuProps={{
              MenuListProps: { 'data-cy': `koopa_select-menu_${name}` } as ISelectMenuProps
            }}
          >
            {options.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </SelectMUI>
        )}
        name={name}
        control={context.form.control}
      />
    </FormControl>
  );
};
