import { CommitteeMemberTitle } from 'types';

export const committeeMembersTitlesArray: CommitteeMemberTitle[] = [
  'president',
  'secretary',
  'vicePresident',
  'treasurer',
  'boardMember',
  'observer',
  'representative',
  'generalManager',
  'manager',
  'chiefExecutiveOfficer',
  'cSuiteMember',
  'employee',
  'administrativeAssistant',
  'investor',
  'externalMember'
];
