import { SystemStyleObject } from '@mui/system';
import { Box, Stack } from '@mui/material';

interface ICardDetailsListProps {
  items: React.ReactNode[];
  sx?: SystemStyleObject;
  spacing?: number | string;
}

export const CardDetailsList: React.FC<ICardDetailsListProps> = (props) => {
  const { items, sx, spacing = 2 } = props;

  const definedItems = items.filter((item) => item);

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        flexWrap: { md: 'wrap' },
        alignItems: { md: 'stretch' },
        mt: 1,
        ...sx
      }}
      spacing={{ xs: '6px', md: '0' }}
    >
      {definedItems.map((item, index, arr) => {
        const isLast = index === arr.length - 1;

        return (
          <Box
            key={index}
            sx={{
              display: { md: 'flex' },
              alignItems: { md: 'center' }
            }}
          >
            <Box sx={{ display: 'inline-block' }}>{item}</Box>
            {!isLast && <Box sx={{ mx: spacing, display: { xs: 'none', md: 'inline-block' } }}>&#183;</Box>}
          </Box>
        );
      })}
    </Stack>
  );
};
