import { useLocale, useParamsKey, useResourceShow } from '@koopajs/react';
import { Helmet } from 'react-helmet';
import { Button, Stack } from '@mui/material';
import { PageContainer } from 'components/temp/PageContainer';
import { IDocumentRecord } from 'types';
import { Loading } from '@koopajs/mui';
import { DocumentRecord } from 'components/DocumentRecords/DocumentRecord';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { DelayComponentRender } from 'components/DelayComponentRender';
import { EmptyState } from 'components/EmptyState';
import { Link as RouterLink } from 'react-router-dom';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { FeatureLocked } from 'components/FeatureLocked';
import { useFeatureGate } from '@statsig/react-bindings';

export const DocumentRecordView: React.FC = () => {
  const documentRecordId = useParamsKey('id');

  const { t } = useLocale();
  const keyPrefix = 'DocumentRecord';

  const {
    resource: documentRecord,
    isProcessing,
    errorMessage
  } = useResourceShow<IDocumentRecord>({
    path: '/document-records',
    id: documentRecordId
  });

  const extractedErrorMessage = extractErrorMessage(errorMessage);

  const { value: isDocumentRecordsEnabled } = useFeatureGate('document-records-preview');

  if (!isDocumentRecordsEnabled) {
    return <FeatureLocked />;
  }

  if (!documentRecord) {
    if (isProcessing) {
      return (
        <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        </Stack>
      );
    } else {
      const is404 = errorMessage?.includes('404');
      return (
        <DelayComponentRender
          delay={1250}
          component={
            <EmptyState
              title={is404 ? t(keyPrefix + '.EmptyState.title') : extractedErrorMessage}
              description={is404 && t(keyPrefix + '.EmptyState.description')}
              button={
                <Button component={RouterLink} to="/" variant="contained" disableElevation>
                  {t('NotFound.EmptyState.goToDashboardButton')}
                </Button>
              }
            />
          }
        />
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${t(keyPrefix + '.title')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ p: '24px' }}>
        <Breadcrumbs
          hierarchyArray={[{ path: '/document-records', text: t('common:navigation.documents') }]}
          pageTitle={documentRecord.lastDocumentTitle || ''}
          sx={{ mb: '24px', display: { xs: 'none', sm: 'block' } }}
          className="rr-mask"
        />

        <DocumentRecord documentRecord={documentRecord} />
      </PageContainer>
    </>
  );
};
