import { IMeeting } from 'types';
import { FormContext, useLocale, useResourceList } from '@koopajs/react';
import {
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Stack
} from '@mui/material';
import { useContext } from 'react';
import { AnotherResolutionRadioGroup } from './AnotherResolutionRadioGroup';
import { Controller } from 'react-hook-form';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import { Loading } from '@koopajs/mui';

interface ICopyResolutionFieldProps {}

export const CopyResolutionField: React.FC<ICopyResolutionFieldProps> = (props) => {
  const { t } = useLocale();
  const keyPrefix = 'Dialogs.CreateWrittenResolution.Form.RadioCopyResolution';

  const context = useContext(FormContext);

  const { resources: recentResolutions, isProcessing } = useResourceList<IMeeting>({
    path: '/resolution-templates',
    searchParams: { size: 50, sort: '$createdAt:DESC' }
  });

  const templateType = context.form?.watch('templateType');

  if (isProcessing)
    return (
      <Stack alignItems="center" sx={{ mt: 2 }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );

  return (
    <>
      {recentResolutions && recentResolutions.length > 0 && (
        <>
          <FormControl sx={{ width: '100%' }} component="fieldset">
            <FormLabel id="copy-resolution-field">
              <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t(keyPrefix + '.label')}
              </Typography>
            </FormLabel>
            <Controller
              // eslint-disable-next-line react/jsx-no-bind
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby="copy-resolution-field"
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                >
                  {/* no template */}
                  <FormControlLabel value="none" control={<Radio />} label={t(keyPrefix + '.optionNone')} />
                  {/* another meeting */}
                  <FormControlLabel
                    value="another-resolution"
                    control={<Radio />}
                    label={t(keyPrefix + '.optionAnotherResolution')}
                  />
                  {templateType === 'another-resolution' && (
                    <AnotherResolutionRadioGroup recentResolutions={recentResolutions} />
                  )}
                </RadioGroup>
              )}
              name="templateType"
              control={context?.form?.control}
            />
          </FormControl>
        </>
      )}

      {(!templateType || templateType === 'none') && (
        <Box sx={{ marginTop: 2 }}>
          <RichTextMultiLine
            name="notes"
            validationRules={{ maxLength: 20000 }}
            height="unset"
            minHeight={`${7 * 23 + 16.5 * 2}px`} // (rows * 23px) + (padding * 2)
            i18n={{ keyPrefix: 'Dialogs.CreateWrittenResolution.Form.RichTextMultiLineNotes' }}
            sx={{ mt: 1 }}
            isLabelVisible
          />
        </Box>
      )}
    </>
  );
};
