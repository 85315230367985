import { Box } from '@mui/material';
import { useBoardMemberViewContext } from './BoardMemberViewProvider';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useLocale } from '@koopajs/react';
import { SystemStyleObject } from '@mui/system';

interface IBoardMemberViewHeaderProps {
  sxContainer?: SystemStyleObject;
}

export const BoardMemberViewHeader: React.FC<IBoardMemberViewHeaderProps> = (props) => {
  const { sxContainer } = props;
  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting } = boardMemberViewContext;

  const { t } = useLocale();

  return (
    <Box
      sx={{
        p: 3,
        height: '72px', // unnecessary but will make sure layout doesn't break if something causes the header to change height
        ...sxContainer
      }}
    >
      <Breadcrumbs
        hierarchyArray={[{ path: '/', text: t('common:navigation.dashboard') }]}
        pageTitle={meeting?.title || ''}
        sx={{
          display: { xs: 'none', lg: 'block' },
          textWrap: 'nowrap',
          '& .MuiBreadcrumbs-ol': { flexWrap: 'nowrap' },
          '& .MuiBreadcrumbs-li': { flexShrink: 0 },
          '& .MuiBreadcrumbs-li:last-child': { flexShrink: 1 } // page title div will shrink
        }}
      />
    </Box>
  );
};
