import { useCallback, useContext, useEffect } from 'react';
import { useLocale } from '@koopajs/react';
import { Stack, Box, FormControlLabel, Switch as MUISwitch, Alert } from '@mui/material';
import { RadioFieldTemp } from 'components/RadioFieldTemp';
import { PastMeetingAdoption } from './PastMeetingAdoption';
import { FormContext } from '@koopajs/react';
import { ITopic, ITopicResolutionOutcome } from 'types';
import { Controller } from 'react-hook-form';
import { IUserPublicProfile } from '@koopajs/app';
import { AutocompleteTemp } from 'components/temp/Autocomplete';
import { ResourcePicker } from 'components/ResourcePicker';

interface IRecordVoteDialogOptionsProps {
  isUnanimousSelected?: boolean;
  isAddResolutionDialog?: boolean;
  setIsUnanimousSelected: (e: boolean) => void;
  isResolutionBookSwitchChecked?: boolean;
  users: IUserPublicProfile[];
  participants: IUserPublicProfile[];
  topic?: ITopic;
  isCommitteeTakingResolutions?: boolean;
  hideEditingVote?: boolean;
}

export const RecordVoteDialogOptions: React.FC<IRecordVoteDialogOptionsProps> = (props) => {
  const {
    isUnanimousSelected,
    setIsUnanimousSelected,
    isAddResolutionDialog,
    isResolutionBookSwitchChecked,
    users,
    participants,
    topic,
    isCommitteeTakingResolutions,
    hideEditingVote = false
  } = props;

  const processedParticipants = participants?.map((p) => {
    return { label: p.username, id: p.id };
  });
  const context = useContext(FormContext);

  const formValues = context.form?.watch() as
    | { isResolution: boolean; resolutionOutcome: ITopicResolutionOutcome }
    | undefined;

  const isResolutionReported = formValues?.resolutionOutcome?.outcome === 'reported';

  const { t } = useLocale();
  const keyPrefix = 'MeetingStateInProgress.ResolutionFields.RecordVoteDialog';

  const baseOutcomeOptions = [
    { id: 'approved', label: t(keyPrefix + '.optionApproved') },
    { id: 'approvedWithMods', label: t(keyPrefix + '.optionApprovedWithMods') },
    { id: 'declined', label: t(keyPrefix + '.optionDeclined') },
    { id: 'reported', label: t(keyPrefix + '.optionReported') }
  ];

  const resolutionOutcomeOptions = baseOutcomeOptions;

  const handleAskForVoteSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUnanimousSelected(!event.target.checked);
  }, []);

  const isAskForVote = !isUnanimousSelected;

  const isPastMinutesApproval = topic?.type === 'pastMinutesApproval';

  const checkIfResolution = useCallback(() => {
    if (!isCommitteeTakingResolutions) return false;
    if (isResolutionReported) return false;
    if (isAddResolutionDialog || isResolutionBookSwitchChecked) return true;
    if (topic?.isResolution !== undefined) return topic.isResolution;
    return Boolean(topic?.type === 'resolution');
  }, [topic?.type, topic?.isResolution, isResolutionReported, isCommitteeTakingResolutions]);

  useEffect(() => {
    if (isResolutionReported) {
      context.form?.setValue('isResolution', false);
    }
  }, [isResolutionReported, context.form?.setValue]);

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <Box sx={{ width: '100%' }}>
          <AutocompleteTemp
            i18n={{ keyPrefix: keyPrefix + '.TextFieldMover' }}
            name="resolutionOutcome.mover"
            validationRules={{ maxLength: 100 }}
            isOptional={true}
            options={users?.map((u) => u.username || '')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#0000001f'
                }
              }
            }}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <AutocompleteTemp
            i18n={{ keyPrefix: keyPrefix + '.TextFieldSeconder' }}
            name="resolutionOutcome.seconder"
            validationRules={{ maxLength: 100 }}
            isOptional={true}
            options={users?.map((u) => u.username || '')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#0000001f'
                }
              }
            }}
          />
        </Box>
      </Stack>
      {!hideEditingVote && (
        <FormControlLabel
          control={
            <MUISwitch
              checked={isAskForVote}
              onChange={handleAskForVoteSwitch}
              data-cy="record-vote-dialog_vote-switch"
            />
          }
          label={t('MeetingStateInProgress.ResolutionFields.labelAskForVote')}
        />
      )}

      <Box sx={{ display: isAskForVote && !hideEditingVote ? 'block' : 'none', mb: 5 }}>
        <Stack direction="row" spacing={2}>
          {processedParticipants && processedParticipants?.length > 0 && (
            <>
              <Box sx={{ width: '100%' }} data-cy="record-vote-dialog_members-for-field">
                <ResourcePicker
                  i18n={{ keyPrefix: keyPrefix + '.ResourcePickerMembersFor' }}
                  name="resolutionOutcome.membersFor"
                  isOptional={true}
                  resources={processedParticipants}
                  isOpenOnFocus
                  isDisabledCloseOnSelect
                  isMultiple
                  translatedLabel=""
                  translatedLabelMultipleSelected=""
                  translatedErrorText=""
                  sx={{ mt: 2, height: '100%' }}
                />
              </Box>
              <Box sx={{ width: '100%' }} data-cy="record-vote-dialog_members-against-field">
                <ResourcePicker
                  i18n={{ keyPrefix: keyPrefix + '.ResourcePickerMembersAgainst' }}
                  name="resolutionOutcome.membersAgainst"
                  isOptional={true}
                  resources={processedParticipants}
                  isOpenOnFocus
                  isDisabledCloseOnSelect
                  isMultiple
                  translatedLabel=""
                  translatedLabelMultipleSelected=""
                  translatedErrorText=""
                  sx={{ mt: 2, height: '100%' }}
                />
              </Box>
              <Box sx={{ width: '100%' }} data-cy="record-vote-dialog_members-abstained-field">
                <ResourcePicker
                  i18n={{ keyPrefix: keyPrefix + '.ResourcePickerMembersAbstaining' }}
                  name="resolutionOutcome.membersAbstained"
                  isOptional={true}
                  resources={processedParticipants}
                  isOpenOnFocus
                  isDisabledCloseOnSelect
                  isMultiple
                  translatedLabel=""
                  translatedLabelMultipleSelected=""
                  translatedErrorText=""
                  sx={{ mt: 2, height: '100%' }}
                />
              </Box>
            </>
          )}
        </Stack>
      </Box>
      {!hideEditingVote && (
        <Box sx={{ mt: 2 }}>
          <RadioFieldTemp
            name="resolutionOutcome.outcome"
            i18n={{ keyPrefix: keyPrefix + '.FormRadio' }}
            testId="record-vote-dialog_outcome-field"
            options={resolutionOutcomeOptions}
          />
        </Box>
      )}

      {isPastMinutesApproval &&
        !hideEditingVote &&
        Boolean(topic?.pastMeetingsToApprove?.length) &&
        !isAddResolutionDialog && <PastMeetingAdoption topic={topic} />}
      {isCommitteeTakingResolutions ? (
        <Controller
          name="isResolution"
          control={context?.form?.control}
          defaultValue={checkIfResolution()}
          // eslint-disable-next-line react/jsx-no-bind
          render={({ field }) => (
            <FormControlLabel
              control={
                <MUISwitch
                  checked={field.value}
                  onChange={field.onChange}
                  disabled={isResolutionReported}
                  data-cy="add-to-res-book-switch"
                />
              }
              label={t(keyPrefix + '.SwitchAddToResolutions.label')}
            />
          )}
        />
      ) : (
        <Alert severity="info">{t(keyPrefix + '.noResolutionBookSwitchMessage')}</Alert>
      )}
    </>
  );
};
