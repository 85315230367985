import {
  TableView as ExcelIcon,
  PictureAsPdf as PdfIcon,
  InsertPhoto as ImageIcon,
  Description as WordIcon,
  Slideshow as PowerpointIcon
} from '@mui/icons-material';
import { SystemStyleObject } from '@mui/system';

import React from 'react';

type IconFunction = (props: { sx?: SystemStyleObject }) => React.ReactNode;

const documentFormatIcons: { [key: string]: IconFunction } = {
  'application/pdf': ({ sx }) => <PdfIcon sx={{ opacity: 0.6, ...sx }} />,
  'application/vnd.ms-excel': ({ sx }) => <ExcelIcon sx={{ opacity: 0.6, ...sx }} />,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ({ sx }) => (
    <ExcelIcon sx={{ opacity: 0.6, ...sx }} />
  ),
  'application/msword': ({ sx }) => <WordIcon sx={{ opacity: 0.6, ...sx }} />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ({ sx }) => (
    <WordIcon sx={{ opacity: 0.6, ...sx }} />
  ),
  'application/vnd.ms-powerpoint': ({ sx }) => <PowerpointIcon sx={{ opacity: 0.6, ...sx }} />,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ({ sx }) => (
    <PowerpointIcon sx={{ opacity: 0.6, ...sx }} />
  ),
  'image/jpeg': ({ sx }) => <ImageIcon sx={{ opacity: 0.6, ...sx }} />,
  'image/png': ({ sx }) => <ImageIcon sx={{ opacity: 0.6, ...sx }} />
};

export const RenderDocumentIcon: React.FC<{ fileType: string; sx?: SystemStyleObject }> = (props) => {
  const { fileType, sx } = props;

  const iconComponent = documentFormatIcons[fileType];
  return <>{iconComponent ? iconComponent({ sx }) : null}</>;
};
