import { Box, Stack, Fab, FabProps, Button } from '@mui/material';

export interface IMobileFloatingButtonsProps {
  fabData: {
    label: string;
    onClick: () => void;
    additionalProps?: FabProps;
  }[];
}

export const MobileFloatingButtons: React.FC<IMobileFloatingButtonsProps> = ({ fabData }) => (
  <Box
    component="div"
    sx={{
      position: 'fixed',
      zIndex: 1200,
      left: 0,
      right: 0,
      bottom: 0,
      display: { xs: 'block', md: 'block', lg: 'none' }
    }}
  >
    <Stack direction="row" sx={{ justifyContent: 'center', position: 'relative' }}>
      <Stack direction="row" sx={{ position: 'absolute', bottom: 30 }} spacing={0.5}>
        {fabData.map((fab, index) => {
          const { label, onClick, additionalProps } = fab;
          return (
            <Fab
              variant="extended"
              color="inherit"
              component={Button}
              size="medium"
              sx={{
                borderRadius: '4px'
              }}
              onClick={onClick}
              key={index}
              {...additionalProps}
            >
              {label}
            </Fab>
          );
        })}
      </Stack>
    </Stack>
  </Box>
);
