import { BodyField } from './Fields/BodyField';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { Box } from '@mui/material';
import { ApproveMinutesList } from '../Header/ApproveMinutes/List';
import { RecordVote } from '../Header/RecordVote';
import { TopicTitle } from '../Header/TopicTitle';

interface IPastMinutesApprovalFields {
  liveMeeting: IUseLiveMeetingResponse;
}

export const PastMinutesApprovalFields: React.FC<IPastMinutesApprovalFields> = ({ liveMeeting }) => {
  return (
    <MeetingInProgressFormController liveMeeting={liveMeeting}>
      <TopicTitle liveMeeting={liveMeeting} />
      <ApproveMinutesList liveMeeting={liveMeeting} />
      <RecordVote liveMeeting={liveMeeting} />
      <BodyField isNotAutofocused />
    </MeetingInProgressFormController>
  );
};
