import { useDocumentDropzone } from 'components/hooks/useDocumentDropzone';
import { Box, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useLocale } from '@koopajs/react';
import { useEffect } from 'react';

interface IAddDocumentButtonProps {
  isIconButton?: boolean;
  path: string;
  isProcessing?: boolean;
  setIsProcessing: (isProcessing: boolean) => void;
}

export const AddDocumentButton: React.FC<IAddDocumentButtonProps> = (props) => {
  const { isIconButton, path, isProcessing: isProcessingOtherDocuments, setIsProcessing } = props;

  const { t } = useLocale();

  const { dropzone, isProcessing } = useDocumentDropzone({ path });

  useEffect(() => {
    setIsProcessing(isProcessing);
  }, [isProcessing, setIsProcessing]);

  return (
    <Box>
      <input {...dropzone.getInputProps()} data-cy="add-document-input" />

      {isIconButton ? (
        <Tooltip title={t('common:labelAddDocument')}>
          <IconButton onClick={dropzone.open} disabled={isProcessingOtherDocuments}>
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : <AttachFileIcon />}
          </IconButton>
        </Tooltip>
      ) : (
        <LoadingButton
          onClick={dropzone.open}
          startIcon={<AttachFileIcon />}
          loading={isProcessing}
          loadingPosition="start"
          disabled={isProcessingOtherDocuments}
        >
          <span>{t('common:labelAddDocument')}</span>
        </LoadingButton>
      )}
    </Box>
  );
};
