import { Paper, Button, Stack, Typography } from '@mui/material';
import { ICommittee, ICommitteeMember } from 'types';
import { useResourceList } from '@koopajs/react';
import { Link as RouterLink } from 'react-router-dom';
import { Person as PersonIcon } from '@mui/icons-material';
import { useLocale } from '@koopajs/react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
interface ICommitteeCardProps {
  committee: ICommittee;
}

export const CommitteeCard: React.FC<ICommitteeCardProps> = (props) => {
  const { committee } = props;
  //TODO: refactor this in the backend? to avoid making too many requests

  const { resources: committeeMembers } = useResourceList<ICommitteeMember>({
    path: `/committees/${committee.id}/members`,
    searchParams: { size: 50 }
  });

  const { t } = useLocale();
  const keyPrefix = 'Organization.OrganizationCommittees.CommitteeCard';

  return (
    <Paper
      sx={{
        p: 3,
        my: 1,
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%'
      }}
      variant="outlined"
      data-cy="organization_committee-card"
    >
      <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2, alignItems: 'flex-start' }}>
        <Typography variant="body1" sx={{ fontSize: '18px' }} data-cy="organization_committee-card-title">
          {committee.name}
        </Typography>

        <Button
          variant="text"
          component={RouterLink}
          to={`/organization/${committee.id}`}
          data-cy="organization_committee-card_view-button"
        >
          {t(keyPrefix + '.viewCommitteeButtonLabel')}
        </Button>
      </Stack>

      <Stack direction="row" spacing={1}>
        {committeeMembers.length > 0 ? <PersonIcon color="action" /> : <PersonOutlineIcon color="action" />}
        <Typography>{t(keyPrefix + '.members', { count: committeeMembers.length })}</Typography>
      </Stack>
    </Paper>
  );
};
