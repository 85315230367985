import { DateTime, DateTimeFormatOptions } from 'luxon';

export type DateFormat =
  | 'DATE_FULL' // October 14, 1983 = default
  | 'DATE_FULL_NO_YEAR' // October 14
  | 'DATE_HUGE' // Friday, October 14, 1983
  | 'DATE_SHORT'; // 10/14/1983

interface IFormatDateProps {
  isoString: string;
  locale: string;
  format?: DateFormat;
  isUTC?: boolean;
}

export const formatDate = (props: IFormatDateProps): string => {
  const { isoString, locale, format = 'DATE_FULL', isUTC } = props;

  let dateFormat: DateTimeFormatOptions;

  switch (format) {
    case 'DATE_HUGE':
      dateFormat = DateTime.DATE_HUGE;
      break;
    case 'DATE_FULL':
      dateFormat = { ...DateTime.DATE_FULL };
      break;
    case 'DATE_FULL_NO_YEAR':
      dateFormat = { ...DateTime.DATE_FULL, year: undefined };
      break;
    case 'DATE_SHORT':
      dateFormat = { ...DateTime.DATE_SHORT };
      break;
  }

  const formattedDate = isUTC
    ? DateTime.fromISO(isoString).setLocale(locale).toUTC().toLocaleString(dateFormat)
    : DateTime.fromISO(isoString).setLocale(locale).toLocaleString(dateFormat);

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
