import { useCallback, useState } from 'react';
import { useLocale } from '@koopajs/react';
import { useDropzone, DropzoneState, FileRejection } from 'react-dropzone';
import { useAppSnackbar } from './useAppSnackbar';

export interface IFile {
  blobUrl?: string;
  file: File;
}
export interface IUseMultipleDocumentsInMemoryDropzoneProps {
  isStoringFileInMemory?: boolean;
  isMultiple?: boolean;
}
export interface IUseMultipleDocumentsInMemoryDropzoneResponse {
  dropzone: DropzoneState;
  isProcessing: boolean;
  files?: IFile[];
  resetFilesInState: () => void;
}

export const useMultipleDocumentsInMemoryDropzone = (
  props: IUseMultipleDocumentsInMemoryDropzoneProps
): IUseMultipleDocumentsInMemoryDropzoneResponse => {
  const { isStoringFileInMemory, isMultiple } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useLocale();
  const keyPrefix = 'hooks.useDocumentDropzone';
  const { triggerSnackbar } = useAppSnackbar();

  const [filesInState, setFilesInState] = useState<IFile[] | undefined>(undefined);

  const resetFilesInState = (): void => {
    setFilesInState(undefined);
  };

  const handleOnDrop = useCallback(
    async (files: File[]) => {
      setIsProcessing(true);

      //formats that we show a preview for
      const filesWithBlobUrl = ['application/pdf', 'image/jpeg', 'image/png'];
      setFilesInState(
        files.map((file: File) => {
          if (filesWithBlobUrl.includes(file.type)) {
            const blobUrl = URL.createObjectURL(file);
            return { blobUrl, file: file };
          } else {
            return { file: file };
          }
        })
      );
      setIsProcessing(false);
    },
    [isStoringFileInMemory]
  );

  const handleOnDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    //code: "file-too-large" | "file-too-small" | "too-many-files" | "file-invalid-type" | string;
    setIsProcessing(false);

    let isErrorTooManyFilesShowed = false;

    rejectedFiles.map((rejectedFile) => {
      const errorMessageCode = rejectedFile.errors[0].code;
      const fileName = rejectedFile.file.name;

      if (errorMessageCode !== 'too-many-files') {
        triggerSnackbar({
          snackbarText: t(`${keyPrefix}.${errorMessageCode}`, { fileName: fileName }),
          variant: 'error'
        });
      } else if (errorMessageCode === 'too-many-files' && !isErrorTooManyFilesShowed) {
        isErrorTooManyFilesShowed = true;

        triggerSnackbar({
          snackbarText: t(`${keyPrefix}.${'too-many-files'}`),
          variant: 'error'
        });
      }
    });
  }, []);

  const dropzone = useDropzone({
    accept: [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'image/jpeg',
      'image/png'
    ],
    multiple: isMultiple ? true : false,
    maxSize: 15000000, //15MB
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop,
    onDropRejected: handleOnDropRejected,
    maxFiles: isMultiple ? 5 : 1
  });

  return {
    dropzone,
    isProcessing: isProcessing,
    files: filesInState,
    resetFilesInState
  };
};
