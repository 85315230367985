import { useLocale } from '@koopajs/react';
import { IResolution } from 'types';
import React from 'react';
import { VisibleByAccordion } from 'components/VisibleByAccordion';
import { SystemStyleObject } from '@mui/system';

interface IResolutionVisibleByProps {
  resolution: IResolution;
  isUserWrittenResolutionCreatorRole: boolean;
}

export const ResolutionVisibleByAccordion: React.FC<IResolutionVisibleByProps> = (props) => {
  const { resolution, isUserWrittenResolutionCreatorRole } = props;

  const { t } = useLocale();
  const keyPrefix = 'WrittenResolution';

  const activeCommitteeMembersWithCreateResolutionRole = resolution?.activeCommitteeMembers?.filter((acm) => {
    return acm.roles?.some((role) => role.role === 'createResolutionProjects');
  });

  const usersVisibleByIds = activeCommitteeMembersWithCreateResolutionRole?.map((acm) => acm.userId);

  const isDraft = resolution.state === 'draft';
  const isAttached = resolution.state === 'attached';
  const isSealed = resolution.state === 'sealed';
  const isDeclined = isSealed && resolution.resolutionOutcome?.outcome === 'declined';

  const generateChipProperties = (): {
    label: string;
    sx: SystemStyleObject;
  } => {
    let sx = { backgroundColor: 'customGrey.main' };
    if (isDraft) {
      return {
        label: t(keyPrefix + '.accordionChipToReviewLabel'),
        sx
      };
    } else {
      let label = undefined;

      if (isAttached) {
        label = t(keyPrefix + '.accordionChipToSignLabel');
        sx = { backgroundColor: 'yellow.60' };
      } else if (isDeclined) {
        label = t(keyPrefix + '.accordionChipDeclinedLabel');
        sx = { backgroundColor: 'red.60' };
      } else {
        label = t(keyPrefix + '.accordionChipAdoptedLabel');
        sx = { backgroundColor: 'greenTwo.60' };
      }

      return {
        label,
        sx
      };
    }
  };

  return (
    <VisibleByAccordion
      title={t('common:writtenResolution')}
      usersVisibleByIds={usersVisibleByIds}
      visibleByState={isDraft ? 'some' : 'all'}
      chip={generateChipProperties()}
      prefixVisibleToSome={t(keyPrefix + '.prefixVisibleToSome')}
      prefixVisibleToAll={t(keyPrefix + '.prefixVisibleToAll')}
      isVisibleByAllMessageHidden={!isUserWrittenResolutionCreatorRole}
    />
  );
};
