import React, { useCallback } from 'react';
import { Dialog, ErrorMessage } from '@koopajs/mui';
import { Typography, Button, Box, Container, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocale, useResourceDelete } from '@koopajs/react';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';
import { IUploadedMinute } from 'types';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import { Operations } from '@koopajs/app';
import { useDispatch } from 'react-redux';
import { useWorkspaceShow, useSearchParams } from '@koopajs/react';

interface IDeleteUploadedMinute {
  uploadedMinute: IUploadedMinute;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteUploadedMinute: React.FC<IDeleteUploadedMinute> = (props) => {
  const { uploadedMinute, isOpen, onClose } = props;

  const { t } = useLocale();
  const history = useHistory();
  const keyPrefix = 'Modals.ModalDeleteUploadedMinute';

  const { triggerSnackbar } = useAppSnackbar();

  const dispatch = useDispatch();
  const resourcesFetchOperation = Operations.Resources.fetch(dispatch);
  const { workspace } = useWorkspaceShow();
  const search = useSearchParams();

  const {
    deleteResource: deleteUploadedMinute,
    errorMessage,
    isProcessing
  } = useResourceDelete({
    path: '/uploaded-minutes',
    id: uploadedMinute?.id as string
  });

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  const handleDeleteUploadedMinute = useCallback(async () => {
    const isSuccess = await deleteUploadedMinute();

    if (isSuccess) {
      await new Promise((resolve) => setTimeout(resolve, 1200));

      await resourcesFetchOperation(workspace?.id || '', `/minutes`, search.searchParams);
      handleCloseModal();

      history.push({ pathname: '/minutes', search: history.location?.search });

      triggerSnackbar({
        snackbarText: t(keyPrefix + '.snackbarDeleteSuccess'),
        variant: 'success'
      });
    } else {
      triggerSnackbar({
        snackbarText: t(keyPrefix + '.snackbarDeleteFail'),
        variant: 'error'
      });
    }
  }, [deleteUploadedMinute, uploadedMinute?.id]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseModal}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'delete-uploaded-minute-modal' } }}
      maxWidth="md"
    >
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 3 }}
      >
        <Typography variant="h6">{t(keyPrefix + '.title')}</Typography>

        <ErrorMessage error={errorMessage} />

        <Typography sx={{ whiteSpace: 'pre-line' }}>{t(keyPrefix + '.description')}</Typography>

        {renderConfirmDeleteField()}

        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={handleCloseModal}>
            {t('common:labelCancel')}
          </Button>
          <Button
            data-cy="delete-uploaded-minute-modal_delete-button"
            color="error"
            sx={{ px: 6.5, mx: 1 }}
            variant="contained"
            onClick={handleDeleteUploadedMinute}
            disabled={!hasUserTypedDeleteConfirmation}
          >
            {t(keyPrefix + '.labelDelete')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
