import { useResourceList } from '@koopajs/react';
import { IDocument, IDocumentRecord } from 'types';
import prettyBytes from 'pretty-bytes';
import {
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material';
import { Loading } from '@koopajs/mui';
import { RenderDocumentIcon } from '../RenderDocumentIcon';
import { formatDate } from 'utils/DateTime/formatDate';
import { useLocale, useSearchParams } from '@koopajs/react';
import { useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { AddDocumentToHistoryButton } from './AddDocumentToHistoryButton';
import { theme } from 'components/Core/theme';

interface IDocumentHistoryProps {
  documentRecord: IDocumentRecord;
  canUserUploadDocuments?: boolean;
}

export const DocumentHistory: React.FC<IDocumentHistoryProps> = (props) => {
  const { documentRecord, canUserUploadDocuments } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'DocumentRecord.DocumentHistory';

  const search = useSearchParams();

  useEffect(() => {
    search.setSearchParams({ page: undefined });
  }, [JSON.stringify(search?.searchParams?.filters)]);

  const {
    resources: documents,
    isProcessing,
    totalCount
  } = useResourceList<IDocument>({
    path: `/document-records/${documentRecord.id}/historical-documents`,
    searchParams: { size: 10, ...search.searchParams }
  });

  const handlePageChange = useCallback(
    (e: unknown, newPage: number) => {
      search.setSearchParams({ page: newPage });
    },
    [search.searchParams.page]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      search.setSearchParams({ size: Number(e.target.value), page: undefined }),
    []
  );

  // sorting again due to no sort when redux updates following document post
  const documentsSortByMostRecent = documents.sort((a, b) => {
    // attachedToDocumentRecord shouldn't be undefined but TS isn't happy so just in case
    if (!a.attachedToDocumentRecord?.activeSince || !b.attachedToDocumentRecord?.activeSince) return 0;

    return b.attachedToDocumentRecord.activeSince.localeCompare(a.attachedToDocumentRecord.activeSince);
  });

  if (!documentsSortByMostRecent.length && !canUserUploadDocuments) return null;

  return (
    <Accordion
      elevation={0}
      sx={{
        borderRadius: '4px'
      }}
      variant="outlined"
      data-cy="document-history"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ borderBottom: `1px solid ${theme.palette.customGrey.light}` }}
      >
        <Typography variant="body1">{t(keyPrefix + '.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {isProcessing ? (
          // calculate height of table to stop the page jumping around when clicking through pages
          <Stack sx={{ height: `${(search.searchParams.size || 10) * 69.5 + 56.5}px` }}>
            <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
          </Stack>
        ) : documentsSortByMostRecent.length ? (
          <TableContainer sx={{ borderBottom: `1px solid ${theme.palette.customGrey.light}` }}>
            <Table sx={{ minWidth: { xs: 'auto', md: 650 } }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '1px' }}>{t(keyPrefix + '.type')}</TableCell>
                  <TableCell align="left">{t(keyPrefix + '.document')}</TableCell>

                  <TableCell align="left">{t(keyPrefix + '.activeSince')}</TableCell>
                  <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {t(keyPrefix + '.size')}
                  </TableCell>
                  <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' }, width: '1px' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {documentsSortByMostRecent?.map((document, index) => {
                  const { attachedToDocumentRecord, attachedFile } = document;

                  const linkToDocumentProps = {
                    component: RouterLink,
                    to: `/document-records/${documentRecord.id}/documents/${document.id}`
                  };

                  const sxTableCellLink = {
                    padding: 0,
                    position: 'relative',
                    height: '62px'
                  };

                  const sxTableCellLinkChild = {
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    padding: 3,
                    width: '100%',
                    color: 'inherit',
                    textDecoration: 'none'
                  };

                  return document ? (
                    <TableRow
                      key={`${attachedToDocumentRecord?.title} - ${index}`}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        textDecoration: 'none',
                        color: 'inherit'
                      }}
                      data-cy="document-history_table_row"
                    >
                      <TableCell component="th" scope="row" sx={sxTableCellLink}>
                        <Box
                          sx={sxTableCellLinkChild}
                          // removes tab index for mobile so only the title field is tabbable
                          tabIndex={-1}
                          {...linkToDocumentProps}
                        >
                          <RenderDocumentIcon fileType={attachedFile.format} />
                        </Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          maxWidth: { xs: '150px', md: '350px' },
                          minWidth: { xs: 0, sm: '250px' },
                          ...sxTableCellLink
                        }}
                      >
                        <Box sx={sxTableCellLinkChild} {...linkToDocumentProps}>
                          <Typography
                            variant="body2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '100%',
                              whiteSpace: 'nowrap',
                              minWidth: 0
                            }}
                            className="rr-mask"
                          >
                            {attachedToDocumentRecord?.title}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell align="left" sx={sxTableCellLink}>
                        <Box sx={sxTableCellLinkChild} tabIndex={-1} {...linkToDocumentProps}>
                          {attachedToDocumentRecord?.activeSince &&
                            formatDate({
                              isoString: attachedToDocumentRecord.activeSince,
                              locale,
                              isUTC: true
                            })}
                        </Box>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ display: { xs: 'none', sm: 'table-cell' }, ...sxTableCellLink }}
                      >
                        <Box sx={sxTableCellLinkChild} tabIndex={-1} {...linkToDocumentProps}>
                          {prettyBytes(attachedFile.sizeBytes || 0)}
                        </Box>
                      </TableCell>
                      <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                        <Button
                          component={RouterLink}
                          to={`/document-records/${documentRecord.id}/documents/${document.id}`}
                          data-cy="document-history_table_row_open-button"
                        >
                          {t(keyPrefix + '.viewButtonLabel')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        {canUserUploadDocuments ? (
          <Box sx={{ borderBottom: `1px solid ${theme.palette.customGrey.light}` }}>
            <AddDocumentToHistoryButton documentRecord={documentRecord} />
          </Box>
        ) : null}
        <TablePagination
          sx={{
            '& .MuiInputBase-root': {
              marginRight: { xs: 1, sm: '32px' }
            },
            '& .MuiTablePagination-actions': {
              marginLeft: { xs: 1, sm: '20px' },
              '& .MuiIconButton-root': {
                padding: { xs: 0, sm: '8px' }
              }
            }
          }}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount || 999}
          rowsPerPage={search.searchParams.size || 10}
          page={search.searchParams.page ? search.searchParams.page : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleSizeChange}
          SelectProps={{
            native: true
          }}
          // eslint-disable-next-line react/jsx-no-bind
          labelDisplayedRows={({ from, to, count }) => {
            const transOptions = { from, to, count };

            return count !== -1
              ? t('common:TablePagination.labelDisplayedRowsCount', transOptions)
              : t('common:TablePagination.labelDisplayedRowsMoreThan', transOptions);
          }}
          labelRowsPerPage={t('common:TablePagination.labelRowsPerPage')}
        />
      </AccordionDetails>
    </Accordion>
  );
};
