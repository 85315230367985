import { Stack, Typography, Paper } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

interface INoMeetingMessage {
  text: string;
}

export const NoMeetingMessage: React.FC<INoMeetingMessage> = (props) => {
  return (
    <Stack sx={{ marginTop: '24px' }}>
      <Stack
        component={Paper}
        variant="outlined"
        direction="row"
        spacing={2}
        sx={{ padding: '24px', borderRadius: '4px' }}
      >
        <CheckCircleOutline /> <Typography>{props.text}</Typography>
      </Stack>
    </Stack>
  );
};
