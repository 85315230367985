import { useLocale } from '@koopajs/react';
import { formatTime } from 'utils/DateTime/formatTime';

interface ITimeStartEndProps {
  isoStringStart?: string;
  isoStringEnd?: string;
}

export const TimeStartEnd: React.FC<ITimeStartEndProps> = (props) => {
  const { isoStringStart, isoStringEnd } = props;
  const { t, locale } = useLocale();

  if (!isoStringStart) return null;

  if (!isoStringEnd) {
    return <>{formatTime({ isoString: isoStringStart, locale })}</>;
  }
  return (
    <>
      {t('common:timeStartEnd', {
        start: formatTime({ isoString: isoStringStart, locale }),
        end: formatTime({ isoString: isoStringEnd, locale })
      })}
    </>
  );
};
