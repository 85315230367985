import { useLocale } from '@koopajs/react';
import { Box } from '@mui/material';
import { SortByFacet } from 'components/Filters/SortByFacet';
import { useResourceList } from '@koopajs/react';
import { ICommittee } from 'types';
import { Facet } from 'components/Filters/Facet';
import { DateRangeFacet } from 'components/Filters/DateRangeFacet';

export const FacetListResolutionsInline: React.FC = () => {
  const path = '/resolutions';

  const { t } = useLocale();

  const keyPrefix = 'Components.Facets';

  const committees = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const committeesOptions =
    committees?.resources.map((committee) => ({ id: committee.id, label: committee.name })) || [];

  const resolutionOutcomeOptions = [
    { id: 'approved', label: t(keyPrefix + '.ResolutionOutcome.options.approved') },
    { id: 'approvedWithMods', label: t(keyPrefix + '.ResolutionOutcome.options.approvedWithMods') },
    { id: 'declined', label: t(keyPrefix + '.ResolutionOutcome.options.declined') }
  ];

  return (
    <Box sx={{ display: { xs: 'none', lg: 'flex' }, gap: 2 }}>
      <Facet
        path={path}
        facetKey="committeeId.keyword"
        label={t(keyPrefix + '.meetingType')}
        options={committeesOptions}
      />

      <DateRangeFacet path={path} facetKey="resolutionOutcome.recordedAt" />

      <Facet
        path={path}
        facetKey="resolutionOutcome.outcome.keyword"
        label={t(keyPrefix + '.ResolutionOutcome.title')}
        options={resolutionOutcomeOptions}
      />

      <SortByFacet
        sortOptions={[
          {
            key: 'resolutionOutcome.recordedAt',
            direction: 'asc',
            label: t(keyPrefix + '.SortBy.labelOldest')
          },
          {
            key: 'resolutionOutcome.recordedAt',
            direction: 'desc',
            label: t(keyPrefix + '.SortBy.labelNewest')
          }
        ]}
      />
    </Box>
  );
};
