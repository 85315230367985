import { useResourceShow, useParamsKey, useLocale } from '@koopajs/react';
import { IDocument } from 'types';
import { Loading } from '@koopajs/mui';
import { useEffect } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import DownloadingIcon from '@mui/icons-material/Downloading';

export const DocumentView: React.FC = () => {
  const documentId = useParamsKey();

  const { t } = useLocale();

  const { resource: document, errorMessage } = useResourceShow<IDocument>({
    path: '/documents',
    id: documentId
  });

  useEffect(() => {
    const documentUrl = document?.attachedFileUri;
    //TODO: instead of error message use isProcessing once is fixed - to be true at the beginning, now it's false
    if (documentUrl) {
      window.location.href = documentUrl;
    } else if (errorMessage) {
      // TODO: change url for new name
      window.location.href = '/';
    }
  }, [document?.attachedFileUri, errorMessage]);

  if (document && !errorMessage) {
    return (
      <>
        <Box
          sx={{
            textAlign: 'center',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className="rr-block"
        >
          <Box>
            <DownloadingIcon fontSize="large" />
            <Typography variant="h5" gutterBottom>
              {t('DocumentView.title')}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ mr: 1 }}>{t('DocumentView.description')}</Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
      <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
    </Stack>
  );
};
