export const sxHtmlButtonReset: object = {
  background: 'none',
  border: 'none',
  color: 'inherit',
  padding: 0,
  cursor: 'pointer',
  textAlign: 'inherit',
  textTransform: 'inherit',
  lineHeight: 'inherit',
  wordSpacing: 'inherit',
  letterSpacing: 'inherit',
  textRendering: 'inherit',
  textIndent: 'inherit',
  textShadow: 'inherit'
};
