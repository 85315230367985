import { IResourcePickerOption, TValue } from './types';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material';
// eslint-disable-next-line
export const isList = (v: TValue | null): v is IResourcePickerOption[] => {
  return Array.isArray(v);
};

export const ensureValueList = (v: TValue): IResourcePickerOption[] => {
  if (isList(v)) return v;
  if (v) return [v];
  return [];
};

export const ensureValueSingle = (v: TValue): IResourcePickerOption | undefined => {
  if (isList(v)) return v[0];
  return v || undefined;
};

export const filter: (
  options: IResourcePickerOption[],
  params: FilterOptionsState<IResourcePickerOption>
) => IResourcePickerOption[] = createFilterOptions<IResourcePickerOption>();
