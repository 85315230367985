import { IMinute, ITopic, IMeeting } from 'types';
import { useWorkspaceShow } from '@koopajs/react';
import { Box, Stack, Typography, Paper, Divider } from '@mui/material';
import React, { forwardRef } from 'react';
import { SignatureList } from 'components/Signature/SignatureList';
import { TopicCardMinutesPrint } from 'components/TopicCard/TopicCardMinutesPrint';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';

interface IViewMinutes {
  minutes?: IMinute | IMeeting;
  topics?: ITopic[];
  ref: React.ForwardedRef<unknown>;
  isReview?: boolean;
}

export const ViewMinutesPrintVersion: React.FC<IViewMinutes> = forwardRef((props, ref) => {
  const { minutes, topics: approvedTopics, isReview } = props;

  const { workspace } = useWorkspaceShow();

  const signaturesWithTitles = getSignaturesWithTitles(minutes);

  return (
    <Box className="printWrapper rr-block" sx={{ display: 'block' }} ref={ref}>
      <>
        {/* LOGO (if they have one) */}
        {workspace?.icon && (
          <Box>
            <img
              src={workspace.icon}
              alt="Logo"
              style={{ maxHeight: '50px', maxWidth: '200px', marginTop: '6px', marginLeft: '14px' }}
            />
          </Box>
        )}

        {minutes && <MinutesHeaderCard minutes={minutes} isViewPrint={true} />}

        <Divider sx={{ mb: 3, mx: 3 }} />

        <Paper elevation={0}>
          <Stack spacing={2} sx={{ mb: 6.25 }}>
            {approvedTopics?.map((topic, index, array) => {
              return (
                <Box key={topic.id}>
                  <TopicCardMinutesPrint
                    topic={topic}
                    order={index + 1}
                    isEditable={false}
                    userHasEditAccess={false}
                    meeting={minutes}
                    isReview={isReview}
                  />
                  {index !== array.length - 1 && <Divider sx={{ mx: 3 }} />}
                </Box>
              );
            })}
          </Stack>
        </Paper>

        {signaturesWithTitles && (
          <>
            <Divider />
            <Typography variant="body2" sx={{ my: 2 }}>
              <SignatureList signatures={signaturesWithTitles} />
            </Typography>
          </>
        )}
      </>
    </Box>
  );
});
