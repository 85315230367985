import React from 'react';
import Alert from '@mui/material/Alert';
import { ICoreProps } from '@koopajs/mui';

export interface IErrorMessageTempProps extends ICoreProps {
  error?: React.ReactNode;
  variant?: 'filled' | 'outlined' | 'standard';
}

export const ErrorMessageTemp: React.FC<IErrorMessageTempProps> = (props: IErrorMessageTempProps) => {
  const { error, sx, variant } = props;

  if (!error) return null;

  return (
    <Alert severity="error" sx={sx} variant={variant}>
      {error}
    </Alert>
  );
};
