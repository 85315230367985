import React, { useCallback } from 'react';
import { Dialog, Loading } from '@koopajs/mui';
import { Typography, Button, Box, Container, IconButton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  useLocale,
  useResourceCreate,
  useWorkspaceShow,
  useSearchParams,
  useResourceList
} from '@koopajs/react';
import { Trans } from 'react-i18next';
import { IDocument, IDocumentRecord } from 'types';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@mui/icons-material';
import { Operations, TrackEvent } from '@koopajs/app';
import { useDispatch } from 'react-redux';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';

interface IDeleteDocumentRecord {
  documentRecord: IDocumentRecord;
  isOpen: boolean;
  onClose: () => void;
}
export const i18nTransComponents: { [k: string]: React.ReactElement } = { div: <div /> };

export const DeleteDocumentRecord: React.FC<IDeleteDocumentRecord> = (props) => {
  const { documentRecord, isOpen, onClose } = props;

  const documentRecordHasArchives = documentRecord.documentsIds && documentRecord.documentsIds?.length > 1;

  const { createResource: deleteDocumentRecord, isProcessing } = useResourceCreate({
    path: `/document-records/${documentRecord.id}/delete`,
    customReducer: {
      path: { resourceType: '/document-records', resourceId: documentRecord.id },
      mapping: (previousObj: object, newObj: object) => {
        return newObj;
      }
    }
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useLocale();
  const history = useHistory();
  const keyPrefix = 'Modals.DeleteDocumentRecord';

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseDeleteModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  // cached historical documents to know whether to redirect
  const { resources: historicalDocuments } = useResourceList<IDocument>({
    path: `/document-records/${documentRecord?.id}/historical-documents`,
    useCache: true
  });

  const { workspace } = useWorkspaceShow();
  const search = useSearchParams();
  const dispatch = useDispatch();
  const resourcesFetchOperation = Operations.Resources.fetch(dispatch);

  const handleOnSubmit = useCallback(async () => {
    const res = await deleteDocumentRecord({});

    if (res) {
      // refetch document history
      await resourcesFetchOperation(
        workspace?.id || '',
        `/document-records/${documentRecord?.id}/historical-documents`,
        { size: 10, ...search.searchParams }
      );

      if (historicalDocuments.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        history.push('/document-records');
      } else {
        handleCloseDeleteModal();
      }
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteSuccess'), {
        variant: 'success',
        action: (key: any) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
    } else {
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteFail'), {
        variant: 'error',
        action: (key: any) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-record-delete-fail');
    }

    return true;
  }, [documentRecord.id, documentRecord.documentsIds?.length, historicalDocuments.length]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseDeleteModal}
      forcedFullScreen={false}
      dialogProps={{
        PaperProps: {
          'data-cy': 'delete-document-record-modal'
        }
      }}
      maxWidth="md"
    >
      {isProcessing ? (
        <Stack sx={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
        </Stack>
      ) : (
        <Container
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3
          }}
        >
          <Typography variant="h6" component="h2">
            {t(keyPrefix + '.title')}
          </Typography>
          {documentRecordHasArchives ? (
            <Trans i18nKey={`${keyPrefix}.deleteLastDocument`} t={t} components={i18nTransComponents} />
          ) : (
            <Trans i18nKey={`${keyPrefix}.deleteDocumentRecord`} t={t} components={i18nTransComponents} />
          )}
          {renderConfirmDeleteField()}
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={handleCloseDeleteModal}>
              {t('common:labelCancel')}
            </Button>
            <Button
              color="error"
              sx={{ px: 6.5, mx: 1 }}
              variant="contained"
              type="submit"
              onClick={handleOnSubmit}
              disabled={!hasUserTypedDeleteConfirmation}
            >
              {t(keyPrefix + '.labelDelete')}
            </Button>
          </Box>
        </Container>
      )}
    </Dialog.View>
  );
};
