import { List, Typography } from '@mui/material';
import { IMeeting, ITopic } from 'types';
import { PastMeetingApprovalItem } from './PastMeetingApprovalItem';
import { useLocale } from '@koopajs/react';
import { usePastMeetingsApprovalList } from './usePastMeetingsApprovalList';

interface IPastMeetingsApprovedListProps {
  meetingId: string;
  topicId: string;
  topic: ITopic;
  isViewPrint?: boolean;
}

export const PastMeetingsApprovedList: React.FC<IPastMeetingsApprovedListProps> = (props) => {
  const { meetingId, topicId, isViewPrint, topic } = props;

  const { t } = useLocale();

  const { meetingsApprovedDuringMeeting } = usePastMeetingsApprovalList({ topic });

  return (
    <>
      {meetingsApprovedDuringMeeting.length > 0 && (
        <>
          <Typography sx={{ opacity: 0.8, mt: !isViewPrint ? 2 : null }}>
            {t('Components.TopicCard.PastMeetingsApprovedList.labelPastMinutesAdopted')}:
          </Typography>
          <List sx={{ mt: '0px!important', pt: 0.5 }}>
            {meetingsApprovedDuringMeeting.map((minute) => {
              return (
                <PastMeetingApprovalItem
                  minutes={minute}
                  meetingId={meetingId}
                  topicId={topicId}
                  key={minute.id}
                  isViewPrint={isViewPrint}
                  isEditable={false}
                />
              );
            })}
          </List>
        </>
      )}
    </>
  );
};
