import { User } from '@koopajs/react';
import { Chip, Avatar } from '@mui/material';

interface IUserChipProps {
  userId: string;
  variant?: 'outlined' | 'filled';
  testId?: string;
}

export const UserChip: React.FC<IUserChipProps> = (props) => {
  const { userId, variant, testId } = props;
  return (
    <User id={userId}>
      {(profile) => {
        return (
          <Chip
            variant={variant}
            avatar={<Avatar alt={profile?.username} src={profile?.profilePicture} />}
            label={profile?.username}
            sx={{
              maxWidth: '400px',
              height: 'auto',
              minHeight: '32px',
              py: '3px',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal'
              }
            }}
            data-cy={testId || 'user-chip'}
          />
        );
      }}
    </User>
  );
};
