import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Dialog } from '@koopajs/mui';
import {
  Typography,
  Button,
  Box,
  Container,
  Stack,
  FormControlLabel,
  Switch,
  IconButton
} from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useResourceUpdate } from '@koopajs/react';
import { useSnackbar } from 'notistack';
import { IResolution } from '../../types';
import { useHistory } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';

interface IDeclineResolution {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}
export const i18nTransComponents: { [k: string]: React.ReactElement } = { div: <div /> };

export const DeclineResolution: React.FC<IDeclineResolution> = (props) => {
  const { id, isOpen, onClose } = props;

  const [isResolutionBookSwitchChecked, setIsResolutionBookSwitchChecked] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChangeResolutionBookSwitch = (event: React.SyntheticEvent, checked: boolean): void => {
    setIsResolutionBookSwitchChecked(checked);
  };

  const { updateResource } = useResourceUpdate({
    path: `/resolutions/${id}/decline`,
    id: '',
    customReducer: {
      path: {
        resourceType: '/resolutions',
        resourceId: id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IResolution;
      }
    }
  });

  const history = useHistory();

  const { t } = useLocale();

  const keyPrefix = 'Modals.ModalDeclineResolution';

  const handleDeclineResolution = useCallback(async () => {
    const res = await updateResource({ isAddingToBook: isResolutionBookSwitchChecked });

    if (res) {
      enqueueSnackbar(
        isResolutionBookSwitchChecked
          ? t(keyPrefix + '.snackbarResolutionDeclined')
          : t(keyPrefix + '.snackbarResolutionDeclinedAndArchived'),
        {
          variant: 'error',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        }
      );
    }
  }, [id, isResolutionBookSwitchChecked]);

  return (
    <Dialog.View isOpen={isOpen} onClose={onClose} forcedFullScreen={false}>
      <Container sx={{ textAlign: 'center' }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }} data-cy="decline-resolution-dialog_title">
            {t(keyPrefix + '.title')}
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{t(keyPrefix + '.description')}</Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Switch defaultChecked data-cy="decline-resolution-dialog_add-to-books-switch" />}
            label={t(keyPrefix + '.resolutionBookSwitchLabel')}
            checked={isResolutionBookSwitchChecked}
            onChange={handleChangeResolutionBookSwitch}
          />
        </Box>
        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button sx={{ minWidth: '275px', flex: 'none' }} variant="outlined" onClick={onClose}>
            {t('common:labelCancel')}
          </Button>
          <Button
            sx={{ minWidth: '275px', flex: 'none' }}
            data-cy="decline-resolution-dialog_decline-button"
            variant="contained"
            onClick={handleDeclineResolution}
          >
            {t(keyPrefix + '.labelDecline')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
