import { Box, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useComponentVisibility } from '@koopajs/react';
import { useCallback } from 'react';
import { IDocument, IDocumentRecord } from 'types';
import { DateTime } from 'luxon';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { generateDocumentRecordFormDefaultValues } from 'utils/DocumentRecords/generateDocumentRecordFormDefaultValues';

interface IAddDocumentToDocumentRecordButtonProps {
  documentRecord: IDocumentRecord;
  sx?: SxProps<Theme>;
}

export const AddDocumentToDocumentRecordButton: React.FC<IAddDocumentToDocumentRecordButtonProps> = (
  props
) => {
  const { documentRecord, sx } = props;

  const { t } = useLocale();
  const keyPrefix = 'DocumentRecord.AddDocumentToDocumentRecordButton';

  const addDocumentToDocumentRecordDialog = useComponentVisibility('addDocumentToDocumentRecordDialog');

  const shouldUpdateDocument =
    documentRecord.reviewInterval && documentRecord.lastDocumentActiveSince
      ? DateTime.now() >
        DateTime.fromISO(documentRecord.lastDocumentActiveSince).toUTC().plus(documentRecord.reviewInterval)
      : documentRecord.toReviewAt
      ? DateTime.now() > DateTime.fromISO(documentRecord.toReviewAt).toUTC()
      : false;

  const openDialog = useCallback(() => {
    addDocumentToDocumentRecordDialog.setVisibleWithContext({
      defaultValues: generateDocumentRecordFormDefaultValues(documentRecord, { isActiveSinceToday: true }),
      documentRecord
    });
  }, [JSON.stringify(documentRecord)]);

  return (
    <Box>
      <Button
        onClick={openDialog}
        variant={shouldUpdateDocument ? 'contained' : 'outlined'}
        sx={sx}
        data-cy="add-document-to-document-record-button"
      >
        <span>{t(keyPrefix + '.updateLabel')}</span>
      </Button>
    </Box>
  );
};
