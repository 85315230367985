import { useLocale } from '@koopajs/react';
import { Box, Typography, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useResourceList } from 'components/temp/useResourceListTemp';
import { PageContainer } from 'components/temp/PageContainer';
import { ICommittee } from 'types';
import { useAppContext, usePermissions } from '@koopajs/react';
import { Prompt, Link as RouterLink } from 'react-router-dom';
import { EmptyState } from 'components/EmptyState';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { UploadMeetingButton } from 'components/UploadedMeeting/UploadMeetingButton';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { IFile, UploadedMeetingAddCard } from 'components/UploadedMeeting/UploadedMeetingAddCard';

export const UploadedMeetings: React.FC = () => {
  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const history = useHistory();

  // redirect to dashboard if user is not workspace owner
  if (!isUserWorkspaceOwner) {
    history.push('/');
  }

  const { t } = useLocale();
  const keyPrefix = 'UploadedMeetings';

  const { getComponentContext } = useAppContext();
  const { files }: { files: IFile[] } = getComponentContext('uploadedMeetings') as { files: IFile[] };

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees'
  });

  const areAllMeetingsAddedToBooks = files?.every((file) => file.id);

  // browser alert when user tries to leave the page with unsaved changes
  useEffect(() => {
    if (areAllMeetingsAddedToBooks) return;

    function beforeUnload(e: BeforeUnloadEvent): void {
      e.preventDefault();
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [areAllMeetingsAddedToBooks]);

  if (!files || files.length === 0)
    return (
      <EmptyState
        title={t(keyPrefix + '.EmptyState.title')}
        description={
          <Typography sx={{ whiteSpace: 'pre-line' }}>{t(keyPrefix + '.EmptyState.description')}</Typography>
        }
        button={<>{isUserWorkspaceOwner && <UploadMeetingButton variant="contained" />}</>}
        windowTitle={`${t(keyPrefix + '.title')} - Panorama`}
      />
    );
  return (
    <>
      <Helmet>
        <title>{`${t(keyPrefix + '.title')} - Panorama`}</title>
      </Helmet>
      <Prompt when={!areAllMeetingsAddedToBooks} message={t(keyPrefix + '.unsavedChangesMessage')} />
      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Breadcrumbs
            hierarchyArray={[{ path: '/past-meetings', text: t('common:navigation.pastMeetings') }]}
            pageTitle={t(keyPrefix + '.title')}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'space-between' },
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'flex-start', md: 'center' },
              gap: { xs: 1, md: 0 }
            }}
          >
            <Typography variant="h1" sx={{ fontSize: '24px' }}>
              {t(keyPrefix + '.title')}
            </Typography>

            <Button
              component={RouterLink}
              to="/past-meetings"
              variant="contained"
              disabled={!areAllMeetingsAddedToBooks}
              data-cy="uploaded-meetings_view-past-meetings-button"
            >
              <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
              {t(keyPrefix + '.viewPastMeetingsLabel')}
            </Button>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {files?.length &&
              files.map((file: IFile, index) => {
                return (
                  <UploadedMeetingAddCard
                    file={file}
                    committees={committees}
                    index={index}
                    key={`${file.file.name} - ${index}`}
                  />
                );
              })}
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
