import { Dialog, Loading } from '@koopajs/mui';
import { useParamsKey, useResourceShow } from '@koopajs/react';
import { MeetingReviewForDialog } from 'components/Meetings/MeetingReviewForDialog';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IMeeting } from 'types';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';

interface IApproveMinutesDialogProps {
  liveMeeting: IUseLiveMeetingResponse;
}

export const ApproveMinutesDialog: React.FC<IApproveMinutesDialogProps> = (props) => {
  const keyPrefix = 'Dialogs.ApproveMinutesDialog.DialogView';

  const { liveMeeting } = props;

  const { currentTopicId, meetingId, topic } = liveMeeting;

  const pastMinutesId = useParamsKey('pastMinutesId');

  // checks if the meeting is set to be approved, if so editing the meeting in <MeetingStatesReview/> is blocked
  const isMinuteApprovedByBoard = topic?.pastMeetingsToApprove?.some((obj) => {
    return obj.meetingId === pastMinutesId && obj.isApproved;
  });

  const history = useHistory();

  const { resource: minutes } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: pastMinutesId
  });

  const handleOnClose = useCallback(() => {
    history.push(`/meetings/${meetingId}/topics/${currentTopicId}`);
  }, [currentTopicId]);

  return (
    <Dialog.View
      isCloseVisible={true}
      isOpen={Boolean(pastMinutesId) && topic?.type === 'pastMinutesApproval'}
      onClose={handleOnClose}
      i18n={{ keyPrefix }}
      dialogProps={{ PaperProps: { 'data-cy': 'approve-minutes-dialog' } }}
    >
      {minutes ? (
        <MeetingReviewForDialog
          meeting={minutes}
          isBeingReviewedInsideMeeting={true}
          isViewMode={isMinuteApprovedByBoard}
        />
      ) : (
        <Loading sx={{ backgroundColor: 'transparent' }} />
      )}
    </Dialog.View>
  );
};
