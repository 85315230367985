import { useFacet, useLocale, useResourceList } from '@koopajs/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  List,
  Divider
} from '@mui/material';
import { ICommittee } from 'types';

export interface ICommitteeTypeFacetProps {
  path: string;
  facetKey?: string;
}

export const CommitteeTypeFacetSidebar: React.FC<ICommitteeTypeFacetProps> = (props) => {
  const { path, facetKey = 'committeeId.keyword' } = props;

  const { t } = useLocale();
  const keyPrefix = 'Sidebars.Facets.CommitteeTypeFacet';

  const facet = useFacet({ facetKey, path, isSingleValue: true });

  let { resources: committeesList } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  if (path === '/resolutions') {
    committeesList = committeesList.filter((committee) => committee.isTakingResolutions);
  }

  if (!facet.values || facet.values.length === 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginTop: { xs: 4, lg: 0 } }}>
        <Box>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              backgroundColor: 'transparent',
              '&:before': {
                display: 'block'
                // background:
                //   'linear-gradient(266deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(224,224,224,1) 100%)'
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography variant="button">{t(keyPrefix + '.title')}</Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ p: 0 }}>
              {facet.values && (
                <Box>
                  <List sx={{ my: -0.5 }}>
                    {facet.values.map((value) => {
                      const committee = committeesList.find((committee) => committee.id === value.key);

                      return committee ? (
                        <ListItem disablePadding key={value.key} sx={{ py: 0.5 }}>
                          {/* eslint-disable-next-line react/jsx-no-bind */}
                          <ListItemButton onClick={facet.addFacetFilter(value.key) as any} sx={{ p: 0 }}>
                            <ListItemText
                              primary={committee.name}
                              primaryTypographyProps={{ variant: 'body2' }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
    </>
  );
};
