import { Dialog } from '@koopajs/mui';
import { Typography, Button, Box, Container } from '@mui/material';
import endImage from '../../assets/group-discussion.png';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';
import { Trans } from 'react-i18next';

interface IEndMeeting {
  isOpen: boolean;
  handleOnClose: () => void;
  liveMeeting: IUseLiveMeetingResponse;
}

export const EndMeeting: React.FC<IEndMeeting> = (props) => {
  const { isOpen, handleOnClose, liveMeeting } = props;
  const { t, handleMeetingPermanentlyFinished } = liveMeeting;

  const keyPrefix = 'Modals.ModalEndMeeting';

  return (
    <Dialog.View isOpen={isOpen} onClose={handleOnClose} forcedFullScreen={false}>
      <Container sx={{ textAlign: 'center' }}>
        <Box>
          <img src={endImage} srcSet="" loading="lazy" style={{ width: '8%' }} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ m: 2 }}>
            {t(keyPrefix + '.title')}
          </Typography>
          <Typography sx={{ m: 2 }}>
            <Trans i18nKey={keyPrefix + '.description-html'} t={t} />
          </Typography>
        </Box>
        <Button sx={{ px: 8, mx: 1 }} variant="outlined" onClick={handleOnClose}>
          {t('common:labelCancel')}
        </Button>
        <Button
          sx={{ px: 10, mx: 1 }}
          variant="contained"
          onClick={handleMeetingPermanentlyFinished}
          data-cy="end-meeting-modal_ok-button"
        >
          OK
        </Button>
      </Container>
    </Dialog.View>
  );
};
