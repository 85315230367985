import { useLocale } from '@koopajs/react';

export const useDocumentReviewIntervalOptions = (): { id: string; label: string }[] => {
  const { t } = useLocale();

  return [
    { id: 'none', label: t('common:documentReviewInterval.none') },
    { id: 'months 3', label: t('common:documentReviewInterval.every3months') },
    { id: 'months 6', label: t('common:documentReviewInterval.every6months') },
    { id: 'years 1', label: t('common:documentReviewInterval.everyYear') },
    { id: 'years 2', label: t('common:documentReviewInterval.every2years') },
    { id: 'years 3', label: t('common:documentReviewInterval.every3years') },
    { id: 'years 4', label: t('common:documentReviewInterval.every4years') },
    { id: 'years 5', label: t('common:documentReviewInterval.every5years') },
    { id: 'custom', label: t('common:documentReviewInterval.custom') }
  ];
};
