import { Dialog, ErrorMessage } from '@koopajs/mui';
import { Typography, Button, Box, Container, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { useLocale, useResourceShow, useResourceUpdate } from '@koopajs/react';
import { useCallback } from 'react';
import { IResolution } from 'types';
import { useHistory } from 'react-router-dom';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';

interface IEditAttachedResolutionModal {
  resolutionId: string;
  isOpen: boolean;
  onClose: () => void;
  handleSetIsEditable: (value: boolean, options?: { isWarningModal?: boolean }) => void;
}

export const EditAttachedResolutionModal: React.FC<IEditAttachedResolutionModal> = (props) => {
  const { resolutionId, isOpen, onClose, handleSetIsEditable } = props;

  const history = useHistory();

  const { t } = useLocale();

  const keyPrefix = 'Modals.EditAttachedResolutionModal';

  const { updateResource } = useResourceUpdate({
    path: `/resolutions/${resolutionId}/unattach`,
    id: '',
    customReducer: {
      path: {
        resourceType: '/resolutions',
        resourceId: resolutionId
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IResolution;
      }
    }
  });

  // hacky way to get correct errormessage and is processing states
  const { errorMessage, isProcessing } = useResourceShow({
    path: '/resolutions',
    id: resolutionId,
    useCache: true
  });

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  const handleSetResolutionToDraft = useCallback(async () => {
    const res = await updateResource({});

    if (res) {
      history.push(`/resolutions/${resolutionId}`);
      handleSetIsEditable(true, { isWarningModal: true });
      resetConfirmDeleteField();
      handleCloseModal();
    }

    return res;
  }, [resolutionId]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseModal}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'edit-attached-resolution-modal' } }}
    >
      <ErrorMessage error={errorMessage} />
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 3 }}
      >
        <Typography variant="h6">{t(keyPrefix + '.title')}</Typography>

        <Typography sx={{ whiteSpace: 'pre-line' }}>
          <Trans i18nKey={keyPrefix + '.description'} t={t} />
        </Typography>

        {renderConfirmDeleteField()}

        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button
            sx={{ minWidth: '275px', flex: 'none' }}
            variant="outlined"
            onClick={handleCloseModal}
            disabled={isProcessing}
          >
            {t('common:labelCancel')}
          </Button>
          <Button
            sx={{ minWidth: '275px', flex: 'none' }}
            variant="contained"
            color="error"
            data-cy="edit-attached-resolution-modal_ok-button"
            disabled={isProcessing || !hasUserTypedDeleteConfirmation}
            onClick={handleSetResolutionToDraft}
          >
            {t(keyPrefix + '.labelEdit')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
