import { useLocale, usePermissions } from '@koopajs/react';
import { Box, Typography, Chip, Stack, Tooltip, IconButton } from '@mui/material';
import { MandateStartEndDates } from 'components/Organization/MandateStartEndDates';
import { UserChip } from 'components/UserChip';
import { ICommitteeMember } from 'types';
import { committeeMemberRoleGroups } from 'utils/committeeMembers/committeeMemberRoleGroups';
import { PermissionRoleChip } from '../../PermissionRoleChip';
import EditIcon from '@mui/icons-material/Edit';
import { theme } from 'components/Core/theme';

interface ICommitteeMemberTableRowCellsProps {
  committeeMember: ICommitteeMember;
  editButton?: React.ReactNode;
  handleSetCommitteeMemberEditable: (committeeMemberId: string) => () => void;
}

export const CommitteeMemberTableRowCells: React.FC<ICommitteeMemberTableRowCellsProps> = (props) => {
  const { committeeMember, handleSetCommitteeMemberEditable } = props;

  const { t } = useLocale();

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const sxTableCell = {
    display: 'table-cell',
    borderBottom: `1px solid ${theme.palette.customGrey?.light}`,
    verticalAlign: 'top'
  };

  return (
    <>
      {/* MEMBER */}
      <Box sx={sxTableCell} role="cell">
        <UserChip userId={committeeMember.userId} variant="outlined" />

        <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
          <MandateStartEndDates committeeMember={committeeMember} />
        </Typography>
      </Box>
      {/* TITLES */}
      <Box sx={sxTableCell} role="cell" data-cy="committee-member-row_table-cell-title">
        <Box>
          <Chip
            variant="outlined"
            label={t(`common:committeeMemberTitles.${committeeMember.title}`)}
            sx={{ whiteSpace: 'normal' }}
          />
        </Box>
      </Box>
      {/* CREATE PERMISSIONS CHIPS */}
      <Box sx={sxTableCell} role="cell" data-cy="committee-member-row_table-cell-create">
        <Stack direction="column" spacing={1} alignItems="flex-start">
          {committeeMemberRoleGroups.create.map((role) => {
            const permissionRole = committeeMember.roles?.find((r) => r.role === role);
            if (permissionRole) {
              return <PermissionRoleChip key={permissionRole.role} role={permissionRole.role} />;
            }
          })}
        </Stack>
      </Box>
      <Box sx={sxTableCell} role="cell" data-cy="committee-member-row_table-cell-write">
        {committeeMemberRoleGroups.write.map((role) => {
          const permissionRole = committeeMember.roles?.find((r) => r.role === role);
          if (permissionRole) {
            return <PermissionRoleChip key={permissionRole.role} role={permissionRole.role} />;
          }
        })}
      </Box>
      {/* REVIEW PERMISSIONS CHIPS */}
      <Box sx={sxTableCell} role="cell" data-cy="committee-member-row_table-cell-edit">
        <Stack direction="column" spacing={1} alignItems="flex-start">
          {committeeMemberRoleGroups.edit.map((role) => {
            const permissionRole = committeeMember.roles?.find((r) => r.role === role);
            if (permissionRole) {
              return (
                <PermissionRoleChip
                  key={permissionRole.role}
                  role={permissionRole.role}
                  isOptional={permissionRole.isOptional}
                />
              );
            }
          })}
        </Stack>
      </Box>
      {/* SIGNATURES PERMISSIONS CHIPS */}
      <Box sx={sxTableCell} role="cell" data-cy="committee-member-row_table-cell-sign">
        <Stack direction="column" spacing={1} alignItems="flex-start">
          {committeeMemberRoleGroups.sign.map((role) => {
            const permissionRole = committeeMember.roles?.find((r) => r.role === role);
            if (permissionRole) {
              return (
                <PermissionRoleChip
                  key={permissionRole.role}
                  role={permissionRole.role}
                  isOptional={permissionRole.isOptional}
                />
              );
            }
          })}
        </Stack>
      </Box>
      {isUserWorkspaceOwner && (
        <Box
          sx={{
            ...sxTableCell,
            textAlign: 'right'
          }}
          role="cell"
        >
          <Tooltip title={t('common:labelEdit')}>
            <IconButton
              onClick={
                handleSetCommitteeMemberEditable && handleSetCommitteeMemberEditable(committeeMember.id)
              }
              data-cy="committee-member-row_desktop-edit-button"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  );
};
