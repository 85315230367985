import { Stack, Box, Typography } from '@mui/material';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { CountdownTimer } from 'components/CountdownTimer';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { theme } from 'components/Core/theme';

interface IMeetingInProgressHeader {
  liveMeeting: IUseLiveMeetingResponse;
}

export const MeetingInProgressHeader: React.FC<IMeetingInProgressHeader> = (props) => {
  const { topic, t, meeting } = props.liveMeeting;
  const keyPrefix = 'MeetingStateInProgress';

  return (
    <Box
      data-cy="meeting-in-progress_header"
      sx={{ padding: 3, borderBottom: `1px solid ${theme.palette.customGrey.light}` }}
    >
      <Breadcrumbs
        hierarchyArray={[{ path: '/', text: t('common:navigation.dashboard') }]}
        pageTitle={meeting?.title || ''}
        sx={{ mb: 3, display: { sm: 'none' } }}
      />
      <Stack direction={{ xs: 'column-reverse', sm: 'row' }} sx={{ '*': { minWidth: 0 } }}>
        <Stack sx={{ flexGrow: '1', marginTop: { xs: '24px', sm: 0 } }}>
          <Breadcrumbs
            hierarchyArray={[{ path: '/', text: t('common:navigation.dashboard') }]}
            pageTitle={meeting?.title || ''}
            sx={{ mb: 3, display: { xs: 'none', sm: 'block', md: 'none' } }}
          />

          <Typography
            component="h1"
            variant="h6"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {t('common:boardMinutes')}
          </Typography>
        </Stack>

        {topic?.durationSeconds && (
          <Box sx={{ marginLeft: { sm: '24px' }, flexShrink: 0 }}>
            <CountdownTimer timeSeconds={topic.durationSeconds} key={topic.id} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
