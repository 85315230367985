import React, { useCallback, useState } from 'react';
import { Dialog, ErrorMessage } from '@koopajs/mui';
import { Typography, Button, Box, Container, Stack, Switch, FormControlLabel } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocale, useResourceCreate } from '@koopajs/react';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';
import { IMeeting } from 'types';

interface IDeleteMeeting {
  meeting: IMeeting;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteMeeting: React.FC<IDeleteMeeting> = (props) => {
  const { meeting, isOpen, onClose } = props;

  const { t } = useLocale();
  const history = useHistory();
  const keyPrefix = 'Modals.ModalDeleteMeeting';

  const [isEmailSwitchChecked, setIsEmailSwitchChecked] = useState(false);

  const { createResource: deleteMeeting, errorMessage } = useResourceCreate({
    path: `/meetings/${meeting?.id}/delete`
  });

  const handleChangeEmailSwitch = (event: React.SyntheticEvent, checked: boolean): void => {
    setIsEmailSwitchChecked(checked);
  };

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
    setIsEmailSwitchChecked(false);
  }, []);

  const handleDeleteMeeting = useCallback(async () => {
    const res = await deleteMeeting({ isSendingEmail: isEmailSwitchChecked });

    if (res) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      history.push('/');
    }
  }, [meeting.id, isEmailSwitchChecked]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseModal}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'delete-meeting-dialog' } }}
    >
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 3 }}
      >
        <Typography variant="h6">{t(keyPrefix + '.title')}</Typography>

        <ErrorMessage error={errorMessage} />

        <Typography sx={{ whiteSpace: 'pre-line' }}>{t(keyPrefix + '.description')}</Typography>

        {meeting.invitationSentAt && Boolean(meeting?.participants?.length) && (
          <FormControlLabel
            control={<Switch data-cy="delete-meeting-dialog_add-to-books-switch" />}
            label={t(keyPrefix + '.emailSwitchLabel')}
            checked={isEmailSwitchChecked}
            onChange={handleChangeEmailSwitch}
          />
        )}

        {renderConfirmDeleteField()}

        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={handleCloseModal}>
            {t('common:labelCancel')}
          </Button>
          <Button
            data-cy="delete-meeting-dialog_delete-button"
            color="error"
            sx={{ px: 6.5, mx: 1 }}
            variant="contained"
            onClick={handleDeleteMeeting}
            disabled={!hasUserTypedDeleteConfirmation}
          >
            {t(keyPrefix + '.labelDelete')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
