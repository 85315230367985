import { Container, Link, Typography, Box } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useCallback } from 'react';
import { useSearchParams, useLocale } from '@koopajs/react';

export interface INoResultsResetFiltersProps {
  resetCallback?: () => void;
}

export const NoResultsResetFilters: React.FC<INoResultsResetFiltersProps> = (props) => {
  const { resetCallback } = props;
  const { t } = useLocale();

  const search = useSearchParams();

  const handleResetSearchParams = useCallback((): void => {
    search.setSearchParams({
      query: undefined,
      filters: undefined,
      page: undefined,
      size: undefined,
      sort: undefined,
      view: undefined
    });

    if (resetCallback) resetCallback();
  }, [resetCallback]);

  return (
    <Container sx={{ textAlign: 'center', mt: 8 }}>
      <Box>
        <SearchOffIcon fontSize="large" />
        <Typography variant="h5" gutterBottom>
          {t('Components.NoSearchResult.title')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ mr: 1 }}>{t('Components.NoSearchResult.description')}</Typography>
          <Link
            onClick={handleResetSearchParams}
            component="button"
            variant="body1"
            sx={{ textDecoration: 'none' }}
          >
            {t('Components.NoSearchResult.descriptionButton')}
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
