import { useLocale, User } from '@koopajs/react';
import { List, ListItem, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

interface IUserListProps {
  userIds: string[];
  icon?: React.ReactNode;
  //isAlphabetical?: boolean;
  variant?: 'listItem' | 'text';
  listSx?: SystemStyleObject;
}

export const UserList: React.FC<IUserListProps> = (props) => {
  const { userIds, icon, variant = 'listItem', listSx } = props;

  const { t } = useLocale();

  if (!userIds?.length) {
    return null;
  }

  return (
    <>
      {variant === 'listItem' && (
        <List sx={listSx}>
          {userIds?.map((userId) => {
            return (
              <ListItem disableGutters key={userId} sx={{ pr: 1 }}>
                <User id={userId}>
                  {(user) => {
                    return (
                      <>
                        {icon && icon}
                        <Typography>{user?.username}</Typography>
                      </>
                    );
                  }}
                </User>
              </ListItem>
            );
          })}
        </List>
      )}
      {variant === 'text' &&
        (userIds?.length === 1 ? (
          <User id={userIds[0]}>
            {(user) => {
              return user?.username;
            }}
          </User>
        ) : (
          userIds?.map((userId, index, array) => {
            let separator = ', ';
            if (index === 0) separator = ''; // first
            if (index === array.length - 1) separator = ` ${t('common:and')} `;

            return (
              <>
                {separator}
                <User id={userId} key={userId}>
                  {(user) => {
                    return user?.username;
                  }}
                </User>
              </>
            );
          })
        ))}
    </>
  );
};
