import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { Typography, Button, Box, Container } from '@mui/material';
import exclamationMark from '../../assets/exclamation-mark.png';
import { useComponentVisibility } from '@koopajs/react';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';

interface INoVoteOutcomeModal {
  isOpen: boolean;
  handleOnClose: () => void;
  liveMeeting: IUseLiveMeetingResponse;
  title: React.ReactNode;
  description?: React.ReactNode;
  labelRecord?: React.ReactNode;
  isRecordVoteDialog?: boolean;
}

export const NoVoteOutcomeModal: React.FC<INoVoteOutcomeModal> = (props) => {
  const { isOpen, handleOnClose, liveMeeting, title, description, labelRecord, isRecordVoteDialog } = props;
  const { t, nextTopicId, handleChangeCurrentTopic, handleMeetingPermanentlyFinished } = liveMeeting;

  const keyPrefix = 'Modals.NoVoteOutcomeModal.';

  const handleContinue = useCallback(() => {
    if (nextTopicId) {
      handleChangeCurrentTopic(nextTopicId);
    }
  }, [nextTopicId]);

  const handleEndMeeting = useCallback(async () => {
    await handleMeetingPermanentlyFinished();
  }, []);

  const defaultValues = {
    resolutionOutcome: {
      outcome: 'approved'
    }
  };

  const showDialog = useComponentVisibility('recordVote');

  const handleShowRecordVote = useCallback(() => {
    handleOnClose();
    if (isRecordVoteDialog) {
      showDialog.setVisibleWithContext({ liveMeeting: props.liveMeeting, defaultValues });
    }
  }, [JSON.stringify(props.liveMeeting)]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleOnClose}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'no-vote-outcome-modal' } }}
    >
      <Container sx={{ textAlign: 'center', mt: 4 }}>
        <Box>
          <img src={exclamationMark} srcSet="" loading="lazy" style={{ width: '8%' }} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ m: 2 }}>
            {title}
          </Typography>
          {description && <Typography sx={{ m: 2 }}>{description}</Typography>}
        </Box>
        {/* Letting the user go to the next topic */}
        {nextTopicId ? (
          <Button
            sx={{ px: 8, mx: 1 }}
            variant="outlined"
            onClick={handleContinue}
            data-cy="no-vote-outcome-modal_skip-button"
          >
            {t(keyPrefix + 'labelSkip')}
          </Button>
        ) : (
          <Button
            sx={{ px: 8, mx: 1 }}
            variant="outlined"
            onClick={handleEndMeeting}
            data-cy="no-vote-outcome-modal_end-button"
          >
            {t('MeetingStateInProgress.labelEndMeeting')}
          </Button>
        )}

        {/* Showing the record vote dialog */}
        <Button
          sx={{ px: 8, mx: 1 }}
          variant="contained"
          onClick={handleShowRecordVote}
          data-cy="no-vote-outcome-modal_record-vote-button"
        >
          {labelRecord}
        </Button>
      </Container>
    </Dialog.View>
  );
};
