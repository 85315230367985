import { parseUTCDateTime } from './parseUTCDateTime';

export interface ITransformMinutesTimestampsProps {
  minutesStartedAt?: string;
  minutesEndedAt?: string;
  minutesStartedAtOverwrittenTime?: string;
  minutesEndedAtOverwrittenTime?: string;
}

export interface ITransformMinutesTimestampsResponse {
  minutesStartedAtOverwritten?: Date;
  minutesEndedAtOverwritten?: Date;
}

export const transformMinutesTimestamp = (
  props: ITransformMinutesTimestampsProps
): ITransformMinutesTimestampsResponse => {
  const { minutesStartedAt, minutesEndedAt, minutesStartedAtOverwrittenTime, minutesEndedAtOverwrittenTime } =
    props;
  const meetingUpdates: { minutesStartedAtOverwritten?: Date; minutesEndedAtOverwritten?: Date } = {};

  if (minutesStartedAt && minutesStartedAtOverwrittenTime) {
    meetingUpdates.minutesStartedAtOverwritten = parseUTCDateTime(
      minutesStartedAt,
      minutesStartedAtOverwrittenTime
    );
  }

  if (minutesEndedAt && minutesEndedAtOverwrittenTime) {
    meetingUpdates.minutesEndedAtOverwritten = parseUTCDateTime(
      minutesEndedAt,
      minutesEndedAtOverwrittenTime
    );
  }

  return meetingUpdates;
};
