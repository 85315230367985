import { IDocument } from 'types';
import { useCallback } from 'react';
import { Button, IconButton, Tooltip, Box } from '@mui/material';
import {
  FilePresent as FilePresentIcon,
  DeleteOutline as DeleteOutlineIcon,
  Close as CloseIcon,
  // Link as LinkIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useLocale, useResourceDelete } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { SystemStyleObject } from '@mui/system';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
interface IDocumentProps {
  document: IDocument;
  isDeletable?: boolean;
  isViewPrint?: boolean;
  path: string;
  setIsProcessing?: (isProcessing: boolean) => void;
  isProcessing: boolean;
  sx?: SystemStyleObject;
}

export const Document: React.FC<IDocumentProps> = (props) => {
  const {
    isDeletable,
    document,
    isViewPrint,
    path,
    setIsProcessing,
    isProcessing: isProcessingOtherDocuments,
    sx
  } = props;

  const isUrl = document?.attachedFile?.format === 'url';

  const keyPrefix = 'MeetingEdit';
  const { t } = useLocale();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const deleteDocument = useResourceDelete({
    path: path,
    id: document.id
  });

  const handleCloseSnackbar = useCallback((key: any) => {
    return () => {
      closeSnackbar(key);
    };
  }, []);

  const handleDeleteDocument = useCallback(async () => {
    if (setIsProcessing) setIsProcessing(true);

    const res = await deleteDocument.deleteResource();

    if (res) {
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteSuccess'), {
        variant: 'success',
        action: (key: any) => (
          <IconButton size="small" onClick={handleCloseSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
    } else {
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteFail'), {
        variant: 'error',
        action: (key: any) => (
          <IconButton size="small" onClick={handleCloseSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-delete-fail');
    }
    if (setIsProcessing) setIsProcessing(false);
  }, []);

  return (
    <Box sx={{ maxWidth: '100%', ...sx }} data-cy="document-view-button-container">
      <Tooltip title={t(keyPrefix + '.viewFileTooltip')}>
        <Button
          sx={{
            textTransform: 'none',
            color: isViewPrint ? 'inherit' : 'link.main',
            p: 0.2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
          component="a"
          target="_blank"
          rel="noopener"
          data-cy="document-view-button"
          href={isUrl ? document.attachedFile.uri : `/documents/${document.id}`}
        >
          {isUrl ? (
            <OpenInNewIcon />
          ) : (
            <RenderDocumentIcon fileType={document.attachedFile.format} sx={{ opacity: 1 }} />
          )}

          <Box
            sx={{
              ml: 1,
              maxWidth: '500px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '16px'
            }}
          >
            {document?.attachedFile?.name || document?.attachedFile?.uri}
          </Box>
        </Button>
      </Tooltip>
      {isDeletable && (
        <Tooltip title={t('common:labelDelete')}>
          <IconButton
            onClick={handleDeleteDocument}
            data-cy="document-delete-button"
            disabled={isProcessingOtherDocuments}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
