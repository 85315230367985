import { useLocale } from '@koopajs/react';
import { Stack } from '@mui/material';
import { CommitteeTypeFacetSidebar } from '../Facets/CommitteeTypeFacetSidebar';
import { DateRangeFacetSidebar } from '../Facets/DateRangeFacetSidebar';
import { SortBySidebar } from '../Facets/SortBySidebar';

export const FacetListPastMeetingsSidebar: React.FC = () => {
  const path = '/past-meetings';

  const { t } = useLocale();

  return (
    <Stack spacing={3}>
      <CommitteeTypeFacetSidebar path={path} />

      <DateRangeFacetSidebar path={path} facetKey="startDateTime" />

      <SortBySidebar
        sortOptions={[
          { key: 'startDateTime', direction: 'asc', label: t('Sidebars.SortBy.labelOldest') },
          { key: 'startDateTime', direction: 'desc', label: t('Sidebars.SortBy.labelNewest') }
        ]}
      />
    </Stack>
  );
};
