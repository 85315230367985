import { useComponentVisibility, useLocale } from '@koopajs/react';
import { Box, IconButton, Drawer } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Logo } from '../Logo';
import { Link } from 'react-router-dom';
import { CreateButton } from 'components/CreateButton';
import { NavLinks } from './NavLinks';
import { useScreenSize } from 'utils/useScreenSize';
import { useCheckIfHidingNavAndSidebar } from 'components/hooks/useCheckIfHidingNavAndSidebar';

export const NavDrawer: React.FC = () => {
  const navMenu = useComponentVisibility('navMenu');
  const { isScreenAboveLg } = useScreenSize();
  const { t } = useLocale();

  const isMainNavAndSidebarHidden = useCheckIfHidingNavAndSidebar();

  return (
    <Drawer
      anchor={'left'}
      open={navMenu.isVisible}
      onClose={navMenu.setHidden}
      PaperProps={{
        sx: {
          width: { xs: '100%', lg: '230px' },
          position: { lg: 'absolute' }
        }
      }}
      sx={{
        width: '230px',
        height: '100%',
        position: { lg: 'relative' },
        display: isMainNavAndSidebarHidden ? 'none' : undefined
      }}
      variant={isScreenAboveLg ? 'permanent' : 'temporary'}
    >
      <Box data-cy="nav-drawer-box">
        <Box
          sx={{
            display: { xs: 'flex', lg: 'none' },
            height: '64px',
            minHeight: '64px',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightGrey',
            px: 2
          }}
        >
          <Link to="/" onClick={navMenu.setHidden} style={{ textDecoration: 'none' }}>
            <Logo />
          </Link>
          <IconButton aria-label={t('common:close')} onClick={navMenu.setHidden} data-cy="mobile-nav-close">
            <ClearIcon />
          </IconButton>
        </Box>

        <Box sx={{ py: 3 }} component="nav">
          <Box sx={{ px: 2 }}>
            <CreateButton sxButton={{ width: '100%' }} isDividerVisible sxDivider={{ my: 3 }} />
          </Box>

          <NavLinks />
        </Box>
      </Box>
    </Drawer>
  );
};
