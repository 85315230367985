import React from 'react';
import { ICoreProps } from '@koopajs/mui';
//import { Form } from '@koopajs/mui';
import { RegisterOptions } from 'react-hook-form';
import { TextField } from 'components/temp/TextFieldTemp';
import { InputProps, InputBaseComponentProps } from '@mui/material';

export interface IDateTimePickerProps extends ICoreProps {
  name: string;
  isOptional?: boolean;
  isAutoFocus?: boolean;
  type?: 'date' | 'time' | 'datetime-local';
  validationRules?: RegisterOptions;
  InputProps?: InputProps;
  inputProps?: InputBaseComponentProps;
}

export const DateTimePicker: React.FC<IDateTimePickerProps> = (props) => {
  const {
    name,
    isOptional,
    isDisabled,
    isLoading,
    isAutoFocus,
    validationRules,
    type = 'datetime-local',
    i18n,
    sx,
    InputProps,
    inputProps
  } = props;

  return (
    <TextField
      testId={`koopa_text-field_${props.name}`}
      InputProps={InputProps}
      inputProps={inputProps}
      type={type}
      name={name}
      isAutoFocus={isAutoFocus}
      isOptional={isOptional}
      isDisabled={isDisabled || isLoading}
      validationRules={validationRules}
      sx={sx}
      InputLabelProps={{
        shrink: true
      }}
      i18n={{
        keyPrefix:
          i18n?.keyPrefix ||
          {
            date: 'FormDatePicker',
            time: 'FormTimePicker',
            'datetime-local': 'FormDateTimePicker'
          }[type]
      }}
    />
  );
};
