import { QuorumFields } from './QuorumFields';
import { NominationsFields } from './NominationsFields';
import { AgendaApprovalFields } from './AgendaApprovalFields';
import { MiscellaneousFields } from './MiscellaneousFields';
import { CeoReportFields } from './CeoReportFields';
import { PastMinutesApprovalFields } from './PastMinutesApproval';
import { ResolutionFields } from './ResolutionFields';
import { InCameraFields } from './InCameraFields';
import { GenericFields } from './GenericFields';
import { AdjournmentFields } from './AdjournmentFields';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';

interface IMeetingInProgressForm {
  liveMeeting: IUseLiveMeetingResponse;
}

export const MeetingInProgressForm: React.FC<IMeetingInProgressForm> = ({ liveMeeting }) => {
  const { currentTopicId } = liveMeeting;

  const props = {
    liveMeeting,
    key: currentTopicId
  };

  switch (liveMeeting.topic?.type) {
    case 'quorum':
      return <QuorumFields {...props} />;
    case 'nominations':
      return <NominationsFields {...props} />;
    case 'agendaApproval':
      return <AgendaApprovalFields {...props} />;
    case 'pastMinutesApproval':
      return <PastMinutesApprovalFields {...props} />;
    case 'ceoReport':
      return <CeoReportFields {...props} />;
    case 'information':
      return <GenericFields {...props} />;
    case 'discussion':
      return <GenericFields {...props} />;
    case 'resolution':
      return <ResolutionFields {...props} />;
    case 'miscellaneous':
      return <MiscellaneousFields {...props} />;
    case 'inCamera':
      return <InCameraFields {...props} />;
    case 'adjournment':
      return <AdjournmentFields {...props} />;
    default:
      return null;
  }
};
