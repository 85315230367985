import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { Typography, Button, Box, Stack, Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocale, useResourceUpdate } from '@koopajs/react';
import { IMeeting } from 'types';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import image from '../../assets/dashboard/note-taking.svg';
import { TimeStartEnd } from '../TimeStartEnd';
import { formatDate } from 'utils/DateTime/formatDate';

interface IStartMeeting {
  meeting: IMeeting;
  userId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export const StartMeeting: React.FC<IStartMeeting> = (props) => {
  const { meeting, isOpen, onClose } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'Modals.ModalStartMeeting';

  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { updateResource } = useResourceUpdate({
    path: `/meetings/${meeting.id}/start`,
    id: '',
    customReducer: {
      path: {
        resourceType: '/meetings',
        resourceId: meeting.id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IMeeting;
      }
    }
  });

  const handleUpdateMeetingTimeline = useCallback(async () => {
    //const res = await Api.client.put(`/meetings/${meeting.id}/start`);

    const res = await updateResource({});

    if (res) {
      history.push(`/meetings/${meeting.id}/topics/${meeting.currentTopicId || meeting?.topics?.[0].id}`);
    } else {
      enqueueSnackbar(t(keyPrefix + '.snackbarError'), {
        variant: 'error',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
    }
  }, [meeting]);

  const hasMeetingStarted = meeting?.minutesStartedAt;

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={onClose}
      forcedFullScreen={false}
      maxWidth="md"
      dialogProps={{ className: 'rr-block' }}
    >
      <Stack sx={{ textAlign: 'center', alignItems: 'center' }}>
        <img src={image} srcSet="" loading="lazy" style={{ width: '100px' }} />
        <Typography variant="h6" sx={{ m: 2, maxWidth: '380px' }}>
          {!hasMeetingStarted ? t(keyPrefix + '.titleStart') : t(keyPrefix + '.titleContinue')}
        </Typography>
        <Alert
          severity="info"
          icon={false}
          sx={{ textAlign: 'center', width: '462px', justifyContent: 'center' }}
        >
          <Typography variant="subtitle2">{meeting.title}</Typography>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            <Box component="span" sx={{ display: 'block' }}>
              {meeting?.startDateTime &&
                formatDate({
                  isoString: meeting.startDateTime,
                  locale,
                  format: 'DATE_FULL'
                })}
            </Box>
            <Box component="span" sx={{ display: 'block' }}>
              <TimeStartEnd isoStringStart={meeting.startDateTime} isoStringEnd={meeting.endDateTime} />
            </Box>
          </Typography>
        </Alert>
        <Box sx={{ mt: 6 }}>
          <Button sx={{ px: 7, mx: 1 }} variant="outlined" onClick={onClose}>
            {t('common:labelCancel')}
          </Button>
          <Button
            sx={{ px: 6.5, mx: 1 }}
            variant="contained"
            onClick={handleUpdateMeetingTimeline}
            data-cy="start-meeting-modal_start-button"
          >
            {!hasMeetingStarted ? t('common:start') : t('common:continue')}
          </Button>
        </Box>
      </Stack>
    </Dialog.View>
  );
};
