import { useLocale } from '@koopajs/react';
import { Stack, Chip, Typography, Box } from '@mui/material';
import { InCameraField } from './InCameraField';
import { NominationsField } from './NominationsField';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { ITopicCardProps } from 'types';
import { formatTime } from 'utils/DateTime/formatTime';
import { DocumentList } from 'components/DocumentList';
import { ApprovedMinutesList } from 'components/Minutes/ApprovedMinutesList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';

interface ITopicCardMinutesPrintProps extends ITopicCardProps {
  isReview?: boolean;
}

export const TopicCardMinutesPrint: React.FC<ITopicCardMinutesPrintProps> = (props) => {
  const { topic, order, meeting, isReview } = props;

  const { t, locale } = useLocale();

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  let adjournmentAt = meeting?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }
  return (
    <Box sx={{ p: 3 }}>
      {/* TODO! : Logo should be displayed here is there is one */}
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly" flexGrow={1}>
            {/* ORDER & TITLE */}
            <Chip label={order} variant="outlined" />

            <Typography sx={{ width: '100%', fontWeight: 'bold' }}>
              <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
            </Typography>
          </Stack>
        </Stack>

        {/* QUORUM  */}
        {topic.type === 'quorum' && (
          <Box>
            {meeting?.minutesStartedAt && (
              <Typography>
                {t('MeetingStateReview.meetingStarted', {
                  time: formatTime({ isoString: meeting.minutesStartedAt, locale })
                })}
              </Typography>
            )}
            {topic.quorumReachedAt && (
              <Typography>
                {t('MeetingStateReview.quorumReached', {
                  time: formatTime({ isoString: topic.quorumReachedAt, locale })
                })}
              </Typography>
            )}
          </Box>
        )}
        {/* NOMINATIONS */}
        {topic.type === 'nominations' && <NominationsField topic={topic} />}

        {/* BODY FIELD */}
        {[
          'quorum',
          'nominations',
          'agendaApproval',
          'pastMinutesApproval',
          'ceoReport',
          'information',
          'discussion',
          'resolution',
          'miscellaneous',
          'adjournment'
        ].includes(topic.type) && (
          <>
            {topic.notes && (
              <RichTextReadOnly
                value={topic.notes}
                sxEditorContainer={{
                  py: 2
                }}
              />
            )}
          </>
        )}

        {/* PAST MINUTES THAT HAVE BEEN APPROVED */}
        {topic.type === 'pastMinutesApproval' && (
          <ApprovedMinutesList approvedMinutes={topic.pastMeetingsToApprove} isViewPrint={true} />
        )}

        {/* ADJOURNMENT */}

        {topic.type === 'adjournment' && adjournmentAt ? (
          <Box>
            <Typography>
              {t('MeetingStateReview.adjournmentAt', {
                time: formatTime({ isoString: adjournmentAt, locale })
              })}
            </Typography>
          </Box>
        ) : null}

        {/* RESOLUTION OUTCOME */}
        {['nominations', 'agendaApproval', 'resolution', 'pastMinutesApproval', 'adjournment'].includes(
          topic.type
        ) &&
          topic?.resolutionOutcome?.outcome && (
            <div style={{ marginTop: '12px', marginBottom: '12px' }}>
              <Stack spacing={3}>
                <Box>
                  <ResolutionOutcomeText topic={topic} />
                </Box>
                {showResolutionOutcomeVotes && <ResolutionOutcomeVotes topic={topic} />}
              </Stack>
            </div>
          )}
      </Stack>
    </Box>
  );
};
