import React, { useCallback, useEffect, useState } from 'react';
import { useForm, WatchObserver } from 'react-hook-form';
import { FormContext } from '@koopajs/react/dist/FormContext';

export interface IFormControllerTempProps {
  onSubmit: (resource: { [k: string]: unknown }, e?: React.BaseSyntheticEvent) => Promise<boolean>;
  watch?: WatchObserver<Record<string, unknown>>;
  defaultValues?: { [k: string]: unknown };
  errorMessage?: string;
  isProcessing?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onSubmitSuccessResetType?:
    | 'DEFAULT_VALUES' // form resets to default values passed into form controller
    | 'FORM_PAYLOAD' // form resets to the payload of the submitted data
    | 'NO_RESET'; // form doesn't reset
  testId?: string;
  formProps?: Record<string, unknown>;
}

export const FormControllerTemp: React.FC<IFormControllerTempProps> = (props) => {
  const {
    children,
    style,
    onSubmit,
    defaultValues,
    errorMessage,
    watch,
    isProcessing: parentIsProcessing,
    onSubmitSuccessResetType = 'DEFAULT_VALUES',
    testId,
    formProps
  } = props;

  const [isProcessing, setIsProcessing] = useState(false);

  const form = useForm({
    // @ts-ignore
    defaultValues
  });

  useEffect(() => {
    if (watch) {
      const subscription = form.watch(watch);
      return () => subscription.unsubscribe();
    }
  }, [watch, form.watch]);

  const handleSubmit = useCallback(
    async (e: React.BaseSyntheticEvent): Promise<void> => {
      setIsProcessing(true);

      await form.handleSubmit(async (data, e) => {
        const isSuccess = await onSubmit(data, e);
        if (isSuccess) {
          if (onSubmitSuccessResetType === 'DEFAULT_VALUES') {
            form.reset();
          } else if (onSubmitSuccessResetType === 'FORM_PAYLOAD') {
            form.reset(data);
          }
        }
      })(e);

      setIsProcessing(false);
    },
    [form.handleSubmit, onSubmit]
  );

  return (
    <FormContext.Provider value={{ form, errorMessage, isProcessing: parentIsProcessing || isProcessing }}>
      <form onSubmit={handleSubmit} style={style} data-cy={testId} {...formProps}>
        {children}
      </form>
    </FormContext.Provider>
  );
};
