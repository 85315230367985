// TODO: CAN BE REMOVED WHEN https://github.com/iwnstudio/koopa/pull/478 MERGES
import React, { useCallback } from 'react';
import { useLocale, useUserShow } from '@koopajs/react';
import { Settings, ICoreProps } from '@koopajs/mui';
import { Box } from '@mui/material';

export interface ILocaleSwitcherProps extends ICoreProps {}

export const LocaleSwitcher: React.FC<ILocaleSwitcherProps> = (props) => {
  const { sx, i18n } = props;
  const { locale, availableLocales, setLocale } = useLocale();

  const { user } = useUserShow();
  const userLocale = user?.locale;

  const coreKeyPrefix = 'SettingsLocaleSwitcher';
  const keyPrefix = i18n?.keyPrefix || coreKeyPrefix;

  const handleLanguageChange = useCallback(async (data: { [k: string]: unknown }): Promise<boolean> => {
    await setLocale(data.language as string);
    return true;
  }, []);

  const filteredLocales = availableLocales.filter((item, i) => {
    return availableLocales.indexOf(item) === i;
  });

  const localeLabels: { [k: string]: string } = {
    en: 'English',
    'en-US': 'English (US)',
    'en-CA': 'English (CA)',
    'en-GB': 'English (GB)',
    fr: 'Français',
    'fr-CA': 'Français (CA)',
    'pl-PL': 'Polski (PL)',
    'es-ES': 'Español (ES)',
    emoji: 'Emoji'
  };

  return (
    <Box sx={{ py: 2, ...sx }}>
      <Settings.FieldEditor
        uniqueDialogId="LocaleSwitcher"
        value={localeLabels[locale]}
        fields={filteredLocales.map((locale) => {
          return {
            key: locale,
            label: localeLabels[locale] || locale
          };
        })}
        defaultValues={{ language: userLocale }}
        radioFieldName="language"
        inputType="radioButton"
        handleValueChange={handleLanguageChange}
        i18n={{ keyPrefix: `${keyPrefix}.SettingsFieldEditor` }}
      />
    </Box>
  );
};
