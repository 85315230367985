import { ITopic } from 'types';
import { BoardMemberViewTopicCardAccordion } from './Components/Accordion';
import { BoardMemberViewTopicCardAccordionSummaryChildren } from './Components/AccordionSummaryChildren';

interface IBoardMemberViewInCameraCard {
  topic: ITopic;
  index: number;
}

export const BoardMemberViewInCameraCard: React.FC<IBoardMemberViewInCameraCard> = (props) => {
  const { topic, index } = props;

  return (
    <BoardMemberViewTopicCardAccordion
      topic={topic}
      index={index}
      isDisabled={true}
      accordionSummary={<BoardMemberViewTopicCardAccordionSummaryChildren topic={topic} index={index} />}
      accordionDetails={<></>}
    />
  );
};
