import { useState, useCallback } from 'react';
import {
  Button,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Link,
  Alert,
  Collapse,
  InputAdornment,
  Switch,
  FormControlLabel
} from '@mui/material';
import { Edit as EditIcon, WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { useLocale, useResourceUpdate } from '@koopajs/react';
import { NominationsField } from './NominationsField';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { ITopicCardProps, ICommittee, ITopic } from 'types';
import { useTopic } from 'components/hooks/useTopic';
import { formatTime } from 'utils/DateTime/formatTime';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { DocumentList } from 'components/DocumentList';
import { ApprovedMinutesList } from 'components/Minutes/ApprovedMinutesList';
import { ResolutionOutcomeIcon } from 'components/ResolutionOutcome/ResolutionOutcomeIcon';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { TextField } from 'components/temp/TextFieldTemp';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { checkIsGenericTopicType, AllTopicType, checkIsDecisionTopicType } from 'utils/topicTypeArrays';
import { transformMinutesTimestamp } from 'utils/transformMinutesTimestamp';
import _ from 'lodash';
import { DateTimePickerWithButton } from 'components/temp/DateTimePickerWithButton';
import { ErrorMessage } from '@koopajs/mui';
import { PastMeetingsApprovedList } from './PastMinutesApprovalField/PastMeetingsApprovedList';
import { theme } from 'components/Core/theme';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { DeleteReviewTopic } from 'components/Modals/DeleteReviewTopic';
import { useFeatureGate } from '@statsig/react-bindings';
import { EditVoteOutcome } from './EditVoteOutcome';
import { IUserPublicProfile } from '@koopajs/app';
import { RecordVoteDialogOptions } from 'components/Dialogs/RecordVote/RecordVoteDialogOptions';

export interface ITopicCardMinutesProps extends ITopicCardProps {
  isMeeting?: boolean;
  isCustomResNum?: boolean;
  isBeingReviewedInsideMeeting?: boolean;
  isBrandNewTopic?: boolean;
  users?: IUserPublicProfile[];
  committee?: ICommittee;
  participants?: IUserPublicProfile[];
}

interface IFormData {
  visibleBy: { [k: string]: string };
  pastMeetingsToApprove: { [k: string]: string };
  notes: string;
  minutesStartedAtOverwrittenTime: string;
  minutesEndedAtOverwrittenTime: string;
  quorumReachedAtOverwrittenTime: string;
  quorumReachedAtOverwritten: string;
  type: AllTopicType;
  resolutionOutcome?: ITopic['resolutionOutcome'];
}

export const TopicCardMinutes: React.FC<ITopicCardMinutesProps> = (props) => {
  const {
    topic,
    order,
    isEditable,
    handleSetTopicEditable,
    userHasEditAccess,
    onSubmitSuccess,
    meeting,
    isMeeting,
    isBeingReviewedInsideMeeting,
    sx,
    isBrandNewTopic,
    users,
    committee,
    participants
  } = props;
  const { title, assignedTo, durationSeconds, type, notes, visibleBy, resolutionNumber } = topic;

  const isCustomResNum = committee?.resolutionNumberFormat === 'custom';
  const [isDeleteTopicModalOpen, setIsDeleteTopicModalOpen] = useState(false);

  const { value: isFullTopicEditEnabled } = useFeatureGate('minutes_review_topics');

  const handleOpenDeleteTopicModal = useCallback(() => {
    setIsDeleteTopicModalOpen(true);
  }, []);
  const handleCloseDeleteTopicModal = useCallback(() => {
    setIsDeleteTopicModalOpen(false);
  }, []);

  const [isMinutesDocumentAlertDismissed, setIsMinutesDocumentAlertDismissed] = useState(
    localStorage.getItem('isMinutesDocumentAlertDismissed') === 'true'
  );

  const handleCloseDocumentAlert = useCallback(() => {
    localStorage.setItem('isMinutesDocumentAlertDismissed', 'true');
    setIsMinutesDocumentAlertDismissed(true);
  }, []);

  const { onSubmit } = useTopic({
    topicId: topic.id,
    meetingId: topic.meetingId,
    isMeetingStarted: true
  });

  const { updateResource: updateMeeting, errorMessage } = useResourceUpdate({
    path: '/meetings',
    id: meeting?.id as string
  });

  // this is for a decision topic that is brand new only
  const [isUnanimousSelected, setIsUnanimousSelected] = useState(true);

  const [isDecisionTopicSwitchChecked, setIsDecisionTopicSwitchChecked] = useState(false);
  const handleIsDecisionTopicSwitch = useCallback((event: React.SyntheticEvent, checked: boolean): void => {
    setIsDecisionTopicSwitchChecked(checked);
  }, []);

  const handleOnSubmit = useCallback(
    async (formData: object) => {
      const formDataTyped = formData as IFormData;

      // switch to resolution if user checked the switch and the topic is a brand new generic topic
      const isGenericTopicType = checkIsGenericTopicType(formDataTyped.type || topic.type);
      if (isDecisionTopicSwitchChecked && isBrandNewTopic && isGenericTopicType) {
        formDataTyped.type = 'resolution';
        if (formDataTyped.resolutionOutcome) {
          // handle ask for vote switch
          if (isUnanimousSelected) {
            formDataTyped.resolutionOutcome.isUnanimous = true;
            delete formDataTyped.resolutionOutcome.membersFor;
            delete formDataTyped.resolutionOutcome.membersAgainst;
            delete formDataTyped.resolutionOutcome.membersAbstained;
          } else {
            formDataTyped.resolutionOutcome.isUnanimous = false;
          }

          // set recordedAt to the end of the meeting
          if (meeting?.minutesEndedAt) formDataTyped.resolutionOutcome.recordedAt = meeting.minutesEndedAt;
        }
      }

      const isDecisionTopicType = checkIsDecisionTopicType(formDataTyped.type || topic.type);

      const topicUpdates = _.omit(formDataTyped, [
        'minutesStartedAtOverwrittenTime',
        'minutesEndedAtOverwrittenTime',
        // removes resolution attributes if user switched away from resolution when creating a brand new generic topic
        ...(!isDecisionTopicType ? ['isResolution', 'resolutionOutcome', 'resolutionNumber'] : [])
      ]);

      let updateMeetingResponse = true;
      if (topic.type === 'quorum' || topic.type === 'adjournment') {
        const meetingUpdates = transformMinutesTimestamp({
          minutesStartedAtOverwrittenTime: formDataTyped.minutesStartedAtOverwrittenTime,
          minutesStartedAt: meeting?.minutesStartedAt as string,
          minutesEndedAtOverwrittenTime: formDataTyped.minutesEndedAtOverwrittenTime,
          minutesEndedAt: meeting?.minutesEndedAt as string
        });
        updateMeetingResponse = await updateMeeting(meetingUpdates);
      }
      const updateTopicResponse = await onSubmit(topicUpdates);

      if (updateMeetingResponse && updateTopicResponse && onSubmitSuccess) {
        onSubmitSuccess(); // close edit mode
      }

      return Boolean(updateTopicResponse && updateMeetingResponse);
    },
    [
      updateMeeting,
      onSubmit,
      meeting?.id,
      onSubmitSuccess,
      topic.id,
      topic.type,
      isBrandNewTopic,
      isDecisionTopicSwitchChecked,
      isUnanimousSelected
    ]
  );

  const { t, locale } = useLocale();
  const keyPrefix = 'Components.TopicCard';

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = Boolean(
    membersFor?.length || membersAbstained?.length || membersAgainst?.length
  );

  const isReviewMode = isMeeting;

  const visibleByObject = visibleBy?.reduce((acc: { [k: string]: boolean }, id, i) => {
    acc[id] = true;
    return acc;
  }, {});

  const defaultValues = {
    // ...topic
    title,
    resolutionNumber,
    assignedTo,
    durationSeconds,
    type,
    notes,
    visibleBy: visibleByObject,
    ...(type === 'quorum'
      ? {
          minutesStartedAtOverwrittenTime: meeting?.minutesStartedAt
            ? DateTime.fromISO(meeting?.minutesStartedAt).toFormat('HH:mm')
            : '',
          quorumReachedAtOverwrittenTime: topic.quorumReachedAt
            ? DateTime.fromISO(topic.quorumReachedAt).toFormat('HH:mm')
            : ''
        }
      : {}),
    ...(type === 'adjournment'
      ? {
          minutesEndedAtOverwrittenTime: meeting?.minutesEndedAt
            ? DateTime.fromISO(meeting?.minutesEndedAt).toFormat('HH:mm')
            : ''
        }
      : {})
  };

  let adjournmentAt = meeting?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }
  const hasDocuments = topic.documentsIds && topic.documentsIds.length > 0;
  const notDeletableTopicTypes: AllTopicType[] = [
    'quorum',
    'nominations',
    'agendaApproval',
    'pastMinutesApproval',
    'inCamera'
  ];
  const isDeletableTopic = isFullTopicEditEnabled && !notDeletableTopicTypes.includes(topic.type);

  const isGenericTopicType = checkIsGenericTopicType(topic.type);

  const renderEditSaveDeleteButton = () => {
    if (!userHasEditAccess || topic.type === 'inCamera') return null;

    return (
      <Stack direction="row">
        <>
          {isEditable ? (
            <>
              {isDeletableTopic && (
                <Tooltip title={t('common:labelDelete')}>
                  <IconButton onClick={handleOpenDeleteTopicModal} data-cy="topic-card-minutes_delete-button">
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Button
                type="submit"
                variant="contained"
                sx={{ ml: 2 }}
                data-cy="topic-card-minutes_save-button"
              >
                {t('common:labelSave')}
              </Button>
            </>
          ) : (
            <Tooltip title={t('common:labelEdit')}>
              <IconButton
                onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                data-cy="topic-card-minutes_edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      </Stack>
    );
  };

  return (
    <>
      {/* DELETE TOPIC MODAL */}
      {isEditable && isDeletableTopic && (
        <DeleteReviewTopic
          topic={topic}
          isOpen={isDeleteTopicModalOpen}
          onClose={handleCloseDeleteTopicModal}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
      <Box
        sx={{ px: 3, py: 2, ...sx }}
        data-cy="topic-card-minutes"
        className={`topic-card-minutes_${topic.type}${isEditable ? ' is-editable' : ''}`}
      >
        <FormControllerTemp
          onSubmit={handleOnSubmit}
          defaultValues={defaultValues}
          onSubmitSuccessResetType="FORM_PAYLOAD"
        >
          <ErrorMessage error={errorMessage} />
          {/* RESOLUTION - Not displayed on print view */}
          {['nominations', 'agendaApproval', 'resolution', 'pastMinutesApproval', 'adjournment'].includes(
            topic.type
          ) &&
            topic?.resolutionOutcome?.outcome &&
            topic?.isResolution && (
              <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography
                    sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '12px', opacity: 0.8 }}
                    data-cy="topic-card-minutes_resolution-number"
                  >
                    {t('common:resolution_one')}
                    {topic.resolutionNumber ? ` #${topic.resolutionNumber}` : ''}
                  </Typography>
                  {/* ADDING CUSTOM RESOLUTION NUMBER */}
                  {isCustomResNum &&
                    userHasEditAccess &&
                    (isEditable ? (
                      <TextField
                        i18n={{ keyPrefix: keyPrefix + '.ResolutionNumberField' }}
                        name="resolutionNumber"
                        validationRules={{ maxLength: 250 }}
                        isOptional
                      />
                    ) : (
                      !topic.resolutionNumber && (
                        <Link
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: 'link.main',
                            textTransform: 'none',
                            fontSize: '1rem',
                            fontWeight: '400',
                            textDecoration: 'none',
                            mt: 1
                          }}
                          onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                          component="button"
                          data-cy="topic-card-minutes_add-resolution-number-button"
                        >
                          <WarningAmberIcon sx={{ mr: 1 }} />
                          {t(keyPrefix + '.customResolutionNumberWarning')}
                        </Link>
                      )
                    ))}
                </Box>
                {/* EDIT AND DELETE BUTTON - resolution topic */}
                {renderEditSaveDeleteButton()}
              </Box>
            )}
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-evenly"
                flexGrow={1}
              >
                {/* ORDER & TITLE */}
                {isEditable && checkIsGenericTopicType(topic.type) ? (
                  <TextField
                    i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
                    name="title"
                    validationRules={{ maxLength: 150 }}
                    sx={{ width: '100%' }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{order}.</InputAdornment>
                    }}
                  />
                ) : (
                  <Typography sx={{ width: '100%', fontWeight: 'bold' }} data-cy="topic-card-minutes_title">
                    <>
                      {order}. {topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}
                    </>
                  </Typography>
                )}
              </Stack>

              {/* resolution buttons are above in code */}
              {!topic.isResolution && renderEditSaveDeleteButton()}
            </Stack>
            {/* QUORUM  */}
            {topic.type === 'quorum' && (
              <Box>
                {/* Edit minutesStartedAt */}
                {meeting?.minutesStartedAt && isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: { sm: 3 },
                      flexDirection: { xs: 'column', sm: 'row' },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    <Typography sx={{ width: '165px' }}>
                      {t('MeetingStateReview.minutesStartedAt')}
                    </Typography>
                    <DateTimePickerWithButton
                      dateTimePickerProps={{
                        name: 'minutesStartedAtOverwrittenTime',
                        i18n: { keyPrefix: keyPrefix + '.DateTimePickerMinutesStartedAtField' },
                        type: 'time',
                        validationRules: {
                          pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                        },
                        sx: { width: '200px', mb: 0 }
                      }}
                      originalTimeValue={DateTime.fromISO(meeting?.minutesStartedAtOriginal || '').toFormat(
                        'HH:mm'
                      )}
                    />
                  </Box>
                ) : (
                  <Typography data-cy="topic-card-minutes_minutes-started-at">
                    {t('MeetingStateReview.meetingStarted', {
                      time: formatTime({ isoString: meeting?.minutesStartedAt || '', locale })
                    })}
                  </Typography>
                )}

                {topic.quorumReachedAt ? (
                  isEditable ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: { sm: 3 },
                        flexDirection: { xs: 'column', sm: 'row' },
                        mb: { xs: 1, sm: 0 }
                      }}
                    >
                      <Typography sx={{ width: '165px' }}>
                        {t('MeetingStateReview.quorumReachedAt')}
                      </Typography>
                      <DateTimePickerWithButton
                        dateTimePickerProps={{
                          name: 'quorumReachedAtOverwrittenTime',
                          i18n: { keyPrefix: keyPrefix + '.DateTimePickerQuorumReachedAtField' },
                          type: 'time',
                          validationRules: {
                            pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                          },
                          sx: { width: '200px', mb: 0 }
                        }}
                        originalTimeValue={DateTime.fromISO(topic.quorumReachedAtOriginal || '').toFormat(
                          'HH:mm'
                        )}
                      />
                    </Box>
                  ) : (
                    <Typography data-cy="topic-card-minutes_quorum-reached-at">
                      {t('MeetingStateReview.quorumReached', {
                        time: formatTime({ isoString: topic?.quorumReachedAt, locale })
                      })}
                    </Typography>
                  )
                ) : null}
              </Box>
            )}
            {/* NOMINATIONS */}
            {topic.type === 'nominations' && <NominationsField topic={topic} />}
            {/* PAST MINUTES THAT HAVE BEEN APPROVED */}
            {topic.type === 'pastMinutesApproval' && isReviewMode ? (
              <PastMeetingsApprovedList meetingId={topic.meetingId} topicId={topic.id} topic={topic} />
            ) : (
              <ApprovedMinutesList approvedMinutes={topic.pastMeetingsToApprove} />
            )}
            {/* ADJOURNMENT */}
            {topic.type === 'adjournment' && (
              <Box>
                {meeting?.minutesEndedAt && isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: { sm: 3 },
                      flexDirection: { xs: 'column', sm: 'row' },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    <Typography sx={{ width: '165px' }}>{t('MeetingStateReview.minutesEndedAt')}</Typography>
                    <DateTimePickerWithButton
                      dateTimePickerProps={{
                        name: 'minutesEndedAtOverwrittenTime',
                        i18n: { keyPrefix: keyPrefix + '.DateTimePickerMinutesEndedAtField' },
                        type: 'time',
                        validationRules: {
                          pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                        },
                        sx: { width: '200px', mb: 0 }
                      }}
                      originalTimeValue={DateTime.fromISO(meeting?.minutesEndedAtOriginal || '').toFormat(
                        'HH:mm'
                      )}
                    />
                  </Box>
                ) : adjournmentAt ? (
                  <Typography data-cy="topic-card-minutes_adjournment-at">
                    {t('MeetingStateReview.adjournmentAt', {
                      time: formatTime({
                        isoString: adjournmentAt,
                        locale
                      })
                    })}
                  </Typography>
                ) : null}
              </Box>
            )}
            {/* RESOLUTION OUTCOME */}
            {['nominations', 'agendaApproval', 'resolution', 'pastMinutesApproval', 'adjournment'].includes(
              topic.type
            ) &&
              topic?.resolutionOutcome?.outcome &&
              users &&
              (isEditable ? (
                <EditVoteOutcome
                  topic={topic}
                  meeting={meeting}
                  users={users}
                  isCommitteeTakingResolutions={Boolean(committee?.isTakingResolutions)}
                />
              ) : (
                <Box sx={{ marginTop: '12px', marginBottom: '12px' }}>
                  <Stack spacing={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: isEditable ? `1px solid ${theme.palette.customGrey.light}` : '',
                        borderRadius: '4px',
                        alignItems: 'center',
                        padding: isEditable ? '8px 14px' : ''
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        {topic.isResolution && (
                          <ResolutionOutcomeIcon voteOutcome={topic?.resolutionOutcome?.outcome} />
                        )}

                        <ResolutionOutcomeText topic={topic} />
                      </Box>
                    </Box>
                    {showResolutionOutcomeVotes && <ResolutionOutcomeVotes topic={topic} />}
                  </Stack>
                </Box>
              ))}

            {/* BRAND NEW TOPIC - RESOLUTION SWITCH & VOTE OUTCOME */}
            {isBrandNewTopic && isGenericTopicType && isEditable && participants && users && (
              <>
                <FormControlLabel
                  control={<Switch data-cy="topic-card-minutes_resolution-topic-switch" />}
                  label={t(keyPrefix + '.isBrandNewResolutionLabel')}
                  checked={isDecisionTopicSwitchChecked}
                  onChange={handleIsDecisionTopicSwitch}
                />
                {isDecisionTopicSwitchChecked && (
                  <RecordVoteDialogOptions
                    participants={participants}
                    topic={topic}
                    isCommitteeTakingResolutions={Boolean(committee?.isTakingResolutions)}
                    isUnanimousSelected={isUnanimousSelected}
                    setIsUnanimousSelected={setIsUnanimousSelected}
                    users={users}
                    isResolutionBookSwitchChecked={true}
                  />
                )}
              </>
            )}

            {/* BODY FIELD */}
            {[
              'quorum',
              'nominations',
              'agendaApproval',
              'pastMinutesApproval',
              'ceoReport',
              'information',
              'discussion',
              'resolution',
              'miscellaneous',
              'adjournment'
            ].includes(topic.type) && (
              <>
                {isEditable ? (
                  <Box sx={{ mx: 1 }}>
                    <RichTextMultiLine
                      name="notes"
                      validationRules={{ maxLength: 20000 }}
                      isOptional={true}
                      height="unset"
                      i18n={{ keyPrefix: 'MeetingStateInProgress.RichTextMultiLineNotes' }}
                    />
                  </Box>
                ) : (
                  topic.notes && (
                    <RichTextReadOnly
                      value={topic.notes}
                      style="padding: 16px 0; margin-top: 8px; border-width: 0;"
                    />
                  )
                )}
              </>
            )}
          </Stack>

          {/* DOCUMENTS LIST */}
          {[
            'pastMinutesApproval',
            'ceoReport',
            'information',
            'discussion',
            'resolution',
            'miscellaneous'
          ].includes(topic.type) &&
            (isReviewMode ? (
              //REVIEW - no approved topic
              <Stack sx={{ mt: 1 }} spacing={1}>
                {isEditable && (
                  <Collapse in={!isMinutesDocumentAlertDismissed}>
                    <Alert
                      severity="info"
                      onClose={handleCloseDocumentAlert}
                      sx={{
                        mb: 0.5
                      }}
                    >
                      <Box display="inline">{t(keyPrefix + '.minutesDocumentsAlertText') + ' '}</Box>
                      {!isBeingReviewedInsideMeeting && (
                        <Link
                          component={RouterLink}
                          sx={{
                            cursor: 'pointer',
                            color: 'link.main',
                            verticalAlign: 'inherit',
                            textDecoration: 'none'
                          }}
                          to={{
                            pathname: `/meetings/${topic.meetingId}/review/agenda`,
                            state: { activePath: '/pending-review' }
                          }}
                          type="button"
                        >
                          {t('common:viewMeeting')}
                        </Link>
                      )}
                    </Alert>
                  </Collapse>
                )}
                <DocumentList
                  isEditable={isEditable}
                  path={`/meetings/${topic.meetingId}/topics/${topic.id}/minutes-documents`}
                />
              </Stack>
            ) : (
              hasDocuments && (
                //MINUTES AND TO SIGN PAGE - approved topic
                <DocumentList
                  isEditable={false}
                  path={`/approved-topics/${topic.id}/documents`}
                  isAccordion={true}
                  accordionOptions={{
                    defaultExpanded: true,
                    documentsVisible: 0
                  }}
                />
              )
            ))}
        </FormControllerTemp>
      </Box>
    </>
  );
};
