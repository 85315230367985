import { useLocale } from '@koopajs/react';
import { Stack } from '@mui/material';
import { CommitteeTypeFacetSidebar } from '../Facets/CommitteeTypeFacetSidebar';
import { DateRangeFacetSidebar } from '../Facets/DateRangeFacetSidebar';
import { SortBySidebar } from '../Facets/SortBySidebar';
import { ResolutionOutcomeFacetSidebar } from '../Facets/ResolutionOutcomeFacetSidebar';

export const FacetListResolutionsSidebar: React.FC = () => {
  const path = '/resolutions';

  const { t } = useLocale();

  return (
    <Stack spacing={3}>
      <CommitteeTypeFacetSidebar path={path} facetKey="committeeId.keyword" />
      <DateRangeFacetSidebar path={path} facetKey="resolutionOutcome.recordedAt" />
      <ResolutionOutcomeFacetSidebar path={path} />

      <SortBySidebar
        sortOptions={[
          {
            key: 'resolutionOutcome.recordedAt',
            direction: 'asc',
            label: t('Sidebars.SortBy.labelOldest')
          },
          {
            key: 'resolutionOutcome.recordedAt',
            direction: 'desc',
            label: t('Sidebars.SortBy.labelNewest')
          }
        ]}
      />
    </Stack>
  );
};
