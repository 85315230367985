import { AddDocumentButton } from 'components/DocumentList/AddDocumentButton';
import { Stack } from '@mui/material';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';
import { AddLinkButton } from 'components/DocumentList/AddLinkButton';

interface IAddDocument {
  liveMeeting: IUseLiveMeetingResponse;
  isProcessing: boolean;
  setIsProcessing: (isProcessing: boolean) => void;
}

export const AddDocument: React.FC<IAddDocument> = (props) => {
  const { liveMeeting, isProcessing, setIsProcessing } = props;
  return (
    <Stack direction="row">
      <AddLinkButton
        path={`/meetings/${liveMeeting.meetingId}/topics/${liveMeeting.currentTopicId}/minutes-documents`}
        isIconButton={true}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />
      <AddDocumentButton
        path={`/meetings/${liveMeeting.meetingId}/topics/${liveMeeting.currentTopicId}/minutes-documents`}
        isIconButton={true}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />
    </Stack>
  );
};
