import { List } from '@mui/material';
import { Typography, Box, ListItem } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { Ref, useLocale } from '@koopajs/react';
import { IMeetingRef } from 'types';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

interface IApprovedMinutesListProps {
  approvedMinutes?: { isApproved: boolean; meetingId: string }[];
  isViewPrint?: boolean;
}

export const ApprovedMinutesList: React.FC<IApprovedMinutesListProps> = (props) => {
  const { approvedMinutes, isViewPrint } = props;

  const { t, locale } = useLocale();

  const filteredApprovedMinutes = approvedMinutes?.filter((m) => m.isApproved);

  return (
    <>
      {filteredApprovedMinutes && filteredApprovedMinutes.length > 0 && (
        <Box>
          <Typography sx={{ opacity: 0.8, mt: !isViewPrint ? 2 : null }}>
            {t('Components.ApprovedMinutesList.pastMinutesAdopted')}:
          </Typography>
          <List sx={{ mt: '0px!important', pt: 0.5 }}>
            {filteredApprovedMinutes.map((meeting) => {
              return (
                <ListItem
                  disableGutters
                  key={meeting.meetingId}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: 0.5,
                    pt: 0
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    {!isViewPrint && <AccessTime sx={{ mr: '8px' }} />}
                    <Ref content={`ref:meeting:${meeting.meetingId}`}>
                      {(item) => {
                        const minutes = item as IMeetingRef;
                        const committeeType = useCommitteeName(minutes?.committeeId || '');
                        return (
                          <>
                            <Typography>
                              {minutes?.startDateTime &&
                                formatDate({
                                  isoString: minutes.startDateTime,
                                  format: 'DATE_FULL',
                                  locale
                                })}
                              {' | '}
                              {committeeType}
                              {' | '}
                              {minutes?.label}
                            </Typography>
                          </>
                        );
                      }}
                    </Ref>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
};
