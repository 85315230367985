import { VoteOutcome } from 'types';
import { CheckCircle, Cancel, RemoveCircle } from '@mui/icons-material';

interface IResolutionOutcomeIconProps {
  voteOutcome: VoteOutcome | undefined;
}

export const ResolutionOutcomeIcon: React.FC<IResolutionOutcomeIconProps> = (props) => {
  const { voteOutcome } = props;

  if (voteOutcome === 'approved' || voteOutcome === 'approvedWithMods') {
    return <CheckCircle color="success" sx={{ mr: 1.5 }} />;
  }
  if (voteOutcome === 'declined') {
    return <Cancel color="error" sx={{ mr: 1.5 }} />;
  }
  if (voteOutcome === 'reported') {
    return <RemoveCircle color="warning" sx={{ mr: 1.5 }} />;
  }

  return null;
};
