import { CommitteeMemberRole } from 'types';
import { Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useCallback } from 'react';
import { useLocale } from '@koopajs/react';
import { PermissionChipsMobile } from 'components/Organization/PermissionChipsMobile';
import { theme } from 'components/Core/theme';
interface ICommitteeMemberAccordionProps {
  permissions: { role: CommitteeMemberRole; isOptional?: boolean }[];
}

export const PermissionTableMobileAccordion: React.FC<ICommitteeMemberAccordionProps> = (props) => {
  const { permissions } = props;
  const { t } = useLocale();

  const [isAccordionExpanded, setAccordionExpanded] = useState(false);

  const handleChangeAccordion = useCallback((event: React.SyntheticEvent, expanded: boolean) => {
    setAccordionExpanded(expanded);
  }, []);

  return (
    <Accordion
      elevation={0}
      disableGutters
      onChange={handleChangeAccordion}
      sx={{
        mt: 3,
        borderTop: `1px solid ${theme.palette.customGrey.light}`,
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          border: 'none'
        }}
      >
        <Stack direction="column" alignItems="start">
          {!isAccordionExpanded && (
            <Typography variant="body2" color="grey" sx={{ pt: 2 }}>
              {t('common:numberPermissions', {
                count: permissions.length || 0
              })}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, py: 3 }}>
        <PermissionChipsMobile roles={permissions} />
      </AccordionDetails>
    </Accordion>
  );
};
