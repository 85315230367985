import { IMeeting } from 'types';
import { FormContext, useLocale, useResourceList } from '@koopajs/react';
import { Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Api } from '@koopajs/app';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { AnotherMeetingRadioGroup } from './AnotherMeetingRadioGroup';
import { Controller } from 'react-hook-form';

interface ICopyAgendaFieldProps {
  lastMeetingFromCommittee?: IMeeting;
}

export const CopyAgendaField: React.FC<ICopyAgendaFieldProps> = (props) => {
  const { lastMeetingFromCommittee } = props;

  const { t } = useLocale();
  const keyPrefix = 'MeetingEdit.MeetingForm.RadioCopyAgenda';

  const context = useContext(FormContext);

  const committeeId = context.form?.watch('committeeId');

  const lastMeetingType = useCommitteeName(committeeId);

  const { resources: recentMeetings } = useResourceList<IMeeting>({
    path: '/meeting-templates',
    searchParams: { size: 50, sort: '$createdAt:DESC' }
  });

  const templateType = context.form?.watch('templateType');

  useEffect(() => {
    // updates last meeting id in form with latest value
    context.form?.setValue('lastMeetingId', lastMeetingFromCommittee?.id);

    // resets "template type" field to "none" if there's no last meeting
    if (templateType === 'last-meeting' && !lastMeetingFromCommittee?.id) {
      context.form?.setValue('templateType', 'none');
    }
  }, [lastMeetingFromCommittee?.id]);

  const defaultValue = 'none';

  return (
    <>
      {recentMeetings && recentMeetings.length > 0 && (
        <>
          <FormControl sx={{ width: '100%' }} component="fieldset">
            <FormLabel id="copy-agenda-field">
              <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t(keyPrefix + '.label')}
              </Typography>
            </FormLabel>
            <Controller
              // eslint-disable-next-line react/jsx-no-bind
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby="copy-agenda-field"
                  value={field.value}
                  name={field.name || defaultValue}
                  onChange={field.onChange}
                >
                  {/* last meeting of type */}
                  {lastMeetingFromCommittee && (
                    <>
                      <FormControlLabel
                        value="last-meeting"
                        control={<Radio />}
                        label={t(keyPrefix + '.optionLastMeeting', { meetingType: lastMeetingType })}
                      />
                    </>
                  )}
                  {/* another meeting */}
                  <FormControlLabel
                    value="another-meeting"
                    control={<Radio />}
                    label={t(keyPrefix + '.optionAnotherMeeting')}
                  />
                  {templateType === 'another-meeting' && (
                    <AnotherMeetingRadioGroup recentMeetings={recentMeetings} />
                  )}
                  {/* no template */}
                  <FormControlLabel value="none" control={<Radio />} label={t(keyPrefix + '.optionNone')} />
                </RadioGroup>
              )}
              name="templateType"
              defaultValue={defaultValue}
              control={context?.form?.control}
            />
          </FormControl>
        </>
      )}
    </>
  );
};
