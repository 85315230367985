import { Dialog } from '@koopajs/mui';
import { useComponentVisibility, useResourceCreate } from '@koopajs/react';
import { Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { TextField } from 'components/temp/TextFieldTemp';
import { useCallback } from 'react';

export const AddLinkDialog: React.FC = () => {
  const { t } = useLocale();

  const keyPrefix = 'Dialogs.AddLinkDialog';

  const addLinkDialog = useComponentVisibility('addLink');
  const { path } = addLinkDialog.getContext() as {
    path: string;
  };

  const { createResource } = useResourceCreate({
    path: path
  });

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const formData = data as {
        attachedFile: { name?: string; uri: string; format?: string; sizeBytes?: number };
      };

      if (!/^(?:f|ht)tps?\:\/\//.test(formData.attachedFile.uri)) {
        formData.attachedFile.uri = 'http://' + formData.attachedFile.uri;
      }

      if (!formData.attachedFile.name) {
        formData.attachedFile.name = formData.attachedFile.uri;
      }

      formData.attachedFile.format = 'url';
      formData.attachedFile.sizeBytes = 0;

      const isSuccess = await createResource(formData);

      return isSuccess;
    },
    [createResource]
  );

  return (
    <Dialog.Form
      dialogKey="addLink"
      onSubmit={handleSubmit}
      i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
      maxWidth="md"
      leftAction={<Button onClick={addLinkDialog.setHidden}>{t('common:labelCancel')}</Button>}
      dialogProps={{ PaperProps: { 'data-cy': 'add-link-dialog' }, className: 'rr-block' }}
    >
      <TextField
        name="attachedFile.name"
        isOptional={true}
        i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
        validationRules={{
          maxLength: 100
        }}
      />
      <TextField
        name="attachedFile.uri"
        i18n={{ keyPrefix: keyPrefix + '.FieldUrl' }}
        validationRules={{
          pattern:
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
        }}
      />
    </Dialog.Form>
  );
};
