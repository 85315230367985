import { Loading } from '@koopajs/mui';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

interface IDelayComponentRenderProps {
  delay?: number;
  component: React.ReactNode;
}

export const DelayComponentRender: React.FC<IDelayComponentRenderProps> = (props) => {
  const { delay = 1250, component } = props;

  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsDisplayed(true);
    }, delay);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isDisplayed ? (
        component
      ) : (
        <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        </Stack>
      )}
    </>
  );
};
