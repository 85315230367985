import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { Typography, Button, Box, Container, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocale } from '@koopajs/react';
import { useResourceDelete } from '@koopajs/react';
import { Trans } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { useConfirmDelete } from 'components/hooks/useConfirmDelete';

interface IDeleteResolution {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}
export const i18nTransComponents: { [k: string]: React.ReactElement } = { div: <div /> };

export const DeleteResolution: React.FC<IDeleteResolution> = (props) => {
  const { id, isOpen, onClose } = props;
  const { deleteResource: deleteResolution } = useResourceDelete({ path: '/resolutions', id });

  const { t } = useLocale();
  const history = useHistory();
  const keyPrefix = 'Modals.ModalDeleteResolution';

  const { hasUserTypedDeleteConfirmation, renderConfirmDeleteField, resetConfirmDeleteField } =
    useConfirmDelete({
      i18nKeyPrefix: keyPrefix + '.ConfirmDeleteField'
    });

  const handleCloseModal = useCallback(() => {
    onClose();
    resetConfirmDeleteField();
  }, []);

  const handleDeleteResolution = useCallback(async () => {
    const res = await deleteResolution();

    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (res) {
      history.push('/pending-review');
    }
  }, [id]);

  return (
    <Dialog.View
      isOpen={isOpen}
      onClose={handleCloseModal}
      forcedFullScreen={false}
      dialogProps={{ PaperProps: { 'data-cy': 'delete-resolution-dialog' } }}
    >
      <Container
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 3 }}
      >
        <Typography variant="h6">{t(keyPrefix + '.title')}</Typography>

        <Typography sx={{ whiteSpace: 'pre-line' }}>{t(keyPrefix + '.description')}</Typography>

        {renderConfirmDeleteField()}

        <Stack gap={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
          <Button sx={{ minWidth: '275px', flex: 'none' }} variant="outlined" onClick={handleCloseModal}>
            {t('common:labelCancel')}
          </Button>
          <Button
            color="error"
            sx={{ minWidth: '275px', flex: 'none' }}
            data-cy="delete-resolution-dialog_delete-button"
            variant="contained"
            onClick={handleDeleteResolution}
            disabled={!hasUserTypedDeleteConfirmation}
          >
            {t(keyPrefix + '.labelDelete')}
          </Button>
        </Stack>
      </Container>
    </Dialog.View>
  );
};
