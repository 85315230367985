import { useLocale } from '@koopajs/react';
import { Box } from '@mui/material';
import { Facet } from 'components/Filters/Facet';
import { SortByFacet } from 'components/Filters/SortByFacet';
import { useResourceList } from '@koopajs/react';
import { ICommittee } from 'types';
import { DateRangeFacet } from 'components/Filters/DateRangeFacet';

export const FacetListMinutesInline: React.FC = () => {
  const path = '/minutes';

  const { t } = useLocale();

  const keyPrefix = 'Components.Facets';

  const committees = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const committeesOptions =
    committees?.resources.map((committee) => ({ id: committee.id, label: committee.name })) || [];

  return (
    <Box sx={{ display: { xs: 'none', lg: 'flex' }, gap: 2 }}>
      <Facet
        path={path}
        facetKey="committeeId.keyword"
        label={t(keyPrefix + '.meetingType')}
        options={committeesOptions}
      />
      <DateRangeFacet path={path} facetKey="startDateTime" />

      <SortByFacet
        sortOptions={[
          {
            key: 'startDateTime',
            direction: 'asc',
            label: t(keyPrefix + '.SortBy.labelOldest')
          },
          {
            key: 'startDateTime',
            direction: 'desc',
            label: t(keyPrefix + '.SortBy.labelNewest')
          }
        ]}
      />
    </Box>
  );
};
