import { LoadingButton } from '@mui/lab';
import { Box, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { useComponentVisibility, useLocale, useResourceCreate } from '@koopajs/react';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { useCallback, useEffect } from 'react';

interface IAddLinkButtonProps {
  isIconButton?: boolean;
  path: string;
  isProcessing?: boolean;
  setIsProcessing?: (isProcessing: boolean) => void;
}

export const AddLinkButton: React.FC<IAddLinkButtonProps> = (props) => {
  const { path, isIconButton, isProcessing: isProcessingOtherDocuments, setIsProcessing } = props;

  const { t } = useLocale();

  const { isProcessing } = useResourceCreate({
    path: path
  });

  useEffect(() => {
    if (setIsProcessing) {
      setIsProcessing(isProcessing);
    }
  }, [isProcessing, setIsProcessing]);

  const addLinkDialog = useComponentVisibility('addLink');

  const handleShowAddLinkDialog = useCallback(() => {
    return () => {
      addLinkDialog.setVisibleWithContext({
        path: path
      });
    };
  }, [addLinkDialog, path]);

  return (
    <Box>
      {isIconButton ? (
        <Tooltip title={t('common:labelAddLink')}>
          <IconButton
            onClick={handleShowAddLinkDialog()}
            disabled={isProcessing || isProcessingOtherDocuments}
            data-cy="add-link-button"
          >
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : <AddLinkIcon />}
          </IconButton>
        </Tooltip>
      ) : (
        <LoadingButton
          onClick={handleShowAddLinkDialog()}
          startIcon={<AddLinkIcon />}
          loading={isProcessing}
          loadingPosition="start"
          data-cy="add-link-button"
          disabled={isProcessingOtherDocuments}
        >
          <span>{t('common:labelAddLink')}</span>
        </LoadingButton>
      )}
    </Box>
  );
};
